import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import PartnerProgramCard from '../PartnerProgramCard';

import { PartnersProgramsListProps } from './types';

function PartnersProgramsList({ programs }: PartnersProgramsListProps) {
  if (!programs || programs.length === 0) {
    return null;
  }

  return (
    <Component>
      {programs.map((program, index) => (
        <PartnerProgramCard key={index} {...program} />
      ))}
    </Component>
  );
}

export default PartnersProgramsList;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;

  ${media.tablet(
    css`
      grid-template-columns: repeat(3, 1fr);
    `
  )}

  ${media.mobile(
    css`
      grid-template-columns: repeat(1, 1fr);
    `
  )}
`;
