import React from 'react';
import styled, { css } from 'styled-components';

import { useTheLineupData } from '@/modules/TheLineup/hooks';
import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/TheLineup/common.styles';
import { TextContent } from '@/components/LandingWidgets';

function BottomText() {
  const {
    templateFields: { bottomText },
  } = useTheLineupData();

  if (!bottomText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <TextContent text={bottomText} />
      </ContentContainer>
    </Component>
  );
}

export default BottomText;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
