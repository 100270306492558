import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useHowToData } from '@/modules/HowTo/HowTo.hooks';
import Faq from '@/components/Faq';

function QuestionsAndAnswers() {
  const { howToTitle, howToQuestionsAndAnswers } = useHowToData();

  if (!howToQuestionsAndAnswers || howToQuestionsAndAnswers.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{howToTitle}</Title>

        <FaqWrapper>
          <Faq
            faq={howToQuestionsAndAnswers.map(({ question, answer }) => ({
              question: question ?? '',
              answer: answer ?? '',
            }))}
          />
        </FaqWrapper>
      </ContentContainerSmall>
    </Component>
  );
}

export default QuestionsAndAnswers;

const Component = styled.section`
  padding: 60px 0;

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}

  ${ContentContainerSmall} {
    max-width: 870px;
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin: 0;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const FaqWrapper = styled.div`
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;
