import Banner from '@/components/Redesign/Banner';
import { colors } from '@/constants/theme';
import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';

function ChatBanner() {
  const {
    templateFields: { chatTitle, chatImage },
  } = useVerifiedAtlantmLandingData();

  if (!chatImage || !chatTitle) {
    return null;
  }

  const handleOpenChats = () => {
    const widget = document.querySelector<HTMLElement>(
      '.b24-widget-button-inner-container'
    );

    if (widget) {
      widget.click();
    }
  };

  return (
    <Banner
      title={chatTitle}
      image={chatImage}
      color={colors.pinkGradient}
      size={'small'}
      orientation={'horizontal'}
      onClick={handleOpenChats}
    />
  );
}

export default ChatBanner;
