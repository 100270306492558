import { Nullable } from '@tager/web-core';

import { OptionType } from '@/typings/common';
import { useTypedSelector } from '@/store/store';
import { getBrandsAndModelsItems } from '@/store/reducers/catalog';
import { getYearsOptions } from '@/utils/date';

import { CarSelectsOptions } from './types';

export const useBrandsAndModelsOptions = (
  brand: Nullable<OptionType>,
  isUniteZeekr?: boolean
): Nullable<CarSelectsOptions> => {
  const brandsAndModels = useTypedSelector(getBrandsAndModelsItems);

  if (!brandsAndModels || brandsAndModels.length === 0) {
    return null;
  }

  let brands: OptionType[];

  if (isUniteZeekr) {
    brands = brandsAndModels
      .filter((brand) => brand.name !== 'Zeekr CN')
      .map((brand) =>
        brand.name === 'Zeekr EU'
          ? {
              label: 'Zeekr',
              value: 'Zeekr',
            }
          : {
              label: brand.name,
              value: brand.name,
            }
      );
  } else {
    brands = brandsAndModels.map(({ name }) => ({
      label: name,
      value: name,
    }));
  }

  const targetBrandName = brand?.label === 'Zeekr' ? 'Zeekr EU' : brand?.label;

  const models: OptionType[] =
    brandsAndModels
      .find(({ name }) => name === targetBrandName)
      ?.models.map(({ name }) => ({ label: name, value: name })) ?? [];

  return {
    brands,
    models,
    years: getYearsOptions(),
  };
};
