import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;
