import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { ServiceCardProps } from '@/components/ServiceCard/ServiceCard.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function ServiceCard({ image, description, title, onClick }: ServiceCardProps) {
  return (
    <Component>
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
      <Title>{title}</Title>
      <Description>{description}</Description>

      <Button onClick={onClick}>Записаться</Button>
    </Component>
  );
}

export default ServiceCard;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Image = styled(Picture)`
  position: relative;
  padding-top: 180px;

  ${media.tablet(css`
    padding-top: 100%;
  `)}

  img,
  picture {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Title = styled.div`
  margin-top: 30px;
  font-weight: 700;
  font-size: 21px;
  height: 50px;
  color: ${(props) => props.theme.black};

  @media (max-width: 1200px) {
    margin-top: 20px;
    font-size: 20px;
  }

  ${media.mobile(css`
    height: auto;
  `)}
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 40px;
  color: ${(props) => props.theme.blue100};

  @media (max-width: 1200px) {
    margin-top: 15px;
  }

  ${media.mobile(css`
    margin: 20px 0;
  `)}
`;

const Button = styled.button`
  align-self: flex-start;
  margin-top: auto;
  padding: 13px 60px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background-color: ${colors.main};
  border: 1px solid ${colors.main};
  color: ${colors.white};
  border-radius: 30px;
  transition: 0.3s;

  &:hover {
    border: 1px solid ${colors.main100};
    color: ${colors.white};
    background-color: ${colors.main100};
  }

  @media (max-width: 1200px) {
    padding: 10px 40px;
    font-size: 16px;
  }
`;
