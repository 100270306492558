import * as Yup from 'yup';

import { ConsultationFormTertiaryFields } from './types';

export const initialConsultationFormTertiaryValues: ConsultationFormTertiaryFields =
  {
    name: '',
    phone: '',
    message: '',
  };

export const validationConsultationFormTertiarySchema: Yup.SchemaOf<ConsultationFormTertiaryFields> =
  Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    message: Yup.string(),
  });
