import React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import SliderWrapper from '@/components/Redesign/SliderWrapper';

import AdvantageItem from '../AdvantageItem';

import { AdvantagesSliderProps } from './types';

function AdvantagesSlider({ advantages, isBig }: AdvantagesSliderProps) {
  return (
    <StyledSliderWrapper
      options={{
        spaceBetween: 20,
        withPagination: true,
      }}
    >
      {advantages.map((advantage, index) => (
        <SwiperSlide key={index}>
          <AdvantageItem {...advantage} isBig={isBig} />
        </SwiperSlide>
      ))}
    </StyledSliderWrapper>
  );
}

export default AdvantagesSlider;

const StyledSliderWrapper = styled(SliderWrapper)`
  .swiper-slide {
    height: auto;
  }
`;
