import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';

import Advantages from './components/Advantages';
import RateCar from './components/RateCar';

function RateAndAdvantages() {
  return (
    <Component>
      <ContentContainerSmall>
        <Inner>
          <Advantages />
          <Right>
            <RateCar />
          </Right>
        </Inner>
      </ContentContainerSmall>
    </Component>
  );
}

export default RateAndAdvantages;

const Component = styled.div`
  padding: 60px 0 80px;
  background: ${(props) => props.theme.gray};

  ${media.tabletSmall(css`
    padding: 0 0 60px;
  `)}
`;

const Right = styled.div`
  ${media.tabletSmall(css`
    margin-top: 40px;
  `)}
`;

const Inner = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    display: block;
  `)}
`;
