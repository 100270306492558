import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/HisunHome/common.styles';
import { useHisunHomeData } from '@/modules/HisunHome/hooks';
import { GuaranteeBanner } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

function Guarantee() {
  const { guaranteeImage, guaranteeMobileImage, guaranteeText } =
    useHisunHomeData();

  if (!guaranteeImage) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <GuaranteeBanner
          image={guaranteeImage}
          imageMobile={guaranteeMobileImage}
          text={guaranteeText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default Guarantee;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
