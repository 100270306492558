import React from 'react';
import styled from 'styled-components';

import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import {
  Title,
  ContentContainer,
} from '@/modules/FinanceLanding/common.styles';
import StepsCards from '@/components/StepsCards';

function HowItWorks() {
  const { howItWorksTitle, howItWorksSubtitle, howItWorksSteps } =
    useFinanceLandingData();

  if (!howItWorksSteps || howItWorksSteps.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{howItWorksTitle}</Title>
        <Subtitle>{howItWorksSubtitle}</Subtitle>

        <StepsCardsWrapper>
          <StepsCards
            id={2}
            steps={howItWorksSteps.map(({ image, description }) => ({
              image,
              text: description ?? '',
            }))}
            cols={3}
          />
        </StepsCardsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default HowItWorks;

const Component = styled.section`
  padding: 15px 0;
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.blue100};
`;

const StepsCardsWrapper = styled.div`
  margin-top: 20px;
`;
