import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { FirstPersonsItem } from '@/modules/Leadership/Leadership.types';
import QuoteIcon from '@/assets/svg/about/quote.svg';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function FirstPersonsCard({
  avatar,
  name,
  position,
  quote,
  quoteAuthor,
}: FirstPersonsItem) {
  return (
    <Component>
      <Left>
        <Image loading="lazy" {...convertThumbnailToPictureProps(avatar)} />
        <Info>
          <Name>{name}</Name>
          <Position>{position}</Position>
        </Info>
      </Left>
      <Right>
        <QuoteIcon />
        <Quote>{quote}</Quote>
        <QuoteAuthor>{quoteAuthor}</QuoteAuthor>
      </Right>
    </Component>
  );
}

export default FirstPersonsCard;

const Component = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
  `)}

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const Left = styled.div`
  display: flex;
  padding: 60px;
  flex: 1 1 66%;
  max-width: 66%;
  min-height: 333px;
  background-color: ${colors.main};

  ${media.tabletSmall(css`
    min-height: 240px;
    padding: 30px 20px 20px;
  `)}

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    padding-bottom: 50px;
  `)}
`;

const Image = styled(Picture)`
  flex: 0 0 156px;
  width: 156px;
  height: 156px;

  ${media.tabletSmall(css`
    flex: 0 0 66px;
    width: 66px;
    height: 66px;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  img {
    object-fit: cover;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;

  ${media.tabletSmall(css`
    max-width: 300px;
    margin-left: 20px;
  `)}

  ${media.mobile(css`
    max-width: 500px;
  `)}
`;

const Name = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${colors.white};

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Position = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  color: ${colors.gray200};

  ${media.tabletSmall(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  `)}
`;

const Right = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 60px 30px;
  width: calc(33.333% + 70px);
  min-height: 273px;
  margin-left: -60px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: ${colors.white};
  box-shadow: 0 5px 30px 0 rgb(80 95 121 / 10%);

  ${media.tabletSmall(css`
    padding: 50px 20px 20px;
    min-height: 200px;
  `)}

  ${media.mobile(css`
    width: auto;
    margin: -30px 20px 0;
  `)}

  svg {
    position: absolute;
    top: 30px;
    width: 44px;
    height: 40px;

    ${media.tabletSmall(css`
      top: 20px;
      width: 23px;
      height: 20px;
    `)}
  }
`;

const Quote = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const QuoteAuthor = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  opacity: 0.7;
  color: ${colors.blue600};
`;
