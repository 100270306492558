import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { BenefitCard } from '@/components/CarModelLandingWidgets';

import { FeatureListSecondaryProps } from './types';

export function FeatureListSecondary({ features }: FeatureListSecondaryProps) {
  if (!features || features.length === 0) {
    return null;
  }

  return (
    <Component>
      {features.map((feature, index) => (
        <BenefitCard key={index} {...feature} />
      ))}
    </Component>
  );
}

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  ${media.tabletSmall(css`
    grid-template-columns: repeat(3, 1fr);
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
