import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import Hero from './components/Hero';
import Links from './components/Links';
import HeroForm from './components/HeroForm';

function Header() {
  return (
    <Component>
      <Hero />
      <Links />
      <HeroForm />
    </Component>
  );
}

export default Header;

const Component = styled.section`
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 0;
  `)}
`;
