import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { SliderWrapper } from '@/components/LandingWidgets';

import PartnerProgramCard from '../PartnerProgramCard';
import { Component as PartnerProgramCardComponent } from '../PartnerProgramCard/PartnerProgramCard';

import { PartnersProgramsSliderProps } from './types';

function PartnersProgramsSlider({ id, programs }: PartnersProgramsSliderProps) {
  if (!programs || programs.length === 0) {
    return null;
  }

  return (
    <StyledSliderWrapper
      id={id}
      options={{ withoutNavs: true, spaceBetween: 30 }}
    >
      {programs.map((program, index) => (
        <SwiperSlide key={index}>
          <PartnerProgramCard {...program} />
        </SwiperSlide>
      ))}
    </StyledSliderWrapper>
  );
}

export default PartnersProgramsSlider;

const StyledSliderWrapper = styled(SliderWrapper)`
  .swiper-slide {
    height: auto;
  }

  ${PartnerProgramCardComponent} {
    height: 100%;
  }
`;
