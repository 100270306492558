import React from 'react';
import styled from 'styled-components';

import { ContentContainer } from '@/modules/HelpOnTheRoad/common.styles';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import ConsultationFormSecondary from '@/components/Forms/ConsultationFormSecondary';
import { helpOnRoadCall } from '@/services/leads/leads-service';
import { ConsultationFormSecondaryFields } from '@/components/Forms/ConsultationFormSecondary/types';
import { googleEvent } from '@/utils/events';

function ConsultationForm() {
  const {
    formImage,
    formImageMobile,
    formTitle,
    formButtonText,
    formGoogleEvent,
  } = useHelpOnTheRoadData();

  if (!formImage || !formTitle || !formButtonText) {
    return null;
  }

  const sendConsultationForm = async (
    values: ConsultationFormSecondaryFields
  ): Promise<void> => {
    try {
      await helpOnRoadCall(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ConsultationFormSecondary
          image={formImage}
          imageMobile={formImageMobile}
          title={formTitle ?? ''}
          buttonText={formButtonText ?? ''}
          onSubmit={sendConsultationForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default ConsultationForm;

const Component = styled.section`
  padding: 30px 0;
`;
