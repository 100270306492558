import React from 'react';
import styled, { css } from 'styled-components';

import ServicesSlider from '@/components/ServicesSlider';
import { media } from '@/utils/mixin';
import {
  ContentContainer,
  Title,
} from '@/modules/ServiceBenefits/common.styles';
import { useServiceBenefitsData } from '@/modules/ServiceBenefits/hooks';

function Services() {
  const { servicesTitle, services } = useServiceBenefitsData();

  if (!services || services.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{servicesTitle}</Title>

        <ServicesSliderWrapper>
          <ServicesSlider
            id={3}
            items={services.map(({ image, title, link }) => ({
              image,
              title: title ?? '',
              link: link ?? '',
            }))}
          />
        </ServicesSliderWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Services;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const ServicesSliderWrapper = styled.div`
  margin-top: 20px;
`;
