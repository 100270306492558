import {
  submitTheLineupCar,
  submitTheLineupTestDrive,
} from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import {
  TheLineupCarParams,
  TheLineupTestDriveParams,
} from '@/services/leads/typings';

export const handleSubmitTheLineupCar = async (
  values: TheLineupCarParams,
  event: string
): Promise<void> => {
  try {
    await submitTheLineupCar(values).then(() => {
      if (event) {
        googleEvent(event);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const handleSubmitTheLineupTestDrive = async (
  values: TheLineupTestDriveParams,
  event: string
): Promise<void> => {
  try {
    await submitTheLineupTestDrive(values).then(() => {
      if (event) {
        googleEvent(event);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};
