import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { TableSectionMobileProps } from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/TableSectionMobile/types';

export const useTableParamsData = (
  packageIndex: number
): TableSectionMobileProps[] => {
  const { packagesDetails } = useHelpOnTheRoadData();

  if (!packagesDetails || packagesDetails.length === 0) {
    return [];
  }

  return packagesDetails
    .map(({ sectionName, sectionParams }) => ({
      title: sectionName ?? '',
      rows: sectionParams
        .map(({ param, value }) => {
          const currentValue = value ? value.split('\n')[packageIndex] : '';

          return {
            title:
              (currentValue === '+'
                ? param
                : currentValue === '-'
                ? ''
                : param) ?? '',
            param:
              currentValue === '+'
                ? ''
                : currentValue === '-'
                ? ''
                : currentValue,
          };
        })
        .filter(({ title }) => title !== ''),
    }))
    .filter(({ rows }) => rows.length !== 0);
};
