import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/ServiceValuationLanding/common.styles';
import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import { media } from '@/utils/mixin';
import DetailedCarSellBanner from '@/components/DetailedCarSellBanner';

function Cabinet() {
  const {
    templateFields: {
      cabinetImage,
      cabinetTitle,
      cabinetText,
      cabinetButtonTitle,
      cabinetButtonLink,
    },
  } = useServiceValuationLandingData();

  if (!cabinetTitle || !cabinetButtonTitle || !cabinetButtonLink) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <DetailedCarSellBanner
          image={cabinetImage}
          title={cabinetTitle}
          description={cabinetText ?? ''}
          buttonTitle={cabinetButtonTitle}
          link={cabinetButtonLink}
        />
      </ContentContainer>
    </Component>
  );
}

export default Cabinet;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
