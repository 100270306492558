import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import { HeroBannerProps } from './types';

function HeroBanner({ image, imageMobile }: HeroBannerProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
      />
    </Component>
  );
}

export default HeroBanner;

const Component = styled.div`
  position: relative;
  padding-top: 30.571%;

  ${media.mobile(css`
    padding-top: 39.114%;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
