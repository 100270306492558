import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';

import SliderWrapper from '@/components/Redesign/SliderWrapper';
import Step from '@/components/Redesign/Steps/components/Step';

import { StepsSliderProps } from './types';

function StepsSlider({ steps }: StepsSliderProps) {
  const isTablet = useMedia('(max-width: 1259px)');
  return (
    <SliderWrapper
      options={{
        spaceBetween: isTablet ? 10 : 20,
        slidesPerView: isTablet ? 1.5 : steps.length > 5 ? 5.5 : steps.length,
      }}
    >
      {steps.map((step, index) => (
        <SwiperSlide style={{ height: 'auto' }} key={index}>
          <Step {...step} counter={index + 1} />
        </SwiperSlide>
      ))}
    </SliderWrapper>
  );
}

export default StepsSlider;
