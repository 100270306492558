import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Atlantm30/Atlantm30.styles';
import { colors } from '@/constants/theme';
import { useAtlantm30Data } from '@/modules/Atlantm30/Atlantm30.hooks';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import StarsImage from '@/assets/svg/atlantm30/stars.svg';
import atlantLogo from '@/assets/images/atlantm30/atlant-logo.webp';

function PromoIntro() {
  const { promoIntroContent } = useAtlantm30Data();

  if (!promoIntroContent) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Content>
          <Left dangerouslySetInnerHTML={{ __html: promoIntroContent }} />
          <Right>
            <Logo loading="lazy" src={atlantLogo} />
            <StyledStarsImage />
          </Right>
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default PromoIntro;

const Component = styled.section`
  padding: 60px 0;
  background: ${colors.white};
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  max-width: 430px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: ${colors.blue700};

    ${media.tabletSmall(css`
      font-size: 24px;
      line-height: 32px;
    `)}
  }
  
  p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    
    
  }
}
`;

const Right = styled.div`
  position: relative;
  margin-left: 20px;

  ${media.mobile(css`
    margin: 50px 0 0;
  `)}
`;

const Logo = styled(Picture)`
  width: 100%;
  height: 100%;
`;

const StyledStarsImage = styled(StarsImage)`
  position: absolute;
  width: 8vw;
  right: 36%;
  top: -45px;

  ${media.mobile(css`
    width: 12vw;
  `)}
`;
