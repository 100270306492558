import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { SubscriptionTypeItem } from '@/modules/Subscription/Subscription.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props extends SubscriptionTypeItem {
  isBig?: boolean;
}

function SubscriptionType({ title, image, isBig }: Props) {
  return (
    <Component isBig={isBig}>
      <Header isBig={isBig}>
        <Title isBig={isBig}>{title}</Title>
      </Header>

      <Image
        loading="lazy"
        isBig={isBig}
        {...convertThumbnailToPictureProps(image)}
      />
    </Component>
  );
}

export default SubscriptionType;

const Component = styled.div<{ isBig?: Props['isBig'] }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 15px 15px 0 0;
  background: #2880ff;

  &:not(:first-child) {
    margin-left: 40px;

    ${media.tabletSmall(css`
      margin-left: 20px;
    `)}

    ${media.mobile(css`
      margin-left: 4px;
    `)}
  }

  ${({ isBig }) =>
    isBig &&
    css`
      min-width: 316px;
      background: ${colors.white};
      box-shadow: 0 0 20px rgba(4, 20, 48, 0.25);

      ${media.tabletSmall(css`
        min-width: 250px;
      `)}

      ${media.mobile(css`
        min-width: 125px;
      `)}
    `}
`;

const Header = styled.div<{ isBig?: Props['isBig'] }>`
  width: 100%;
  padding: 12px 0 7px;
  border-radius: 15px 15px 0 0;
  background: #0050c5;

  ${media.mobile(css`
    padding: 7px 0;
  `)}

  ${({ isBig }) =>
    isBig &&
    css`
      padding: 16px 0 17px;
      background: #003b91;

      ${media.mobile(css`
        padding: 9px 0;
      `)}
    `}
`;

const Title = styled.p<{ isBig?: Props['isBig'] }>`
  font-weight: 700;
  font-size: 30px;
  line-height: 52px;
  text-align: center;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 16px;
    line-height: normal;
  `)}

  ${({ isBig }) =>
    isBig &&
    css`
      font-size: 40px;

      ${media.mobile(css`
        font-size: 20px;
        line-height: normal;
      `)}
    `}
`;

const Image = styled(Picture)<{ isBig?: Props['isBig'] }>`
  margin-top: 22px;
  width: 100%;
  max-width: 228px;
  height: 100%;
  max-height: 129px;

  ${media.mobile(css`
    margin-top: 8px;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  ${({ isBig }) =>
    isBig &&
    css`
      max-width: 258px;
      max-height: 146px;
    `}
`;
