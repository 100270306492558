import React from 'react';
import styled, { css } from 'styled-components';

import FaqAccordion from '@/components/Redesign/FaqAccordion';
import { useEvaluationRedemptionData } from '@/modules/EvaluationRedemption/hooks';
import {
  ContentContainer,
  Title,
} from '@/modules/EvaluationRedemption/common.styles';
import { media } from '@/utils/mixin';

function Faq() {
  const {
    templateFields: { questionItems, questionTitle },
  } = useEvaluationRedemptionData();

  if (!questionItems || !questionTitle || questionItems.length === 0) {
    return null;
  }

  const questions = questionItems.map(({ question, answer }) => ({
    question: question ?? '',
    answer: answer ?? '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Title>{questionTitle ?? ''}</Title>
        <FaqAccordionWrapper>
          <FaqAccordion questions={questions} />
        </FaqAccordionWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Faq;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)};
`;

const FaqAccordionWrapper = styled.div`
  margin-top: 20px;
`;
