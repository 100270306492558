import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Header from './components/Header';
import Main from './components/Main';

export function CreditLeasing() {
  return (
    <Layout>
      <Component>
        <Header />
        <Main />
      </Component>
    </Layout>
  );
}

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
