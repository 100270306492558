import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { StepsProps } from './types';

function Steps({ steps, activeStep }: StepsProps) {
  if (!steps || steps.length === 0) {
    return null;
  }

  return (
    <Component>
      {steps.map((step, index) => (
        <React.Fragment>
          <Step
            key={index}
            isActive={step === activeStep}
            isDisabled={step !== activeStep && step > activeStep}
            isFinished={step !== activeStep && step < activeStep}
          >
            {step}
          </Step>
          {index !== steps.length - 1 && <Line />}
        </React.Fragment>
      ))}
    </Component>
  );
}

export default Steps;

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const Step = styled.div<{
  isActive: boolean;
  isDisabled: boolean;
  isFinished: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 50px;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${(props) => props.theme.black200};
      border: 1px solid ${colors.main};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${colors.gray500};
      border: 1px solid ${colors.gray500};
    `}

  ${({ isFinished }) =>
    isFinished &&
    css`
      color: ${colors.white};
      background: ${colors.main};
      border: 1px solid ${colors.main};
    `}
`;

const Line = styled.div`
  width: 100px;
  margin: 0 10px;
  padding-top: 1px;
  border-top: 1px dashed ${colors.gray500};
`;
