import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import RefitTypesList from './components/RefitTypesList';
import RefitTypesSlider from './components/RefitTypesSlider';
import { RefitTypesProps } from './types';

function RefitTypes({ title, refitData }: RefitTypesProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <Title>{title}</Title>

      {isTablet ? (
        <RefitTypesSlider refitData={refitData} />
      ) : (
        <RefitTypesList refitData={refitData} />
      )}
    </Component>
  );
}

export default RefitTypes;

const Component = styled.div``;

const Title = styled.h2`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 28px;
  `)}
`;
