import React from 'react';
import styled from 'styled-components';

import { useTokensLandingData } from '@/modules/TokensLanding/hooks';
import { Title, ContentContainer } from '@/modules/TokensLanding/common.styles';
import StepsCards from '@/components/StepsCards';

function HowItWorks() {
  const { howItWorksTitle, howItWorksSteps } = useTokensLandingData();

  if (!howItWorksSteps || howItWorksSteps.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{howItWorksTitle}</Title>

        <StepsCardsWrapper>
          <StepsCards
            id={2}
            steps={howItWorksSteps.map(({ image, description }) => ({
              image,
              text: description ?? '',
            }))}
            cols={3}
          />
        </StepsCardsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default HowItWorks;

const Component = styled.section`
  padding: 15px 0;
`;

const StepsCardsWrapper = styled.div`
  margin-top: 20px;
`;
