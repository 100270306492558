import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { TippyPopupProps } from './types';

function TippyPopup({ children }: TippyPopupProps) {
  return <Component>{children}</Component>;
}

export default TippyPopup;

const Component = styled.div`
  padding: 10px 20px;
  background: ${colors.white};
  border-radius: 5px;
  box-shadow: ${colors.blackShadow};
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-transform: none;
  color: ${colors.black};

  ${media.tablet(css`
    padding: 10px;
  `)}
`;
