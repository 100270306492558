import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import Static from '@/components/Static';
import { media } from '@/utils/mixin';
import Layout from '@/layout';

function DefaultTemplate() {
  const page = useCurrentPage();
  const title = page.title;
  const body = page?.body ?? '';

  return (
    <Layout>
      <Component>
        <Title>{title}</Title>
        <Static body={body} />
      </Component>
    </Layout>
  );
}

export default DefaultTemplate;

const Component = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 90px auto;
  background: ${(props) => props.theme.white};

  ${media.tablet(css`
    margin: 60px 0;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    margin: 20px 0;
  `)}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin: 0 0 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 20px;
  `)}
`;
