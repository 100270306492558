import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/B2bHome/common.styles';
import { media } from '@/utils/mixin';
import { SloganPrimary } from '@/components/LandingWidgets';
import { useB2bHomeData } from '@/modules/B2bHome/hooks';

function SloganText() {
  const { sloganText } = useB2bHomeData();

  if (!sloganText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <SloganPrimary text={sloganText} />
      </ContentContainer>
    </Component>
  );
}

export default SloganText;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
