import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Questions from './components/Questions';
import MayLike from './components/MayLike';
import Banner from './components/Banner';
import Benefit from './components/Benefit';
import Consultation from './components/Consultation';
import Remember from './components/Remember';

function SpecialOffer() {
  return (
    <Layout>
      <Component>
        <Banner />
        <Benefit />
        <MayLike />
        <Remember />
        <Consultation />
        <Questions />
      </Component>
    </Layout>
  );
}

export default SpecialOffer;

const Component = styled.div`
  background: ${(props) => props.theme.gray};
`;
