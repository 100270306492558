import React from 'react';
import styled from 'styled-components';
import Script from 'next/script';

import Layout from '@/layout';

import Hero from './components/Hero';
import About from './components/About';
import Benefits from './components/Benefits';
import Insurance from './components/Insurance';
import Form from './components/Form';

function VedaemLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <About />
        <Benefits />
        <Insurance />
        <Form />
      </Component>

      <Script
        id="vedaem-calculator-widget"
        src="https://vedaem.by/widget/widget.js"
        strategy="beforeInteractive"
      />

      <Script
        id="vedaem-widget-init"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.vedaemWidget = {
      UTM: 'utm_source=vijet&utm_medium=cpa&utm_campaign=atlantm'
    }`,
        }}
      />
    </Layout>
  );
}

export default VedaemLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
