import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';

import ModelsDesktop from './components/ModelsDesktop';
import ModelsMobile from './components/ModelsMobile';

function Models() {
  const isMobile = useMedia('(max-width: 1259px)');

  return (
    <Component>{!isMobile ? <ModelsDesktop /> : <ModelsMobile />}</Component>
  );
}

export default Models;

const Component = styled.section`
  z-index: 6;
  position: sticky;
  top: 0;
  margin-top: 30px;

  ${media.tablet(css`
    position: initial;
  `)}
`;
