import React from 'react';
import styled from 'styled-components';

import PackagesTabs from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/PackagesTabs';
import { useHelpOnTheRoadContext } from '@/modules/HelpOnTheRoad/hooks';

import { useTabsData } from './hooks';

function Tabs() {
  const tabs = useTabsData();
  const { selectedPackageTab, setSelectedPackageTab } =
    useHelpOnTheRoadContext();

  return (
    <Component>
      <PackagesTabsWrapper>
        <PackagesTabs
          activeTab={selectedPackageTab.value}
          tabs={tabs}
          onClick={setSelectedPackageTab}
        />
      </PackagesTabsWrapper>
    </Component>
  );
}

export default Tabs;

const Component = styled.div`
  position: relative;
  flex: 0 0 40px;
  max-width: 40px;
  margin-left: 10px;
`;

const PackagesTabsWrapper = styled.div`
  transform: rotate(90deg);
`;
