import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/TheLineup/common.styles';
import { Models } from '@/components/LandingWidgets';
import { useTheLineupData } from '@/modules/TheLineup/hooks';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { handleSubmitTheLineupCar } from '@/modules/TheLineup/common.helpers';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { Header as SliderWrapperHeader } from '@/components/LandingWidgets/components/SliderWrapper';

function ModelsList() {
  const openModal = useModal();
  const {
    templateFields: { modelsItems },
  } = useTheLineupData();

  if (!modelsItems || modelsItems.length === 0) {
    return null;
  }

  const handleOpenApplicationFormModal = (
    title: string,
    model: string,
    event: string
  ) => {
    openModal(
      ApplicationFormModal,
      {
        title,
        onSubmit: (values) =>
          handleSubmitTheLineupCar({ ...values, model }, event),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <Models
          models={modelsItems.map(
            ({
              image,
              imageMobile,
              name,
              price,
              description,
              specifications,
              preorderButtonText,
              preorderFormTitle,
              link,
              isNewTabLink,
              isInStock,
              googleEvent,
            }) => ({
              image,
              imageMobile,
              name: name ?? '',
              price: price ?? '',
              description: description ?? '',
              specifications:
                specifications && specifications.length !== 0
                  ? specifications.map(({ label, value }) => ({
                      label: label ?? '',
                      value: value ?? '',
                    }))
                  : [],
              preorderText: preorderButtonText ?? '',
              link: link ?? '',
              isNewTabLink,
              isInStock,
              onPreorder: () =>
                handleOpenApplicationFormModal(
                  preorderFormTitle ?? '',
                  name ?? '',
                  googleEvent ?? ''
                ),
            })
          )}
        />
      </ContentContainer>
    </Component>
  );
}

export default ModelsList;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 70px 0 20px;

    ${ContentContainer} {
      padding: 0;
    }

    ${SliderWrapperHeader} {
      right: 20px;
    }
  `)}
`;
