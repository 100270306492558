import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { Title } from '@/components/CarModelLandingWidgets';

import ComplectationStorageContentDesktop from './components/ComplectationStorageContentDesktop';
import ComplectationStorageContentMobile from './components/ComplectationStorageContentMobile';

function ComplectationStorage() {
  const { complectationsTitle, complectations } = useCarModelLandingData();
  const isTabletSmall = useMedia('(max-width: 1023px)');

  if (!complectations || complectations.length === 0) {
    return null;
  }

  return (
    <Component id="complectation">
      <ContentContainer>
        <Title title={complectationsTitle ?? ''} />

        <Main>
          {!isTabletSmall ? (
            <ComplectationStorageContentDesktop />
          ) : (
            <ComplectationStorageContentMobile />
          )}
        </Main>
      </ContentContainer>
    </Component>
  );
}

export default ComplectationStorage;

const Component = styled.section`
  padding: 30px 0;
`;

const Main = styled.div`
  margin-top: 20px;
`;
