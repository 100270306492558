import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useBrandsData } from '@/modules/Brands/Brands.hooks';
import BrandsCard from '@/components/BrandsCard';

function BrandsBlock() {
  const { brandsTitle, brandsItems } = useBrandsData();

  if (!brandsItems || brandsItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{brandsTitle}</Title>
        <Cards>
          {brandsItems.map(({ image, name, link }, index) => (
            <BrandsCard
              key={index}
              image={image}
              name={name ?? ''}
              link={link ?? ''}
            />
          ))}
        </Cards>
      </ContentContainerSmall>
    </Component>
  );
}

export default BrandsBlock;

const Component = styled.section`
  padding: 60px 0;

  ${media.tablet(css`
    padding: 60px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Cards = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1px;

  ${media.mobile(css`
    grid-template-columns: repeat(2, 1fr);
  `)}
`;
