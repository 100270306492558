import React from 'react';
import styled, { css } from 'styled-components';

import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { media } from '@/utils/mixin';
import { BannersSliderPrimary, VideoBanner } from '@/components/LandingWidgets';

function Hero() {
  const { heroVideo, heroBanners } = useTopLevelFunnelLandingData();

  if (!heroVideo && !heroBanners) {
    return null;
  }

  return (
    <Component>
      {heroVideo && heroVideo?.url && <VideoBanner video={heroVideo.url} />}

      {heroBanners && heroBanners.length > 0 && (
        <BannersSliderPrimary
          id={1}
          banners={heroBanners.map(({ image, mobileImage, text, url }) => ({
            image,
            mobileImage,
            text: text ?? '',
            url: url ?? '',
          }))}
        />
      )}
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-bottom: 15px;

  ${media.tablet(css`
    padding-bottom: 10px;
  `)}
`;
