import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { HeroFeaturesBannerProps } from './types';
import Features from './components/Features';

function HeroFeaturesBanner({
  image,
  title,
  text,
  isWhiteColor,
  features,
  mobileImage,
}: HeroFeaturesBannerProps) {
  return (
    <Component>
      <Image
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(mobileImage ?? image)}
        mobile={convertThumbnailToPictureImage(mobileImage ?? image)}
        alt="banner-image"
      />

      <Content>
        <Header>
          <Title isWhiteColor={isWhiteColor}>{title}</Title>
          <Text isWhiteColor={isWhiteColor}>{text}</Text>
        </Header>

        <Features features={features} isWhiteColor={isWhiteColor} />
      </Content>
    </Component>
  );
}

export default HeroFeaturesBanner;

const Component = styled.div`
  position: relative;
`;

const Image = styled(Picture)`
  padding-top: 29.412%;

  img {
    border-radius: 20px;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }

  ${media.tablet(css`
    padding-top: 112%;

    img {
      border-radius: 0;
    }
  `)}
`;

const Content = styled.div`
  position: absolute;
  top: 70px;
  right: 100px;
  bottom: 40px;
  left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.tablet(css`
    inset: 20px;
  `)}
`;

const Header = styled.div`
  max-width: 460px;
`;

const Title = styled.h1<{
  isWhiteColor?: boolean;
}>`
  font-weight: 500;
  font-size: 34px;
  line-height: 37px;
  color: ${({ isWhiteColor }) => (isWhiteColor ? colors.white : colors.black)};

  ${media.tablet(css`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  `)}
`;

const Text = styled.p<{
  isWhiteColor?: boolean;
}>`
  margin-top: 5px;
  font-size: 20px;
  line-height: 24px;
  color: ${({ isWhiteColor }) => (isWhiteColor ? colors.white : colors.black)}
    ${media.tablet(css`
      font-size: 18px;
      line-height: 20px;`)};
`;
