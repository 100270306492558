import { SwiperSlide } from 'swiper/react';

import SliderWrapper from '@/components/Redesign/SliderWrapper';

import RefitTypesItem from '../RefitTypesItem';

import { RefitTypesSliderProps } from './types';

function RefitTypesSlider({ refitData }: RefitTypesSliderProps) {
  return (
    <SliderWrapper
      options={{
        spaceBetween: 20,
        withPagination: true,
      }}
    >
      {refitData.map((refit, index) => (
        <SwiperSlide key={index}>
          <RefitTypesItem {...refit} />
        </SwiperSlide>
      ))}
    </SliderWrapper>
  );
}

export default RefitTypesSlider;
