import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Redesign/BreadCrumbs';
import Guarantees from '@/components/Redesign/Guarantees';
import { media } from '@/utils/mixin';
import { useRefitSingleLanding } from '@/modules/RefitSingleLanding/hooks';
import { ContentContainer } from '@/modules/RefitSingleLanding/common.styles';

function AboutSection() {
  const {
    title,
    path,
    templateFields: {
      descriptionImage,
      descriptionTitle,
      descriptionSubtitle,
      descriptionText,
      descriptionTicks,
    },
  } = useRefitSingleLanding();

  if (!descriptionTitle) {
    return null;
  }

  const ticks = descriptionTicks.map(({ text }) => ({ text: text ?? '' }));

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={[
            { label: 'Главная', link: '/' },
            { label: title, link: path },
          ]}
        />

        <Guarantees
          title={descriptionTitle ?? ''}
          text={descriptionText ?? ''}
          subTitle={descriptionSubtitle ?? ''}
          ticks={ticks}
          image={descriptionImage}
          ticksColumns={2}
          isReverse
        />
      </ContentContainer>
    </Component>
  );
}

export default AboutSection;

const Component = styled.section`
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;
