import React from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { useMedia } from '@tager/web-core';

import ArrowIcon from '@/assets/svg/arrow-right-40px.svg';
import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useOnlineShoppingData } from '@/modules/OnlineShopping/OnlineShopping.hooks';
import RecommendationCard from '@/modules/OnlineShopping/components/Recommendation/components/RecommendationCard';
import { colors } from '@/constants/theme';

SwiperCore.use([Navigation, Pagination]);

function Recommendation() {
  const { recommendationsTitle, recommendationsItems } =
    useOnlineShoppingData();

  const isDesktop = useMedia('(min-width: 1260px)');

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{recommendationsTitle}</Title>

        <Slider>
          <Swiper
            slidesPerView={1}
            navigation={{
              prevEl: `.swiper-prev`,
              nextEl: `.swiper-next`,
            }}
            noSwipingClass={'swiper-no-swiping'}
          >
            {recommendationsItems &&
              recommendationsItems.map(
                ({ author, city, history, car, image }, index) => (
                  <SwiperSlide
                    key={index}
                    className={isDesktop ? 'swiper-no-swiping' : ''}
                  >
                    <RecommendationCard
                      author={author}
                      city={city}
                      history={history}
                      car={car}
                      image={image}
                    />
                  </SwiperSlide>
                )
              )}

            <NavButton className={`swiper-prev`} prev>
              <ArrowIcon />
            </NavButton>
            <NavButton className={`swiper-next`} next>
              <ArrowIcon />
            </NavButton>
          </Swiper>
        </Slider>
      </ContentContainerSmall>
    </Component>
  );
}

export default Recommendation;

const Component = styled.section`
  padding: 60px 0;

  ${media.tablet(css`
    padding-bottom: 60px;
  `)}
  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Slider = styled.div`
  margin-top: 30px;

  ${media.tabletUp(css`
    .swiper-wrapper {
      transform: translate3d(0, 0, 0) !important;
      height: 425px;
    }

    .swiper-container {
      position: relative;
    }

    .swiper-slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
      transform: translate3d(0, 0, 0) !important;

      &-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s linear 0.3s;
      }
    }
  `)}

  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const NavButton = styled.button<{
  prev?: boolean;
  next?: boolean;
}>`
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 40px;
  left: 60px;
  border: 1px solid ${colors.main};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: 0.3s;
  cursor: pointer;

  ${(props) =>
    props.next &&
    css`
      left: 120px;

      ${media.mobile(css`
        right: 20px;
        left: auto;
      `)}
    `}
  ${(props) =>
    props.prev &&
    css`
      ${media.mobile(css`
        left: 20px;
      `)}
    `}
  &:hover {
    background: ${colors.main};

    svg {
      fill: ${colors.white};
    }
  }

  svg {
    width: 15px;
    height: 21px;
    transform: ${(props) => (props.prev ? 'rotate(180deg)' : 'rotate(0deg)')};
    fill: ${colors.main};
  }

  ${media.mobile(css`
    bottom: 20px;
  `)}
`;
