import React from 'react';
import styled, { css } from 'styled-components';

import FeatureCard from '@/components/FeatureCard';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';
import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';

function Programs() {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;

  return (
    <Component>
      <Title>{pageFields.programsBlockTitle}</Title>

      <ContentContainerSmall>
        <FeaturesRow>
          {pageFields.programsBlockItems.map((program, index) => {
            return (
              <FeaturesCell key={index}>
                <FeatureCard
                  title={program.title}
                  icon={program.icon}
                  text={program.text}
                  list={program.list ? program.list.split('\n') : []}
                />
              </FeaturesCell>
            );
          })}
        </FeaturesRow>
      </ContentContainerSmall>
    </Component>
  );
}

export default Programs;

const Component = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  background: ${(props) => props.theme.gray};
`;

const Title = styled.h2`
  font-size: 42px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 36px;
  `)};

  ${media.mobile(css`
    font-size: 30px;
  `)};
`;

const FeaturesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px -15px 0;

  ${media.tablet(css`
    margin: 0;
  `)};

  ${media.tabletSmall(css`
    display: block;
    margin: 15px 0 0 0;
  `)};

  ${media.mobile(css`
    display: block;
    margin-top: 0;
  `)};
`;

const FeaturesCell = styled.div`
  flex-basis: 33.3333%;
`;
