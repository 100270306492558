import React from 'react';
import styled, { css } from 'styled-components';

import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import { ContentContainer } from '@/modules/EmergencyManager/common.styles';
import { media } from '@/utils/mixin';
import Advantages from '@/components/Redesign/Advantages';

function AdvantagesList() {
  const {
    templateFields: { advantagesItems: items, advantagesTitle: title },
  } = useEmergencyManagerData();

  if (!title || !items) {
    return null;
  }

  const advantages = items?.map(({ image, text }) => ({
    image,
    text: text ?? '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Advantages advantages={advantages} title={title} />
      </ContentContainer>
    </Component>
  );
}

export default AdvantagesList;

const Component = styled.section`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)}
`;
