import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

function Tooltip({ text }: { text?: string }) {
  return <Component>{text ? text : 'Сначала выберите марку авто'}</Component>;
}

export default Tooltip;

const Component = styled.div`
  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;

  padding: 15px;

  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  color: ${colors.black};
`;
