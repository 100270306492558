import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useAboutData } from '@/modules/About/About.hooks';

import StatsCard from './components/StatsCard';

function Stats() {
  const { statsItems } = useAboutData();

  if (!statsItems || statsItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Cards>
          {statsItems.map(({ number, description, image }, index) => (
            <StatsCard
              key={index}
              number={number}
              description={description}
              image={image}
            />
          ))}
        </Cards>
      </ContentContainerSmall>
    </Component>
  );
}

export default Stats;

const Component = styled.section`
  padding: 45px 0;

  ${media.tablet(css`
    padding: 10px 0 40px;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}

    ${media.mobile(css`
      padding: 0;
    `)}
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 2px;
  `)}
`;
