import React from 'react';
import styled, { css } from 'styled-components';

import { useEvaluationRedemptionData } from '@/modules/EvaluationRedemption/hooks';
import { ContentContainer } from '@/modules/EvaluationRedemption/common.styles';
import Advantages from '@/components/Redesign/Advantages';
import { media } from '@/utils/mixin';

import { AdvantagesType } from './types';

function AdvantagesList() {
  const {
    templateFields: { advantagesItems, advantagesTitle },
  } = useEvaluationRedemptionData();

  if (!advantagesItems || advantagesItems.length === 0) {
    return null;
  }

  const advantages: AdvantagesType[] = advantagesItems.map(
    ({ image, text, title }) => ({
      image,
      title: title ?? '',
      text: text ?? '',
    })
  );

  return (
    <Component>
      <ContentContainer>
        <Advantages
          columns={3}
          title={advantagesTitle ?? ''}
          advantages={advantages}
          isBig
        />
      </ContentContainer>
    </Component>
  );
}

export default AdvantagesList;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
