import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { useOnlineShoppingData } from '@/modules/OnlineShopping/OnlineShopping.hooks';
import Button from '@/components/Button';
import CallbackForm from '@/components/modals/CallbackForm';
import { getPhoneLink } from '@/utils/common';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { handleSubmitCallbackForm } from '@/utils/forms';

function Connection() {
  const {
    connectionTitle,
    connectionPhone,
    connectionButtonTitle,
    connectionImage,
  } = useOnlineShoppingData();

  const openModal = useModal();

  function handleCallClick() {
    openModal(
      CallbackForm,
      {
        request: (values) => handleSubmitCallbackForm(values),
        title: 'Заказать обратный звонок',
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Wrapper>
          <Background
            loading="lazy"
            {...convertThumbnailToPictureProps(connectionImage)}
          />
          <Content>
            <Title>{connectionTitle}</Title>
            {connectionPhone ? (
              <Phone to={getPhoneLink(connectionPhone)}>
                {connectionPhone}
              </Phone>
            ) : null}
            <ButtonWrapper>
              <StyledButton onClick={handleCallClick}>
                {connectionButtonTitle}
              </StyledButton>
            </ButtonWrapper>
          </Content>
        </Wrapper>
      </ContentContainerSmall>
    </Component>
  );
}

export default Connection;

const Component = styled.section`
  padding-bottom: 90px;

  ${media.tablet(css`
    padding-bottom: 60px;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 300px;
  padding: 35px 50px;
  background: ${colors.main};

  @media (max-width: 599px) {
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 20px;
  }
`;

const Background = styled(Picture)`
  position: absolute;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0;
  right: 50px;
  bottom: 0;
  left: 0;

  img,
  picture {
    height: 100%;
  }

  @media (max-width: 599px) {
    display: none;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 599px) {
    flex: 1;
    align-items: center;
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${colors.white};

  @media (max-width: 599px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const Phone = styled(Link)`
  font-size: 40px;
  line-height: 52px;
  color: ${colors.white};

  @media (max-width: 599px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 1.5vw;

  @media (max-width: 599px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  display: block;
  max-width: 100%;
  width: 280px;
  border-radius: 20px;
  padding: 11px 26px;
  height: 40px;
  transition: 0.3s;
  text-align: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border: 1.5px solid ${colors.white};
  text-transform: uppercase;
  color: ${colors.white};

  &:hover {
    border-color: ${colors.white_alpha60};
  }

  @media (max-width: 599px) {
    width: 100%;
  }

  @media (max-width: 360px) {
    padding: 11px 20px;
    font-size: 13px;
  }
`;
