import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { useTypedDispatch } from '@/store/store';
import {
  setOnlineChosenBrand,
  setOnlineChosenModel,
  setOnlineChosenYear,
} from '@/store/reducers/online-valuation-user-data';

interface Props {
  value: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  selectedValue: Nullable<string>;
  onClose: Dispatch<SetStateAction<boolean>>;
  isBrand?: boolean;
  isModel?: boolean;
  isYear?: boolean;
}

const HorizontalCollapsedItem = ({
  value,
  setFieldValue,
  selectedValue,
  isBrand,
  isModel,
}: Props) => {
  const dispatch = useTypedDispatch();

  function handleClick() {
    if (isBrand) {
      setFieldValue('brand', value);
      dispatch(setOnlineChosenBrand(value));
    } else if (isModel) {
      setFieldValue('model', value);
      dispatch(setOnlineChosenModel(value));
    } else {
      setFieldValue('year', value);
      dispatch(setOnlineChosenYear(value));
    }
  }

  return (
    <Value onClick={handleClick} isSelected={selectedValue === String(value)}>
      {value}
    </Value>
  );
};

export default HorizontalCollapsedItem;

const Value = styled.span<{ isSelected: boolean }>`
  min-width: 110px;
  display: block;
  font-size: 16px;
  padding: 10px 15px 10px 0;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? `${colors.main}` : props.theme.black};

  &:hover {
    color: ${colors.main};
  }
`;
