import React from 'react';
import styled, { css } from 'styled-components';

import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import { ContentContainer } from '@/modules/ServiceValuationLanding/common.styles';
import { media } from '@/utils/mixin';
import CompaniesTicker from '@/components/CompaniesTicker';

function Brands() {
  const {
    templateFields: { brands },
  } = useServiceValuationLandingData();

  if (!brands || brands.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <CompaniesTicker images={brands} />
      </ContentContainer>
    </Component>
  );
}

export default Brands;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
