import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import { colors } from '@/constants/theme';
import InfoSvg from '@/assets/svg/info-icon.svg';

import { StepProps } from './types';

function Step({ text, description, counter, title }: StepProps) {
  return (
    <Component>
      {title && <Title>{title}</Title>}
      <Text>{text}</Text>
      {description && (
        <Tippy
          content={<TippyContent>{description}</TippyContent>}
          placement="bottom"
          interactive
          appendTo={isBrowser() ? document.body : 'parent'}
        >
          <SvgWrapper>
            <InfoSvg />
          </SvgWrapper>
        </Tippy>
      )}
      <Counter>{counter}</Counter>
    </Component>
  );
}

export default Step;

const Component = styled.div`
  position: relative;
  background-color: ${colors.yellow300};
  border-radius: 20px;
  height: 100%;
  padding: 20px 35px 20px 15px;
`;

const Text = styled.p`
  word-wrap: break-word;
  display: inline;
  font-size: 16px;
  line-height: 19px;
  padding-right: 5px;
  color: ${colors.black};
`;

const Title = styled(Text)`
  display: block;
  font-weight: 700;
  margin-bottom: 20px;
`;

const SvgWrapper = styled.div`
  box-sizing: content-box;
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 0 8px 8px 0;
  top: -4px;
  height: 12px;
  width: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const TippyContent = styled.div`
  position: relative;
  left: 30px;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: ${colors.white};
  font-size: 12px;
  line-height: 16px;
  max-width: 190px;
  z-index: 3;
`;

const Counter = styled.p`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: 700;
  font-size: 60px;
  line-height: 54px;
  color: ${colors.white};
`;
