import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import PromoIntro from './components/PromoIntro';
import Steps from './components/Steps';
import DetailSteps from './components/DetailSteps';
import Brands from './components/Brands';
import Footer from './components/Footer';
import Help from './components/Help';

function Atlantm30() {
  return (
    <Layout>
      <Component>
        <Hero />
        <PromoIntro />
        <Steps />
        <DetailSteps />
        <Brands />
        <Help />
        <Footer />
      </Component>
    </Layout>
  );
}

export default Atlantm30;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
