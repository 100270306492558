import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';
import { useMedia } from '@tager/web-core';

import {
  ContentContainer,
  Title,
} from '@/modules/ElectrocarsLanding/common.styles';
import { media } from '@/utils/mixin';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { useTypedSelector } from '@/store/store';
import { colors } from '@/constants/theme';
import {
  Header as SliderWrapperHeader,
  Component as SliderWrapperComponent,
} from '@/components/LandingWidgets/components/SliderWrapper';
import {
  getCatalogCarButton,
  getCatalogCarImages,
  getCatalogCarLink,
  getCatalogCarsByIds,
} from '@/modules/Catalog/Catalog.helper';
import useSettingItem from '@/hooks/useSettingItem';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { getCatalogItems } from '@/store/reducers/catalog';
import { CatalogCarCardProps } from '@/components/CatalogCarCard/CatalogCarCard.types';
import { SelectionCarCardProps } from '@/components/LandingWidgets/components/CarsCatalogList/components/SelectionCarCard/types';
import { CarsCatalogList } from '@/components/LandingWidgets';
import { handleSubmitElectrocarsLandingSelectionCar } from '@/modules/ElectrocarsLanding/common.helpers';
import CarSelectionHelperModal from '@/components/modals/CarSelectionHelperModal';

function CatalogCars() {
  const openModal = useModal();
  const {
    popularModelsTitle,
    popularModelsItems,
    popularModelsCardImage,
    popularModelsCardTitle,
    popularModelsCardDescription,
    popularModelsCardButtonTitle,
    popularModelsCardModalText,
    popularModelsCardModalImage,
    popularModelsCardGoogleEvent,
  } = useElectrocarsLandingData();
  const catalogCars = getCatalogCarsByIds(
    useTypedSelector(getCatalogItems).catalogItemsData,
    popularModelsItems ?? []
  );
  const catalogMainGoogleEvent = useSettingItem('CATALOG_MAIN_GOOGLE_EVENT');
  const catalogMainPartnerCarGoogleEvent = useSettingItem(
    'CATALOG_MAIN_PARTNER_CAR_GOOGLE_EVENT'
  );
  const isTablet = useMedia('(max-width: 1259px)');
  const isMobile = useMedia('(max-width: 767px)');

  const handleOpenModal = () => {
    openModal(
      CarSelectionHelperModal,
      {
        text: popularModelsCardModalText ?? '',
        image: popularModelsCardModalImage,
        onSubmit: (values) =>
          handleSubmitElectrocarsLandingSelectionCar(
            values,
            popularModelsCardGoogleEvent ?? ''
          ),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  if (!catalogCars || catalogCars.length === 0) {
    return null;
  }

  const catalogCarsData: CatalogCarCardProps[] = catalogCars.map((car) => ({
    view: 'portrait',
    images: getCatalogCarImages(car),
    name: car.name,
    button: getCatalogCarButton(
      openModal,
      car,
      `/cars/new/${car.brand.alias}/${car.model.alias}`,
      (car.isPartnerCar
        ? catalogMainPartnerCarGoogleEvent
        : catalogMainGoogleEvent) ?? ''
    ),
    link: getCatalogCarLink(car),
    badge: car.badge ?? '',
    badgeHint: car.badgeHint ?? '',
    price: car.stock.minPrice ?? 0,
  }));

  const selectionCarCardData: SelectionCarCardProps = {
    image: popularModelsCardImage,
    title: popularModelsCardTitle ?? '',
    description: popularModelsCardDescription ?? '',
    buttonTitle: popularModelsCardButtonTitle ?? '',
    onClick: handleOpenModal,
  };

  const slidesPerView = isMobile ? undefined : isTablet ? 2 : 3;
  const isWithoutNavs = isMobile
    ? true
    : isTablet
    ? catalogCars.length === 1
    : catalogCars.length <= 2;
  const isNoSwiping = isMobile
    ? catalogCars.length === 1
    : isTablet
    ? catalogCars.length === 1
    : catalogCars.length <= 2;

  return (
    <Component id="popularModels">
      <ContentContainer>
        <Title>{popularModelsTitle}</Title>

        <Slider>
          <CarsCatalogList
            id={1}
            cars={catalogCarsData}
            selectionCarCard={selectionCarCardData}
            options={{
              slidesPerView,
              spaceBetween: 20,
              noSwipingClass: 'swiper-no-swiping',
              withoutNavs: isWithoutNavs,
              withoutPagination: true,
              isNoSwiping,
            }}
          />
        </Slider>
      </ContentContainer>
    </Component>
  );
}

export default CatalogCars;

const Component = styled.section`
  padding: 0 0 60px;
  background: ${colors.purpleGradient};

  ${media.tablet(css`
    padding: 0 0 40px;
  `)}
`;

const Slider = styled.div`
  margin-top: 58px;

  ${media.tablet(css`
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    margin: 30px -20px 0 0;

    ${SliderWrapperComponent} {
      & > .swiper-container {
        & > .swiper-wrapper {
          & > .swiper-slide {
            min-width: calc(100% - 40px);
            flex-shrink: initial;
          }
        }
      }
    }
  `)}

  ${SliderWrapperHeader} {
    top: -100px;

    ${media.tablet(css`
      top: -60px;
    `)}

    .swiper-prev1:not(.swiper-button-disabled),
    .swiper-next1:not(.swiper-button-disabled) {
      border: 1px solid ${colors.white};

      svg {
        fill: ${colors.white};
      }
    }
  }
`;
