import React from 'react';
import styled, { css } from 'styled-components';

import FeatureCard from '@/components/FeatureCard';
import { media } from '@/utils/mixin';
import { useServicesData } from '@/modules/Services/Services.hooks';

function Advantages() {
  const data = useServicesData();

  if (!data || !data.advantagesItems || !data.advantagesItems.length) {
    return null;
  }

  return (
    <Content>
      <Title>{data.advantagesTitle ?? ''}</Title>

      <FeaturesRow>
        {data.advantagesItems.map((soldCar, index) => {
          return (
            <FeaturesCell key={index}>
              <FeatureCard
                icon={soldCar.icon}
                title={soldCar.title}
                text={soldCar.text}
                list={soldCar.list ? soldCar.list.split('\n') : []}
              />
            </FeaturesCell>
          );
        })}
      </FeaturesRow>
    </Content>
  );
}

const Content = styled.div`
  max-width: 1440px;
  margin: 60px auto 0;
  padding: 0 30px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  margin: 40px 0 20px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 32px;
    line-height: 38px;
    margin: 20px 0 10px;
  `)}
`;

const FeaturesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px -15px 0;

  ${media.tablet(css`
    margin: 0 -15px;
  `)};

  ${media.tabletSmall(css`
    display: block;
    margin: 24px -15px 0;
  `)};
`;

const FeaturesCell = styled.div`
  flex-basis: 33.3333%;
`;

export default Advantages;
