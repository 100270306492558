import React, { useState } from 'react';
import styled from 'styled-components';

import {
  CarComplectation,
  CarComplectationInfo,
} from '@/components/CarModelLandingWidgets';

import { CarComplectationCollapsedProps } from './types';

export function CarComplectationCollapsed({
  complectaionImage,
  complectaionName,
  complectaionInfo,
}: CarComplectationCollapsedProps) {
  const [isOpened, setOpened] = useState(false);

  const handleComplectationOpened = () => {
    setOpened(!isOpened);
  };

  return (
    <Component>
      <CarComplectation
        image={complectaionImage}
        name={complectaionName}
        onClick={handleComplectationOpened}
        isActive={isOpened}
      />

      {isOpened &&
        complectaionInfo.list &&
        complectaionInfo.list.length > 0 &&
        complectaionInfo.image && (
          <Info>
            <CarComplectationInfo
              title={complectaionInfo.title}
              list={complectaionInfo.list}
              image={complectaionInfo.image}
            />
          </Info>
        )}
    </Component>
  );
}

const Component = styled.div`
  & > div:nth-child(1) {
    max-width: 400px;
    margin: 0 auto;
  }
`;

const Info = styled.div`
  margin-top: 30px;
  width: 100%;
`;
