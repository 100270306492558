import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useTypedSelector } from '@/store/store';
import { selectDealers } from '@/store/reducers/dealers';
import { media } from '@/utils/mixin';
import DealersMap from '@/components/DealersMap';
import { Marker } from '@/typings/model';
import { Switch } from '@/components/Switch';
import Layout from '@/layout';

import { switchOptions } from './helpers';
import DealerCard from './components/DealerCard';

function Dealers() {
  const [isSwitchChecked, setSwitchChecked] = useState(false);
  const dealersTitle = useCurrentPage().seoParams.title;
  const dealers = useTypedSelector(selectDealers);

  const dealersWithNewCars = dealers.filter(
    ({ name }) => name !== 'Атлант-М Автомобили с пробегом'
  );

  const dealersWithAmpCars = dealers.filter(
    ({ name }) => name === 'Атлант-М Автомобили с пробегом'
  );

  const dealersData = useMemo(
    () => (!isSwitchChecked ? dealersWithNewCars : dealersWithAmpCars),
    [dealersWithAmpCars, dealersWithNewCars, isSwitchChecked]
  );

  if (!dealersData || dealersData.length === 0) {
    return null;
  }

  const markers = dealersData
    .map(({ locations }) =>
      locations && locations.length !== 0
        ? locations.reduce<Marker[]>(
            (acc, { name, address, latitude, longitude }) => {
              acc?.push({
                name: name,
                address: address,
                latitude: latitude ?? 0,
                longitude: longitude ?? 0,
              });
              return acc;
            },
            []
          )
        : []
    )
    .flat();

  return (
    <Layout>
      <Component>
        <ContentContainerSmall>
          <Title>{dealersTitle}</Title>

          <SwitchWrapper>
            <Switch
              options={switchOptions}
              onChange={setSwitchChecked}
              checked={isSwitchChecked}
            />
          </SwitchWrapper>
        </ContentContainerSmall>

        <Content>
          <DealersMap markers={markers} />

          <ContentContainerSmall>
            <Cards>
              {dealersData.map(
                (
                  {
                    id,
                    dealerId,
                    url,
                    name,
                    legalName,
                    rating,
                    brands,
                    locations,
                    hideLogos,
                  },
                  index
                ) => (
                  <DealerCard
                    key={index}
                    url={url ?? ''}
                    name={name ?? ''}
                    legalName={legalName ?? ''}
                    rating={rating}
                    logos={
                      !hideLogos && brands && brands.length !== 0
                        ? brands.map(({ logo }) => ({ url: logo ?? '' }))
                        : []
                    }
                    locations={
                      locations && locations.length !== 0
                        ? locations.map((location) => ({
                            name:
                              locations.length > 1 ? location.name ?? '' : '',
                            address: location.address ?? '',
                            phones: location.phones,
                            socialYoutube: location.socialYoutube ?? '',
                            socialInstagram: location.socialInstagram ?? '',
                            socialOdnoklassnikiUrl:
                              location.socialOdnoklassnikiUrl ?? '',
                            socialFacebookUrl: location.socialFacebookUrl ?? '',
                            socialVkontakteUrl:
                              location.socialVkontakteUrl ?? '',
                            socialTelegramUrl: location.socialTelegramUrl ?? '',
                            socialViberUrl: location.socialViberUrl ?? '',
                            socialSkypeUrl: location.socialSkypeUrl ?? '',
                            divisions:
                              location.divisions &&
                              location.divisions.length !== 0
                                ? location.divisions.map(
                                    ({ name }) => name ?? ''
                                  )
                                : [],
                          }))
                        : []
                    }
                  />
                )
              )}
            </Cards>
          </ContentContainerSmall>
        </Content>
      </Component>
    </Layout>
  );
}

export default Dealers;

const Component = styled.section`
  padding-top: 60px;
  background: ${(props) => props.theme.white};

  ${media.tablet(css`
    padding-top: 20px;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin: 0;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 26px;
    line-height: 34px;
  `)}
`;

const SwitchWrapper = styled.div`
  margin-top: 30px;
`;

const Content = styled.div`
  position: relative;
  height: auto;
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  ${ContentContainerSmall} {
    ${media.mobile(css`
      padding: 0;
    `)}
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;

  ${media.mobile(css`
    padding: 0;
  `)}
`;
