import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';

import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import {
  Title,
  ContentContainer,
} from '@/modules/FinanceLanding/common.styles';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import CallbackForm from '@/components/modals/CallbackForm';
import { submitFinanceLandingProgram } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { FinanceLandingProgramParams } from '@/services/leads/typings';

import PartnersPrograms from './components/PartnersPrograms';

function Programs() {
  const openModal = useModal();
  const { financeProgramsTitle, financePrograms } = useFinanceLandingData();

  if (!financePrograms || financePrograms.length === 0) {
    return null;
  }

  const sendProgram = async (
    values: FinanceLandingProgramParams,
    event: string
  ): Promise<void> => {
    try {
      await submitFinanceLandingProgram(values).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenProgramModal = (
    title: string,
    bank: string,
    program: string,
    event: string
  ) => {
    openModal(
      CallbackForm,
      {
        title,
        request: (values) => sendProgram({ ...values, bank, program }, event),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <Title>{financeProgramsTitle}</Title>

        <PartnersProgramsWrapper>
          <PartnersPrograms
            id={1}
            programs={financePrograms.map(({ type, programs }) => ({
              type: type ?? '',
              programs:
                programs && programs.length !== 0
                  ? programs.map(
                      ({
                        bank,
                        logo,
                        name,
                        description,
                        buttonTitle,
                        modalTitle,
                        googleEvent,
                      }) => ({
                        logo,
                        name: name ?? '',
                        description: description ?? '',
                        buttonTitle: buttonTitle ?? '',
                        onClick: () =>
                          handleOpenProgramModal(
                            modalTitle ?? '',
                            bank ?? '',
                            name ?? '',
                            googleEvent ?? ''
                          ),
                      })
                    )
                  : [],
            }))}
          />
        </PartnersProgramsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Programs;

const Component = styled.section`
  padding: 15px 0;
`;

const PartnersProgramsWrapper = styled.div`
  margin-top: 20px;
`;
