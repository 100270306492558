import styled, { css } from 'styled-components';
import React from 'react';

import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import { ContentContainer } from '@/modules/VerifiedAtlantmLanding/common.styles';
import HeroBanner from '@/components/Redesign/HeroBanner';
import { media } from '@/utils/mixin';
import { handleScrollToDomElement } from '@/utils/scroll';

function Hero() {
  const {
    templateFields: {
      heroTitle,
      heroSubtitle,
      heroButtonText,
      heroImage,
      heroMobileImage,
    },
  } = useVerifiedAtlantmLandingData();

  if (!heroImage || !heroTitle) {
    return null;
  }

  return (
    <Component>
      <ContentContainer isBig>
        <HeroBanner
          image={heroImage}
          title={heroTitle}
          tabletImage={heroMobileImage}
          subtitle={heroSubtitle ?? ''}
          buttonText={heroButtonText ?? ''}
          buttonOnClick={() => handleScrollToDomElement('verifiedForm')}
        />
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  margin-top: 20px;
  margin-bottom: 40px;

  ${media.tablet(css`
    margin-top: 0;
    margin-bottom: 20px;

    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
