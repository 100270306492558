import React from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, FormikTouched } from 'formik';
import { FormikHandlers } from 'formik/dist/types';

import TextInput from '@/components/TextInput';
import { media } from '@/utils/mixin';

import { ContactFormValuesModel } from './typings';

interface Props {
  values: ContactFormValuesModel;
  errors: FormikErrors<ContactFormValuesModel>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FormikHandlers['handleBlur'];
  touched?: FormikTouched<ContactFormValuesModel>;
}

function ContactForm({ values, errors, onChange, onBlur, touched }: Props) {
  return (
    <Component>
      <Title>Контактные данные</Title>

      <FormContainer>
        <TextInput
          id="name"
          name="name"
          label="Имя"
          placeholder="Имя"
          value={values.name}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={touched?.name && errors.name ? ' ' : undefined}
        />

        <TextInput
          name="phone"
          label="Телефон"
          mask={process.env.NEXT_PUBLIC_PHONE_MASK}
          value={values.phone}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={touched?.phone && errors.phone ? ' ' : undefined}
        />

        <TextInput
          id="email"
          name="email"
          label="Email"
          placeholder="Email"
          value={values.email}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={touched?.email && errors.email ? ' ' : undefined}
        />
      </FormContainer>
    </Component>
  );
}

export default ContactForm;

const Component = styled.div`
  margin-top: 30px;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 24px;
  color: ${(props) => props.theme.black};
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  width: 100%;

  & > div {
    &:not(:first-child) {
      margin-left: 24px;

      ${media.mobile(css`
        margin-left: 0;
        margin-top: 30px;
      `)}
    }
  }

  ${media.mobile(css`
    justify-content: normal;
    flex-direction: column;
    align-items: center;
  `)}
`;
