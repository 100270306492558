import { TableSectionDesktopProps } from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/TableSectionDesktop/types';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';

export const useTableParamsData = (): TableSectionDesktopProps[] => {
  const { packagesDetails } = useHelpOnTheRoadData();

  if (!packagesDetails || packagesDetails.length === 0) {
    return [];
  }

  return packagesDetails.map(({ sectionName, sectionParams }) => ({
    title: sectionName ?? '',
    rows: sectionParams.map(({ param, value }) => ({
      title: param ?? '',
      cells: value
        ? value
            .split('\n')
            .map((resultValue) =>
              resultValue === '+'
                ? ''
                : resultValue === '-'
                ? null
                : resultValue
            )
        : [],
    })),
  }));
};
