import React, { useCallback, useState } from 'react';

import { CarModelCompareLandingContext } from './CarModelCompareLandingContext';

export const CarModelCompareLandingProvider: React.FC = ({ children }) => {
  const [isDifferentParams, setNewDifferentParams] = useState(false);
  const [currentSlideIndex, setNewCurrentSlideIndex] = useState(0);

  const setDifferentParams = useCallback((value: boolean) => {
    setNewDifferentParams(value);
  }, []);

  const setCurrentSlideIndex = useCallback((index: number) => {
    setNewCurrentSlideIndex(index);
  }, []);

  return (
    <CarModelCompareLandingContext.Provider
      value={{
        isDifferentParams,
        setDifferentParams,
        currentSlideIndex,
        setCurrentSlideIndex,
      }}
    >
      {children}
    </CarModelCompareLandingContext.Provider>
  );
};
