import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ContentContainer, Title } from '@/modules/HelpOnTheRoad/common.styles';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { media } from '@/utils/mixin';

import Desktop from './components/Desktop';
import Mobile from './components/Mobile';

function Packages() {
  const { packagesTitle, packages, packagesDetails } = useHelpOnTheRoadData();
  const isTablet = useMedia('(max-width: 1259px)');

  if (
    !packages ||
    packages.length === 0 ||
    !packagesDetails ||
    packagesDetails.length === 0
  ) {
    return null;
  }

  return (
    <Component>
      <Content>
        <ContentContainer>
          <Title>{packagesTitle}</Title>
          <Main>{!isTablet ? <Desktop /> : <Mobile />}</Main>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default Packages;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(
    css`
      padding: 20px 0;
    `
  )}
`;

const Content = styled.div`
  ${media.mobile(
    css`
      padding: 0 20px;
    `
  )}
`;

const Main = styled.div`
  margin-top: 57px;

  ${media.tablet(
    css`
      margin-top: 20px;
    `
  )}
`;
