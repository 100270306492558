import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import CarCheckForm from '@/components/Redesign/Forms/CarCheckForm';
import { CarCheckFormValues } from '@/components/Redesign/Forms/CarCheckForm/types';
import { submitRefitConsultation } from '@/services/leads/leads-service';
import { ContentContainer } from '@/modules/RefitSingleLanding/common.styles';
import { useRefitSingleLanding } from '@/modules/RefitSingleLanding/hooks';
import { googleEvent } from '@/utils/events';

function Form() {
  const {
    templateFields: {
      formTitle,
      formImage,
      formImageMobile,
      formDealer,
      formGoogleEvent,
    },
  } = useRefitSingleLanding();

  if (!formTitle) {
    return null;
  }

  const fieldsConfig = {
    withMessage: true,
  };

  const sendRefitForm = async (values: CarCheckFormValues) => {
    try {
      const { name, phone, message } = values;

      await submitRefitConsultation({
        name,
        phone,
        message,
        dealer: formDealer[0].crmId,
      }).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <CarCheckForm
          image={formImage}
          mobileImage={formImageMobile}
          isAbsoluteImage
          isCenterImage
          title={formTitle}
          fieldsConfig={fieldsConfig}
          onSubmit={sendRefitForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
