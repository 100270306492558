import React from 'react';

import { useMedia } from '@tager/web-core';

import StepsList from './components/StepsList';
import StepsSlider from './components/StepsSlider';
import { StepsCardsProps } from './types';

function StepsCards({ id, steps, cols }: StepsCardsProps) {
  const isMobile = useMedia('(max-width: 767px)');

  if (!steps || steps.length === 0) {
    return null;
  }

  return !isMobile ? (
    <StepsList steps={steps} cols={cols} />
  ) : (
    <StepsSlider id={id} steps={steps} />
  );
}

export default StepsCards;
