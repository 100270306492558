import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import Models from './components/Models';
import News from './components/News';
import Map from './components/Map';
import Slogan from './components/Slogan';

function ZeekrHome() {
  return (
    <Layout>
      <Component>
        <Hero />
        <Slogan />
        <Models />
        <News />
        <Map />
      </Component>
    </Layout>
  );
}

export default ZeekrHome;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
