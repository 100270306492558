import React from 'react';
import styled from 'styled-components';

import TableSectionDesktop from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/TableSectionDesktop';

import { useTableParamsData } from './hooks';

function TableParams() {
  const params = useTableParamsData();

  if (!params || params.length === 0) {
    return null;
  }

  return (
    <Component>
      {params.map((param, index) => (
        <TableSectionDesktopWrapper key={index}>
          <TableSectionDesktop {...param} />
        </TableSectionDesktopWrapper>
      ))}
    </Component>
  );
}

export default TableParams;

const Component = styled.div`
  margin-top: 20px;
`;

const TableSectionDesktopWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
