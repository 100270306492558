import styled from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

import { RefitTypesItemProps } from './types';

function RefitTypesItem({ text, link, image }: RefitTypesItemProps) {
  return (
    <Component>
      <Image
        desktop={convertThumbnailToPictureImage(image)}
        alt="feature-image"
      />
      <Text>{text}</Text>
      <ButtonLink to={link} />
    </Component>
  );
}

export default RefitTypesItem;

const Component = styled.div`
  position: relative;
`;

const Image = styled(Picture)`
  padding-top: 65.82%;

  img,
  picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    border-radius: 10px;
    object-fit: cover;
  }
`;

const ButtonLink = styled(Link)`
  position: absolute;
  inset: 0;
  border-radius: 10px;
  background: ${colors.blackGradient100};

  &:hover {
    background-color: ${colors.blue1300_alpha70};
  }
`;

const Text = styled.p`
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.white};
`;
