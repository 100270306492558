import React, { Dispatch, Ref, SetStateAction } from 'react';
import styled from 'styled-components';

import { useUpdateEffect } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectRateCarsData,
  setRateCarGenerations,
  setRateCarGenerationsPending,
  setRateCarParameters,
  setRateCarParametersPending,
} from '@/store/reducers/rate-car-data';
import {
  selectOnlineUserChoice,
  setOnlineChosenBodyType,
  setOnlineChosenGeneration,
  setOnlineUserChoice,
} from '@/store/reducers/online-valuation-user-data';
import ParametersContent from '@/modules/ValueCar/components/OnlineValuation/components/ChosenCarsInfo/components/ParametersContent';
import { OnlineCheckedValuesModel } from '@/modules/ValueCar/components/OnlineValuation/interfaces';
import { getOnlineDataWithValues } from '@/modules/ValueCar/components/OnlineValuation/helpers';
import {
  getZoomosChosenParameters,
  getZoomosGenerations,
} from '@/services/requests';

import TypedContent from './components/TypedContent';

interface Props {
  bodyTypeRef: Ref<HTMLDivElement | null>;
  generationRef: Ref<HTMLDivElement | null>;
  parametersRef: Ref<HTMLDivElement | null>;
  isBodyTypeChecked: boolean;
  isGenerationChecked: boolean;
  isParametersChecked: boolean;
  checkedValues: OnlineCheckedValuesModel;
  setCheckedValues: Dispatch<SetStateAction<OnlineCheckedValuesModel>>;
}

function ChosenCarsInfo({
  bodyTypeRef,
  generationRef,
  parametersRef,
  isBodyTypeChecked,
  isParametersChecked,
  isGenerationChecked,
  checkedValues,
  setCheckedValues,
}: Props) {
  const rateCarData = useTypedSelector(selectRateCarsData);
  const userChoice = useTypedSelector(selectOnlineUserChoice);

  const dispatch = useTypedDispatch();

  useUpdateEffect(() => {
    (async () => {
      if (
        userChoice.bodyType &&
        userChoice.brand &&
        userChoice.model &&
        userChoice.year
      ) {
        dispatch(setRateCarGenerationsPending);
        dispatch(setRateCarGenerations([]));
        dispatch(setRateCarParameters([]));
        dispatch(
          setOnlineUserChoice(
            getOnlineDataWithValues(
              userChoice.brand,
              userChoice.model,
              userChoice.year,
              userChoice.bodyType
            )
          )
        );
        const response = await getZoomosGenerations(
          userChoice.brand,
          userChoice.model,
          userChoice.year,
          userChoice.bodyType
        );

        dispatch(setRateCarGenerations(response));
        setCheckedValues({ ...checkedValues, bodyTypeCheck: true });
      }
    })();
  }, [userChoice.bodyType]);

  useUpdateEffect(() => {
    (async () => {
      if (
        userChoice.bodyType &&
        userChoice.brand &&
        userChoice.model &&
        userChoice.year &&
        userChoice.generation
      ) {
        dispatch(setRateCarParametersPending);
        dispatch(setRateCarParameters([]));
        dispatch(
          setOnlineUserChoice(
            getOnlineDataWithValues(
              userChoice.brand,
              userChoice.model,
              userChoice.year,
              userChoice.bodyType,
              userChoice.generation
            )
          )
        );

        const response = await getZoomosChosenParameters(
          userChoice.brand,
          userChoice.model,
          userChoice.year,
          userChoice.bodyType,
          userChoice.generation
        );

        const params = [
          {
            field: 'Топливо',
            label: 'fuel',
            values: response.fuels,
          },
          {
            field: 'Коробка передач',
            label: 'gearBox',
            values: response.gearboxes,
          },
          {
            field: 'Мощность двигателя',
            label: 'enginePower',
            values: response.enginePowers.map(
              (item) => item.toString() + ' л.с'
            ),
          },
          {
            field: 'Объем двигателя',
            label: 'engineVolume',
            values: response.engineVolumes.map(
              (item) => item.toString() + ' л.'
            ),
          },
        ];

        dispatch(setRateCarParameters(params));
        setCheckedValues({ ...checkedValues, generationCheck: true });
      }
    })();
  }, [userChoice.generation]);

  return (
    <Component>
      <TypedContent
        ref={bodyTypeRef}
        isShowIcon={Boolean(rateCarData.bodyTypes.length === 0)}
        title={'Тип кузова'}
        values={{ valueTitle: '', valuesList: rateCarData.bodyTypes }}
        dispatch={dispatch}
        isContentSelect={userChoice.bodyType}
        hintText={'Выберите год выпуска'}
        setValue={setOnlineChosenBodyType}
        isChecked={isBodyTypeChecked}
      />

      <TypedContent
        ref={generationRef}
        isShowIcon={Boolean(rateCarData.generations.length === 0)}
        title={'Поколение'}
        values={{ valueTitle: '', valuesList: rateCarData.generations }}
        dispatch={dispatch}
        isContentSelect={userChoice.generation}
        hintText={'Выберите тип кузова'}
        setValue={setOnlineChosenGeneration}
        isChecked={isGenerationChecked}
      />

      <ParametersContent
        ref={parametersRef}
        isShowIcon={
          rateCarData.parameters ? rateCarData.parameters.length === 0 : true
        }
        title={'Технические характеристики'}
        hintText={'Выберите поколение'}
        values={rateCarData.parameters}
        isChecked={isParametersChecked}
        checkedValues={checkedValues}
        setCheckedValues={setCheckedValues}
      />
    </Component>
  );
}

export default ChosenCarsInfo;

const Component = styled.div`
  margin-top: 40px;
`;
