import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/EvaluationRedemption/common.styles';
import { useEvaluationRedemptionData } from '@/modules/EvaluationRedemption/hooks';
import HeroFeaturesBanner from '@/components/Redesign/HeroFeaturesBanner';
import { media } from '@/utils/mixin';
import { FeatureItemProps } from '@/components/Redesign/HeroFeaturesBanner/components/Features/components/FeatureItem/types';

function Hero() {
  const {
    templateFields: {
      heroImage,
      heroImageMobile,
      heroTitle,
      heroText,
      heroAdvantages,
    },
  } = useEvaluationRedemptionData();

  if (!heroImage || !heroTitle) {
    return null;
  }

  const features: FeatureItemProps[] = heroAdvantages.map(
    ({ image, text }) => ({
      image,
      text: text ?? '',
    })
  );

  return (
    <Component>
      <ContentContainer isBig>
        <HeroFeaturesBanner
          image={heroImage}
          mobileImage={heroImageMobile}
          title={heroTitle ?? ''}
          text={heroText ?? ''}
          features={features}
        />
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.div`
  ${media.tablet(css`
    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
