import React from 'react';
import styled from 'styled-components';

import {
  useHelpOnTheRoadContext,
  useHelpOnTheRoadData,
} from '@/modules/HelpOnTheRoad/hooks';
import {
  getPackageInfoBySystemName,
  handleBuyPackage,
} from '@/modules/HelpOnTheRoad/helpers';
import BuyCard from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/BuyCard';

function Price() {
  const packages = useHelpOnTheRoadContext().packagesData;
  const adminPackages = useHelpOnTheRoadData().packages;

  const { setSelectedPackage, selectedPackageTab } = useHelpOnTheRoadContext();

  if (
    !packages ||
    packages.length === 0 ||
    !adminPackages ||
    adminPackages.length === 0
  ) {
    return null;
  }

  const selectedPackage = packages[Number(selectedPackageTab.value)];

  return (
    <Component id="prices">
      <BuyCard
        price={Number(selectedPackage.price)}
        onClick={() =>
          handleBuyPackage(
            {
              systemName: selectedPackage.systemName,
              fullName:
                getPackageInfoBySystemName(
                  adminPackages,
                  selectedPackage.systemName
                )?.fullName ?? '',
              price: selectedPackage.price,
            },
            setSelectedPackage
          )
        }
      />
    </Component>
  );
}

export default Price;

const Component = styled.div`
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ebecf0;
`;
