import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/AutoSelectionLanding/common.styles';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import StepsCards from '@/components/StepsCards';
import { media } from '@/utils/mixin';

function HowWork() {
  const { howWorkTitle, howWorkSteps } = useAutoSelectionLandingData();

  if (!howWorkSteps || howWorkSteps.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{howWorkTitle}</Title>

        <StepsCardsWrapper>
          <StepsCards
            id={2}
            steps={howWorkSteps.map(({ image, text }) => ({
              image,
              text: text ?? '',
            }))}
            cols={5}
          />
        </StepsCardsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default HowWork;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const StepsCardsWrapper = styled.div`
  margin-top: 20px;
`;
