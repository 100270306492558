import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { TabsProps } from './types';

export function Tabs<T>({
  data,
  onChange,
  activeValue,
  isBlock,
}: TabsProps<T>) {
  if (!data || data.length === 0) {
    return null;
  }

  const handleChangeTab = (value: T) => {
    onChange?.(value);
  };

  return (
    <Component>
      <TabsWrapper isBlock={isBlock}>
        {data.map(({ value, label }, index) => (
          <Tab
            key={index}
            onClick={() => handleChangeTab(value)}
            isActiveTab={value === activeValue}
          >
            {label}
          </Tab>
        ))}
      </TabsWrapper>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
`;

const TabsWrapper = styled.div<{ isBlock?: boolean }>`
  display: flex;
  border-radius: 15px;
  background: ${colors.gray200};

  ${({ isBlock }) =>
    isBlock &&
    css`
      ${media.tablet(css`
        flex-direction: column;
      `)}
    `};
`;

const Tab = styled.div<{ isActiveTab?: boolean }>`
  padding: 8px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border-radius: 15px;
  transition: 0.15s;
  cursor: pointer;
  text-align: center;
  background: ${colors.gray200};
  color: ${colors.black100};

  &:hover {
    color: ${colors.main};
  }

  ${({ isActiveTab }) =>
    isActiveTab &&
    css`
      font-weight: 700;
      background: rgba(20, 27, 39, 0.56);
      color: ${colors.white};

      &:hover {
        color: ${colors.white};
      }
    `};
`;
