import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import FaqAccordion from '@/components/FaqAccordion';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';

function Questions() {
  const { questionTitle, questionItems } = useSpecialOfferData();

  return (
    <Component>
      <Container>
        <Title>{questionTitle}</Title>
        <QuestionsContainer>
          <FaqAccordion data={questionItems} />
        </QuestionsContainer>
      </Container>
    </Component>
  );
}

export default Questions;

const Component = styled.div`
  margin-top: 60px;
`;

const Container = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.h3`
  font-size: 36px;
  line-height: 36px;
  color: ${(props) => props.theme.black};
`;

const QuestionsContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 80px;

  ${media.mobile(css`
    margin-bottom: 40px;
  `)}
`;
