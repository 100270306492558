import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/AutoSelectionLanding/common.styles';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import {
  AboutCardPrimary,
  Image as AboutCardPrimaryImage,
} from '@/components/LandingWidgets/components/AboutCardPrimary';
import { media } from '@/utils/mixin';

function About() {
  const { aboutImage, aboutMobileImage, aboutTitle, aboutText } =
    useAutoSelectionLandingData();

  if (!aboutImage || !aboutText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardPrimary
          image={aboutImage}
          imageMobile={aboutMobileImage}
          title={aboutTitle ?? ''}
          topText={aboutText}
        />
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}

  ${AboutCardPrimaryImage} {
    flex: 0 0 500px;
    height: 300px;

    ${media.tablet(css`
      flex: auto;
      height: auto;
    `)}
  }
`;
