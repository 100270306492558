import styled from 'styled-components';

import { colors } from '@/constants/theme';

import { TextFieldProps } from './types';

function TextField({ errorMessage, isRequired, ...props }: TextFieldProps) {
  return (
    <TextFieldWrapper>
      <Input {...props}></Input>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {isRequired && <RequiredMessage>*</RequiredMessage>}
    </TextFieldWrapper>
  );
}

export default TextField;

const TextFieldWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  border-radius: 10px;
  background: ${colors.white};
  padding: 12px 40px 12px 20px;
  color: ${colors.gray1500};
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
`;

const ErrorMessage = styled.p`
  position: absolute;
  color: ${colors.red};
  font-size: 13px;
  line-height: 17px;
  bottom: -22px;
`;

const RequiredMessage = styled.div`
  width: 10px;
  height: 17px;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 13px;
  line-height: 17px;
  color: ${colors.red};
`;
