import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const ContentContainer = styled.div`
  max-width: 1610px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 28px;
  `)}
`;
