import React from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import NavArrow from '@/assets/svg/nav-arrow.svg';
import { StylesHoverLink } from '@/modules/Home/components/common.styles';
import Picture from '@/components/Picture';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { HomeBenefit } from '@/modules/Home/Home.types';
import { NavButtonWrapper, NavigationButton } from '@/components/styles';

SwiperCore.use([Autoplay, Navigation, Pagination]);

interface Props {
  benefits: HomeBenefit[];
}

function BenefitsSlider({ benefits }: Props) {
  const isShowPagination = benefits?.length > 1;

  return (
    <Component>
      <Swiper
        slidesPerView="auto"
        loop={true}
        touchMoveStopPropagation={true}
        speed={1250}
        allowTouchMove={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        navigation={{
          prevEl: `.swiper-benefits-prev`,
          nextEl: `.swiper-benefits-next`,
        }}
        pagination={{
          el: '.swiper-benefits-pagination',
          type: 'bullets',
          clickable: true,
        }}
      >
        {benefits?.map((benefit, index) => (
          <SwiperSlide key={index}>
            <SliderCard>
              <StyledContainer>
                <SliderCardInner>
                  <SliderLeftBlock>
                    <SliderCardTitle>{benefit.title}</SliderCardTitle>
                    <SliderCardDescription>
                      {benefit.description}
                    </SliderCardDescription>
                    <SliderCardLink to={benefit.leftButtonLink}>
                      {benefit.leftButtonTitle}
                    </SliderCardLink>
                  </SliderLeftBlock>
                  <SliderRightBlock>
                    <StyledPicture
                      loading="lazy"
                      {...convertThumbnailToPictureProps(benefit.image)}
                    />
                    <BenefitsBlock>
                      <BenefitList>
                        {benefit.benefits.map(
                          ({ title, description }, index) => (
                            <BenefitItem key={index}>
                              <BenefitIndex rotate={(index + 1) * 45}>
                                {index + 1}
                              </BenefitIndex>
                              <BenefitInfo>
                                <BenefitTitle>{title}</BenefitTitle>
                                <BenefitDescription>
                                  {description}
                                </BenefitDescription>
                              </BenefitInfo>
                            </BenefitItem>
                          )
                        )}
                      </BenefitList>
                      <StyledButton to={benefit.rightButtonLink}>
                        {benefit.rightButtonTitle}
                      </StyledButton>
                    </BenefitsBlock>
                  </SliderRightBlock>
                </SliderCardInner>
              </StyledContainer>
            </SliderCard>
          </SwiperSlide>
        ))}
        <NavButtonWrapper className="swiper-benefits-prev" prev>
          <NavigationButton prev>
            <NavArrow />
          </NavigationButton>
        </NavButtonWrapper>
        <NavButtonWrapper className="swiper-benefits-next" next>
          <NavigationButton next>
            <NavArrow />
          </NavigationButton>
        </NavButtonWrapper>
        {isShowPagination && (
          <PaginationComponent className="swiper-benefits-pagination" />
        )}
      </Swiper>
    </Component>
  );
}

export default BenefitsSlider;

const Component = styled.div`
  padding: 30px 0;
  background: ${(props) => props.theme.white};

  ${NavigationButton} {
    svg {
      stroke: ${(props) => props.theme.black};

      path {
        fill: ${(props) => props.theme.black};
      }
    }
  }

  ${media.tabletSmall(css`
    padding: 0;

    ${NavButtonWrapper} {
      display: none;
    }
  `)}

  ${media.mobile(css`
    margin-top: 32px;
  `)}
`;

const StyledContainer = styled(ContentContainerSmall)`
  ${media.tabletSmall(css`
    padding: 0 !important;
  `)}
`;

const PaginationComponent = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;

  .swiper-pagination-bullet {
    margin: 0 4px;
    background: ${(props) => props.theme.black};
  }

  ${media.tabletSmall(css`
    bottom: 20px !important;
  `)}
`;

const SliderCard = styled.div`
  padding: 30px 0;
`;

const SliderCardInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    flex-direction: column;
  `)}
`;

const SliderCardTitle = styled.span`
  display: block;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  color: ${(props) => props.theme.black};
`;

const SliderCardDescription = styled.p`
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.blue100};
`;

const SliderCardLink = styled(Link)`
  display: inline-flex;
  margin-top: 40px;
  color: ${colors.main};

  ${StylesHoverLink}
`;

const SliderLeftBlock = styled.div`
  width: 33.33%;
  padding-right: 60px;

  ${media.tabletSmall(css`
    width: 100%;
    padding: 0 20px;
  `)}
`;

const StyledButton = styled(Link)`
  font-weight: 700;
  font-size: 0.8rem;
  background-color: ${colors.main};
  text-transform: uppercase;
  text-align: center;
  color: ${colors.white};
  padding: 13px 28px;
  border: none;
  border-radius: 1.25rem;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: ${colors.main100};
    border: none;
  }
`;

const SliderRightBlock = styled.div`
  width: 66.66%;
  display: flex;
  align-items: center;
  margin-left: 30px;
  padding: 60px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tabletSmall(css`
    width: 100%;
    align-items: initial;
    flex-direction: column;
    box-shadow: none;
    margin: 20px 0 0 0;
    padding: 0;
  `)}
`;

const BenefitIndex = styled.div<{ rotate: number }>`
  margin-right: 20px;
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.black};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: 1px solid ${colors.gray400};
    border-left-color: ${colors.main};
    border-bottom-color: ${colors.main};
    border-top-color: ${colors.main};
    transform: rotate(-${(props) => props.rotate}deg);
  }
`;

const StyledPicture = styled(Picture)`
  flex: 1;
  padding-top: 83%;
  margin: 0 60px 0 -90px;

  ${media.tabletSmall(css`
    flex: auto;
    padding-top: 126.59%;
    margin: 0;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const BenefitItem = styled.li`
  margin-top: 30px;
  display: flex;

  &:first-child {
    margin-top: 0;
  }

  ${media.tabletSmall(css`
    width: calc(50% - 20px);

    &:first-child {
      margin-top: 30px;
    }
  `)}

  ${media.mobile(
    css`
      width: 100%;
    `
  )}
`;

const BenefitsBlock = styled.div`
  flex: 1 1 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;

  ${media.tabletSmall(css`
    flex: auto;
    max-width: none;
    padding: 0 20px 20px;
  `)}
`;

const BenefitList = styled.ul`
  ${media.tabletSmall(css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  `)}
`;

const BenefitInfo = styled.div``;

const BenefitTitle = styled.span`
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black};
`;

const BenefitDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black};
`;
