import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Redesign/BreadCrumbs';
import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/VerifiedAtlantmLanding/common.styles';
import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';

import PhoneBanner from './components/PhoneBanner';
import ChatBanner from './components/ChatBanner';
import ApplicationBanner from './components/ApplicationBanner';

function Banners() {
  const { title, path } = useVerifiedAtlantmLandingData();

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={[
            { label: 'Главная', link: '/' },
            { label: title, link: path },
          ]}
        />

        <BannersWrapper>
          <ApplicationBanner />
          <PhoneBanner />
          <ChatBanner />
        </BannersWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Banners;

const Component = styled.section`
  margin-bottom: 60px;
`;

const BannersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    & > *:nth-child(1) {
      grid-column: 1/3;
    }
  `)}
`;
