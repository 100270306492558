import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

import { PostImageProps } from './types';

function PostImage({ title, image }: PostImageProps) {
  return (
    <Component>
      <ImageWrapper>
        <Image
          loading="lazy"
          {...convertThumbnailToPictureProps(image)}
          alt={'post-image'}
        />
      </ImageWrapper>

      {title && (
        <Content>
          <Title>{title}</Title>
        </Content>
      )}
    </Component>
  );
}

export default PostImage;

const Component = styled.div`
  position: relative;
  height: 272px;
  width: 600px;

  ${media.tablet(css`
    margin: 0;
    height: initial;
    width: 100%;

    order: 2;
  `)}
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 45.33%;

  ${media.tablet(css`
    padding-top: 56.716%;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
`;

const Title = styled.h1`
  display: none;

  ${media.tablet(css`
    display: block;
    font-weight: 500;
    font-size: 20px;
    max-width: 240px;
    line-height: 23px;
    margin: 0;
    color: ${(props) => props.theme.white};
  `)}
`;
