import styled from 'styled-components';

import WorkIcon from '@/assets/svg/dot.svg?url';
import { colors } from '@/constants/theme';

import { WorkProps } from './types';

function Work({ work }: WorkProps) {
  return (
    <Component>
      <Icon />
      <Text>{work}</Text>
    </Component>
  );
}

export default Work;

const Component = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

const Icon = styled.div`
  position: relative;
  min-width: 16px;
  height: 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${WorkIcon}');
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.black};
`;
