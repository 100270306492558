import React from 'react';
import styled, { css } from 'styled-components';

import { OptionType } from '@/typings/common';
import CheckIcon from '@/assets/svg/check.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { scrollStyle } from '@/components/Redesign/styles';
import ClearButton from '@/components/Redesign/ClearButton';

import { SelectOptionsProps } from './types';

function SelectOptions<T>({
  value,
  options,
  label,
  hasMaxHeight,
  onChange,
}: SelectOptionsProps<T>) {
  const handleChangeSelectOptions = (option: OptionType<T>) => {
    if (value?.value === option.value) {
      return;
    }

    onChange?.(option);
  };

  return (
    <Component>
      {label && <Label>{label}</Label>}

      <Menu hasMaxHeight={hasMaxHeight}>
        {options.map((option, index) => {
          const isActive = option.value === value?.value;

          return (
            <MenuItem
              key={index}
              isActive={isActive}
              onClick={() => handleChangeSelectOptions(option)}
            >
              {option.label}

              {isActive && <StyledCheckIcon />}
            </MenuItem>
          );
        })}
      </Menu>

      <ClearButtonWrapper>
        <ClearButton
          label="Очистить список"
          onClick={() => value && onChange?.(null)}
          isFullWidth
        />
      </ClearButtonWrapper>
    </Component>
  );
}

export default SelectOptions;

const Component = styled.div``;

const Label = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0 20px 10px;
  color: ${colors.black};
`;

const Menu = styled.ul<{ hasMaxHeight?: SelectOptionsProps['hasMaxHeight'] }>`
  max-height: 440px;
  overflow-y: auto;

  ${scrollStyle};

  ${({ hasMaxHeight }) =>
    !hasMaxHeight &&
    css`
      ${media.tablet(css`
        max-height: none;
      `)}
    `}
`;

const MenuItem = styled.li<{ isActive: boolean }>`
  cursor: pointer;
  padding: 10px 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 17px;
  transition: 0.3s;
  color: ${colors.black};

  &:hover {
    background: ${colors.gray800};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 600;
    `}
`;

const StyledCheckIcon = styled(CheckIcon)`
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  color: ${colors.blue1100};
`;

const ClearButtonWrapper = styled.div`
  margin: 20px 20px 0;
`;
