import React from 'react';

import { Page } from '@/typings/page';
import {
  ServiceContextTypes,
  ServicesTypes,
} from '@/modules/Services/Services.types';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ServiceContext } from '@/modules/Services/ServiceContext';

export function useServicesData(): Page<ServicesTypes>['templateFields'] {
  return useCurrentPage<Page<ServicesTypes>>().templateFields;
}

export function useServicesSharesTags() {
  return useServicesData().sharesItems.map((item) => item.tag ?? '');
}

export const useServiceContext = (): ServiceContextTypes =>
  React.useContext(ServiceContext);
