import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ButtonLink } from '@/components/Button';
import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { getPhoneLink } from '@/utils/common';

function Contacts() {
  const { phone } = useTopLevelFunnelLandingData();

  if (!phone) {
    return null;
  }

  return (
    <Component>
      <StyledButtonLink variant="contained" href={getPhoneLink(phone)}>
        {phone}
      </StyledButtonLink>
    </Component>
  );
}

export default Contacts;

const Component = styled.section`
  display: flex;
  justify-content: center;
  padding: 15px 0 30px;

  ${media.tablet(
    css`
      padding: 10px 0 20px;
    `
  )}
`;

const StyledButtonLink = styled(ButtonLink)`
  max-width: 300px;
`;
