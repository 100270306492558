import React from 'react';
import styled from 'styled-components';

import HeroBanner from '@/components/HeroBanner';
import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';

function Hero() {
  const { heroImage, heroMobileImage, heroTitle, heroSubtitle } =
    useFinanceLandingData();

  if (!heroImage || !heroTitle) {
    return null;
  }

  return (
    <Component>
      <HeroBanner
        image={heroImage}
        imageMobile={heroMobileImage}
        title={heroTitle}
        subtitle={heroSubtitle ?? ''}
      />
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-bottom: 15px;
`;
