import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/EmergencyManager/common.styles';
import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import HeroBanner from '@/components/Redesign/HeroBanner';

function Hero() {
  const {
    templateFields: {
      mainImage: image,
      mainImageMobile: tabletImage,
      mainTitle: title,
      mainSubtitle: subtitle,
      mainLabel: label,
    },
  } = useEmergencyManagerData();

  if (!image || !title) {
    return null;
  }

  return (
    <Component>
      <ContentContainer isBig>
        <HeroBanner
          image={image}
          title={title}
          tabletImage={tabletImage}
          subtitle={subtitle || ''}
          label={label || ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  margin-top: 20px;
  margin-bottom: 40px;

  ${media.tablet(css`
    margin-top: 0;
    margin-bottom: 20px;

    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
