import { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { useTypedDispatch } from '@/store/store';
import {
  setOnlineChosenBrand,
  setOnlineChosenModel,
  setOnlineChosenYear,
} from '@/store/reducers/online-valuation-user-data';
import { media } from '@/utils/mixin';

interface Props {
  value: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  selectedValue: Nullable<string>;
  onClose: Dispatch<SetStateAction<boolean>>;
  isBrand?: boolean;
  isModel?: boolean;
  isYear?: boolean;
}

const VerticalCollapsedItem = ({
  value,
  setFieldValue,
  selectedValue,
  isBrand,
  isModel,
}: Props) => {
  const dispatch = useTypedDispatch();

  function handleClick() {
    if (isBrand) {
      setFieldValue('brand', value);
      dispatch(setOnlineChosenBrand(value));
    } else if (isModel) {
      setFieldValue('model', value);
      dispatch(setOnlineChosenModel(value));
    } else {
      setFieldValue('year', value);
      dispatch(setOnlineChosenYear(value));
    }
  }

  return (
    <Value
      onClick={handleClick}
      isSelected={String(selectedValue) === String(value)}
    >
      {value}
    </Value>
  );
};

export default VerticalCollapsedItem;

const Value = styled.span<{ isSelected: boolean }>`
  min-width: 139px;
  display: block;
  font-size: 16px;
  padding: 10px 15px 10px 0;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? `${colors.main}` : props.theme.black};

  &:hover {
    color: ${colors.main};
  }

  ${media.mobile(
    css`
      min-width: 108px;
    `
  )}
`;
