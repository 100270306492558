import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { useServicesData } from '@/modules/Services/Services.hooks';
import Marker from '@/components/DealersMap/components/Marker';
import { getMapProps } from '@/utils/map';

function ServicesMap() {
  const data = useServicesData();

  if (!data.dealersItems || data.dealersItems.length === 0) {
    return null;
  }

  const mapProps = getMapProps(
    data.dealersItems
      .map(({ addressesMap }) =>
        addressesMap && addressesMap.length !== 0
          ? addressesMap.map(({ addressMapLatitude, addressMapLongitude }) => ({
              latitude: Number(addressMapLatitude),
              longitude: Number(addressMapLongitude),
            }))
          : []
      )
      .flat(1)
  );

  return (
    <Component>
      <Content>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
          }}
          center={mapProps.center}
          defaultZoom={mapProps.zoom}
        >
          {data.dealersItems.map(
            (item) =>
              item.addressesMap &&
              item.addressesMap.length > 0 &&
              item.addressesMap.map((itemMap, index) => {
                const latitude = +itemMap.addressMapLatitude;
                const longitude = +itemMap.addressMapLongitude;

                return (
                  <Marker
                    key={index}
                    name={item.name ?? ''}
                    address={itemMap.addressMap ?? ''}
                    lat={latitude}
                    lng={longitude}
                  />
                );
              })
          )}
        </GoogleMapReact>
      </Content>
    </Component>
  );
}

export default ServicesMap;

const Component = styled.div`
  position: relative;
  padding-top: 60%;
  overflow: hidden;
`;

const Content = styled.div`
  position: absolute;
  inset: 0;
`;
