import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import SuccessForm from '@/components/Forms/SuccessForm';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import Form from './components/Form';
import { ConsultationFormPrimaryProps } from './types';

function ConsultationFormPrimary({
  title,
  subtitle,
  request,
}: ConsultationFormPrimaryProps) {
  const [isRequestAccept, setIsRequestAccept] = useState(false);

  return (
    <Component isRequestAccept={isRequestAccept}>
      {isRequestAccept ? (
        <SuccessForm />
      ) : (
        <Content>
          <Title>{title}</Title>

          {subtitle && <SubTitle>{subtitle}</SubTitle>}

          <Form setIsRequestAccept={setIsRequestAccept} request={request} />
        </Content>
      )}
    </Component>
  );
}

export default ConsultationFormPrimary;

const Component = styled.div<{ isRequestAccept: boolean }>`
  position: relative;
  border-radius: 17px;

  display: ${(props): string => (props.isRequestAccept ? 'flex' : '')};
  align-items: ${(props): string => (props.isRequestAccept ? 'center' : '')};
  justify-content: ${(props): string =>
    props.isRequestAccept ? 'center' : ''};

  background: ${(props) => props.theme.white_1};
`;

const Content = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  box-shadow: ${(props) => props.theme.blackShadow100};
  height: fit-content;
  border-radius: 17px;

  ${media.mobile(css`
    padding: 20px;
  `)}
`;

const Title = styled.h2`
  font-size: 36px;
  padding: 48px 0 0;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 30px;
  `)}
`;

const SubTitle = styled.span`
  display: block;
  color: ${colors.gray};
  margin-top: 24px;
  font-size: 16px;
  text-align: center;
`;
