import * as Yup from 'yup';

import { ConsultationModalFields } from './types';

export const initialConsultationModalValues: ConsultationModalFields = {
  name: '',
  phone: '',
  message: '',
};

export const validationConsultationModalSchema: Yup.SchemaOf<ConsultationModalFields> =
  Yup.object().shape({
    name: Yup.string().required('Введите имя'),
    phone: Yup.string().required('Введите телефон'),
    message: Yup.string(),
  });
