import React from 'react';
import styled from 'styled-components';

import { SwitchLinks } from '@/components/LandingWidgets';
import { useCreditLeasingData } from '@/modules/CreditLeasing/hooks';

function Switch() {
  const {
    templateFields: { pageType, creditLink, leasingLink },
  } = useCreditLeasingData();

  return (
    <Component>
      <SwitchLinksWrapper>
        <SwitchLinks
          options={{
            from: { label: 'Кредит', value: creditLink ?? '' },
            to: { label: 'Лизинг', value: leasingLink ?? '' },
          }}
          checked={pageType === 'leasing'}
        />
      </SwitchLinksWrapper>
    </Component>
  );
}

export default Switch;

const Component = styled.div`
  display: flex;
  justify-content: center;
`;

const SwitchLinksWrapper = styled.div`
  width: 100%;
  max-width: 970px;
`;
