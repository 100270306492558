import * as Yup from 'yup';

import { emailRegexp } from '@/utils/textInput';

import { CallbackFormSecondaryFields } from './types';

export const initialCallbackSecondaryFormValues: CallbackFormSecondaryFields = {
  name: '',
  phone: '',
  email: '',
  brand: { label: '', value: '' },
  message: '',
};

export const validationCallbackSecondaryFormSchema: Yup.SchemaOf<CallbackFormSecondaryFields> =
  Yup.object().shape({
    name: Yup.string().required('Введите имя'),
    phone: Yup.string().required('Введите телефон'),
    email: Yup.string()
      .required('Введите Email')
      .matches(emailRegexp, 'Email введен неверно'),
    brand: Yup.object({
      label: Yup.string().required('Выберите бренд'),
      value: Yup.string().required('Выберите бренд'),
    }),
    message: Yup.string(),
  });
