import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Gallery from '@/components/Redesign/Gallery';
import { useRefitSingleLanding } from '@/modules/RefitSingleLanding/hooks';
import {
  ContentContainer,
  Title,
} from '@/modules/RefitSingleLanding/common.styles';

function MainGallery() {
  const {
    templateFields: { additionalGalleryImages, additionalGalleryTitle },
  } = useRefitSingleLanding();

  if (
    !additionalGalleryTitle ||
    !additionalGalleryImages ||
    additionalGalleryImages.length === 0
  ) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{additionalGalleryTitle}</Title>

        <GalleryWrapper>
          <Gallery
            title={additionalGalleryTitle}
            gallery={additionalGalleryImages}
          />
        </GalleryWrapper>
      </ContentContainer>
    </Component>
  );
}

export default MainGallery;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const GalleryWrapper = styled.div`
  margin-top: 20px;
`;
