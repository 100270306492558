import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  tabletPadding,
} from '@/modules/EmergencyManager/common.styles';
import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import { Title } from '@/modules/EmergencyManager/common.styles';
import Steps from '@/components/Redesign/Steps';
import { media } from '@/utils/mixin';

function StepsList() {
  const {
    templateFields: { stepsItems, stepsTitle: title },
  } = useEmergencyManagerData();

  if (!title || !stepsItems) {
    return null;
  }

  const steps = stepsItems.map(({ text, description }) => ({
    text: text || '',
    description: description || '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Title>{title}</Title>
        <Steps steps={steps} />
      </ContentContainer>
    </Component>
  );
}

export default StepsList;

const Component = styled.section`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin: 0 -${tabletPadding}px 40px 0;
  `)}
`;
