import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { Tabs as MainTabs } from '@/components/CarModelLandingWidgets';
import Tabs from '@/components/Tabs';
import { ButtonNextLink } from '@/components/Button';
import Picture from '@/components/Picture';
import TechParamsItem from '@/components/CarModelLandingWidgets/CarsTechSpec/components/TechParamsItem';
import { media } from '@/utils/mixin';

interface Props {
  title: string;
  button: { label: string; link: string };
  items: Array<{
    title: string;
    equipments: Array<{
      title: string;
      image: ThumbnailType;
      specifications: Array<{
        title: string;
        params: Array<{
          value: Nullish<string>;
          label: string;
        }>;
      }>;
    }>;
  }>;
}

function CarsTechSpec({ title, button, items }: Props) {
  const [mainTab, setMainTab] = useState(0);
  const [tab, setTab] = useState(0);

  return (
    <Components>
      <TabsWrapper>
        <MainTabs
          data={items.map((item, index) => ({
            value: index,
            label: item.title,
          }))}
          activeValue={mainTab}
          onChange={(value) => {
            setMainTab(value);
            setTab(0);
          }}
          isBlock
        />
      </TabsWrapper>
      <Title>{title}</Title>
      <ButtonWrapper>
        <ButtonNextLink to={button.link} target={'_blank'} variant={'outlined'}>
          {button.label}
        </ButtonNextLink>
      </ButtonWrapper>

      {items.length && items[mainTab] && (
        <InfoBlock>
          <InfoImage>
            <StyledPicture
              loading={'lazy'}
              {...convertThumbnailToPictureProps(
                items[mainTab].equipments[tab]?.image
              )}
            />
            <SmallTabsWrapper>
              <Tabs
                data={items[mainTab].equipments.map((equipment, index) => ({
                  value: index,
                  label: equipment.title,
                }))}
                activeTab={tab}
                onChange={setTab}
                isWhiteBackground
              />
            </SmallTabsWrapper>
          </InfoImage>
          <InfoSpec>
            {items[mainTab].equipments[tab]?.specifications.map(
              (spec, index) => (
                <SpecItem key={index}>
                  <TechParamsItem title={spec.title} params={spec.params} />
                </SpecItem>
              )
            )}
          </InfoSpec>
        </InfoBlock>
      )}
    </Components>
  );
}

export default CarsTechSpec;

const Components = styled.div``;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};

  margin: 40px 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;

  a {
    width: auto;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  margin-top: 30px;

  ${media.tablet(css`
    display: block;
  `)}
`;

const InfoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 50%;
`;

const InfoSpec = styled.div`
  flex: 50%;
  padding-left: 20px;
  ${media.tablet(css`
    padding: 20px 0 0;
  `)}
`;

const StyledPicture = styled(Picture)`
  position: relative;
  width: 100%;
  padding-top: 53.33%;

  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  ${media.tablet(css`
    width: 100%;
  `)}
`;

const SmallTabsWrapper = styled.div`
  margin-top: 20px;
`;

const SpecItem = styled.div`
  :not(:first-child) {
    margin-top: 60px;
  }
`;
