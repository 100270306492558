import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/AutoSelectionLanding/common.styles';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import { useTypedSelector } from '@/store/store';
import { selectDealerReviews } from '@/store/reducers/single-dealer';
import DealerReviews from '@/components/DealerReviews';
import { media } from '@/utils/mixin';

function Reviews() {
  const { reviewsTitle, reviewsDealer } = useAutoSelectionLandingData();
  const reviews = useTypedSelector(selectDealerReviews);

  if (
    !reviewsDealer ||
    !reviews ||
    !reviews.data ||
    reviews.data.length === 0
  ) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{reviewsTitle}</Title>

        <DealerReviewsWrapper>
          <DealerReviews
            dealerId={Number(reviewsDealer)}
            options={{
              sort: { disabled: true },
              withoutAnswer: true,
              withoutRating: true,
            }}
          />
        </DealerReviewsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Reviews;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const DealerReviewsWrapper = styled.div`
  margin-top: 20px;
`;
