import React from 'react';
import styled from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/FinanceLanding/common.styles';
import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import { FeatureCards } from '@/components/CarModelLandingWidgets';

function Instruction() {
  const { instructionTitle, instruction } = useFinanceLandingData();

  if (!instruction || instruction.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{instructionTitle}</Title>
        <FeatureCardsWrapper>
          <FeatureCards
            cards={[
              {
                cards: instruction.map(
                  ({ image, title, description, options }, index) => ({
                    image,
                    title: title ?? '',
                    description: description ?? '',
                    options:
                      options && options.length !== 0
                        ? options.map(({ image, title, description }) => ({
                            image,
                            title: title ?? '',
                            description: description ?? '',
                          }))
                        : [],
                    isReverse: index % 2 === 1,
                  })
                ),
              },
            ]}
          />
        </FeatureCardsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Instruction;

const Component = styled.section`
  padding: 15px 0;
`;

const FeatureCardsWrapper = styled.div`
  margin-top: 20px;
`;
