import React from 'react';
import styled, { css } from 'styled-components';

import {
  useHelpOnTheRoadContext,
  useHelpOnTheRoadData,
} from '@/modules/HelpOnTheRoad/hooks';
import BuyCard from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/BuyCard';
import { ButtonWrapper as BuyCardButtonWrapper } from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/BuyCard/BuyCard';
import {
  getPackageInfoBySystemName,
  handleBuyPackage,
} from '@/modules/HelpOnTheRoad/helpers';

function PriceList() {
  const packages = useHelpOnTheRoadContext().packagesData;
  const adminPackages = useHelpOnTheRoadData().packages;

  const { setSelectedPackage } = useHelpOnTheRoadContext();

  if (
    !packages ||
    packages.length === 0 ||
    !adminPackages ||
    adminPackages.length === 0
  ) {
    return null;
  }

  return (
    <Component id="prices">
      <Inner pricesCount={packages.length}>
        {packages.map(({ systemName, price }, index) => (
          <BuyCard
            key={index}
            price={Number(price)}
            onClick={() =>
              handleBuyPackage(
                {
                  systemName,
                  fullName:
                    getPackageInfoBySystemName(adminPackages, systemName)
                      ?.fullName ?? '',
                  price,
                },
                setSelectedPackage
              )
            }
          />
        ))}
      </Inner>
    </Component>
  );
}

export default PriceList;

const Component = styled.div`
  display: grid;
  grid-template-columns: 620px 1fr;
  grid-auto-flow: dense;
`;

const Inner = styled.div<{ pricesCount: number }>`
  grid-column: 2;
  display: grid;
  gap: 20px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ebecf0;

  ${BuyCardButtonWrapper} {
    max-width: 230px;
  }

  ${({ pricesCount }) =>
    pricesCount &&
    css`
      grid-template-columns: repeat(${pricesCount}, 1fr);
    `}
`;
