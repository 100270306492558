import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import Button from '@/components/Button';

import { BuyCardProps } from './types';

function BuyCard({ price, onClick }: BuyCardProps) {
  return (
    <Component>
      <Price>
        {price} <Currency>BYN</Currency>
      </Price>

      <ButtonWrapper>
        <Button variant="contained" onClick={onClick}>
          Купить
        </Button>
      </ButtonWrapper>
    </Component>
  );
}

export default BuyCard;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Price = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: ${colors.orange};
`;

const Currency = styled.span`
  font-size: 22px;
  line-height: 28px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;
