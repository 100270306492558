import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import TextField from '@/components/Redesign/TextField';
import TextareaField from '@/components/Redesign/TextareaField';
import { Button } from '@/components/Redesign/Button';
import SuccessForm from '@/components/Forms/SuccessForm';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import CloseButton from '@/components/modals/shared/CloseButton';
import CheckboxAgreement from '@/components/Checkbox/CheckboxAgreement';

import {
  initialConsultationModalValues,
  validationConsultationModalSchema,
} from './ConsultationModal.helpers';
import { ConsultationModalFields, ConsultationModalProps } from './types';

function ConsultationModal({ closeModal, innerProps }: ConsultationModalProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  const { title, request } = innerProps;

  useLockBodyScroll();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
  } = useFormik<ConsultationModalFields>({
    initialValues: initialConsultationModalValues,
    validationSchema: validationConsultationModalSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await request?.(values).then(() => {
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ConsultationModalFields>;
        setErrors(errorMap);
        console.error(error);
      }

      setSubmitting(false);
    },
  });

  return (
    <Component>
      <CloseButton onClick={closeModal} />

      {!isFormSend ? (
        <InfoWrapper>
          <Title>{title}</Title>

          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <ConsultationTextField
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Имя"
              isRequired
              errorMessage={
                errors.name && touched.name ? errors.name : undefined
              }
            />

            <ConsultationTextField
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              placeholder="Телефон"
              isRequired
              errorMessage={
                errors.phone && touched.phone ? errors.phone : undefined
              }
            />

            <TextAreaWrapper>
              <ConsultationTextAreaField
                id="message"
                name="message"
                value={values.message}
                onChange={handleChange}
                placeholder="Укажите важную на ваш взгляд информацию"
              />
            </TextAreaWrapper>

            <CheckboxWrapper>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckboxWrapper>

            <ButtonWrapper>
              <Button
                size={'big'}
                variant={'contained'}
                type="submit"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                Отправить
              </Button>
            </ButtonWrapper>
          </Form>
        </InfoWrapper>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default ConsultationModal;

const Component = styled.div`
  max-width: 670px;
  border-radius: 20px;
  background: ${colors.white};
  padding: 30px 20px;
`;

const ConsultationTextField = styled(TextField)`
  border: 1px solid ${colors.gray1300};
`;

const ConsultationTextAreaField = styled(TextareaField)`
  border: 1px solid ${colors.gray1300};
`;

const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px 10px;

  ${media.tablet(css`
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
  `)}
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${colors.black};
`;

const CheckboxWrapper = styled.div`
  grid-column: span 2;

  ${media.tablet(css`
    grid-column: auto;
  `)}
`;

const ButtonWrapper = styled.div`
  max-width: 300px;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const TextAreaWrapper = styled.div`
  grid-column: span 2;

  ${media.tablet(css`
    grid-column: span 1;
  `)}
`;
