import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useModal } from '@tager/web-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { Button } from '@/components/Redesign/Button';
import { media } from '@/utils/mixin';
import GalleryModal from '@/components/Redesign/GalleryModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';

import { GalleryProps } from './types';

function Gallery({ gallery, title, subtitle }: GalleryProps) {
  const openModal = useModal();

  const handleOpenModal = () => {
    openModal(
      GalleryModal,
      { title, subtitle, gallery },
      {
        components: { Overlay: StyledOverlay },
        scrollLockDisabled: true,
      }
    );
  };

  return (
    <Component>
      <Content>
        {gallery.slice(0, 5).map(({ image }, index) => (
          <Image
            key={index}
            {...convertThumbnailToPictureImage(image)}
            alt="gallery image"
          />
        ))}

        {gallery.length > 5 && (
          <StyledButton
            variant="outlined"
            size="small"
            onClick={handleOpenModal}
          >
            Показать еще
          </StyledButton>
        )}
      </Content>
    </Component>
  );
}

export default Gallery;

const Component = styled.div``;

const Content = styled.div`
  display: grid;
  grid-template: repeat(3, auto) / repeat(6, 1fr);
  gap: 20px;
  margin-top: 20px;

  ${media.tablet(css`
    grid-template: repeat(5, auto) / repeat(6, 1fr);
    gap: 10px;
  `)}
`;

const galleryItemStyle = css`
  padding-top: 57.853%;
  border-radius: 10px;
  background: ${colors.grayGradient100};

  ${media.tablet(css`
    padding-top: 58.21%;
  `)}

  &:nth-of-type(1) {
    grid-area: 1 / 1 / 4 / 4;

    ${media.tablet(css`
      grid-area: 1 / 1 / 1 / 7;
    `)}
  }

  &:nth-of-type(2) {
    grid-area: 1 / 4 / 3 / 6;

    ${media.tablet(css`
      grid-area: 2 / 1 / 4 / 4;
    `)}
  }

  &:nth-of-type(3) {
    ${media.tablet(css`
      grid-area: 2 / 4 / 3 / 7;
    `)}
  }

  &:nth-of-type(4) {
    ${media.tablet(css`
      grid-area: 3 / 4 / 4 / 7;
    `)}
  }

  &:nth-of-type(5) {
    grid-area: 3 / 4 / 3 / 6;
    padding-top: 25%;

    ${media.tablet(css`
      grid-area: 4 / 1 / 5 / 7;
      padding-top: 32.838%;
    `)}
  }

  img,
  video,
  article {
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    inset: 0;
    object-fit: cover;
  }
`;

const Image = styled(Picture)`
  ${galleryItemStyle};
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;

  ${media.tablet(css`
    grid-area: 5 / 1 / 5 / 7;
  `)}
`;
