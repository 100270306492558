import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/JetourHome/common.styles';
import { useJetourHomeData } from '@/modules/JetourHome/hooks';
import { media } from '@/utils/mixin';
import { DepartmentList } from '@/components/LandingWidgets';

function Departments() {
  const { departments } = useJetourHomeData();

  if (!departments || departments.length === 0) {
    return null;
  }

  return (
    <Component id="contacts">
      <ContentContainer>
        <DepartmentList
          departments={departments.map(({ name, phones, workTimes }) => ({
            name: name ?? '',
            phones:
              phones && phones.length !== 0
                ? phones.map(({ phone }) => phone ?? '')
                : [],
            workTimes:
              workTimes && workTimes.length !== 0
                ? workTimes.map(({ workTime }) => workTime ?? '')
                : [],
          }))}
        />
      </ContentContainer>
    </Component>
  );
}

export default Departments;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 0 0 40px;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;
