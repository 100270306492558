import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import About from './components/About';
import BenefitItems from './components/BenefitItems';
import PackageItems from './components/PackageItems';
import HowWork from './components/HowWork';
import Guarantee from './components/Guarantee';
import Reviews from './components/Reviews';
import Form from './components/Form';

function AutoSelectionLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <About />
        <BenefitItems />
        <PackageItems />
        <HowWork />
        <Guarantee />
        <Reviews />
        <Form />
      </Component>
    </Layout>
  );
}

export default AutoSelectionLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
