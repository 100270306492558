import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { DealersCardsItem } from '@/modules/Brands/Brands.types';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

function DealersCard({ imageItems, title, logoImage, link }: DealersCardsItem) {
  return (
    <Component to={link}>
      <Info>
        {imageItems && imageItems.length > 0 && (
          <List>
            {imageItems.map(({ image }, index) => (
              <CarLogo
                key={index}
                loading="lazy"
                {...convertThumbnailToPictureProps(image)}
              />
            ))}
          </List>
        )}

        <Title>{title}</Title>
      </Info>
      <Logo loading="lazy" {...convertThumbnailToPictureProps(logoImage)} />
    </Component>
  );
}

export default DealersCard;

const Component = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-height: 200px;
  background: ${colors.white};

  ${media.tablet(css`
    padding: 20px;
  `)}

  &:hover {
    box-shadow: ${(props) => props.theme.blackShadow100};
  }
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid rgba(165, 173, 186, 0.3);
`;

const CarLogo = styled(Picture)`
  &:not(:first-child) {
    margin-top: 15px;
  }

  picture,
  img {
    flex: 0 0 80px;
    width: 80px;
    height: 50px;
  }

  img {
    object-fit: contain;
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.blue700};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Logo = styled(Picture)`
  position: absolute !important;
  bottom: 0;
  right: 0;
  max-height: 200px;
  max-width: 50%;
`;
