import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import StockCarCard from '@/components/StockCarCard';
import { ButtonNextLink } from '@/components/Button';

import { StockCarsListProps } from './types';

function StockCarsList({ cars, buttonTitle, buttonLink }: StockCarsListProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  if (!cars || cars.length === 0) {
    return null;
  }

  return (
    <Component>
      <List>
        {cars.map((car, index) => (
          <StockCarCard key={index} {...car} isLaptop isMobile={isTablet} />
        ))}
      </List>

      <Action>
        <StyledButtonLink variant="outlined" to={buttonLink}>
          {buttonTitle}
        </StyledButtonLink>
      </Action>
    </Component>
  );
}

export default StockCarsList;

const Component = styled.div`
  position: relative;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.mobile(
    css`
      grid-template-columns: repeat(1, 1fr);
    `
  )}
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 58px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 226px;

  ${media.tablet(css`
    max-width: 280px;
  `)}
`;
