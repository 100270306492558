import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainer, Title } from '@/modules/HelpOnTheRoad/common.styles';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import StepsCards from '@/components/StepsCards';

function Rules() {
  const { rulesTitle, rulesItems } = useHelpOnTheRoadData();

  if (!rulesItems || rulesItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <Content>
        <ContentContainer>
          <Title>{rulesTitle}</Title>

          <StepsCardsWrapper>
            <StepsCards
              id={2}
              steps={rulesItems.map(({ image, text }) => ({
                image,
                text: text ?? '',
              }))}
            />
          </StepsCardsWrapper>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default Rules;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const Content = styled.div`
  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const StepsCardsWrapper = styled.div`
  margin-top: 20px;
`;
