import React from 'react';
import styled from 'styled-components';

import { useTokensLandingData } from '@/modules/TokensLanding/hooks';
import { AboutCardPrimary } from '@/components/LandingWidgets';
import { ContentContainer } from '@/modules/TokensLanding/common.styles';

function About() {
  const {
    aboutImage,
    aboutMobileImage,
    aboutTitle,
    aboutTopText,
    aboutBenefits,
    aboutBottomText,
  } = useTokensLandingData();

  if (!aboutTitle || !aboutTopText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardPrimary
          id={1}
          image={aboutImage}
          imageMobile={aboutMobileImage}
          title={aboutTitle}
          topText={aboutTopText}
          benefits={
            aboutBenefits && aboutBenefits.length !== 0
              ? aboutBenefits.map(({ image, text }) => ({
                  image,
                  text: text ?? '',
                }))
              : []
          }
          bottomText={aboutBottomText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding: 15px 0;
`;
