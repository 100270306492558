import React, { useLayoutEffect, useState } from 'react';

import { Page } from '@/typings/page';
import useCurrentPage from '@/hooks/useCurrentPage';
import { HelpOnTheRoadPackage } from '@/services/orders/typings';
import { getHelpOnTheRoadPackagesInfo } from '@/services/orders/orders-service';

import { HelpOnTheRoad, HelpOnTheRoadContextType } from './types';
import { HelpOnTheRoadContext } from './HelpOnTheRoadContext';

export function useHelpOnTheRoadData(): Page<HelpOnTheRoad>['templateFields'] {
  return useCurrentPage<Page<HelpOnTheRoad>>().templateFields;
}

export function usePackagesInfoData(): HelpOnTheRoadPackage[] {
  const [packagesInfo, setPackagesInfo] = useState<HelpOnTheRoadPackage[]>([]);

  useLayoutEffect(() => {
    (async () => {
      try {
        const response = await getHelpOnTheRoadPackagesInfo();
        setPackagesInfo(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return packagesInfo;
}

export const useHelpOnTheRoadContext = (): HelpOnTheRoadContextType =>
  React.useContext(HelpOnTheRoadContext);
