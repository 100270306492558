import React from 'react';
import styled from 'styled-components';

import HeroWithoutContent from '@/components/HeroWithoutContent';
import Info from '@/modules/PersonalCabinet/components/Info';
import MobileApp from '@/modules/PersonalCabinet/components/MobileApp';
import Layout from '@/layout';

import { useMobileAppLandingData } from './MobileAppLanding.hooks';
import Video from './components/Video';

function MobileAppLanding() {
  const {
    heroImage,
    heroTabletImage,
    heroMobileImage,
    mobileAppInfoTitle,
    mobileAppInfoItems,
    mobileAppTitle,
    mobileAppText,
    mobileAppItems,
    mobileAppImage,
  } = useMobileAppLandingData();

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
        />
        <Info
          title={mobileAppInfoTitle}
          infoItems={mobileAppInfoItems}
          mobileAppLanding
        />
        <Video />
        <MobileApp
          title={mobileAppTitle}
          text={mobileAppText}
          links={mobileAppItems}
          image={mobileAppImage}
        />
      </Component>
    </Layout>
  );
}

export default MobileAppLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
