import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { TabsProps } from './Tabs.types';

function Tabs<T>({
  data,
  activeTab,
  onChange,
  small,
  isWhiteBackground,
}: TabsProps<T>) {
  const handleActiveTabChange = (activeTab: T) => {
    onChange?.(activeTab);
  };

  return (
    <Component>
      <TabsWrapper isWhiteBackground={isWhiteBackground}>
        {data.map(({ label, value, disabled }, index) => (
          <Tab
            key={index}
            isActive={activeTab === value}
            onClick={() => !disabled && handleActiveTabChange(value)}
            small={small}
            isWhiteBackground={isWhiteBackground}
            disabled={disabled}
          >
            {label}
          </Tab>
        ))}
      </TabsWrapper>
    </Component>
  );
}

export default Tabs;

export const Component = styled.div`
  display: flex;
`;

const TabsWrapper = styled.div<{
  isWhiteBackground: TabsProps['isWhiteBackground'];
}>`
  display: flex;
  border-radius: 20px;
  background: ${colors.white};

  ${({ isWhiteBackground }) =>
    isWhiteBackground &&
    css`
      background: ${colors.gray200};
    `}
`;

export const Tab = styled.div<{
  isActive: boolean;
  small: TabsProps['small'];
  isWhiteBackground: TabsProps['isWhiteBackground'];
  disabled?: boolean;
}>`
  font-size: 14px;
  line-height: 17px;
  padding: 10px 14px;
  text-align: center;
  border-radius: 20px;
  background: ${colors.white};
  color: ${colors.black200};
  cursor: pointer;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}

  ${media.tablet(css`
    font-size: 13px;
    line-height: 16px;
    padding: 8px 16px;
  `)}

  ${({ small }) =>
    small &&
    css`
      font-size: 16px;
      line-height: 24px;
      padding: 8px 16px;

      ${media.tablet(css`
        font-size: 13px;
        line-height: 16px;
      `)}
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.main};
      color: ${colors.white};
    `}

  ${({ isWhiteBackground, isActive }) =>
    isWhiteBackground &&
    !isActive &&
    css`
      background: ${colors.gray200};
    `}
`;
