import React from 'react';
import styled, { css } from 'styled-components';

import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import PackageCard from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/PackageCard';
import { Component as PackageCardComponent } from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/PackageCard/PackageCard';

function PackageList() {
  const { packages } = useHelpOnTheRoadData();

  if (!packages || packages.length === 0) {
    return null;
  }

  return (
    <Component>
      <Inner packagesCount={packages.length}>
        {packages.map(({ shortName, isHit, image }, index) => (
          <PackageCardItem key={index}>
            <PackageCard title={shortName ?? ''} isHit={isHit} image={image} />
          </PackageCardItem>
        ))}
      </Inner>
    </Component>
  );
}

export default PackageList;

const Component = styled.div`
  display: grid;
  grid-template-columns: 620px 1fr;
  grid-auto-flow: dense;
`;

const Inner = styled.div<{ packagesCount: number }>`
  grid-column: 2;
  display: grid;
  gap: 20px;

  ${({ packagesCount }) =>
    packagesCount &&
    css`
      grid-template-columns: repeat(${packagesCount}, 1fr);
    `}
`;

const PackageCardItem = styled.div`
  ${PackageCardComponent} {
    width: 100%;
  }
`;
