import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { Brand as BrandProps } from '@/modules/Atlantm30/Atlantm30.types';
import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function Brand({ image, name, link }: BrandProps) {
  return (
    <Component to={link}>
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
      <Name>{name}</Name>
    </Component>
  );
}

export default Brand;

const Component = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;

  ${media.tabletSmall(css`
    padding: 20px 10px;
  `)}
`;

const Image = styled(Picture)`
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
`;

const Name = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.main};
`;
