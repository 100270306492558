import React from 'react';
import styled, { css } from 'styled-components';

import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { media } from '@/utils/mixin';

import Buttons from './components/Buttons';
import CarsSlider from './components/CarsSlider';
import CarsGrid from './components/CarsGrid';

function MayLike() {
  const { mayLikeTitle, mayLikeSubtitle, mayLikeCarsView } =
    useSpecialOfferData();

  return (
    <Component>
      <Container>
        <Title>{mayLikeTitle}</Title>
        <Subtitle>{mayLikeSubtitle}</Subtitle>
        {mayLikeCarsView === 'row' ? <CarsSlider /> : <CarsGrid />}
        <Buttons />
      </Container>
    </Component>
  );
}

export default MayLike;

const Component = styled.div``;

const Container = styled.div`
  margin: 20px auto 0 auto;
  max-width: 1280px;
  padding: 0 40px;
  text-align: center;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.h2`
  font-size: 36px;
  line-height: 42px;
  color: ${(props) => props.theme.black};
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 30px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue200};
`;
