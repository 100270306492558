import React from 'react';
import styled, { css } from 'styled-components';

import { useEvaluationRedemptionData } from '@/modules/EvaluationRedemption/hooks';
import { ContentContainer } from '@/modules/EvaluationRedemption/common.styles';
import Breadcrumbs from '@/components/Redesign/BreadCrumbs';
import { media } from '@/utils/mixin';

function Links() {
  const { title, path } = useEvaluationRedemptionData();

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={[
            { label: 'Главная', link: '/' },
            { label: title, link: path },
          ]}
        />
      </ContentContainer>
    </Component>
  );
}

export default Links;

const Component = styled.div`
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;
