import { useCarModelCompareLandingData } from '@/modules/CarModelCompareLanding/hooks';
import { CollapseTableProps } from '@/components/CarModelCompareLandingWidgets/CollapseTable/types';

export const useCollapseTableData = (
  isDifferentParams?: boolean
): CollapseTableProps[] => {
  const { models, modelsDetails } = useCarModelCompareLandingData();

  if (
    !models ||
    models.length === 0 ||
    !modelsDetails ||
    modelsDetails.length === 0
  ) {
    return [];
  }

  const modelsComplectations = models.map(({ name }) => name);

  const convertedData = modelsDetails
    .map(({ sectionName, sectionParams }) => ({
      title: sectionName ?? '',
      collapse: sectionParams.map(({ param, value }) => ({
        name: param ?? '',
        cells: value
          ? value.split('\n').map((resultValue, index) => ({
              name: modelsComplectations[index] ?? '',
              value:
                resultValue === '+'
                  ? ''
                  : resultValue === '-'
                  ? null
                  : resultValue,
            }))
          : [],
      })),
    }))
    .filter(({ collapse }) => collapse.length !== 0);

  if (!isDifferentParams) {
    return convertedData;
  } else {
    return convertedData
      .map((item) => {
        return {
          title: item.title,
          collapse: item.collapse.filter((item) => {
            const values = item.cells.map((item) => item.value);
            const uniqueValues = [...new Set(values)];

            return uniqueValues.length > 1;
          }),
        };
      })
      .filter(({ collapse }) => collapse.length !== 0);
  }
};
