import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import Brand from '@/components/Redesign/Brand';
import { media } from '@/utils/mixin';
import { HideScrollbar } from '@/components/styles';

import SliderWrapper from '../SliderWrapper';

import { CarBrandsSliderProps } from './types';

function BrandsSlider({ brands }: CarBrandsSliderProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <SliderWrapper
        options={{
          slidesPerView: 'auto',
          spaceBetween: !isTablet ? 20 : 10,
          withNavigation: true,
        }}
      >
        {brands.map((brand, index) => (
          <SwiperSlide key={index} className={'swiper-no-swiping'}>
            <Brand image={brand} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default BrandsSlider;

const Component = styled.div`
  transition: ease 0.3s;

  .swiper-container {
    ${HideScrollbar}

    overflow: auto;
  }

  .swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
  }

  .swiper-no-swiping {
    flex: 0 0 140px;
    height: 70px;

    ${media.tablet(css`
      flex: 0 0 105px;
      height: 50px;
    `)}
  }
`;
