import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer, Title } from '@/modules/HelpOnTheRoad/common.styles';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { media } from '@/utils/mixin';
import Benefits from '@/components/Benefits';

function BenefitItems() {
  const { benefitsTitle, benefits } = useHelpOnTheRoadData();

  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <Content>
        <ContentContainer>
          <Title>{benefitsTitle}</Title>

          <BenefitsWrapper>
            <Benefits
              id={1}
              benefits={benefits.map(({ image, title, text }) => ({
                image,
                title: title ?? '',
                text: text ?? '',
              }))}
            />
          </BenefitsWrapper>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default BenefitItems;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const Content = styled.div`
  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const BenefitsWrapper = styled.div`
  margin-top: 20px;
`;
