import React from 'react';
import styled from 'styled-components';

import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import CarsTechSpec from '@/components/CarModelLandingWidgets/CarsTechSpec';
import { ContentContainer } from '@/modules/CarModelLanding/common.styles';

function TechSpecifications() {
  const {
    techSpecificationsTitle,
    techSpecificationsItems,
    techSpecificationsButtonLabel,
    techSpecificationsButtonLink,
  } = useCarModelLandingData();

  if (!techSpecificationsItems || techSpecificationsItems.length === 0) {
    return null;
  }

  const itemsList = techSpecificationsItems.map((item) => ({
    title: item.title ?? '',
    equipments: item.equipments.map((equipment) => ({
      title: equipment.title ?? '',
      image: equipment.image,
      specifications: equipment.specifications.map((spec) => ({
        title: spec.title ?? '',
        params: spec.params.map((param) => ({
          value: param.value,
          label: param.label ?? '',
        })),
      })),
    })),
  }));

  return (
    <Components id={'tech-specification'}>
      <ContentContainer>
        <CarsTechSpec
          title={techSpecificationsTitle ?? ''}
          button={{
            label: techSpecificationsButtonLabel ?? '',
            link: techSpecificationsButtonLink ?? '',
          }}
          items={itemsList}
        />
      </ContentContainer>
    </Components>
  );
}

export default TechSpecifications;

const Components = styled.section`
  padding: 30px 0;
`;
