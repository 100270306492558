import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/AutoSelectionLanding/common.styles';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import ServiceBanner from '@/components/ServiceBanner';
import { media } from '@/utils/mixin';

function Guarantee() {
  const {
    guaranteeImage,
    guaranteeMobileImage,
    guaranteeTitle,
    guaranteeList,
  } = useAutoSelectionLandingData();

  if (!guaranteeImage) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <ServiceBanner
          title={guaranteeTitle ?? ''}
          list={guaranteeList ? guaranteeList.split('\n') : []}
          image={guaranteeImage}
          imageMobile={guaranteeMobileImage}
        />
      </ContentContainer>
    </Component>
  );
}

export default Guarantee;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
