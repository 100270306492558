import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useAboutData } from '@/modules/About/About.hooks';
import { colors } from '@/constants/theme';

function HoldingStructure() {
  const { holdingStructureTitle, holdingStructureItems } = useAboutData();

  if (!holdingStructureItems || holdingStructureItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <Header>
            <Title>{holdingStructureTitle}</Title>
          </Header>
          <List>
            {holdingStructureItems.map(({ number, text }, index) => (
              <Stats key={index}>
                <Number>{number}</Number>
                <Text>{text}</Text>
              </Stats>
            ))}
          </List>
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default HoldingStructure;

const Component = styled.section`
  padding: 45px 0;

  ${media.tablet(css`
    padding: 40px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Header = styled.div`
  padding: 10px;
  width: 50%;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  max-width: 400px;
  margin-top: 10px;

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
    max-width: none;
    margin-top: 0;
  `)}
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  ${media.tablet(css`
    margin-top: 20px;
  `)};

  @media (max-width: 598px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
`;

const Number = styled.span`
  font-weight: 700;
  font-size: 72px;
  line-height: 88px;
  color: ${colors.main};

  ${media.tablet(css`
    font-size: 40px;
    line-height: 52px;
  `)}
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  color: ${colors.blue600};
`;
