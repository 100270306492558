import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/ServiceValuationLanding/common.styles';
import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import { AboutCardPrimary } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

function About() {
  const {
    templateFields: { aboutTitle, aboutText },
  } = useServiceValuationLandingData();

  if (!aboutText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardPrimary title={aboutTitle ?? ''} topText={aboutText} />
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
