import React, { useEffect } from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import PencilIcon from '@/assets/svg/pencil.svg';

import { CarValuationSubtitleProps } from './types';

function CarValuationSubtitle({
  brand,
  model,
  year,
  setIsFormShown,
}: CarValuationSubtitleProps) {
  function openCollapsedMenu() {
    setIsFormShown((prevState) => !prevState);
  }

  useEffect(() => {
    if (!Boolean(brand) || !Boolean(model) || !Boolean(year)) {
      setIsFormShown(true);
    }
  }, []);

  return (
    <Component>
      <Span>{`${brand} ${model} ${year}`}</Span>
      <OpenCollapsedMenu>
        <PencilIcon onClick={openCollapsedMenu} />
      </OpenCollapsedMenu>
    </Component>
  );
}

export default CarValuationSubtitle;

const Component = styled.div`
  display: flex;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const Span = styled.span`
  color: ${colors.main};
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
`;

const OpenCollapsedMenu = styled.div`
  margin-left: 20px;
  width: 16px;
  height: 20px;

  svg {
    fill: ${colors.main};
    cursor: pointer;
  }
`;
