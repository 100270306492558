import React from 'react';
import { SwiperSlide } from 'swiper/react';

import SliderWrapper from '@/components/Redesign/SliderWrapper';

import FeatureItem from '../FeatureItem';

import { FeaturesSliderProps } from './types';

function FeatureSlider({ features, isWhiteColor }: FeaturesSliderProps) {
  return (
    <SliderWrapper
      options={{
        withPagination: true,
        spaceBetween: 20,
      }}
    >
      {features.map((feature, index) => (
        <SwiperSlide key={index}>
          <FeatureItem {...feature} isWhiteColor={isWhiteColor} />
        </SwiperSlide>
      ))}
    </SliderWrapper>
  );
}

export default FeatureSlider;
