import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { BenefitCard } from '@/components/CarModelLandingWidgets';
import sedan from '@/assets/svg/car-model-landing/sedan.svg?url';
import complectation from '@/assets/svg/car-model-landing/complectation.svg?url';
import petrol from '@/assets/svg/car-model-landing/petrol.svg?url';
import fuel from '@/assets/svg/car-model-landing/fuel.svg?url';
import speed from '@/assets/svg/car-model-landing/speed.svg?url';
import guaranteeIcon from '@/assets/svg/car-model-landing/guarantee.svg?url';

import { getComplectationText } from './helpers';
import { FeatureListPrimaryProps } from './types';

export function FeatureListPrimary({
  bodyType,
  complectationsCount,
  fuelSpendBoth,
  fuelSpendCity,
  fuelSpendTrack,
  fuelType,
  timeTo100,
  guarantee,
}: FeatureListPrimaryProps) {
  const isFuelSpend = fuelSpendCity || fuelSpendTrack || fuelSpendBoth;
  const getFuelSpendTitle = `Расход от ${
    fuelSpendCity ? fuelSpendCity + '/' : ''
  }${fuelSpendTrack ? fuelSpendTrack + '/' : ''}${
    fuelSpendBoth ? fuelSpendBoth : ''
  }`;
  const getFuelSpendText = `${fuelSpendCity ? 'город,' : ''}${
    fuelSpendTrack ? 'трасса,' : ''
  }${fuelSpendBoth ? 'смешанный' : ''}`;

  return (
    <Component>
      {bodyType && <BenefitCard image={{ url: sedan }} title={bodyType} />}

      {complectationsCount ? (
        <BenefitCard
          image={{ url: complectation }}
          title={`${String(complectationsCount)} ${getComplectationText(
            complectationsCount
          )}`}
        />
      ) : null}

      {fuelType && <BenefitCard image={{ url: petrol }} title={fuelType} />}

      {isFuelSpend ? (
        <BenefitCard
          image={{ url: fuel }}
          title={getFuelSpendTitle}
          text={getFuelSpendText}
        />
      ) : null}

      {timeTo100 && (
        <BenefitCard
          image={{ url: speed }}
          title={`Разгон от ${String(timeTo100)} сек`}
        />
      )}

      {guarantee && (
        <BenefitCard image={{ url: guaranteeIcon }} title={guarantee} />
      )}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  justify-content: space-around;

  ${media.tabletSmall(css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
