import React from 'react';
import styled, { css } from 'styled-components';

import Loader from '@/components/Loader';
import { media } from '@/utils/mixin';

import {
  BottomWrapper,
  PriceWrapper,
  Price,
  PriceLabel,
  StyledButton,
} from '../../common.styles';

import { PaymentProvidersProps } from './types';
import PaymentCheckbox from './components/PaymentCheckbox';
import { Component as PaymentCheckboxComponent } from './components/PaymentCheckbox/PaymentCheckbox';

function PaymentProviders({
  price,
  isLoading,
  paymentType,
  setPaymentType,
  paymentProviders,
  onSubmit,
}: PaymentProvidersProps) {
  const handlePaymentCheck = (
    isChecked: boolean,
    paymentType: 'WEBPAY' | 'WEBPAY_ERIP' | 'OPLATI'
  ) => {
    if (isChecked) {
      setPaymentType(paymentType);
    } else {
      setPaymentType('');
    }
  };

  return (
    <Component>
      <Title>Выберите способ оплаты</Title>
      <Payments>
        {paymentProviders.find((item) => item === 'WEBPAY') && (
          <PaymentCheckboxWrapper>
            <PaymentCheckbox
              label="Картой Visa, MasterCard или Белкарт"
              checked={paymentType === 'WEBPAY'}
              paymentType="BANK_CARD"
              onChange={(value) => handlePaymentCheck(value, 'WEBPAY')}
            />
          </PaymentCheckboxWrapper>
        )}

        {paymentProviders.find((item) => item === 'OPLATI') && (
          <PaymentCheckboxWrapper>
            <PaymentCheckbox
              label="Оплати (QR-код)"
              checked={paymentType === 'OPLATI'}
              paymentType="OPLATI"
              onChange={(value) => handlePaymentCheck(value, 'OPLATI')}
            />
          </PaymentCheckboxWrapper>
        )}

        {paymentProviders.find((item) => item === 'WEBPAY_ERIP') && (
          <PaymentCheckboxWrapper>
            <PaymentCheckbox
              label="ЕРИП"
              checked={paymentType === 'WEBPAY_ERIP'}
              paymentType="ERIP"
              onChange={(value) => handlePaymentCheck(value, 'WEBPAY_ERIP')}
            />
          </PaymentCheckboxWrapper>
        )}
      </Payments>

      <BottomWrapper>
        <PriceWrapper>
          <PriceLabel>К оплате</PriceLabel>
          <Price>{price} BYN</Price>
        </PriceWrapper>
        <StyledButton
          variant="contained"
          onClick={onSubmit}
          disabled={isLoading || !paymentType}
        >
          {isLoading ? <Loader color={'#fff'} /> : 'Оплатить'}
        </StyledButton>
      </BottomWrapper>
    </Component>
  );
}

export default PaymentProviders;

const Component = styled.div``;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.black200};
`;

const Payments = styled.div`
  display: flex;
  margin-top: 20px;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const PaymentCheckboxWrapper = styled.div`
  width: 300px;

  ${media.mobile(css`
    width: 100%;
  `)}

  ${PaymentCheckboxComponent} {
    height: 100%;

    ${media.tablet(css`
      height: 121px;
    `)}
  }

  &:not(:first-child) {
    margin-left: 20px;

    ${media.tablet(css`
      margin-left: 0;
      margin-top: 20px;
    `)}
  }
`;
