import styled, { css } from 'styled-components';

import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import FaqAccordion from '@/components/Redesign/FaqAccordion';
import { media } from '@/utils/mixin';
import {
  ContentContainer,
  Title,
} from '@/modules/VerifiedAtlantmLanding/common.styles';

function Faq() {
  const {
    templateFields: { faqTitle, faqItems },
  } = useVerifiedAtlantmLandingData();

  if (!faqItems || faqItems.length === 0) {
    return null;
  }

  const questions = faqItems.map(({ question, answer }) => ({
    question: question ?? '',
    answer: answer ?? '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Title>{faqTitle}</Title>
        <FaqAccordion questions={questions} />
      </ContentContainer>
    </Component>
  );
}

export default Faq;

const Component = styled.section`
  margin-bottom: 135px;

  ${media.tablet(css`
    margin-bottom: 66px;
  `)};
`;
