import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/ZeekrHome/common.styles';
import { media } from '@/utils/mixin';
import { useZeekrHomeData } from '@/modules/ZeekrHome/hooks';
import { Title, News as NewsList } from '@/components/LandingWidgets';

function News() {
  const { newsTitle, newsItems, newsButtonText, newsButtonLink } =
    useZeekrHomeData();

  if (!newsItems || newsItems.length === 0) {
    return null;
  }

  return (
    <Component id="news">
      <ContentContainer>
        <Title title={newsTitle ?? ''} />

        <NewsListWrapper>
          <NewsList
            news={newsItems.map(
              ({ image, title, description, date, link }) => ({
                image,
                title: title ?? '',
                description: description ?? '',
                date: date ?? '',
                url: link ?? '',
              })
            )}
            buttonText={newsButtonText ?? ''}
            buttonLink={newsButtonLink ?? ''}
          />
        </NewsListWrapper>
      </ContentContainer>
    </Component>
  );
}

export default News;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const NewsListWrapper = styled.div`
  margin-top: 20px;
`;
