import React from 'react';
import styled, { css } from 'styled-components';

import { ScrollStyles } from '@/components/styles';
import { BenefitItemType } from '@/modules/SpecialOffer/SpecialOffer.types';
import { media } from '@/utils/mixin';

import BenefitItem from './component/BenefitItem';

interface Props {
  benefitsItems: BenefitItemType[];
}

function BenefitsList({ benefitsItems }: Props) {
  return (
    <Content>
      {benefitsItems &&
        benefitsItems.map(
          (
            { title, image, description, list, note, textColor, cardColor },
            index
          ) => {
            return (
              <BenefitItem
                key={index}
                title={title}
                image={image}
                description={description}
                list={list}
                note={note}
                textColor={textColor}
                cardColor={cardColor}
              />
            );
          }
        )}
    </Content>
  );
}

export default BenefitsList;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    overflow-x: auto;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    overflow-x: unset;
    align-items: center;
    flex-direction: column;
    padding: 0;
  `)}

  ${ScrollStyles}
`;
