import React from 'react';
import styled from 'styled-components';

import { DealershipsProps } from '@/components/Dealerships/Dealerships.types';

import Dealer from './components/Dealer';

function Dealerships({ dealers }: DealershipsProps) {
  return (
    <Component>
      <DealersList>
        {dealers.map(({ name, legalName, phones, url, address }, index) => (
          <Dealer
            key={index}
            url={url}
            name={name}
            legalName={legalName}
            phones={phones}
            address={address}
          />
        ))}
      </DealersList>
    </Component>
  );
}

export default Dealerships;

const Component = styled.div``;

const DealersList = styled.div``;
