import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import Benefit from '../Benefit';

import { BenefitListProps } from './types';

function BenefitList({ benefits, cols = 4, isHorizontal }: BenefitListProps) {
  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component cols={cols} isHorizontal={isHorizontal}>
      {benefits.map((benefit, index) => (
        <Benefit key={index} isHorizontal={isHorizontal} {...benefit} />
      ))}
    </Component>
  );
}

export default BenefitList;

const Component = styled.div<{ cols?: number; isHorizontal?: boolean }>`
  display: grid;
  gap: 30px;

  ${({ cols, isHorizontal }) =>
    css`
      grid-template-columns: repeat(${!isHorizontal ? cols : 1}, 1fr);

      ${media.tablet(css`
        grid-template-columns: repeat(${!isHorizontal ? 2 : 1}, 1fr);
      `)}
    `}
`;
