import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { ContentContainerSmall } from '@/components/ContentContainer';
import InfoListItem from '@/modules/PersonalCabinet/components/Info/InfoListItem';
import { media } from '@/utils/mixin';
import { PersonalCabinetInfoItem } from '@/modules/PersonalCabinet/PersonalCabinet.types';

interface InfoProps {
  title: Nullable<string>;
  infoItems: PersonalCabinetInfoItem[];
  mobileAppLanding?: boolean;
}

function Info({ title, infoItems, mobileAppLanding }: InfoProps) {
  return (
    <Component>
      <ContentContainerSmall>
        <Title>{title}</Title>
        <List>
          {infoItems &&
            infoItems.map(({ text, image }, index) => (
              <InfoListItem
                key={index}
                text={text}
                image={image}
                mobileAppLanding={mobileAppLanding}
              />
            ))}
        </List>
      </ContentContainerSmall>
    </Component>
  );
}

export default Info;

const Component = styled.section`
  padding: 40px 0 20px;

  ${media.mobile(css`
    padding: 20px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  margin: 0;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const List = styled.div`
  margin-top: 40px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;
