import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

import { MobileSliderProps } from './types';

function MobileSlider({ images, link }: MobileSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <Component>
      <Swiper
        onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
        slidesPerView={1}
      >
        {images.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Slide to={link}>
                <StyledPicture
                  loading="lazy"
                  {...convertThumbnailToPictureProps(item)}
                />
              </Slide>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {images.length > 1 && (
        <PaginationList>
          {images.map((item, index) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
              />
            );
          })}
        </PaginationList>
      )}
    </Component>
  );
}

export default MobileSlider;

const Component = styled.div`
  position: relative;
  background-color: #efefef;
  padding-top: 65%;

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100% !important;
  }
`;

const Slide = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const StyledPicture = styled(Picture)`
  position: static !important;

  img,
  picture {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  img {
    object-fit: contain;
  }

  &::before {
    position: absolute;
    content: '';
    display: none;
    inset: 0;
    background: ${colors.white_alpha60};
    z-index: 10;
  }
`;

const PaginationList = styled.div`
  z-index: 3;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const PaginationListItem = styled.div<{
  isActive: boolean;
}>`
  content: '';
  width: 30px;
  height: 4px;
  background-color: ${colors.black};
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.white};
    `}
`;
