import React from 'react';
import styled, { css } from 'styled-components';

import ConsultationFormPrimary from '@/components/Forms/ConsultationFormPrimary';
import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';
import { media } from '@/utils/mixin';
import { FormFields } from '@/components/Forms/ConsultationFormPrimary/components/Form/types';
import { submitAmpLandingConsultation } from '@/services/leads/leads-service';

function Consultation() {
  const { formTitle, formSubtitle } = useAmpLandingData();

  if (!formTitle) {
    return null;
  }

  const handleSubmitConsultationForm = async (values: FormFields) => {
    try {
      await submitAmpLandingConsultation(values);
    } catch (error) {
      console.log('error = ', error);
      return Promise.reject(error);
    }
  };

  return (
    <Component id="consultation">
      <Container>
        <ConsultationFormPrimary
          title={formTitle}
          subtitle={formSubtitle ?? ''}
          request={handleSubmitConsultationForm}
        />
      </Container>
    </Component>
  );
}

export default Consultation;

const Component = styled.div`
  margin-top: 60px;
`;

const Container = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0;
  }

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;
