import React from 'react';
import styled from 'styled-components';

import { CarComplectationCollapsed } from '@/components/CarModelLandingWidgets';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { CarComplectationCollapsedProps } from '@/components/CarModelLandingWidgets/CarComplectationCollapsed/types';

function ComplectationStorageContentMobile() {
  const { complectations } = useCarModelLandingData();

  if (!complectations || complectations.length === 0) {
    return null;
  }

  const convertedComplectations: CarComplectationCollapsedProps[] =
    complectations.map(({ image, name, title, list, imageDetail }) => ({
      complectaionImage: image,
      complectaionName: name ?? '',
      complectaionInfo: {
        title: title ?? '',
        list: list.map(({ text }) => text ?? ''),
        image: imageDetail,
      },
    }));

  return (
    <Component>
      {convertedComplectations.map((complectation, index) => (
        <CarComplectationCollapsedWrapper key={index}>
          <CarComplectationCollapsed {...complectation} />
        </CarComplectationCollapsedWrapper>
      ))}
    </Component>
  );
}

export default ComplectationStorageContentMobile;

const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const CarComplectationCollapsedWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;
