import React from 'react';
import styled, { css } from 'styled-components';

import { useZeekrHomeData } from '@/modules/ZeekrHome/hooks';
import { VideoBanner } from '@/components/LandingWidgets';
import { Container as VideoBannerContainer } from '@/components/LandingWidgets/components/VideoBanner';
import { media } from '@/utils/mixin';

function Hero() {
  const { heroTitle, heroSubtitle, heroVideo } = useZeekrHomeData();

  if (!heroVideo || !heroVideo.url) {
    return null;
  }

  return (
    <StyledVideoBanner
      video={heroVideo.url}
      title={heroTitle ?? ''}
      subtitle={heroSubtitle ?? ''}
    />
  );
}

export default Hero;

const StyledVideoBanner = styled(VideoBanner)`
  ${VideoBannerContainer} {
    padding-top: calc(100vh - 80px);

    ${media.mobile(
      css`
        padding-top: calc(100vh - 60px);
      `
    )}
  }
`;
