import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { useIsomorphicLayoutEffect } from '@tager/web-core';

import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import StepCard from '@/modules/ElectrocarsLanding/components/PurchaseSteps/components/StepCard';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import stepListLineDesktopImage from '@/assets/svg/electrocars-landing/stepListLineDesktop.svg?url';
import stepListLineMobileImage from '@/assets/svg/electrocars-landing/stepListLineMobile.svg?url';
import carImage from '@/assets/images/electrocars-landing/car.png';
import carWheelImage from '@/assets/images/electrocars-landing/wheel.png';
import carLineImage from '@/assets/svg/electrocars-landing/carLine.svg?url';
import peopleImage from '@/assets/svg/electrocars-landing/people.svg?url';

gsap.registerPlugin(ScrollTrigger);

function AnimationBlock() {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [innerHeight, setInnerHeight] = useState(0);
  const { stepsItems } = useElectrocarsLandingData();

  useIsomorphicLayoutEffect(() => {
    if (!componentRef.current) {
      return;
    }

    const ctx = gsap.context((self) => {
      const scrollTriggerStart = 'top top';

      const scrollTriggerEnd = 'bottom bottom';

      const inner = self?.selector?.('.inner') as HTMLDivElement[] | undefined;

      if (inner && inner[0]) {
        setInnerHeight(inner[0].offsetHeight);
      }

      const steps = self?.selector?.('.steps') as HTMLDivElement[] | undefined;

      if (steps && steps[0] && componentRef && componentRef.current) {
        gsap.to(steps[0], {
          x: -(steps[0].scrollWidth - componentRef.current.clientWidth),
          scrollTrigger: {
            trigger: componentRef.current,
            start: scrollTriggerStart,
            end: scrollTriggerEnd,
            scrub: true,
          },
        });
      }

      const wheels = self?.selector?.('.wheel') as HTMLDivElement[] | undefined;

      if (wheels) {
        wheels.forEach((wheel: HTMLDivElement) => {
          gsap.to(wheel, {
            rotate: 360,
            scrollTrigger: {
              trigger: componentRef.current,
              start: scrollTriggerStart,
              end: scrollTriggerEnd,
              scrub: true,
            },
          });
        });
      }

      const people = self?.selector?.('.people') as
        | HTMLDivElement[]
        | undefined;

      if (people && people[0] && componentRef && componentRef.current) {
        gsap.to(people[0], {
          x: -(people[0].scrollWidth - componentRef.current.clientWidth),
          scrollTrigger: {
            trigger: componentRef.current,
            start: scrollTriggerStart,
            end: scrollTriggerEnd,
            scrub: true,
          },
        });
      }
    }, componentRef);

    return () => ctx.revert();
  }, []);

  if (!stepsItems || stepsItems.length === 0) {
    return null;
  }

  return (
    <Component ref={componentRef}>
      <Inner className="inner" height={innerHeight}>
        <StepList className="steps">
          {stepsItems.map(({ text }, index) => (
            <StepCardWrapper key={index}>
              <StepCard
                number={String(index + 1)}
                text={text ?? ''}
                isLastCard={stepsItems.length - 1 === index}
              />
            </StepCardWrapper>
          ))}

          <StepListLineImage
            loading="lazy"
            desktop={{ src: stepListLineDesktopImage }}
            laptop={{ src: stepListLineDesktopImage }}
            tabletSmall={{ src: stepListLineMobileImage }}
            mobile={{ src: stepListLineMobileImage }}
          />
        </StepList>

        <CarContent>
          <CarInner>
            <CarImage src={carImage} />
            <CarWheels>
              <CarWheelImage
                className="wheel"
                loading="lazy"
                src={carWheelImage}
              />
              <CarWheelImage
                className="wheel"
                loading="lazy"
                src={carWheelImage}
              />
            </CarWheels>
          </CarInner>

          <CarLineImage loading="lazy" src={carLineImage} />

          <PeopleImage className="people" loading="lazy" src={peopleImage} />
        </CarContent>
      </Inner>
    </Component>
  );
}

export default AnimationBlock;

const Component = styled.div`
  position: relative;
  height: 1500px;
`;

const Inner = styled.div<{ height: number }>`
  position: sticky;

  ${({ height }) =>
    height &&
    css`
      top: calc((100vh - 80px - ${height}px) / 2);

      ${media.mobile(css`
        top: calc((100vh - 60px - ${height}px) / 2);
      `)}
    `}
`;

const StepList = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 400px;
  padding: 0 10px;
`;

const StepCardWrapper = styled.div`
  z-index: 1;
  position: relative;
  min-width: 300px;

  ${media.tablet(css`
    min-width: 220px;
  `)}

  &:not(:first-child) {
    margin-left: 80px;

    ${media.tablet(css`
      margin-left: 50px;
    `)}
  }

  &:nth-child(odd) {
    transform: rotate(-7deg);
  }

  &:nth-child(even) {
    transform: translateY(50%) rotate(7deg);
  }
`;

const StepListLineImage = styled(Picture)`
  position: absolute;
  top: 150px;
  width: 1850px;
  height: 150px;

  ${media.tablet(css`
    top: 160px;
    width: 1320px;
    height: 126px;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;

const CarContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 10px;

  ${media.tablet(css`
    margin-top: 50px;
  `)}
`;

const CarInner = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-start;
`;

const CarImage = styled(Picture)``;

const CarWheels = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 7.652% 0 13.627%;
`;

const CarWheelImage = styled(Picture)`
  flex: 0 0 19.974%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const CarLineImage = styled(Picture)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 16px;

  picture,
  img {
    width: 100%;
    height: 16px;
  }

  img {
    object-fit: contain;
  }
`;

const PeopleImage = styled(Picture)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1850px;
  height: 800px;

  ${media.tablet(css`
    width: 1320px;
    height: 520px;
  `)}

  ${media.mobile(css`
    width: 1000px;
    height: 350px;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;
