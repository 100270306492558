import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Header from './components/Header';
import ModelsList from './components/ModelsList';
import BottomText from './components/BottomText';
import Form from './components/Form';
import Panel from './components/Panel';

function TheLineup() {
  return (
    <Layout>
      <Component>
        <Header />
        <ModelsList />
        <BottomText />
        <Form />
        <Panel />
      </Component>
    </Layout>
  );
}

export default TheLineup;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
