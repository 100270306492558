import { Page } from '@/typings/page';
import useCurrentPage from '@/hooks/useCurrentPage';
import useSettingItem from '@/hooks/useSettingItem';
import { usePageUrl } from '@/hooks/usePageUrl';
import { getFirstDateOfYear, getLastDateOfYear } from '@/utils/date';
import { getEventMicromarking } from '@/utils/seo';

import { CarModelLanding } from './types';

export function useCarModelLandingData(): Page<CarModelLanding>['templateFields'] {
  return useCurrentPage<Page<CarModelLanding>>().templateFields;
}

export function useCarModelLandingEventMicromarking(): string[] {
  const { seoParams, templateFields } = useCurrentPage<Page<CarModelLanding>>();
  const organization = useSettingItem('ORGANIZATION_NAME');
  const address = useSettingItem('MAIN_ADDRESS');
  const url = usePageUrl();

  const eventMicromarkingNames = [
    '✔️ Каталог автомобилей',
    '🔥 Характеристики',
    '🔥 Новинки',
  ];
  const eventMicromarkingData = eventMicromarkingNames.map((name) => ({
    image: templateFields.heroImage?.url ?? '',
    name,
    description: seoParams.description ?? '',
    startDate: getFirstDateOfYear(),
    endDate: getLastDateOfYear(),
    url,
    organization: organization ?? '',
    address: address ?? '',
  }));

  return eventMicromarkingData.map((data) => getEventMicromarking(data));
}
