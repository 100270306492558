import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer, Title } from '@/modules/HelpOnTheRoad/common.styles';
import FaqAccordion from '@/components/FaqAccordion';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { media } from '@/utils/mixin';

function Faq() {
  const { questionsTitle, questionsItems } = useHelpOnTheRoadData();

  if (!questionsItems || questionsItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <Content>
        <ContentContainer>
          <Title>{questionsTitle}</Title>
          <FaqAccordionWrapper>
            <FaqAccordion data={questionsItems} />
          </FaqAccordionWrapper>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default Faq;

const Component = styled.section`
  padding: 30px 0 60px;
  background: ${(props) => props.theme.gray};

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}
`;

const Content = styled.div`
  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const FaqAccordionWrapper = styled.div`
  margin-top: 20px;
`;
