import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/ServiceBenefits/common.styles';
import { useServiceBenefitsData } from '@/modules/ServiceBenefits/hooks';
import Benefits from '@/components/Benefits';
import { media } from '@/utils/mixin';

function BenefitItems() {
  const { benefitsTitle, benefits } = useServiceBenefitsData();

  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{benefitsTitle}</Title>

        <BenefitsWrapper>
          <Benefits
            id={2}
            benefits={benefits.map(({ image, title, text }) => ({
              image,
              title: title ?? '',
              text: text ?? '',
            }))}
          />
        </BenefitsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default BenefitItems;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const BenefitsWrapper = styled.div`
  margin-top: 20px;
`;
