import React from 'react';
import styled, { css } from 'styled-components';

import { useAtlantm30Data } from '@/modules/Atlantm30/Atlantm30.hooks';
import { ContentContainer } from '@/modules/Atlantm30/Atlantm30.styles';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';

function Footer() {
  const {
    moreQuestionsTitle,
    moreQuestionsDescription,
    moreQuestionsCopyright,
    phone,
  } = useAtlantm30Data();

  return (
    <Component>
      <ContentContainer>
        <Title>{moreQuestionsTitle}</Title>
        <Description>{moreQuestionsDescription}</Description>
        <Phone to={getPhoneLink(phone ?? '')}>{phone}</Phone>
        <Copyright>{moreQuestionsCopyright}</Copyright>
      </ContentContainer>
    </Component>
  );
}

export default Footer;

const Component = styled.section`
  padding: 30px 0 40px;
  background: ${colors.white};
  text-align: center;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.black100};

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.5vw;
  color: ${colors.blue600};
`;

const Phone = styled(Link)`
  font-size: 32px;
  line-height: 42px;
  color: ${colors.main};
`;

const Copyright = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${colors.blue600};
  margin-top: 20px;
`;
