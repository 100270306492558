import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Header from './components/Header';
import Panel from './components/Panel';
import Models from './components/Models';
import { CarModelCompareLandingProvider } from './CarModelCompareLandingProvider';
import Compare from './components/Compare';

function CarModelCompareLanding() {
  return (
    <Layout>
      <CarModelCompareLandingProvider>
        <Component>
          <Header />
          <Main>
            <Models />
            <Compare />
          </Main>
          <Panel />
        </Component>
      </CarModelCompareLandingProvider>
    </Layout>
  );
}

export default CarModelCompareLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;

const Main = styled.div`
  position: relative;
`;
