import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/HelpOnTheRoad/common.styles';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { media } from '@/utils/mixin';

function Text() {
  const { text } = useHelpOnTheRoadData();

  if (!text) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Content dangerouslySetInnerHTML={{ __html: text }} />
      </ContentContainer>
    </Component>
  );
}

export default Text;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}

  ${media.mobile(css`
    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.black};
`;
