import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { ButtonStyles } from '@/components/CarCard/components/common.styles';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { media } from '@/utils/mixin';

function Buttons() {
  const { mayLikeButtons } = useSpecialOfferData();

  if (!mayLikeButtons || mayLikeButtons.length === 0) {
    return null;
  }

  return (
    <Component>
      {mayLikeButtons.map(({ title, link }, index) => (
        <StyledLink key={index} to={link}>
          {title}
        </StyledLink>
      ))}
    </Component>
  );
}

export default Buttons;

const Component = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 0 auto;
  padding: 0 40px;
  width: 100%;

  ${media.mobile(css`
    flex-direction: column;
    padding: 0;
  `)}
`;

const StyledLink = styled(Link)`
  ${ButtonStyles};
  padding: 16px 0;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 8px;
  font-size: 18px;
  width: 384px;
  text-align: center;
  margin-right: 28px;

  &:last-child {
    margin-right: 0;
  }

  ${media.mobile(css`
    width: 100%;
    margin-right: 0;

    &:last-child {
      margin-top: 15px;
    }
  `)}
`;
