import { createGlobalStyle, css } from 'styled-components';

import { colors } from '@/constants/theme';

export const appearanceEffectStyle = css<{
  isVisible: boolean;
  withoutHeight?: boolean;
}>`
  visibility: hidden;
  opacity: 0;
  transition: 0.15s;

  ${({ withoutHeight }) =>
    withoutHeight &&
    css`
      height: 0;
      overflow: hidden;
    `}

  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  ${({ withoutHeight }) =>
    withoutHeight &&
    css`
      height: auto;
      overflow: initial;
    `}
`;

const showEffectStyle = css`
  content: '';
  position: absolute;
  z-index: 1;
  width: 70px;
  height: 100%;
  pointer-events: none;
`;

export const disappearanceStyle = css`
  &::after {
    right: 0;
    background: ${colors.whiteGradient};

    ${showEffectStyle}
  }
`;

export const scrollStyle = css`
  &::-webkit-scrollbar {
    width: 5px;
    border: 5px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.gray800};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray1300};
    border-radius: 20px;
  }

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: ${colors.gray1300} ${colors.gray800};
  }
`;

export const BalloonStyles = createGlobalStyle`
  .ymaps-2-1-79-balloon {
    box-shadow: none !important;
  }

  .ymaps-2-1-79-balloon__content {
    padding: 0 !important;
    background: transparent !important;
    margin: 0 !important;
  }

  .ymaps-2-1-79-balloon__layout,
  .ymaps-2-1-79-balloon__close-button {
    background: transparent !important;
    border: none !important;
  }

  .ymaps-2-1-79-balloon__tail {
    display: none;
  }`;

export const hideScrollStyle = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
