import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export const tabletPadding = 20;

export const ContentContainer = styled.div<{ isBig?: boolean }>`
  width: 100%;
  height: 100%;
  max-width: 1310px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 ${tabletPadding}px;
  `)}

  ${({ isBig }) =>
    isBig &&
    css`
      max-width: 1440px;
    `}
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 28px;
  `)}
`;
