import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import HistoryCard from '../HistoryCard';

import { StoriesProps } from './Stories.types';

const Stories = React.memo(
  ({ stories = [], storiesBlockItemsRefs }: StoriesProps) => {
    return (
      <Component>
        {stories.map(({ year, yearItems }, index) => (
          <StoryBlock
            key={index}
            id={year ?? ''}
            ref={storiesBlockItemsRefs.current[index]}
          >
            <YearTitle>{year}</YearTitle>
            {yearItems.map(({ date, title, text, image }, index) => (
              <HistoryCard
                key={index}
                date={date}
                title={title}
                text={text}
                image={image}
              />
            ))}
          </StoryBlock>
        ))}
      </Component>
    );
  }
);

export default Stories;

const Component = styled.div`
  flex: 1 1 800px;
  max-width: 800px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const StoryBlock = styled.div`
  &:last-child > div {
    padding-bottom: 0;
  }

  &:not(:first-child) {
    ${media.mobile(css`
      margin-top: 30px;
    `)}
  }
`;

const YearTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  color: ${colors.blue700};
  border-left: 1px solid ${colors.main};

  display: none;

  ${media.tablet(css`
    display: block;
  `)}

  ${media.mobile(css`
    margin-left: 20px;
  `)}
`;
