import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { ContentContainer } from '@/modules/HisunHome/common.styles';
import { media } from '@/utils/mixin';
import { useHisunHomeData } from '@/modules/HisunHome/hooks';
import { Title } from '@/components/LandingWidgets';
import { Component as TitleComponent } from '@/components/LandingWidgets/components/Title';
import { Header as SliderWrapperHeader } from '@/components/LandingWidgets/components/SliderWrapper';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { submitHisunCarsLeadForm } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import ModelsSlider from '@/components/LandingWidgets/components/Models/components/ModelsSlider';
import { getDealersOptions } from '@/utils/common';

function Buggy() {
  const openModal = useModal();
  const { buggyTitle, buggyItems, dealersRequests } = useHisunHomeData();

  if (!buggyItems || buggyItems.length === 0) {
    return null;
  }

  const sendApplicationForm = async (
    values: ApplicationFormFields,
    model: string,
    event: string
  ): Promise<void> => {
    try {
      await submitHisunCarsLeadForm({
        ...values,
        model,
        dealer: values.dealer ? Number(values.dealer.value) : undefined,
      }).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenModal = (title: string, model: string, event: string) => {
    openModal(
      ApplicationFormModal,
      {
        title,
        fieldsConfig: { dealers: getDealersOptions(dealersRequests) },
        onSubmit: (values) => sendApplicationForm(values, model, event),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component id="buggy">
      <ContentContainer>
        <TitleWrapper>
          <Title title={buggyTitle ?? ''} />
        </TitleWrapper>

        <ModelsSlider
          id={3}
          models={buggyItems.map(
            ({
              image,
              imageMobile,
              name,
              price,
              description,
              specifications,
              preorderButtonText,
              preorderFormTitle,
              link,
              isNewTabLink,
              isInStock,
              googleEvent,
            }) => ({
              image,
              imageMobile,
              name: name ?? '',
              price: price ?? '',
              description: description ?? '',
              specifications:
                specifications && specifications.length !== 0
                  ? specifications.map(({ label, value }) => ({
                      label: label ?? '',
                      value: value ?? '',
                    }))
                  : [],
              preorderText: preorderButtonText ?? '',
              link: link ?? '',
              isNewTabLink,
              isInStock,
              onPreorder: () =>
                handleOpenModal(
                  preorderFormTitle ?? '',
                  name ?? '',
                  googleEvent ?? ''
                ),
            })
          )}
        />
      </ContentContainer>
    </Component>
  );
}

export default Buggy;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${SliderWrapperHeader} {
      right: 20px;
    }
  `)}
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  ${media.tablet(css`
    margin-left: 20px;

    ${TitleComponent} {
      text-align: left;
    }
  `)}
`;
