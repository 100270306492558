import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import Navigation from './components/Navigation';
import Values from './components/Values';
import Form from './components/Form';
import Cabinet from './components/Cabinet';
import About from './components/About';
import Brands from './components/Brands';
import Services from './components/Services';
import Faq from './components/Faq';
import ConsultationForm from './components/ConsultationForm';

function ServiceValuationLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <Navigation />
        <Values />
        <Form />
        <Cabinet />
        <About />
        <Brands />
        <Services />
        <Faq />
        <ConsultationForm />
      </Component>
    </Layout>
  );
}

export default ServiceValuationLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
