import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { DescriptionProps } from './types';

export function Description({ description }: DescriptionProps) {
  return <Component>{description}</Component>;
}

const Component = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.black200};

  ${media.mobile(css`
    text-align: left;
  `)}
`;
