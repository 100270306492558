import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import PlusIcon from '@/assets/svg/plus-black.svg';
import MinusIcon from '@/assets/svg/minus.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { FaqAccordionItemProps } from './types';

function FaqAccordionItem({ question, answer }: FaqAccordionItemProps) {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Component isExpanded={isExpanded} onClick={() => setExpanded(!isExpanded)}>
      <Display isExpanded={isExpanded}>
        <Title>{question}</Title>
        <DisplayIcon>{isExpanded ? <MinusIcon /> : <PlusIcon />}</DisplayIcon>
      </Display>
      <Content isExpanded={isExpanded}>
        <ContentContainer>
          <Span dangerouslySetInnerHTML={{ __html: answer ?? '' }} />
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default FaqAccordionItem;

const Component = styled.div<{ isExpanded?: boolean }>`
  width: 100%;
  position: relative;
  border: 1px solid ${(props) => props.theme.gray1300};
  border-radius: 10px;
  overflow: hidden;
`;

const Content = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  cursor: pointer;

  @keyframes stretching {
    0% {
      height: 0;
    }
    70% {
      height: 35px;
    }
    100% {
      height: initial;
    }
  }
  @keyframes compression {
    0% {
      height: initial;
    }
    30% {
      height: 35px;
    }
    100% {
      height: 0;
    }
  }

  animation: ${(props) =>
    props.isExpanded ? 'stretching .1s' : 'compression .1s'};
  height: ${(props) => (props.isExpanded ? 'initial' : 0)};
  overflow: hidden;
  background: ${(props) => props.theme.gray800};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  text-align: left;

  ${media.tablet(css`
    padding: 0 60px 22px 20px;
  `)}
`;

const Display = styled.div<{
  isExpanded?: boolean;
}>`
  position: relative;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  cursor: pointer;
  background: ${(props) => props.theme.white_1};

  svg {
    fill: ${colors.main};
    transition: 0.3s;

    path {
      fill: ${colors.main};
    }
  }

  ${media.tablet(css`
    padding: 16px 20px;
  `)}

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      background: ${(props) => props.theme.gray800};
    `}
`;

const Title = styled.span`
  font-weight: 700;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue200};
`;

const Span = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const DisplayIcon = styled.span<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  svg {
    fill: ${(props) => props.theme.black};
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      svg {
        fill: ${(props) => props.theme.black};
      }
    `}
`;
