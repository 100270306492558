import React from 'react';
import styled from 'styled-components';

import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import {
  Title,
  ContentContainer,
} from '@/modules/FinanceLanding/common.styles';
import Faq from '@/components/Faq';

function Questions() {
  const { questionsTitle, questions } = useFinanceLandingData();

  if (!questions || questions.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{questionsTitle}</Title>

        <FaqWrapper>
          <Faq
            faq={questions.map(({ question, answer }) => ({
              question: question ?? '',
              answer: answer ?? '',
            }))}
          />
        </FaqWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Questions;

const Component = styled.section`
  padding: 15px 0 30px;

  ${Title} {
    text-align: left;
  }
`;

const FaqWrapper = styled.div`
  margin-top: 20px;
`;
