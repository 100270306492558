import React, { useEffect } from 'react';
import styled from 'styled-components';

import { scroller } from '@tager/web-core';

import Loader from '@/components/Loader';
import { colors } from '@/constants/theme';

type Props = {
  enabled: boolean;
};

const PageLoader = ({ enabled }: Props) => {
  useEffect(() => {
    if (enabled) {
      scroller.lock();
    } else {
      scroller.unlockAll();
    }
  }, [enabled]);

  if (!enabled) {
    return null;
  }

  return (
    <Wrapper>
      <Loader isFixedPosition={true} color={colors.white} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 2222222;
  background: ${colors.main};

  > div {
    z-index: 2222223;
  }
`;

export default PageLoader;
