import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

import { FeatureItemProps } from './types';

function FeatureItem({ image, text, isWhiteColor }: FeatureItemProps) {
  return (
    <Component>
      <Image
        desktop={convertThumbnailToPictureImage(image)}
        alt="feature-image"
      />
      <Text isWhiteColor={isWhiteColor}>{text}</Text>
    </Component>
  );
}

export default FeatureItem;

const Component = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

const Image = styled(Picture)`
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
`;

const Text = styled.p<{ isWhiteColor?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  color: ${({ isWhiteColor }) => (isWhiteColor ? colors.white : colors.black)};
`;
