import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import About from './components/About';
import Map from './components/Map';
import Departments from './components/Departments';
import Form from './components/Form';

function Contacts() {
  return (
    <Layout>
      <Component>
        <About />
        <Map />
        <Departments />
        <Form />
      </Component>
    </Layout>
  );
}

export default Contacts;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
