import React, { useState } from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import ItemsList from '@/components/LocationsMap/components/ItemsList/ItemsList';

import { ILocationCity } from './LocationsMap.typings';

type Props = {
  isForm?: boolean;
  activeId: Nullable<number>;
  setActiveId: (id: number) => void;
  data: Array<ILocationCity>;
};

const LocationsMapMobile = ({
  data,
  activeId,
  setActiveId,
  isForm = false,
}: Props) => {
  const [expandedCityIds, setExpandedCityIds] = useState<Array<number>>(
    data.length ? [data[0].id] : []
  );

  const onSelect = (id: number) => {
    setActiveId(id);
  };

  const onExpandedChange = (id: number, state: boolean) => {
    if (state) {
      setExpandedCityIds([...expandedCityIds, id]);
    } else {
      setExpandedCityIds(expandedCityIds.filter((item) => item !== id));
    }
  };

  return (
    <Component>
      <ItemsList
        data={data}
        selectedId={activeId}
        expandedIds={expandedCityIds}
        onExpandedChange={onExpandedChange}
        isMobile={true}
        isForm={isForm}
        onSelect={onSelect}
      />
    </Component>
  );
};

const Component = styled.div`
  position: relative;
`;

export default LocationsMapMobile;
