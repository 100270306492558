import React from 'react';
import styled, { css } from 'styled-components';

import { useServiceBenefitsData } from '@/modules/ServiceBenefits/hooks';
import { media } from '@/utils/mixin';
import { BannersSliderPrimary } from '@/components/LandingWidgets';

function Hero() {
  const { heroBanners } = useServiceBenefitsData();

  if (!heroBanners || heroBanners.length === 0) {
    return null;
  }

  return (
    <Component>
      <BannersSliderPrimary
        id={1}
        banners={heroBanners.map(({ image, mobileImage, text, url }) => ({
          image,
          mobileImage,
          text: text ?? '',
          url: url ?? '',
        }))}
      />
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
