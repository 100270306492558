import React from 'react';

import { Page } from '@/typings/page';
import useCurrentPage from '@/hooks/useCurrentPage';

import {
  CarModelCompareLanding,
  CarModelCompareLandingContextType,
} from './types';
import { CarModelCompareLandingContext } from './CarModelCompareLandingContext';

export function useCarModelCompareLandingData(): Page<CarModelCompareLanding>['templateFields'] {
  return useCurrentPage<Page<CarModelCompareLanding>>().templateFields;
}

export const useCarModelCompareLandingContext =
  (): CarModelCompareLandingContextType =>
    React.useContext(CarModelCompareLandingContext);
