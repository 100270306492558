import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  answer: string;
  linkHrefs?: Record<string, () => void>;
}

function CollapsedQuestionItem({ answer, linkHrefs }: Props) {
  const ref = useRef(null);

  if (linkHrefs) {
    for (let href in linkHrefs) {
      answer = answer.replace(
        /<a href="(.+?)">(.+?)<\/a>/,
        '<button class="_link js-action-btn" data-action="' +
          href +
          '">$2</button>'
      );
    }
  }

  useEffect(() => {
    if (!ref.current || !linkHrefs) return;

    (ref.current as HTMLDivElement)
      .querySelectorAll('.js-action-btn')
      .forEach((button) => {
        button.addEventListener('click', (e) => {
          e.preventDefault();
          const action = (e.target as HTMLDivElement).dataset.action;
          if (action && action in linkHrefs) {
            linkHrefs[action]();
          }
        });
      });
  }, [ref.current]);

  return <Span ref={ref} dangerouslySetInnerHTML={{ __html: answer ?? '' }} />;
}

export default CollapsedQuestionItem;

const Span = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.blue100};

  p:not(:first-child) {
    margin-top: 1vw;
  }

  a,
  ._link {
    color: ${colors.main};
  }

  ul,
  ol {
    margin: 1vw 0 0 2vw;

    &:first-child {
      margin-top: 0;
    }

    li {
      padding-left: 0.6vw;
      list-style-type: square;

      &:not(:first-child) {
        margin-top: 6px;

        ${media.tablet(css`
          margin-top: 0;
        `)}
      }
    }
  }
`;
