import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

import { PackageCardProps } from './types';

function PackageCard({ title, description, image, isHit }: PackageCardProps) {
  return (
    <Component>
      <Content>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {isHit && <Hit>ХИТ</Hit>}
      </Content>

      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
    </Component>
  );
}

export default PackageCard;

export const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100px;
  padding: 20px;
  border-radius: 10px 10px 0 0;
`;

const Content = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.white};
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  color: ${colors.gray200};
`;

const Hit = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  padding: 4px 20px;
  margin-top: 10px;
  color: ${colors.white};
  background: ${colors.orange};
  border-radius: 20px;
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }

  img {
    object-fit: cover;
  }
`;
