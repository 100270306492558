import React from 'react';
import styled, { css } from 'styled-components';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import { getYouTubeId, isYouTubeVideo } from '@/utils/common';

import { VideoProps } from './types';

function Video({ className, src, autoPlay }: VideoProps) {
  return (
    <Component className={className}>
      {isYouTubeVideo(src) ? (
        <LiteYouTubeEmbed id={getYouTubeId(src)} title="YouTube video" />
      ) : (
        <StyledVideo
          src={src + '#t=0.1'}
          preload="metadata"
          playsInline
          controls={!autoPlay}
          autoPlay={autoPlay}
          muted={autoPlay}
          loop={autoPlay}
        />
      )}
    </Component>
  );
}

export default Video;

const videoStyle = css`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Component = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  article {
    ${videoStyle};
  }
`;

const StyledVideo = styled.video`
  ${videoStyle};
`;
