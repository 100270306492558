import React from 'react';
import styled, { css } from 'styled-components';

import { useVedaemLandingData } from '@/modules/VedaemLanding/hooks';
import { ContentContainer, Title } from '@/modules/VedaemLanding/common.styles';
import { media } from '@/utils/mixin';
import BenefitsCards from '@/components/BenefitsCards';

function Benefits() {
  const { benefitsTitle, benefits } = useVedaemLandingData();

  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{benefitsTitle}</Title>

        <List>
          <BenefitsCards
            id={1}
            cards={benefits.map(({ image, title, text }) => ({
              image,
              title: title ?? '',
              text: text ?? '',
            }))}
          />
        </List>
      </ContentContainer>
    </Component>
  );
}

export default Benefits;

const Component = styled.section`
  padding: 60px 0 30px;
  background: ${(props) => props.theme.gray};

  ${media.tablet(css`
    padding: 40px 0 20px;
    overflow: hidden;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const List = styled.div`
  margin-top: 20px;
`;
