import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { ContentContainer } from '@/modules/JacHome/common.styles';
import { useJacHomeData } from '@/modules/JacHome/hooks';
import { media } from '@/utils/mixin';
import { CreditSlider, Title } from '@/components/LandingWidgets';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { submitJacCreditForm } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { getDealersOptions } from '@/utils/common';

function Credit() {
  const openModal = useModal();
  const { creditTitle, creditItems, dealersRequests } = useJacHomeData();

  if (!creditItems || creditItems.length === 0) {
    return null;
  }

  const sendApplicationForm = async (
    values: ApplicationFormFields,
    event: string
  ): Promise<void> => {
    try {
      await submitJacCreditForm({
        ...values,
        dealer: values.dealer ? Number(values.dealer.value) : undefined,
      }).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenModal = (title: string, event: string) => {
    openModal(
      ApplicationFormModal,
      {
        title,
        fieldsConfig: { dealers: getDealersOptions(dealersRequests) },
        onSubmit: (values) => sendApplicationForm(values, event),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component id="credit">
      <ContentContainer>
        <TitleWrapper>
          <Title title={creditTitle ?? ''} />
        </TitleWrapper>

        <CreditSlider
          id={5}
          credits={creditItems.map(
            ({
              image,
              imageMobile,
              title,
              price,
              period,
              description,
              buttonText,
              formTitle,
              googleEvent,
            }) => ({
              image,
              imageMobile,
              title: title ?? '',
              price: price ?? '',
              period: period ?? '',
              description: description ?? '',
              buttonText: buttonText ?? '',
              onSubmit: () =>
                handleOpenModal(formTitle ?? '', googleEvent ?? ''),
            })
          )}
        />
      </ContentContainer>
    </Component>
  );
}

export default Credit;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;
