import React from 'react';
import styled, { css } from 'styled-components';

import FeatureCard from '@/components/FeatureCard';
import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';
import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';

function SoldCar() {
  const { soldCarTitle, soldCarItems } = useAmpLandingData();

  if (!soldCarItems || soldCarItems.length === 0) {
    return null;
  }

  return (
    <Content>
      <ContentContainerSmall>
        <SoldCarHeader>{soldCarTitle}</SoldCarHeader>
        <FeaturesRow>
          {soldCarItems.map((soldCar, index) => {
            return (
              <FeaturesCell>
                <FeatureCard
                  key={index}
                  icon={soldCar.icon}
                  title={soldCar.title}
                  text={soldCar.text}
                  list={soldCar.list ? soldCar.list.split('\n') : []}
                />
              </FeaturesCell>
            );
          })}
        </FeaturesRow>
      </ContentContainerSmall>
    </Content>
  );
}

const Content = styled.div`
  margin-top: 60px;
`;

const SoldCarHeader = styled.h2`
  font-size: 36px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 30px;
  `)}
`;

const FeaturesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px -15px 0;

  ${media.tablet(css`
    margin: 0;
  `)};

  ${media.tabletSmall(css`
    display: block;
    margin: 24px 0 0 0;
  `)};
`;

const FeaturesCell = styled.div`
  flex-basis: 33.3333%;
`;

export default SoldCar;
