import React from 'react';
import styled from 'styled-components';

import { useHistoryData } from '@/modules/History/History.hooks';
import HeroWithoutContent from '@/components/HeroWithoutContent';
import LinkTabs from '@/components/LinkTabs';
import Layout from '@/layout';

import HistoryBlock from './components/HistoryBlock';

function History() {
  const { heroImage, heroTabletImage, heroMobileImage, linksTabs } =
    useHistoryData();

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
          holding
        />
        <LinkTabs linksTabs={linksTabs} />
        <HistoryBlock />
      </Component>
    </Layout>
  );
}

export default History;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
