import React from 'react';
import { Formik } from 'formik';

import { useTypedSelector } from '@/store/store';
import { selectOnlineUserChoice } from '@/store/reducers/online-valuation-user-data';
import { selectRateCarsData } from '@/store/reducers/rate-car-data';

import FormComponent from './components/FormComponent';
import { FormRateCarModel } from './FormRateCar.models';

interface Props {
  isHorizontalForm?: boolean;
  isValuationPage?: boolean;
  isChecked?: boolean;
  submitLabel?: string;
}

function FormRateCar({
  isHorizontalForm,
  isValuationPage,
  isChecked,
  submitLabel,
}: Props) {
  const rateCarData = useTypedSelector(selectRateCarsData);
  const userChoice = useTypedSelector(selectOnlineUserChoice);

  if (!rateCarData) {
    return null;
  }

  async function onSubmit() {
    return;
  }

  return (
    <>
      <Formik<FormRateCarModel> onSubmit={onSubmit} initialValues={userChoice}>
        {({ values, setFieldValue }) => {
          return (
            <FormComponent
              rateCarData={rateCarData}
              userChoice={userChoice}
              isHorizontalForm={isHorizontalForm}
              values={values}
              setFieldValue={setFieldValue}
              isValuationPage={isValuationPage}
              isChecked={isChecked}
              submitLabel={submitLabel}
            />
          );
        }}
      </Formik>
    </>
  );
}

export default FormRateCar;
