import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';

import {
  ContentContainer,
  Title,
} from '@/modules/ElectrocarsLanding/common.styles';
import { media } from '@/utils/mixin';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { SliderWrapper } from '@/components/LandingWidgets';
import { Header as SliderWrapperHeader } from '@/components/LandingWidgets/components/SliderWrapper';
import { colors } from '@/constants/theme';
import PostCard from '@/components/PostCard';

function Reviews() {
  const { reviewsTitle, reviewsItems } = useElectrocarsLandingData();
  const isMobile = useMedia('(max-width: 767px)');

  if (!reviewsItems || reviewsItems.length === 0) {
    return null;
  }

  const slidesPerView = !isMobile ? 3 : undefined;
  const isWithoutNavs = !isMobile ? reviewsItems.length <= 3 : true;
  const isNoSwiping = !isMobile
    ? reviewsItems.length <= 3
    : reviewsItems.length === 1;

  return (
    <Component id="reviews">
      <ContentContainer>
        <Title isBlack>{reviewsTitle}</Title>
        <Slider>
          <SliderWrapper
            id={3}
            options={{
              slidesPerView,
              spaceBetween: 20,
              noSwipingClass: 'swiper-no-swiping',
              withoutNavs: isWithoutNavs,
              withoutPagination: true,
            }}
          >
            {reviewsItems.map(({ image, title, link }, index) => (
              <SwiperSlide
                key={index}
                className={isNoSwiping ? 'swiper-no-swiping' : ''}
              >
                <PostCard title={title ?? ''} url={link ?? ''} image={image} />
              </SwiperSlide>
            ))}
          </SliderWrapper>
        </Slider>
      </ContentContainer>
    </Component>
  );
}

export default Reviews;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const Slider = styled.div`
  margin-top: 58px;

  ${media.tablet(css`
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    margin: 30px -20px 0 0;

    .swiper-slide {
      min-width: calc(100% - 40px);
      flex-shrink: initial;
    }
  `)}

  ${SliderWrapperHeader} {
    top: -100px;

    ${media.tablet(css`
      top: -60px;
    `)}

    ${media.mobile(css`
      right: 20px;
    `)}

    .swiper-prev4:not(.swiper-button-disabled),
    .swiper-next4:not(.swiper-button-disabled) {
      border: 1px solid ${colors.main};
    }
  }
`;
