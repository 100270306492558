import styled, { css } from 'styled-components';

import Advantages from '@/components/Redesign/Advantages';
import { useRefitSingleLanding } from '@/modules/RefitSingleLanding/hooks';
import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/RefitSingleLanding/common.styles';

function AdditionalList() {
  const {
    templateFields: { additionalTitle, additionalItems },
  } = useRefitSingleLanding();

  if (!additionalTitle || !additionalItems) {
    return null;
  }

  const advantages = additionalItems.map(({ text, image }) => ({
    text: text ?? '',
    image,
  }));

  return (
    <Component>
      <ContentContainer>
        <Advantages
          advantages={advantages}
          title={additionalTitle}
          columns={advantages.length}
        />
      </ContentContainer>
    </Component>
  );
}

export default AdditionalList;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
