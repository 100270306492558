import React from 'react';
import styled from 'styled-components';

import { useTokensLandingData } from '@/modules/TokensLanding/hooks';
import { ContentContainer, Title } from '@/modules/TokensLanding/common.styles';
import ImagesSlider from '@/components/ImagesSlider';

function Gallery() {
  const { galleryTitle, galleryImages } = useTokensLandingData();

  if (!galleryImages || galleryImages.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{galleryTitle}</Title>
      </ContentContainer>

      <ImagesSliderWrapper>
        <ImagesSlider slides={galleryImages.map((image) => ({ image }))} />
      </ImagesSliderWrapper>
    </Component>
  );
}

export default Gallery;

const Component = styled.section`
  padding: 15px 0;
`;

const ImagesSliderWrapper = styled.div`
  margin-top: 20px;
`;
