import React from 'react';
import styled, { css } from 'styled-components';

import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { AboutCardPrimary } from '@/components/LandingWidgets';
import { Image as AboutCardPrimaryImage } from '@/components/LandingWidgets/components/AboutCardPrimary';
import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { media } from '@/utils/mixin';

function AboutDealer() {
  const {
    aboutDealerImage,
    aboutDealerTitle,
    aboutDealerTopText,
    aboutDealerBenefits,
    aboutDealerBottomText,
  } = useTopLevelFunnelLandingData();

  if (!aboutDealerImage || !aboutDealerTitle || !aboutDealerTopText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardPrimary
          id={7}
          image={aboutDealerImage}
          title={aboutDealerTitle}
          topText={aboutDealerTopText}
          benefits={
            aboutDealerBenefits && aboutDealerBenefits.length !== 0
              ? aboutDealerBenefits.map(({ image, text }) => ({
                  image,
                  text: text ?? '',
                }))
              : []
          }
          bottomText={aboutDealerBottomText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default AboutDealer;

const Component = styled.section`
  padding: 15px 0;

  ${AboutCardPrimaryImage} {
    height: 320px;

    ${media.tablet(css`
      display: none;
    `)}
  }

  ${media.tablet(css`
    padding: 10px 0;
  `)}
`;
