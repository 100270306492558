import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import Description from './components/Description';
import Programs from './components/Programs';
import CalculationForm from './components/CalculationForm';
import HowItWorks from './components/HowItWorks';
import Partners from './components/Partners';
import Offers from './components/Offers';
import StockCars from './components/StockCars';
import Instruction from './components/Instruction';
import Questions from './components/Questions';

function FinanceLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <Description />
        <Programs />
        <CalculationForm />
        <HowItWorks />
        <Partners />
        <Offers />
        <StockCars />
        <Instruction />
        <Questions />
      </Component>
    </Layout>
  );
}

export default FinanceLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
