import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import ProgramLoyalty from './components/ProgramLoyalty';
import Help from './components/Help';

function BonusProgram() {
  return (
    <Layout>
      <Component>
        <Hero />
        <ProgramLoyalty />
        <Help />
      </Component>
    </Layout>
  );
}

export default BonusProgram;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
