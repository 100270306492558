import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import Loader from '@/components/Loader';
import { NavButtonWrapper, NavigationButton } from '@/components/styles';
import NavArrow from '@/assets/svg/nav-arrow.svg';

import { BannersCarouselProps } from './types';

SwiperCore.use([Autoplay, Navigation]);

function BannersCarousel({ banners, small, index }: BannersCarouselProps) {
  const [isMount, setMount] = useState(false);
  const paginationClassname = index
    ? `swiper-pagination${index}`
    : `swiper-pagination`;
  const isOneBanner = banners.length <= 1;

  useEffect(() => {
    setMount(true);
  }, []);

  if (!banners || banners.length === 0) {
    return null;
  }

  return (
    <Component>
      {isMount ? (
        <Swiper
          slidesPerView="auto"
          loop={!isOneBanner}
          touchMoveStopPropagation={true}
          speed={1250}
          allowTouchMove={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          pagination={{
            el: `.${paginationClassname}`,
            type: 'bullets',
            clickable: true,
          }}
          navigation={{
            prevEl: `.swiper-prev`,
            nextEl: `.swiper-next`,
          }}
          noSwipingClass={'swiper-no-swiping'}
        >
          {banners.map(({ image, openNewTab, link }, index) => (
            <SwiperSlide
              key={index}
              className={isOneBanner ? 'swiper-no-swiping' : ''}
            >
              <SlideItem>
                <SlidePicture
                  loading="lazy"
                  desktop={convertThumbnailToPictureImage(image.desktop)}
                  laptop={convertThumbnailToPictureImage(image.desktop)}
                  tabletSmall={convertThumbnailToPictureImage(image.desktop)}
                  mobile={convertThumbnailToPictureImage(
                    image.mobile ?? image.desktop
                  )}
                />
                <SlideLink to={link} target={openNewTab ? '_blank' : '_self'} />
              </SlideItem>
            </SwiperSlide>
          ))}

          {!isOneBanner && (
            <React.Fragment>
              <StyledNavButtonWrapper
                className="swiper-prev"
                prev
                small={small}
              >
                <NavigationButton prev>
                  <NavArrow />
                </NavigationButton>
              </StyledNavButtonWrapper>
              <StyledNavButtonWrapper
                className="swiper-next"
                next
                small={small}
              >
                <NavigationButton next>
                  <NavArrow />
                </NavigationButton>
              </StyledNavButtonWrapper>
            </React.Fragment>
          )}
        </Swiper>
      ) : (
        <Loader />
      )}

      <PaginationComponent className={paginationClassname} />
    </Component>
  );
}

export default BannersCarousel;

const Component = styled.div`
  position: relative;
  width: 100%;
  padding-top: 25.334%;

  ${media.mobile(css`
    padding-top: 90.5%;
  `)}

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const SlideItem = styled.li`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const SlidePicture = styled(Picture)`
  position: initial !important;

  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const SlideLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
`;

const PaginationComponent = styled.div`
  position: absolute;
  bottom: -20px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2px;

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 100%;
    max-width: 35px;
    height: 2px;
    border-radius: 0;
    background: ${(props) => props.theme.black};

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.blue100};
  }
`;

const StyledNavButtonWrapper = styled(NavButtonWrapper)<{
  small?: boolean;
  next?: boolean;
  prev?: boolean;
}>`
  ${({ next }) =>
    next &&
    css`
      padding-left: 7%;
    `}

  ${({ prev }) =>
    prev &&
    css`
      padding-right: 7%;
    `}
  
  ${({ small }) =>
    small &&
    css`
      & > button {
        padding: 18px 9px;

        svg {
          width: 12px;
          height: 19px;
        }
      }
    `}

  ${({ small, next }) =>
    small &&
    next &&
    css`
      padding-left: 15%;
    `}

  ${({ small, prev }) =>
    small &&
    prev &&
    css`
      padding-right: 15%;
    `}
`;
