import styled, { css } from 'styled-components';

import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import { ContentContainer } from '@/modules/VerifiedAtlantmLanding/common.styles';
import { media } from '@/utils/mixin';
import Advantages from '@/components/Redesign/Advantages';

function AdvantagesList() {
  const {
    templateFields: { advantageTitle, advantages },
  } = useVerifiedAtlantmLandingData();

  if (!advantageTitle || !advantages) {
    return null;
  }

  const advantagesItems = advantages.map(({ image, description }) => ({
    image,
    text: description ?? '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Advantages
          columns={3}
          advantages={advantagesItems}
          title={advantageTitle}
        />
      </ContentContainer>
    </Component>
  );
}

export default AdvantagesList;

const Component = styled.section`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)}
`;
