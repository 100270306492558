import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { Button } from '@/components/Redesign/Button';

import { HeroBannerProps } from './types';

function HeroBanner({
  image,
  tabletImage,
  title,
  subtitle,
  label,
  buttonText,
  buttonOnClick,
  buttonVariant,
  isBlackColor,
  advantages,
}: HeroBannerProps) {
  return (
    <Component>
      <ImageWrapper>
        <Image
          loading="lazy"
          desktop={convertThumbnailToPictureImage(image)}
          laptop={convertThumbnailToPictureImage(image)}
          tabletSmall={convertThumbnailToPictureImage(tabletImage || image)}
          mobile={convertThumbnailToPictureImage(tabletImage || image)}
          alt={'banner-image'}
        />
      </ImageWrapper>

      <Content>
        {label && <Badge>{label}</Badge>}
        <Title isBlackColor={isBlackColor}>{title}</Title>
        {subtitle && (
          <Subtitle isBlackColor={isBlackColor}>{subtitle}</Subtitle>
        )}

        {advantages && advantages.length > 0 && (
          <AdvantagesWrapper>
            {advantages.map(({ title, subtitle }, index) => (
              <AdvantagesItem key={index}>
                <AdvantagesTitle>{title}</AdvantagesTitle>
                <AdvantagesSubtitle>{subtitle}</AdvantagesSubtitle>
              </AdvantagesItem>
            ))}
          </AdvantagesWrapper>
        )}

        {buttonText && (
          <ButtonWrapper>
            <Button
              variant={buttonVariant ?? 'contained100'}
              size={'small'}
              onClick={buttonOnClick}
            >
              {buttonText}
            </Button>
          </ButtonWrapper>
        )}
      </Content>
    </Component>
  );
}

export default HeroBanner;

const Component = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 26.471%;

  ${media.tablet(css`
    padding-top: 80%;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  height: fit-content;
  top: 50%;
  max-width: 507px;
  transform: translateY(-50%);
  padding: 0 0 0 67px;

  ${media.tablet(css`
    height: 100%;
    padding: 20px 0 0 20px;
  `)}
`;

const Badge = styled.p`
  display: inline-block;
  background-color: ${colors.orange400};
  border-radius: 4px;
  padding: 4px 5px;
  color: ${colors.white};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  `)}
`;

const Title = styled.h1<{ isBlackColor?: boolean }>`
  font-weight: 700;
  font-size: 40px;
  max-width: 440px;
  line-height: 47px;
  color: ${(props) =>
    props.isBlackColor ? props.theme.black : props.theme.white};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    max-width: 240px;
  `)}
`;

const Subtitle = styled.p<{ isBlackColor?: boolean }>`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) =>
    props.isBlackColor ? props.theme.black : props.theme.white};

  ${media.tablet(css`
    max-width: 240px;
    font-size: 16px;
    line-height: 19px;
  `)}
`;

const ButtonWrapper = styled.div`
  max-width: 285px;
  margin-top: 20px;

  ${media.tablet(css`
    max-width: 250px;
  `)}
`;

const AdvantagesWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 30px;
`;

const AdvantagesItem = styled.div``;

const AdvantagesTitle = styled.p`
  color: ${colors.black};
  font-size: 24px;
  font-weight: 600;
  line-height: 110%;
`;

const AdvantagesSubtitle = styled.p``;
