import React from 'react';
import styled, { css } from 'styled-components';

import { useJacHomeData } from '@/modules/JacHome/hooks';
import { BannersSliderPrimary } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

function HeroSlider() {
  const { heroBanners } = useJacHomeData();

  if (!heroBanners || heroBanners.length === 0) {
    return null;
  }

  return (
    <Component>
      <BannersSliderPrimary
        id={1}
        banners={heroBanners.map(({ image, mobileImage, text, url }) => ({
          image,
          mobileImage,
          text: text ?? '',
          url: url ?? '',
        }))}
      />
    </Component>
  );
}

export default HeroSlider;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
