import React from 'react';
import styled, { css } from 'styled-components';
import EvaluationRedemptionModal from 'src/components/modals/EvaluationRedemptionModal';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useModal } from '@tager/web-components';

import Picture from '@/components/Picture';
import {
  useCarValues,
  useEvaluationRedemptionData,
} from '@/modules/EvaluationRedemption/hooks';
import { ContentContainer } from '@/modules/EvaluationRedemption/common.styles';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { Button } from '@/components/Redesign/Button';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { CarSelectsValues } from '@/modules/EvaluationRedemption/types';
import Select from '@/components/Redesign/Select';
import { submitRateCarOffline } from '@/services/leads/leads-service';
import { useBrandsAndModelsOptions } from '@/hooks/useBrandsAndModelsOptions';

function FormBanner() {
  const {
    templateFields: { formTitle, formButtonText, formImage, formImageMobile },
  } = useEvaluationRedemptionData();
  const modal = useModal();
  const [values, setValues] = useCarValues();
  const options = useBrandsAndModelsOptions(values.brand);

  if (!formButtonText || !formImage) {
    return null;
  }

  const onSubmit = (values: CarSelectsValues) => {
    modal(
      EvaluationRedemptionModal,
      {
        title: 'Оценка и выкуп',
        selectedFields: {
          brand: values.brand?.value ?? '',
          model: values.model?.value ?? '',
          year: Number(values.year?.value) ?? 0,
        },
        onSubmit: async (values) => {
          try {
            await submitRateCarOffline({
              brand: values.brand,
              model: values.model,
              year: values.year,
              name: values.name,
              phone: values.phone,
            });
          } catch (error: any) {
            console.log(error);
          }
        },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <Banner>
          <Title>{formTitle}</Title>

          <Image
            desktop={convertThumbnailToPictureImage(formImage)}
            laptop={convertThumbnailToPictureImage(formImage)}
            tabletSmall={convertThumbnailToPictureImage(
              formImageMobile ?? formImage
            )}
            mobile={convertThumbnailToPictureImage(
              formImageMobile ?? formImage
            )}
            alt={'form-image'}
          />

          <FiltersWrapper>
            <Select
              label="Марка"
              options={options?.brands ?? []}
              value={values.brand}
              selectOptions={{ isLabelInner: true }}
              onChange={(value) => setValues?.('brand', value)}
            />

            <Select
              label="Модель"
              options={options?.models ?? []}
              value={values.model}
              selectOptions={{
                isLabelInner: true,
                disabled: !values.brand,
                disabledHint:
                  'Вы не можете выбрать "Модель" пока не выберите Марку.',
              }}
              onChange={(value) => setValues?.('model', value)}
            />

            <Select
              label="Год выпуска"
              options={options?.years ?? []}
              value={values.year}
              selectOptions={{
                isLabelInner: true,
                disabled: !values.model,
                disabledHint:
                  'Вы не можете выбрать "Год выпуска" пока не выберите Модель.',
              }}
              onChange={(value) => setValues?.('year', value)}
            />
          </FiltersWrapper>

          <ButtonWrapper>
            <Button
              disabled={!values.year}
              variant="contained"
              size="small"
              onClick={() => onSubmit?.(values)}
            >
              {formButtonText}
            </Button>
          </ButtonWrapper>
        </Banner>
      </ContentContainer>
    </Component>
  );
}

export default FormBanner;

const Component = styled.section`
  margin: 60px 0 20px;

  ${media.tablet(css`
    margin: 40px 0 20px;
  `)}
`;

const Banner = styled.div`
  padding: 40px 260px 40px 30px;
  position: relative;
  border-radius: 20px;
  background: ${colors.purpleGradient200};

  ${media.tablet(css`
    padding: 40px 20px;
  `)}
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black};
`;

const Image = styled(Picture)`
  position: absolute;
  width: 405px;
  right: 0;
  bottom: 0;
  top: 35px;

  img {
    border-radius: 0 0 20px 0;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }

  ${media.tablet(css`
    position: relative;
    width: auto;
    padding-top: 52.571%;
    margin-right: -20px;
    top: 5px;

    img {
      border-radius: 0;
    }
  `)}
`;

const FiltersWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  ${media.tablet(css`
    margin-top: 35px;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  `)}
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  max-width: 300px;

  ${media.tablet(css`
    max-width: none;
  `)}
`;
