import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser, Nullable } from '@tager/web-core';

import Tooltip from '@/components/Collapse/components/Tooltip';
import { DisplayIcon } from '@/components/Collapse/Collapse';
import LockIcon from '@/assets/svg/lock.svg';
import TypedContent from '@/modules/ValueCar/components/OnlineValuation/components/ChosenCarsInfo/components/TypedContent';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectOnlineUserChoice,
  setOnlineChosenParameters,
} from '@/store/reducers/online-valuation-user-data';
import { CarParametersModel } from '@/modules/ValueCar/ValueCar.types';
import { OnlineCheckedValuesModel } from '@/modules/ValueCar/components/OnlineValuation/interfaces';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

interface Props {
  isShowIcon: boolean;
  title: string;
  hintText: string;
  values: Nullable<Array<CarParametersModel>>;
  isChecked: boolean;
  checkedValues: OnlineCheckedValuesModel;
  setCheckedValues: Dispatch<SetStateAction<OnlineCheckedValuesModel>>;
}

const ParametersContent = React.forwardRef<HTMLDivElement | null, Props>(
  (
    {
      isShowIcon,
      title,
      hintText,
      values,
      isChecked,
      checkedValues,
      setCheckedValues,
    },
    ref
  ) => {
    const userChoice = useTypedSelector(selectOnlineUserChoice);
    const dispatch = useTypedDispatch();

    return (
      <Component ref={ref} isChecked={isChecked}>
        <Content isShowIcon={isShowIcon}>
          <TitleContainer>
            <Title>{title}</Title>
            {isShowIcon && (
              <Tippy
                content={<Tooltip text={hintText} />}
                offset={[25, 10]}
                placement="bottom"
                interactive
                appendTo={isBrowser() ? document.body : 'parent'}
              >
                <DisplayIcon>
                  <LockIcon />
                </DisplayIcon>
              </Tippy>
            )}
          </TitleContainer>
          <TypedContentContainer>
            {values?.map((item, index) => (
              <TypedContent
                key={index}
                isParameter={true}
                title={item.field}
                label={item.label}
                userChoiceParameter={userChoice.parameters}
                dispatch={dispatch}
                setParameter={setOnlineChosenParameters}
                parametersList={item.values}
                isChecked={true}
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
              />
            ))}
          </TypedContentContainer>
        </Content>
      </Component>
    );
  }
);

export default ParametersContent;

const Component = styled.div<{ isChecked?: boolean }>`
  border-radius: 8px;

  ${({ isChecked }) =>
    !isChecked &&
    css`
      border: 1px solid ${colors.red700};
      background: ${(props) => props.theme.red};
    `};
`;

const Content = styled.div<{ isMobile?: boolean; isShowIcon?: boolean }>`
  padding-top: 32px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 30px;
    `};

  ${({ isShowIcon }) =>
    isShowIcon &&
    css`
      padding-bottom: 32px;
    `};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 24px;
  line-height: 24px;
  padding-right: 10px;
  color: ${(props) => props.theme.black};
`;

const TypedContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.mobile(
    css`
      flex-direction: column;
    `
  )}
`;
