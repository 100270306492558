import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { BrandsCardProps } from './types';

function BrandsCard({ image, name, link }: BrandsCardProps) {
  const inner = (
    <React.Fragment>
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
      <Name>{name}</Name>
    </React.Fragment>
  );

  return !link ? <Card>{inner}</Card> : <LinkCard to={link}>{inner}</LinkCard>;
}

export default BrandsCard;

const CardStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background: ${colors.white};

  ${media.tablet(css`
    padding: 20px;
  `)}
`;

const Card = styled.div`
  ${CardStyles}
`;

const LinkCard = styled(Link)`
  ${CardStyles}

  &:hover {
    box-shadow: ${(props) => props.theme.blackShadow100};
  }
`;

const Image = styled(Picture)`
  img,
  picture {
    width: 100px;
    height: 70px;
  }

  img {
    object-fit: contain;
  }
`;

const Name = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 4px;
  color: ${colors.blue600};
`;
