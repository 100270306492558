import React from 'react';
import styled, { css } from 'styled-components';

import FormRateCar from '@/components/FormRateCar';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';
import { media } from '@/utils/mixin';

function RateCar() {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;

  return (
    <Content>
      <Title>{pageFields.bottomBlockTitle}</Title>
      <FormContainer>
        <FormRateCar isHorizontalForm={false} isChecked={true} />
      </FormContainer>
    </Content>
  );
}

export default RateCar;

const Content = styled.div``;

const Title = styled.h2`
  font-size: 36px;
  padding: 0 40px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 36px;
  `)};

  ${media.mobile(css`
    font-size: 30px;
  `)};
`;

const FormContainer = styled.div`
  max-width: 640px;
  margin: 40px auto 0;
  position: relative;
`;
