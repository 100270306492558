import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import SearchIcon from '@/assets/svg/search-black.svg';

interface Props {
  rateCarListData: Array<string>;
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
}

function Search({ rateCarListData, setValues }: Props) {
  const [inputValue, setInputValue] = useState('');

  function handleSearchData(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
    setValues(
      rateCarListData.filter((item) =>
        String(item).toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  }

  return (
    <Container>
      <Content>
        <SearchIconContainer>
          <SearchIcon />
        </SearchIconContainer>
        <Input
          value={inputValue}
          onChange={(event) => handleSearchData(event)}
        />
      </Content>
    </Container>
  );
}

export default Search;

const Container = styled.div`
  z-index: 50;
  background: ${(props) => props.theme.white_1};
  position: sticky;
  top: 0;
`;

const Content = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 180px;
`;

const SearchIconContainer = styled.div`
  width: 21px;
  height: 21px;
  position: absolute;
  top: 7px;
  right: 0;

  svg {
    path {
      stroke: ${(props) => props.theme.black};
    }
  }
`;

const Input = styled.input`
  height: 35px;
  border: none;
  border-bottom: 1px solid #ededed;
  background: transparent;
  color: ${(props) => props.theme.black};
`;
