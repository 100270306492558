import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { Banner } from '@/components/LandingWidgets';
import { useJetourHomeData } from '@/modules/JetourHome/hooks';

function Universe() {
  const {
    universeImage,
    universeMobileImage,
    universeVideo,
    universeTitle,
    universeButtonText,
    universeButtonLink,
  } = useJetourHomeData();

  if (!universeImage && !universeVideo) {
    return null;
  }

  return (
    <Component id="universe">
      <Banner
        title={universeTitle ?? ''}
        buttonText={universeButtonText ?? ''}
        buttonLink={universeButtonLink ?? ''}
        image={universeImage}
        mobileImage={universeMobileImage}
        video={universeVideo?.url ?? ''}
      />
    </Component>
  );
}

export default Universe;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
