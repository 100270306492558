import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { ColorPickerProps } from './types';

export function ColorPicker({
  colors,
  onChange,
  activeColor,
}: ColorPickerProps) {
  const handleChangeColor = (activeColorIndex: number, activeColor: string) => {
    onChange?.({ activeColorIndex, activeColor });
  };

  if (colors.length < 1) {
    return null;
  }

  return (
    <Component>
      {colors.map(({ color }, index) => (
        <Color
          key={index}
          onClick={() => handleChangeColor(index, color)}
          color={color}
          isActive={color === activeColor}
        />
      ))}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Color = styled.div<{ color: string; isActive?: boolean }>`
  width: 24px;
  height: 24px;
  border: 2px solid ${colors.gray200};
  border-radius: 50%;
  cursor: pointer;
  margin: 0 1px;

  ${({ color }) =>
    color &&
    css`
      background: ${color};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: ${(props) => props.theme.blackShadow};
    `}
`;
