import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { ButtonNextLink } from '@/components/Button';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { AboutCardProps } from './types';

export function AboutCard({
  image,
  text,
  buttonTitle,
  buttonLink,
}: AboutCardProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        {...convertThumbnailToPictureProps(image)}
        alt={text}
      />

      <Content>
        <Text dangerouslySetInnerHTML={{ __html: text }} />

        {buttonTitle && buttonLink && (
          <Actions>
            <StyledButtonLink to={buttonLink} variant="contained">
              {buttonTitle}
            </StyledButtonLink>
          </Actions>
        )}
      </Content>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  align-items: flex-start;

  ${media.mobile(css`
    flex-direction: column;
    align-items: initial;
  `)}
`;

const Image = styled(Picture)`
  flex: 1 1 55%;
  max-width: 600px;
  padding-top: 18%;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    padding-top: 50%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  margin-left: 40px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    margin: 20px 0 0;
  `)}
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.black};

  a {
    color: ${colors.main};
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 294px;

  ${media.mobile(css`
    max-width: none;
  `)}
`;
