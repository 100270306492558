import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import { BenefitsCardProps } from './types';

function BenefitCard({ image, title, text }: BenefitsCardProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        alt={title}
        {...convertThumbnailToPictureProps(image)}
      />
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Component>
  );
}

export default BenefitCard;

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 10px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};
`;

const Image = styled(Picture)`
  picture,
  img {
    width: 100px;
    height: 100px;
  }

  img {
    object-fit: cover;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-top: 30px;
  color: ${(props) => props.theme.black200};

  ${media.tablet(
    css`
      margin-top: 10px;
    `
  )}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};
`;
