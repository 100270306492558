import React from 'react';
import styled from 'styled-components';

import {
  useHelpOnTheRoadContext,
  useHelpOnTheRoadData,
} from '@/modules/HelpOnTheRoad/hooks';
import PackageCard from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/PackageCard';

function Package() {
  const { packages } = useHelpOnTheRoadData();
  const { selectedPackageTab } = useHelpOnTheRoadContext();

  if (!packages || packages.length === 0) {
    return null;
  }

  const selectedPackage = packages[Number(selectedPackageTab.value)];

  return (
    <Component>
      <PackageCard
        title={selectedPackage.shortName ?? ''}
        isHit={selectedPackage.isHit}
        image={selectedPackage.image}
      />
    </Component>
  );
}

export default Package;

const Component = styled.div``;
