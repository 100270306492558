import React, { useState } from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { BenefitsItem } from '@/modules/OnlineShopping/OnlineShopping.types';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import Button from '@/components/Button';
import CallbackForm from '@/components/modals/CallbackForm';
import { handleSubmitCallbackForm } from '@/utils/forms';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';

function BenefitsListCollapsedItem({
  title,
  image,
  description,
  info,
  buttonTitle,
  buttonLink,
}: BenefitsItem) {
  const [isOpened, setOpened] = useState(false);
  const openModal = useModal();

  function handleCallClick() {
    openModal(
      CallbackForm,
      {
        request: (values) => handleSubmitCallbackForm(values),
        title: 'Заказать обратный звонок',
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  }

  const handleChangeOpenedState = () => {
    setOpened(!isOpened);
  };

  return (
    <Component>
      <Header onClick={handleChangeOpenedState}>
        <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
        <Title>{title}</Title>
        <Arrow isOpened={isOpened}>
          <ArrowIcon />
        </Arrow>
      </Header>
      {isOpened && (
        <Content>
          <Description>{description}</Description>
          <Bottom>
            <Info>{info}</Info>
            <ButtonWrapper>
              {buttonLink ? (
                <ButtonLink to={buttonLink}>{buttonTitle}</ButtonLink>
              ) : (
                <StyledButton onClick={handleCallClick}>
                  {buttonTitle}
                </StyledButton>
              )}
            </ButtonWrapper>
          </Bottom>
        </Content>
      )}
    </Component>
  );
}

export default BenefitsListCollapsedItem;

const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const Image = styled(Picture)`
  img {
    width: 50px;
    height: 50px;
  }
`;

const Title = styled.span`
  flex: 1;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-left: 20px;
  color: ${(props) => props.theme.black};
`;

const Arrow = styled.div<{ isOpened: boolean }>`
  svg {
    width: 11px;
    height: 7px;
    transform: ${(props) => (props.isOpened ? `rotate(180deg)` : `rotate(0)`)};
    transition: 0.15s;

    path {
      fill: ${(props) => props.theme.black100};
    }
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black200};
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Info = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.blue200};
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const ButtonLink = styled(Link)`
  display: block;
  max-width: 100%;
  width: 280px;
  border-radius: 20px;
  padding: 11px 26px;
  height: 40px;
  text-align: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colors.white};
  background: ${colors.main};

  &:hover {
    background: ${colors.main100};
  }
`;

const StyledButton = styled(Button)`
  display: block;
  max-width: 100%;
  width: 280px;
  border-radius: 20px;
  padding: 11px 26px;
  height: 40px;
  transition: 0.3s;
  text-align: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colors.white};
  background: ${colors.main};

  &:hover {
    background: ${colors.main100};
  }
`;
