import React, { useState } from 'react';
import styled from 'styled-components';

import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { Tabs, Title } from '@/components/CarModelLandingWidgets';
import ImagesSlider from '@/components/ImagesSlider';
import { ContentContainer } from '@/modules/CarModelLanding/common.styles';

function Gallery() {
  const { galleryTitle, galleryImages } = useCarModelLandingData();

  const tabsData =
    galleryImages && galleryImages.length !== 0
      ? galleryImages.map((image, index) => ({
          label: image.section ?? '',
          value: index,
        }))
      : [];
  const hasTabs = tabsData.length !== 0;

  const [activeTab, setActiveTab] = useState<number>(
    hasTabs ? tabsData[0].value : 0
  );

  if (!galleryImages || galleryImages.length === 0) {
    return null;
  }

  const slides = galleryImages[activeTab].images.map((image) => ({
    image,
  }));

  return (
    <Component id="gallery">
      <ContentContainer>
        <Title title={galleryTitle ?? ''} />

        <TabsWrapper>
          <Tabs
            data={tabsData}
            activeValue={activeTab}
            onChange={setActiveTab}
          />
        </TabsWrapper>
      </ContentContainer>

      <SliderWrapper>
        <ImagesSlider slides={slides} />
      </SliderWrapper>
    </Component>
  );
}

export default Gallery;

const Component = styled.section`
  padding: 30px 0;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const SliderWrapper = styled.div`
  margin-top: 30px;
`;
