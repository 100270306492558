import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import AboutSection from './components/AboutSection';
import Advantages from './components/Advantages';
import Form from './components/Form';
import Map from './components/Map';
import RefitTypesGallery from './components/RefitTypesGallery';

function RefitLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <AboutSection />
        <Advantages />
        <RefitTypesGallery />
        <Form />
        <Map />
      </Component>
    </Layout>
  );
}

export default RefitLanding;

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
