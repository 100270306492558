import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import LeadershipCard from '@/components/LeadershipCard';
import { LeaderItem } from '@/modules/Leadership/Leadership.types';

interface LeadersProps {
  title: Nullable<string>;
  items: LeaderItem[];
}

function Leaders({ title, items }: LeadersProps) {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{title}</Title>
        <Cards>
          {items.map(({ avatar, name, position }, index) => (
            <LeadershipCard
              key={index}
              avatar={avatar}
              name={name}
              position={position}
            />
          ))}
        </Cards>
      </ContentContainerSmall>
    </Component>
  );
}

export default Leaders;

const Component = styled.section`
  padding: 45px 0;

  ${media.tablet(css`
    padding: 30px 0;
  `)}

  ${media.mobile(css`
    padding: 20px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px -15px 0 -15px;
  width: calc(100% + 30px);

  ${media.tabletSmall(css`
    margin: 20px -5px 0 -5px;
    width: calc(100% + 10px);
  `)}
`;
