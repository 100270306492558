import React from 'react';
import styled, { css } from 'styled-components';

import ConsultationFormPrimary from '@/components/Forms/ConsultationFormPrimary';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { FormFields } from '@/components/Forms/ConsultationFormPrimary/components/Form/types';
import { googleEvent } from '@/utils/events';
import { submitSpecialOffer } from '@/services/leads/leads-service';
import { media } from '@/utils/mixin';

import OtherQuestions from './components/OtherQuestions';

function Consultation() {
  const { formTitle, formGoogleEventName } = useSpecialOfferData();

  const handleSubmitConsultationForm = async (values: FormFields) => {
    try {
      await submitSpecialOffer(values).then(() => {
        if (formGoogleEventName) {
          googleEvent(formGoogleEventName);
        }
      });
    } catch (error) {
      console.log('error = ', error);
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <Container>
        <ConsultationFormPrimary
          title={formTitle ?? ''}
          request={handleSubmitConsultationForm}
        />
        <OtherQuestions />
      </Container>
    </Component>
  );
}

export default Consultation;

const Component = styled.div`
  margin-top: 30px;
`;

const Container = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;
