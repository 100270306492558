import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { useAboutData } from '@/modules/About/About.hooks';

function Birthday() {
  const { birthdayBackground, birthdayTitle, birthdaySubtitle } =
    useAboutData();

  if (!birthdayBackground || !birthdayTitle) {
    return null;
  }

  return (
    <Component>
      <Background
        loading="lazy"
        {...convertThumbnailToPictureProps(birthdayBackground)}
      />
      <ContentContainerSmall>
        <Content>
          <Title>{birthdayTitle}</Title>
          <Subtitle>{birthdaySubtitle}</Subtitle>
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default Birthday;

const Component = styled.section`
  position: relative;

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Background = styled(Picture)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  padding-top: 180px;
  padding-bottom: 190px;
  min-height: 280px;

  ${media.tablet(css`
    text-align: left;
    padding-top: 64px;
    padding-bottom: 60px;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Subtitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;
  `)}
`;
