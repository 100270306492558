import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/EvaluationRedemption/common.styles';
import Advantages from '@/components/Redesign/Advantages';
import { useEvaluationRedemptionData } from '@/modules/EvaluationRedemption/hooks';
import { AdvantageItemProps } from '@/components/Redesign/Advantages/components/AdvantageItem/types';

function Programs() {
  const {
    templateFields: { programsItems, programsTitle },
  } = useEvaluationRedemptionData();

  if (!programsItems || programsItems.length === 0) {
    return null;
  }

  const programs: AdvantageItemProps[] = programsItems.map(
    ({ image, text, title, list }) => ({
      image,
      title: title ?? '',
      text: text ?? '',
      list: list.map(({ text }) => text ?? ''),
    })
  );

  return (
    <Component>
      <ContentContainer>
        <Advantages
          columns={3}
          title={programsTitle ?? ''}
          advantages={programs}
          isMobileSlider
          isBig
        />
      </ContentContainer>
    </Component>
  );
}

export default Programs;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
