import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { BrandProps } from './types';

function Brand({ image }: BrandProps) {
  return (
    <Component>
      <Image
        desktop={convertThumbnailToPictureImage(image)}
        alt={'brand image'}
      />
    </Component>
  );
}
export default Brand;

const Component = styled.div`
  border-radius: 10px;
  background: ${colors.white};
  height: inherit;

  ${media.tablet(css`
    border: 1px solid ${colors.gray1600};
  `)}
`;

const Image = styled(Picture)`
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;
