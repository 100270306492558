import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { LeaderItem } from '@/modules/Leadership/Leadership.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function LeadershipCard({ avatar, name, position }: LeaderItem) {
  const nameArray = name && name.split(' ');

  return (
    <Component>
      <Wrapper>
        <Avatar {...convertThumbnailToPictureProps(avatar)} />
        <Name>
          {nameArray &&
            nameArray.map((name, index) => (
              <NameItem key={index}>{name}</NameItem>
            ))}
        </Name>
        <Position>{position}</Position>
      </Wrapper>
    </Component>
  );
}

export default LeadershipCard;

const Component = styled.div`
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;

  ${media.tabletSmall(
    css`
      width: 50%;
      padding-left: 5px;
      padding-right: 5px;
    `
  )};

  @media (max-width: 599px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;

  ${media.tabletSmall(
    css`
      padding: 20px;
    `
  )};

  @media (max-width: 599px) {
    padding: 20px 0;
  }
`;

const Avatar = styled(Picture)`
  align-items: flex-start;
  justify-content: flex-start;

  picture,
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  img {
    object-fit: cover;
  }
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${media.tabletSmall(
    css`
      margin-top: 20px;
    `
  )}
`;

const NameItem = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.blue700};

  ${media.tabletSmall(
    css`
      font-size: 20px;
      line-height: 30px;
    `
  )}
`;

const Position = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
  color: ${colors.blue600};
  opacity: 0.7;

  ${media.tabletSmall(
    css`
      margin-top: 10px;
    `
  )}
`;
