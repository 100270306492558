import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { YearItem } from '@/modules/History/History.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function HistoryCard({ date, title, text, image }: YearItem) {
  return (
    <Component>
      <Content>
        <Date>{date}</Date>
        <Title>{title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: text ?? '' }} />
      </Content>
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
    </Component>
  );
}

export default HistoryCard;

const Component = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
    flex-direction: column-reverse;
    align-items: initial;
  `)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${media.mobile(css`
    padding: 0 20px;
    margin-top: 20px;
  `)}
`;

const Date = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray500};
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 30px;
  color: ${colors.blue600};

  a {
    color: ${colors.main};
  }

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  `)}
`;

const Image = styled(Picture)`
  flex: 0 0 380px;
  max-width: 380px;
  height: 245px;
  margin-left: 30px;

  ${media.tablet(css`
    flex: 0 0 320px;
    max-width: 320px;
    height: 205px;
  `)}

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    height: auto;
    padding-top: 64%;
    margin-left: 0;
  `)}

  img,
  picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
