import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { useBonusProgramData } from '@/modules/BonusProgram/BonusProgram.hooks';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

function Hero() {
  const { heroTitle, heroImage, heroMobileImage } = useBonusProgramData();
  return (
    <Component>
      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(heroImage)}
        laptop={convertThumbnailToPictureImage(heroImage)}
        tabletSmall={convertThumbnailToPictureImage(heroMobileImage)}
        mobile={convertThumbnailToPictureImage(heroMobileImage)}
      />
      <Wrapper>
        <ContentContainer>
          <Content>
            <TitleContainer>
              <Title>{heroTitle}</Title>
            </TitleContainer>
          </Content>
        </ContentContainer>
      </Wrapper>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  position: relative;
  z-index: 0;
`;

const Background = styled(Picture)`
  display: block;
  width: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    object-fit: cover;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 599px) {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  padding: 0 100px;

  ${media.mobile(css`
    padding: 0 50px;
  `)}
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 60px 0 130px;
  z-index: 2;
  height: 100%;

  ${media.tablet(css`
    justify-content: flex-end;
    padding: 10px 0 130px;
  `)};

  ${media.mobile(css`
    padding: 20px 0 130px;
  `)};

  @media (max-width: 599px) {
    padding: 50px 0;
    justify-content: flex-start;
  }
`;

const TitleContainer = styled.div`
  width: 50%;

  ${media.mobile(css`
    width: auto;
  `)}
`;

const Title = styled.h1`
  margin: 0;

  ${media.tabletSmallUp(css`
    font-size: 3vw;
    line-height: 3.3vw;
  `)}

  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 599px) {
    color: ${colors.white};
  }
`;
