import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { SwitchProps } from './types';

export function Switch({ options, onChange, checked }: SwitchProps) {
  const handleChangeToggle = (checked: boolean) => {
    onChange?.(checked);
  };

  return (
    <Component>
      <Title
        isBold={!checked}
        onClick={() => checked && handleChangeToggle(false)}
      >
        {options.from.label}
      </Title>
      <Toggle checked={checked} onClick={() => handleChangeToggle(!checked)}>
        <ToggleCircle checked={checked} />
      </Toggle>
      <Title
        isBold={checked}
        onClick={() => !checked && handleChangeToggle(true)}
      >
        {options.to.label}
      </Title>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span<{ isBold?: boolean }>`
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
  transition: 0.2s;
  user-select: none;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 16px;
    line-height: 19px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 19px;
  `)}

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 700;
    `}
`;

const Toggle = styled.div<{ checked?: boolean }>`
  cursor: pointer;
  position: relative;
  width: 44px;
  height: 22px;
  border-radius: 100px;
  margin: 0 5px;
  background: #1890ff;
  transition: all 0.2s;

  ${({ checked }) =>
    checked &&
    css`
      background: #1890ff;
    `}
`;

const ToggleCircle = styled.div<{ checked?: boolean }>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: ${colors.white};
    border-radius: 9px;
    box-shadow: 0 2px 4px #00230b33;
    transition: all 0.2s ease-in-out;
  }

  ${({ checked }) =>
    checked &&
    css`
      left: calc(100% - 20px);
    `}
`;
