import React from 'react';

import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { HeroBanner } from '@/components/CarModelLandingWidgets';
import { VideoBanner } from '@/components/LandingWidgets';

function Hero() {
  const { heroImage, heroMobileImage, heroBrand, heroModel, heroVideo } =
    useCarModelLandingData();

  if (!heroImage && !heroVideo) {
    return null;
  }

  return (
    <React.Fragment>
      {heroImage && (
        <HeroBanner
          image={{ desktop: heroImage, tablet: heroMobileImage }}
          brand={heroBrand ?? ''}
          model={heroModel ?? ''}
        />
      )}

      {heroVideo && heroVideo?.url && <VideoBanner video={heroVideo.url} />}
    </React.Fragment>
  );
}

export default Hero;
