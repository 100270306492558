import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { InsuranceTypesItem } from '@/modules/Insurance/Insurance.types';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function InsuranceCard({ image, title, text }: InsuranceTypesItem) {
  return (
    <Component>
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
      <Content>
        <Title>{title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: text ?? '' }} />
      </Content>
    </Component>
  );
}

export default InsuranceCard;

const Component = styled.div`
  display: flex;
  align-items: center;
  padding: 60px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};

  &:not(:first-child) {
    margin-top: 20px;

    ${media.tabletSmall(css`
      margin-top: 0;
    `)}
  }

  ${media.tabletSmall(css`
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const Image = styled(Picture)`
  margin-left: -120px;

  ${media.tablet(css`
    margin-left: -120px;
  `)};

  ${media.tabletSmall(css`
    width: 100%;
    height: 100%;
    margin-left: 0;
  `)}

  img,
  picture {
    width: 336px;
    height: 286px;

    ${media.tablet(css`
      width: 432px;
      height: 371px;
    `)};

    ${media.tabletSmall(css`
      width: 100%;
      height: 100%;
    `)}
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  margin-left: 60px;

  ${media.tablet(css`
    max-width: 500px;
  `)};

  ${media.tabletSmall(css`
    margin-left: 0;
    padding: 20px 20px 30px;
    max-width: none;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Text = styled.div`
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    margin-top: 10px;
  `)}

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.blue100};

    ${media.tabletSmall(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    &:not(:last-child) {
      margin-bottom: 1.5vw;

      ${media.tabletSmall(css`
        margin-bottom: 2vw;
      `)}
    }
  }

  ul,
  ol {
    margin: 20px 0 20px 2vw;

    li {
      list-style-type: square;
      padding-left: 0.6vw;
      font-size: 16px;
      line-height: 24px;
      color: ${(props) => props.theme.blue100};

      ${media.tabletSmall(css`
        font-size: 14px;
        line-height: 20px;
      `)}

      &:not(:first-child) {
        margin-top: 5px;

        ${media.tabletSmall(css`
          margin-top: 0;
        `)}
      }
    }
  }
`;
