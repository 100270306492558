import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { StockFilterPeriodProps } from './types';

function StockFilterPeriod({
  label,
  periods,
  value,
  onChange,
}: StockFilterPeriodProps) {
  if (!periods || periods.length === 0) {
    return null;
  }

  return (
    <Component>
      <Label>{label}</Label>
      <Periods>
        {periods.map((period, index) => (
          <Period
            key={index}
            type="button"
            isActive={value === period}
            onClick={() => onChange?.(period)}
          >
            {period}
          </Period>
        ))}
      </Periods>
    </Component>
  );
}

export default StockFilterPeriod;

const Component = styled.div`
  position: relative;
`;

const Label = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue100};
`;

const Periods = styled.div`
  display: flex;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.gray1000};
  margin-top: 10px;
`;

const Period = styled.button<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 11px 5px 10px;
  font-size: 16px;
  line-height: 19px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black200};

  &:first-child {
    border-radius: 6px 0 0 6px;
  }

  &:last-child {
    border-radius: 0 6px 6px 0;
  }

  &:not(:first-child) {
    border-left: 1px solid ${(props) => props.theme.gray1000};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.main};
      color: ${colors.white};
    `}
`;
