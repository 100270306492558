import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { InsuranceServicesItem } from '@/modules/Insurance/Insurance.types';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function QuestionAndAnswerItem({ question, answer }: InsuranceServicesItem) {
  const [isOpened, setOpened] = useState(false);

  const handleChangeOpenedState = () => {
    setOpened(!isOpened);
  };

  return (
    <Component>
      <Header>
        <Wrapper isOpened={isOpened} onClick={handleChangeOpenedState}>
          <Question isOpened={isOpened}>{question}</Question>
          <Arrow isOpened={isOpened}>
            <ArrowIcon />
          </Arrow>
        </Wrapper>
      </Header>
      {isOpened && (
        <Content dangerouslySetInnerHTML={{ __html: answer ?? '' }} />
      )}
    </Component>
  );
}

export default QuestionAndAnswerItem;

const Component = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const Header = styled.div`
  display: flex;
`;

const Wrapper = styled.div<{ isOpened: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    span {
      border-color: ${colors.main};

      ${({ isOpened }) =>
        isOpened &&
        css`
          color: ${colors.main};
        `}
    }
  }
`;

const Question = styled.span<{ isOpened: boolean }>`
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid transparent;
  transition: 0.15s;
  color: ${(props) => props.theme.main};

  ${({ isOpened }) =>
    isOpened &&
    css`
      font-weight: 700;
      color: ${(props) => props.theme.black};
    `}
`;

const Arrow = styled.span<{ isOpened: boolean }>`
  margin-left: 8px;

  svg {
    width: 11px;
    height: 7px;
    transform: ${(props) => (props.isOpened ? `rotate(180deg)` : `rotate(0)`)};
    transition: 0.15s;

    path {
      fill: ${(props) => props.theme.black100};
    }
  }
`;

const Content = styled.div`
  margin-top: 10px;
  color: ${(props) => props.theme.black};

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.blue100};

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    &:not(:first-child) {
      margin-top: 1.5vw;

      ${media.tablet(css`
        margin-top: 2vw;
      `)}
    }
  }

  ul,
  ol {
    margin: 20px 0 0 2vw;

    li {
      list-style-type: square;
      padding-left: 0.6vw;
      font-size: 16px;
      line-height: 24px;
      color: ${(props) => props.theme.blue100};

      ${media.tablet(css`
        font-size: 14px;
        line-height: 20px;
      `)}

      &:not(:first-child) {
        margin-top: 5px;

        ${media.tablet(css`
          margin-top: 0;
        `)}
      }
    }
  }
`;
