import React from 'react';
import styled from 'styled-components';

import { AboutCardPrimary } from '@/components/LandingWidgets';
import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import { ContentContainer } from '@/modules/FinanceLanding/common.styles';

function Description() {
  const { descriptionTitle, description } = useFinanceLandingData();

  if (!descriptionTitle || !description) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardPrimary title={descriptionTitle} topText={description} />
      </ContentContainer>
    </Component>
  );
}

export default Description;

const Component = styled.section`
  padding: 15px 0;
`;
