import React from 'react';

import { useMedia } from '@tager/web-core';

import FeatureSlider from './components/FeatureSlider';
import { FeaturesProps } from './types';
import FeaturesList from './components/FeaturesList';

function Features(props: FeaturesProps) {
  const isMobile = useMedia('(max-width: 1259px)');

  return !isMobile ? <FeaturesList {...props} /> : <FeatureSlider {...props} />;
}

export default Features;
