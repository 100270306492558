import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Header from './components/Header';
import Programs from './components/Programs';
import Faq from './components/Faq';
import FormBanner from './components/FormBanner';
import AdvantagesList from './components/AdvantagesList';

export function EvaluationRedemption() {
  return (
    <Layout>
      <Component>
        <Header />
        <Programs />
        <Faq />
        <AdvantagesList />
        <FormBanner />
      </Component>
    </Layout>
  );
}

export default EvaluationRedemption;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
