import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/CreditLeasing/common.styles';
import { media } from '@/utils/mixin';
import { ServiceFormSecondaryFields } from '@/components/LandingWidgets/components/ServiceFormSecondary/types';
import { submitCreditLeasingRequest } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { useCreditLeasingSingleData } from '@/modules/CreditLeasing/pages/CreditLeasingSingle/hooks';
import { ServiceFormSecondary } from '@/components/LandingWidgets';
import { getSelectOptions } from '@/utils/common';

function Form() {
  const {
    templateFields: {
      formTitle,
      formSubtitle,
      formImage,
      formMobileImage,
      formButtonTitle,
      formDealersRequests,
      formModelsRequests,
      formIsShowDealers,
      formIsShowModels,
      formGoogleEvent,
    },
  } = useCreditLeasingSingleData();

  if (!formTitle || !formImage) {
    return null;
  }

  const fieldsConfig = {
    dealers: !formIsShowDealers
      ? getSelectOptions(formDealersRequests)
      : undefined,
    models: !formIsShowModels
      ? getSelectOptions(formModelsRequests)
      : undefined,
  };

  const sendCreditLeasingForm = async (
    values: ServiceFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitCreditLeasingRequest(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ServiceFormSecondary
          fieldsConfig={fieldsConfig}
          title={formTitle}
          subtitle={formSubtitle ?? ''}
          image={formImage}
          buttonTitle={formButtonTitle ?? ''}
          imageMobile={formMobileImage}
          onSubmit={sendCreditLeasingForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 20px 0 40px;

    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
