import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { SliderWrapper } from '@/components/LandingWidgets';

import Benefit from '../Benefit';
import { Component as BenefitComponent } from '../Benefit/Benefit';

import { BenefitSliderProps } from './types';

function BenefitSlider({ id, benefits }: BenefitSliderProps) {
  if (!benefits || benefits.length === 0) {
    return null;
  }

  const isWithoutPagination = benefits.length === 1;
  const isNoSwiping = benefits.length === 1;

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          spaceBetween: 30,
          noSwipingClass: 'swiper-no-swiping',
          withoutPagination: isWithoutPagination,
          withoutNavs: true,
        }}
      >
        {benefits.map((benefit, index) => (
          <SwiperSlide
            key={index}
            className={isNoSwiping ? 'swiper-no-swiping' : ''}
          >
            <Benefit key={index} {...benefit} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default BenefitSlider;

const Component = styled.div`
  .swiper-slide {
    height: auto;

    ${BenefitComponent} {
      height: 100%;
    }
  }
`;
