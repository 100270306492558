import React from 'react';
import styled, { css } from 'styled-components';

import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { media } from '@/utils/mixin';
import { ServiceCard } from '@/components/LandingWidgets';

function ServiceBanner() {
  const {
    serviceTitle,
    serviceText,
    serviceNote,
    serviceImage,
    serviceMobileImage,
    serviceButtonText,
    serviceButtonLink,
  } = useTopLevelFunnelLandingData();

  if (!serviceTitle || !serviceText || !serviceImage) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <ServiceCard
          title={serviceTitle}
          description={serviceText}
          note={serviceNote ?? ''}
          image={serviceImage}
          imageMobile={serviceMobileImage}
          buttonText={serviceButtonText ?? ''}
          buttonLink={serviceButtonLink ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default ServiceBanner;

const Component = styled.section`
  padding: 15px 0;

  ${media.tablet(css`
    padding: 10px 0;
  `)}
`;
