import React from 'react';
import styled from 'styled-components';

import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import {
  Title,
  ContentContainer,
} from '@/modules/FinanceLanding/common.styles';
import CompaniesTicker from '@/components/CompaniesTicker';

function Partners() {
  const { partnersTitle, partners } = useFinanceLandingData();

  if (!partners || partners.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{partnersTitle}</Title>

        <CompaniesTickerWrapper>
          <CompaniesTicker images={partners} />
        </CompaniesTickerWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Partners;

const Component = styled.section`
  padding: 15px 0;
`;

const CompaniesTickerWrapper = styled.div`
  margin-top: 20px;
`;
