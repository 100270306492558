import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { media } from '@/utils/mixin';

function OtherQuestions() {
  const { formText } = useSpecialOfferData();

  return <Component dangerouslySetInnerHTML={{ __html: formText ?? '' }} />;
}

export default OtherQuestions;

const Component = styled.div`
  text-align: center;
  margin-top: 60px;
  color: ${(props) => props.theme.black};

  h3 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 500;
    margin-bottom: 15px;

    ${media.mobile(css`
      font-size: 26px;
    `)}
  }

  p {
    a {
      color: ${colors.main};
      font-size: 30px;
      line-height: 42px;

      ${media.mobile(css`
        font-size: 28px;
      `)}
    }
  }
`;
