import React from 'react';
import styled from 'styled-components';

import {
  useCarModelCompareLandingContext,
  useCarModelCompareLandingData,
} from '@/modules/CarModelCompareLanding/hooks';
import { CollapseInfo } from '@/components/CarModelCompareLandingWidgets';

import { useCollapseInfoData } from './hooks';

function CompareDesktop() {
  const { models } = useCarModelCompareLandingData();
  const { isDifferentParams, currentSlideIndex } =
    useCarModelCompareLandingContext();
  const collapseInfoData = useCollapseInfoData(isDifferentParams);

  if (
    !models ||
    models.length === 0 ||
    !collapseInfoData ||
    collapseInfoData.length === 0
  ) {
    return null;
  }

  return (
    <Component>
      {collapseInfoData.map((collapse, index) => (
        <CollapseItem key={index}>
          <CollapseInfo {...collapse} currentSlideIndex={currentSlideIndex} />
        </CollapseItem>
      ))}
    </Component>
  );
}

export default CompareDesktop;

const Component = styled.div``;

const CollapseItem = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;
