import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/ServiceValuationLanding/common.styles';
import ServiceForm from '@/components/Forms/ServiceForm';
import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { getServiceBrands, getServiceDealers } from '@/store/reducers/service';
import { getServicePageOptions } from '@/modules/Service/common.helpers';
import { OptionType } from '@/typings/common';
import { ServiceFormFields } from '@/components/Forms/ServiceForm/types';
import { submitServiceValuationLandingRequest } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function Form() {
  const {
    templateFields: {
      formTitle,
      formButtonTitle,
      formServices,
      formGoogleEvent,
    },
  } = useServiceValuationLandingData();
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);

  if (!formTitle || !formServices || formServices.length === 0) {
    return null;
  }

  const { brands, dealers } = getServicePageOptions(
    serviceDealers,
    serviceBrands
  );

  const services: OptionType[] = formServices.map(({ name }) => ({
    label: name ?? '',
    value: name ?? '',
  }));

  const handleSubmitServiceForm = async (
    values: ServiceFormFields
  ): Promise<void> => {
    try {
      await submitServiceValuationLandingRequest({
        ...values,
        brand: values.brand.value,
        dealer: Number(values.dealer.value),
        service: values.service.value,
      }).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ServiceForm
          title={formTitle ?? undefined}
          buttonTitle={formButtonTitle ?? ''}
          options={{ brands, dealers, services }}
          onSubmit={handleSubmitServiceForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;
