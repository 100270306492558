import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { useBonusProgramData } from '@/modules/BonusProgram/BonusProgram.hooks';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

function Help() {
  const { helpTitle, helpText, helpAppsItems, helpImage } =
    useBonusProgramData();

  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <Main>
            <Title>{helpTitle}</Title>
            <Text dangerouslySetInnerHTML={{ __html: helpText ?? '' }} />
            <AppsList>
              {helpAppsItems &&
                helpAppsItems.map(({ image, link }, index: number) => (
                  <App key={index} to={link} target="_blank">
                    <AppImage
                      loading="lazy"
                      {...convertThumbnailToPictureProps(image)}
                    />
                  </App>
                ))}
            </AppsList>
          </Main>

          <Image
            loading="lazy"
            {...convertThumbnailToPictureProps(helpImage)}
          />
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default Help;

const Component = styled.section`
  padding: 60px 0;

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Main = styled.div`
  flex: 1 1 610px;
  max-width: 610px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Text = styled.div`
  margin-top: 30px;
  color: ${(props) => props.theme.black};

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.black200};

    &:last-child {
      margin-top: 30px;

      ${media.mobile(css`
        margin-top: 20px;
      `)}
    }

    a {
      color: ${colors.main};
    }

    strong {
      margin-top: 20px;
    }
  }
`;

const Image = styled(Picture)`
  margin-left: 40px;

  ${media.mobile(css`
    display: none;
  `)}
`;

const AppsList = styled.div`
  display: flex;
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const App = styled(Link)`
  &:not(:first-child) {
    margin-left: 30px;
  }
`;

const AppImage = styled(Picture)`
  img {
    width: 132px;
    height: 40px;
  }
`;
