import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/ServiceValuationLanding/common.styles';
import FaqAccordion from '@/components/FaqAccordion';
import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import { media } from '@/utils/mixin';

function Faq() {
  const {
    templateFields: { questionsTitle, questions },
  } = useServiceValuationLandingData();

  if (!questions || questions.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{questionsTitle}</Title>

        <FaqAccordionWrapper>
          <FaqAccordion data={questions} />
        </FaqAccordionWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Faq;

const Component = styled.section`
  padding: 60px 0;
  background: ${(props) => props.theme.gray};

  ${media.tablet(css`
    padding: 40px 0;
  `)}
`;

const FaqAccordionWrapper = styled.div`
  margin-top: 20px;
`;
