import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Ticks from '@/components/Redesign/Ticks';

import { GuaranteesProps } from './types';
import PostImage from './components/PostImage';

function Guarantees({
  text,
  image,
  subTitle,
  title,
  imageTitle,
  ticks,
  ticksColumns,
  banner,
  isReverse,
}: GuaranteesProps) {
  return (
    <Component isReverseSection={isReverse}>
      <Block>
        <PostImage image={image} title={imageTitle} />
        {banner && <BannerWrapper>{banner}</BannerWrapper>}
      </Block>
      <Block>
        <Title>{title}</Title>
        <Text
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        {ticks && (
          <TicksWrapper>
            <Ticks columns={ticksColumns} ticks={ticks} />
          </TicksWrapper>
        )}
      </Block>
    </Component>
  );
}

export default Guarantees;

const Component = styled.div<{
  isReverseSection: GuaranteesProps['isReverse'];
}>`
  flex: 1;
  display: flex;
  gap: 30px;

  ${media.tablet(css`
    flex-direction: column;
    gap: 20px;
  `)}

  ${({ isReverseSection }) =>
    isReverseSection &&
    css`
      flex-direction: row-reverse;

      ${media.tablet(css`
        flex-direction: column-reverse;
      `)}
    `}
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.black};
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${colors.black};
`;

const SubTitle = styled.h3`
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: ${colors.black};
`;

const TicksWrapper = styled.div`
  margin-top: 20px;
`;

const BannerWrapper = styled.div`
  margin-top: 20px;
`;
