import React from 'react';
import styled from 'styled-components';

import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import { ContentContainer } from '@/modules/FinanceLanding/common.styles';
import FinanceCalculationForm from '@/components/Forms/FinanceCalculationForm';
import { submitFinanceLandingCalculation } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { FinanceCalculationFormFields } from '@/components/Forms/FinanceCalculationForm/types';

function CalculationForm() {
  const {
    calculationFormTitle,
    calculationFormSubtitle,
    calculationFormRate,
    calculationFormMinAmount,
    calculationFormMaxAmount,
    calculationFormMinPeriod,
    calculationFormMaxPeriod,
    calculationFormButtonTitle,
    calculationFormGoogleEvent,
  } = useFinanceLandingData();

  if (
    !calculationFormTitle ||
    !calculationFormSubtitle ||
    !calculationFormRate ||
    !calculationFormMinAmount ||
    !calculationFormMaxAmount ||
    !calculationFormMinPeriod ||
    !calculationFormMaxPeriod ||
    !calculationFormButtonTitle
  ) {
    return null;
  }

  const sendCalculation = async (
    values: FinanceCalculationFormFields
  ): Promise<void> => {
    try {
      await submitFinanceLandingCalculation(values).then(() => {
        if (calculationFormGoogleEvent) {
          googleEvent(calculationFormGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <FinanceCalculationForm
          title={calculationFormTitle}
          subtitle={calculationFormSubtitle}
          rate={calculationFormRate}
          minAmount={calculationFormMinAmount}
          maxAmount={calculationFormMaxAmount}
          minPeriod={calculationFormMinPeriod}
          maxPeriod={calculationFormMaxPeriod}
          buttonTitle={calculationFormButtonTitle}
          onSubmit={sendCalculation}
        />
      </ContentContainer>
    </Component>
  );
}

export default CalculationForm;

const Component = styled.section`
  padding: 15px 0;
`;
