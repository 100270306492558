import React from 'react';
import styled from 'styled-components';

import { useTypedSelector } from '@/store/store';
import { selectHomeBigBanners } from '@/store/reducers/banners';
import Layout from '@/layout';

import { useHomeData } from './Home.hooks';
import Banners from './components/Banners';
import PreviewCatalog from './components/PreviewCatalog';
import News from './components/News/News';
import BenefitsSlider from './components/BenefitsSlider';

function Home() {
  const { homeBenefits } = useHomeData();
  const homeSliderBig = useTypedSelector(selectHomeBigBanners);

  return (
    <Layout>
      <Component>
        {homeSliderBig && homeSliderBig.data.length > 0 && <Banners />}
        <PreviewCatalog />
        <News />
        {homeBenefits && homeBenefits.length > 0 && (
          <BenefitsSlider benefits={homeBenefits} />
        )}
      </Component>
    </Layout>
  );
}

export default Home;

const Component = styled.div`
  background: ${(props) => props.theme.gray};
`;
