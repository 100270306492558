import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import InfoMessage from '@/components/Forms/HelpOnTheRoadForm/components/VinInfo/InfoMessage';
import Info from '@/assets/svg/info.svg';

function VinInfo() {
  return (
    <Tippy
      content={<InfoMessage />}
      interactive
      placement="bottom"
      appendTo={isBrowser() ? document.body : 'parent'}
    >
      <SvgWrapper>
        <Info />
      </SvgWrapper>
    </Tippy>
  );
}

export default VinInfo;

const SvgWrapper = styled.div`
  svg {
    width: 20px;
    height: 20px;

    path {
      &:first-child {
        stroke: ${(props) => props.theme.black};
      }

      &:not(:first-child) {
        fill: ${(props) => props.theme.blue100};
      }
    }
  }
`;
