import React, { useEffect, useMemo, useState } from 'react';

import { isNotNullish } from '@tager/web-core';

import useTab from '@/modules/Home/hooks/useTab';
import BaseHomeSection from '@/modules/Home/components/BaseHomeSection';
import {
  Links,
  TabBlogButtons,
  TabBlogContent,
} from '@/modules/Home/components/common.components';
import { fetchPostsListPostsThunk } from '@/store/reducers/posts-list';
import { useTypedDispatch } from '@/store/store';
import { BlogPosts } from '@/typings/model';
import { CatalogBlogTab } from '@/modules/Home/Home.types';
import PostCard from '@/components/PostCard';

import { linkList } from './News.constants';

const initialState: BlogPosts = {
  total: 0,
  items: [],
};

function News() {
  const [activeIndex, setActiveIndex] = useTab();
  const [activeData, setActiveData] = useState<BlogPosts>(initialState);
  const dispatch = useTypedDispatch();

  const tabData: CatalogBlogTab[] = useMemo(
    () => [
      { text: 'Акции', url: 'offers', items: activeData.items },
      { text: 'Новости', url: 'news', items: activeData.items },
      {
        text: 'Публикации',
        url: 'reviews',
        items: activeData.items,
      },
    ],
    [activeData.items]
  );

  useEffect(() => {
    const category = tabData[activeIndex].url;
    if (category) {
      dispatch(fetchPostsListPostsThunk({ category, limit: 4 }))
        .then((response) => {
          setActiveData(isNotNullish(response) ? response : initialState);
        })
        .catch((error) => console.log(error));
    }
  }, [activeIndex, dispatch]);

  const LinksComponent = React.createElement(Links, {
    itemList: linkList,
  });

  const TabContentComponent = React.createElement(TabBlogContent, {
    tabItemComponent: PostCard,
    data: tabData,
    activeIndex: activeIndex,
  });

  const TabButtonsComponent = React.createElement(TabBlogButtons, {
    data: tabData,
    activeIndex: activeIndex,
    onClick: setActiveIndex,
  });

  return (
    <BaseHomeSection
      sectionTitle="Новости"
      sectionDescription="Акции, новости и обзоры"
      Links={LinksComponent}
      TabButtons={TabButtonsComponent}
      TabContent={TabContentComponent}
    />
  );
}

export default News;
