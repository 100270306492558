import React, { useState } from 'react';
import styled from 'styled-components';

import { Tabs } from '@/components/CarModelLandingWidgets';

import { FeatureCardsProps } from './types';
import FeatureCard from './components/FeatureCard';

export function FeatureCards({ cards }: FeatureCardsProps) {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <Component>
      {cards[activeTab].section && (
        <TabsWrapper>
          <Tabs
            data={cards.map(({ section }, index) => ({
              label: section ?? '',
              value: index,
            }))}
            activeValue={activeTab}
            onChange={setActiveTab}
            isBlock
          />
        </TabsWrapper>
      )}

      {cards[activeTab].cards.map((card, index) => (
        <FeatureCardWrapper key={index}>
          <FeatureCard {...card} />
        </FeatureCardWrapper>
      ))}
    </Component>
  );
}

const Component = styled.div``;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const FeatureCardWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;
