import React from 'react';
import styled, { css } from 'styled-components';

import Dealerships from '@/components/Dealerships';
import { useServicesData } from '@/modules/Services/Services.hooks';
import { media } from '@/utils/mixin';
import ServicesMap from '@/modules/Services/components/Dealers/components/ServicesMap';

function Dealers() {
  const data = useServicesData();

  if (!data.dealersTitle || !data.dealersItems || !data.dealersItems.length) {
    return null;
  }

  const dealers = data.dealersItems.map((item) => {
    return {
      name: item.name,
      legalName: item.legalName,
      phones: item.phones.map((phone) => phone.phone),
      address: item.addresses?.map((address) => address.address ?? []),
    };
  });

  return (
    <Component>
      <Title>{data.dealersTitle}</Title>

      <Container>
        <Dealerships dealers={dealers} />
      </Container>

      <MapWrapper>
        <ServicesMap />
      </MapWrapper>
    </Component>
  );
}

export default Dealers;

const Component = styled.div`
  max-width: 1440px;
  margin: 0 auto 50px;
  padding: 0 30px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  margin: 40px 0 20px;
  color: ${(props) => props.theme.black};

  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 38px;
    margin: 20px 0 10px;
  }
`;

const Container = styled.div`
  max-width: 585px;
`;

const MapWrapper = styled.div`
  margin-top: 20px;
`;
