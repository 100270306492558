import React from 'react';
import styled, { css } from 'styled-components';

import { TextContent } from '@/components/LandingWidgets';
import { useCreditLeasingData } from '@/modules/CreditLeasing/hooks';
import { media } from '@/utils/mixin';

function Text() {
  const {
    templateFields: { bottomText },
  } = useCreditLeasingData();

  return (
    <Component>
      <TextContent text={bottomText ?? ''} />
    </Component>
  );
}

export default Text;

const Component = styled.div`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
