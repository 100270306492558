import React from 'react';
import styled, { css } from 'styled-components';

import PackageCard from '@/components/Packages/components/PackageCard';
import { PackagesProps } from '@/components/Packages/types';
import Table from '@/components/Packages/components/Table';

function Desktop({ packages, table }: PackagesProps) {
  if (!packages || packages.length === 0 || !table || table.length === 0) {
    return null;
  }

  return (
    <Component>
      <Packages>
        <PackagesInner packagesCount={packages.length}>
          {packages.map((item, index) => (
            <PackageCard key={index} {...item} />
          ))}
        </PackagesInner>
      </Packages>

      {table.map((item, index) => (
        <TableWrapper key={index}>
          <Table {...item} />
        </TableWrapper>
      ))}
    </Component>
  );
}

export default Desktop;

const Component = styled.div``;

const Packages = styled.div`
  display: grid;
  grid-template-columns: 620px 1fr;
  grid-auto-flow: dense;
`;

const PackagesInner = styled.div<{ packagesCount: number }>`
  grid-column: 2;
  display: grid;
  gap: 20px;

  ${({ packagesCount }) =>
    packagesCount &&
    css`
      grid-template-columns: repeat(${packagesCount}, 1fr);
    `}
`;

const TableWrapper = styled.div`
  margin-top: 10px;
`;
