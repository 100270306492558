import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import HeroSlider from './components/HeroSlider';
import Slogan from './components/Slogan';
import About from './components/About';
import SpecialOfferBanner from './components/SpecialOfferBanner';
import ModelsList from './components/ModelsList';
import TechSpecifications from './components/TechSpecifications';
import TradeIn from './components/TradeIn';
import Credit from './components/Credit';
import Form from './components/Form';
import Map from './components/Map';
import Benefits from './components/Benefits';

function SollersHome() {
  return (
    <Layout>
      <Component>
        <HeroSlider />
        <Slogan />
        <Benefits />
        <About />
        <SpecialOfferBanner />
        <ModelsList />
        <TechSpecifications />
        <TradeIn />
        <Credit />
        <Form />
        <Map />
      </Component>
    </Layout>
  );
}

export default SollersHome;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
