import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useIsomorphicLayoutEffect } from '@tager/web-core';

import { useCreditLeasingData } from '@/modules/CreditLeasing/hooks';
import { CreditOfferCard } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import { CreditLeasingOffer } from '@/modules/CreditLeasing/types';

const maxOffersOnPage = 3;

function Offers() {
  const {
    templateFields: { creditLeasingOffers },
  } = useCreditLeasingData();
  const [offers, setOffers] = useState<CreditLeasingOffer[]>([]);

  useIsomorphicLayoutEffect(() => {
    setOffers(
      creditLeasingOffers && creditLeasingOffers.length !== 0
        ? creditLeasingOffers.slice(0, maxOffersOnPage)
        : []
    );
  }, [creditLeasingOffers]);

  if (!offers || offers.length === 0) {
    return null;
  }

  const isShowMoreButtonShown =
    creditLeasingOffers.length > maxOffersOnPage &&
    creditLeasingOffers.length !== offers.length;

  const handleShowMore = () => {
    setOffers(creditLeasingOffers.slice(0, offers.length + maxOffersOnPage));
  };

  return (
    <Component>
      {offers.map(
        ({ image, title, description, buttonText, link, badge }, index) => (
          <CreditOfferCardWrapper key={index}>
            <CreditOfferCard
              image={image}
              title={title ?? ''}
              description={description ?? ''}
              buttonText={buttonText ?? ''}
              link={link ?? ''}
              badge={badge ?? ''}
            />
          </CreditOfferCardWrapper>
        )
      )}

      {isShowMoreButtonShown && (
        <ButtonWrapper>
          <StyledButton variant="outlined" onClick={handleShowMore}>
            Показать еще
          </StyledButton>
        </ButtonWrapper>
      )}
    </Component>
  );
}

export default Offers;

const Component = styled.div`
  margin-top: 40px;
`;

const CreditOfferCardWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }

  ${media.tablet(css`
    margin: 0 -20px;
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  max-width: 300px;
`;
