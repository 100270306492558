import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import CheckIcon from '@/assets/svg/checkbox-tick.svg';
import { colors } from '@/constants/theme';
import { ButtonNextLink } from '@/components/Button';

import { InsuranceCardProps } from './types';

function InsuranceCard({
  image,
  name,
  description,
  benefits,
  buttonTitle,
  buttonLink,
}: InsuranceCardProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        alt={name}
        {...convertThumbnailToPictureProps(image)}
      />

      <Name>{name}</Name>
      <Description>{description}</Description>
      {benefits && benefits.length > 0 && (
        <Benefits>
          {benefits.map((benefit, index) => (
            <Benefit key={index}>
              <CheckIcon />
              <BenefitText dangerouslySetInnerHTML={{ __html: benefit }} />
            </Benefit>
          ))}
        </Benefits>
      )}
      {buttonTitle && (
        <ButtonWrapper>
          <ButtonNextLink to={buttonLink} variant="outlined">
            {buttonTitle}
          </ButtonNextLink>
        </ButtonWrapper>
      )}
    </Component>
  );
}

export default InsuranceCard;

const Component = styled.div`
  display: grid;
  grid-template-areas:
    'a b'
    'a c'
    'a d'
    'a e';
  grid-template-columns: 90px 1fr;
  grid-template-rows: auto auto auto 1fr;
  padding: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.white_1};

  ${media.tablet(css`
    grid-template-areas:
      'a b'
      'c c'
      'd d'
      'e e';
  `)}
`;

const Image = styled(Picture)`
  grid-area: a;
  align-items: flex-start;
  flex: 0 0 70px;
  margin-right: 20px;

  picture,
  img {
    width: 70px;
    height: 70px;
  }

  img {
    object-fit: cover;
  }
`;

const Name = styled.p`
  grid-area: b;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.black};
`;

const Description = styled.p`
  grid-area: c;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};
`;

const Benefits = styled.div`
  grid-area: d;
  margin-top: 20px;
`;

const Benefit = styled.div`
  display: flex;

  svg {
    flex: 0 0 20px;
    height: 20px;

    path {
      fill: ${colors.main};
      stroke: ${colors.main};
    }
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const BenefitText = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
  color: ${(props) => props.theme.black};
`;

const ButtonWrapper = styled.div`
  grid-area: e;
  display: flex;
  align-items: flex-end;
  max-width: 240px;
  margin-top: 20px;

  ${media.mobile(css`
    max-width: none;
  `)}
`;
