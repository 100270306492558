import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { useSubscriptionData } from '@/modules/Subscription/Subscription.hooks';

import AllInclusiveCard from './components/AllInclusiveCard';
import AllInclusiveCardSlider from './components/AllInclusiveCardSlider';

function AllInclusive() {
  const { allInclusiveTitle, allInclusiveItems, allInclusiveText } =
    useSubscriptionData();
  const isMobile = useMedia('(max-width: 767px)');

  if (!allInclusiveItems || allInclusiveItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title dangerouslySetInnerHTML={{ __html: allInclusiveTitle ?? '' }} />

        {!isMobile ? (
          <Cards>
            {allInclusiveItems.map(({ image, text }, index) => (
              <AllInclusiveCard key={index} image={image} text={text} />
            ))}
          </Cards>
        ) : (
          <AllInclusiveCardSlider items={allInclusiveItems} />
        )}

        {allInclusiveText && (
          <Text dangerouslySetInnerHTML={{ __html: allInclusiveText }} />
        )}
      </ContentContainer>
    </Component>
  );
}

export default AllInclusive;

const Component = styled.section`
  padding: 60px 0;
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    margin: 0 20px;
  `)}
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 30px;
  margin-top: 30px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 150%;
  margin-top: 15px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 14px;
    padding: 0 20px;
    margin-top: 50px;
  `)}
`;
