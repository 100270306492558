import React, { useState } from 'react';
import styled from 'styled-components';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import {
  CarConfigurationSlider,
  ColorPicker,
  Description,
  FeatureListSecondary,
  Tabs,
  Title,
} from '@/components/CarModelLandingWidgets';
import { ColorPickerOnChangeConfig } from '@/components/CarModelLandingWidgets/ColorPicker/types';

function SecondModelConfiguration() {
  const {
    configurationSecondTitle,
    configurationSecondGallery,
    configurationSecondText,
    configurationSecondItems,
  } = useCarModelLandingData();

  const complectationTabs =
    configurationSecondGallery && configurationSecondGallery.length !== 0
      ? configurationSecondGallery.map(({ complectation }, index) => ({
          label: complectation ?? '',
          value: index,
        }))
      : [];
  const hasTabs = complectationTabs.length !== 0;

  const [activeComplectationTab, setActiveComplectationTab] = useState<number>(
    hasTabs ? complectationTabs[0].value : 0
  );

  const colors =
    configurationSecondGallery && configurationSecondGallery.length !== 0
      ? configurationSecondGallery[
          activeComplectationTab
        ]?.complectationItems.map((item) => ({ color: item.color ?? '' }))
      : [];

  const [activeComplectationColor, setActiveComplectationColor] =
    useState<ColorPickerOnChangeConfig>({
      activeColorIndex: 0,
      activeColor: colors[0]?.color ?? '',
    });

  const sliderImages =
    configurationSecondGallery && configurationSecondGallery.length !== 0
      ? configurationSecondGallery[activeComplectationTab]?.complectationItems[
          activeComplectationColor.activeColorIndex
        ]?.images
      : [];

  if (
    !configurationSecondTitle &&
    !hasTabs &&
    sliderImages?.length === 0 &&
    colors?.length === 0 &&
    !configurationSecondText &&
    configurationSecondItems?.length === 0
  ) {
    return null;
  }

  return (
    <Component id="configuration">
      <ContentContainer>
        {configurationSecondTitle && <Title title={configurationSecondTitle} />}

        {hasTabs && (
          <TabsWrapper>
            <Tabs
              data={complectationTabs}
              activeValue={activeComplectationTab}
              onChange={(value) => {
                setActiveComplectationTab(value);
                setActiveComplectationColor({
                  activeColorIndex: 0,
                  activeColor: colors[0]?.color ?? '',
                });
              }}
              isBlock
            />
          </TabsWrapper>
        )}

        {sliderImages && sliderImages.length !== 0 && (
          <SliderWrapper>
            <CarConfigurationSlider images={sliderImages} />
          </SliderWrapper>
        )}

        {colors && colors.length !== 0 && (
          <ColorPickerWrapper>
            <ColorPicker
              colors={colors}
              activeColor={activeComplectationColor.activeColor}
              onChange={setActiveComplectationColor}
            />
          </ColorPickerWrapper>
        )}

        {configurationSecondText && (
          <DescriptionWrapper>
            <Description description={configurationSecondText} />
          </DescriptionWrapper>
        )}

        {configurationSecondItems && configurationSecondItems.length !== 0 && (
          <FeatureListWrapper>
            <FeatureListSecondary
              features={configurationSecondItems.map(
                ({ image, title, description }) => ({
                  image,
                  title: title ?? '',
                  text: description ?? '',
                })
              )}
            />
          </FeatureListWrapper>
        )}
      </ContentContainer>
    </Component>
  );
}

export default SecondModelConfiguration;

const Component = styled.section`
  padding: 30px 0;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const SliderWrapper = styled.div`
  margin-top: 30px;
`;

const ColorPickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 40px;
`;

const FeatureListWrapper = styled.div`
  margin-top: 60px;
`;
