import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';

import {
  ContentContainer,
  Title,
} from '@/modules/FinanceLanding/common.styles';
import { useTypedSelector } from '@/store/store';
import { getStockCarList } from '@/store/reducers/cars-stock';
import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import {
  getCarBadges,
  getCarImages,
  getCarLink,
  getCarPrice,
  getCarStatus,
  getCarTags,
  handleOpenApplicationModal,
} from '@/modules/CarsStock/components/common.helpers';
import { StockCarCardProps } from '@/components/StockCarCard/types';
import useSettingItem from '@/hooks/useSettingItem';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import StockCarsList from '@/components/StockCarsList';

function StockCars() {
  const { stockTitle, stockButtonTitle, stockButtonLink } =
    useFinanceLandingData();
  const carsListData = useTypedSelector(getStockCarList);
  const openModal = useModal();
  const stockNewApplicationGoogleEvent = useSettingItem(
    'STOCK_NEW_APPLICATION_GOOGLE_EVENT'
  );
  const stockPartnerCarApplicationGoogleEvent = useSettingItem(
    'STOCK_PARTNER_CAR_APPLICATION_GOOGLE_EVENT'
  );
  const phone = usePhoneNumber();

  if (
    !carsListData ||
    !carsListData.items ||
    carsListData.items.length === 0 ||
    !stockButtonTitle ||
    !stockButtonLink
  ) {
    return null;
  }

  const carsStockListData: StockCarCardProps[] = carsListData.items.map(
    (car) => ({
      view: 'portrait',
      images: getCarImages(car),
      link: getCarLink('new', car.id),
      name: car.name,
      subName: car.complectation ?? '',
      price: getCarPrice(car, phone),
      totalImagesCount: car.imagesCount,
      tags: getCarTags(car),
      badgesList: getCarBadges(car),
      carStatus: getCarStatus(car),
      stockStatus: car.stockStatus ?? undefined,
      buttonHandle: () =>
        handleOpenApplicationModal(
          car,
          openModal,
          (car.isPartnerCar
            ? stockPartnerCarApplicationGoogleEvent
            : stockNewApplicationGoogleEvent) ?? ''
        ),
    })
  );

  return (
    <Component>
      <ContentContainer>
        <Title>{stockTitle}</Title>

        <CarsStockListWrapper>
          <StockCarsList
            cars={carsStockListData}
            buttonTitle={stockButtonTitle}
            buttonLink={stockButtonLink}
          />
        </CarsStockListWrapper>
      </ContentContainer>
    </Component>
  );
}

export default StockCars;

const Component = styled.section`
  padding: 15px 0;
`;

const CarsStockListWrapper = styled.div`
  margin-top: 20px;
`;
