import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import {
  useBrandsOptions,
  useSubscriptionData,
} from '@/modules/Subscription/Subscription.hooks';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import Picture from '@/components/Picture';
import phoneImage from '@/assets/images/subscription/phone.png';
import vector1 from '@/assets/images/subscription/vector1.png';
import vector2 from '@/assets/images/subscription/vector2.png';
import phoneVector from '@/assets/images/subscription/phoneVector.png';
import PhoneIcon from '@/assets/svg/phone.svg';
import Link from '@/components/Link';
import { getPhoneLink } from '@/utils/common';

import { handleOpenModalFormClick } from '../../Subscription.helpers';

function About() {
  const {
    aboutTitle,
    aboutButtonTitle,
    aboutImage,
    aboutFormType,
    aboutGoogleEvent,
    phone,
  } = useSubscriptionData();
  const openModal = useModal();
  const brands = useBrandsOptions();

  return (
    <Component>
      <ContentContainer>
        <Content>
          <LeftSide>
            <Title>{aboutTitle}</Title>
            <Buttons>
              <StyledButton
                onClick={() =>
                  handleOpenModalFormClick(
                    openModal,
                    aboutFormType,
                    brands,
                    aboutGoogleEvent
                  )
                }
              >
                {aboutButtonTitle}
              </StyledButton>
            </Buttons>
          </LeftSide>

          {aboutImage ? (
            <ImageWrapper>
              <Image
                loading="lazy"
                {...convertThumbnailToPictureProps(aboutImage)}
              />
              <ImageLink to={getPhoneLink(phone ?? '')} />
            </ImageWrapper>
          ) : phone ? (
            <Vectors>
              <PhoneImage loading="lazy" src={phoneImage} />
              <Vector loading="lazy" isSmall src={vector1} />
              <Phone to={getPhoneLink(phone)}>
                <PhoneVector loading="lazy" src={phoneVector} />
                <PhoneText>{phone}</PhoneText>
              </Phone>
              <VectorWrapper>
                <Vector loading="lazy" isBig src={vector2} />
                <PhoneIcon />
              </VectorWrapper>
            </Vectors>
          ) : null}
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  margin-bottom: -72px;
  z-index: 2;

  ${media.mobile(css`
    margin-bottom: -202px;
  `)}
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 60px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tablet(css`
    flex-direction: column;
    justify-content: center;
    padding: 30px 20px;
    min-height: 400px;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 50%;
  max-width: 50%;

  ${media.tablet(css`
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 35px;
    text-align: center;
  `)};
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;

  ${media.tablet(css`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `)}
`;

const StyledButton = styled(Button)`
  min-width: 280px;
  font-weight: 700;
  padding: 11px 0;
  border-radius: 21px;
  transition: 0.3s;
  background: ${colors.orange};
  color: ${colors.white};

  ${media.mobile(css`
    width: 100%;
    min-width: auto;
    max-width: 280px;
  `)}

  &:not(:first-child) {
    margin-left: 20px;

    ${media.tablet(css`
      margin: 25px 0 0;
    `)}
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Vectors = styled.div`
  position: absolute;
  bottom: 0;
  right: 164px;

  ${media.tablet(css`
    width: 347px;
    margin: 0 auto;
    right: 0;
    left: 0;
  `)}

  ${media.mobile(css`
    width: auto;
    max-width: 320px;
  `)};

  @media (max-width: 450px) {
    margin: 0 0 0 -25px;
  }
`;

const PhoneImage = styled(Picture)`
  ${media.mobile(css`
    max-width: 320px;
  `)}
`;

const VectorWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 132px;
  height: 129px;
  bottom: 16px;
  right: -77px;

  ${media.mobile(css`
    width: 82px;
    height: 82px;
    bottom: 35px;
    right: -15px;
  `)};

  @media (max-width: 374.9px) {
    right: 5px;
  }

  svg {
    position: relative;
    width: 58px;
    height: 58px;
    margin-top: 20px;

    ${media.mobile(css`
      width: 36px;
      height: 36px;
      margin-top: 13px;
    `)}
  }
`;

const Vector = styled(Picture)<{
  isSmall?: boolean;
  isBig?: boolean;
}>`
  position: absolute;

  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 91px;
      height: 89px;
      bottom: 16px;
      left: -20px;

      ${media.mobile(css`
        width: 51px;
        height: 49px;
        bottom: 80px;
        left: 41px;
      `)}
    `}

  ${({ isBig }) =>
    isBig &&
    css`
      width: 132px;
      height: 129px;

      ${media.mobile(css`
        width: 82px;
        height: 82px;
      `)}
    `}
`;

const Phone = styled(Link)`
  position: absolute;
  padding: 8px;
  top: 0;
  right: -124px;
  min-width: 211px;
  height: 57px;

  ${media.mobile(css`
    padding: 10px;
    min-width: 127px;
    height: 52px;
    right: -34px;
  `)};

  @media (max-width: 374.9px) {
    right: 5px;
    top: -10px;
  }
`;

const PhoneVector = styled(Picture)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

const PhoneText = styled.p`
  position: relative;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 12px;
    line-height: 14px;
  `)}
`;

const ImageWrapper = styled.div`
  position: relative;
  flex: 1 1 50%;
  max-width: 50%;
  padding-top: 15.278%;
  margin-left: 50px;

  ${media.tablet(css`
    display: flex;
    justify-content: center;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 165px;
    padding-top: initial;
    width: 100%;
    margin-left: 0;
  `)}

  ${media.mobile(css`
    margin-top: 70px;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  ${media.tablet(css`
    position: initial;
    max-width: 515px;
    max-height: 165px;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    ${media.tablet(css`
      position: initial;
      max-width: 515px;
      max-height: 165px;
    `)}
  }

  img {
    object-fit: cover;
  }
`;

const ImageLink = styled(Link)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  ${media.tablet(css`
    left: 50%;
    transform: translateX(-50%);
    max-width: 515px;
    max-height: 165px;
  `)}
`;
