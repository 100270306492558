import React from 'react';
import styled, { css } from 'styled-components';

import { MenuPanel } from '@/components/CarModelLandingWidgets';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { media } from '@/utils/mixin';

function Menu() {
  const { menuItems } = useCarModelLandingData();

  if (!menuItems || menuItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <MenuPanel
        menu={menuItems.map(({ text, anchor }) => ({
          text: text ?? '',
          anchor: anchor ?? '',
        }))}
      />
    </Component>
  );
}

export default Menu;

const Component = styled.section`
  z-index: 100;
  position: sticky;
  top: 0;
  padding-bottom: 30px;

  ${media.tablet(css`
    top: 80px;
  `)}

  ${media.mobile(css`
    top: 60px;
  `)}
`;
