import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { SliderWrapper } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

import BenefitCard, {
  Component as BenefitCardComponent,
} from './components/BenefitCard';
import { BenefitsCardsProps } from './types';

function BenefitsCards({ cards, id }: BenefitsCardsProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  const convertedBenefits = cards.map((card, index) => {
    const item = <BenefitCard {...card} />;

    return !isTablet ? (
      <React.Fragment key={index}>{item}</React.Fragment>
    ) : (
      <SwiperSlide key={index}>{item}</SwiperSlide>
    );
  });

  return (
    <Component>
      {!isTablet ? (
        convertedBenefits
      ) : (
        <SliderWrapper
          id={id}
          options={{ spaceBetween: 20, withoutNavs: true }}
        >
          {convertedBenefits}
        </SliderWrapper>
      )}
    </Component>
  );
}

export default BenefitsCards;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  ${media.tablet(css`
    display: block;

    .swiper-container {
      overflow: initial;
    }

    .swiper-slide {
      height: auto;
    }

    ${BenefitCardComponent} {
      height: 100%;
    }
  `)}
`;
