import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import { DatePickerStyles } from '@/components/styles';
import TextInput from '@/components/TextInput';
import DateIcon from '@/assets/svg/date-icon.svg';

import { DatapickerProps } from './types';

function Datapicker({ errorMessage, value, label, ...props }: DatapickerProps) {
  return (
    <Component>
      <Picker>
        <DatePicker
          {...props}
          customInput={
            <TextInput
              label={label}
              errorMessage={errorMessage}
              badge={<DateIcon />}
            />
          }
        />
      </Picker>
    </Component>
  );
}

export default Datapicker;

const Component = styled.div``;

const Picker = styled.div`
  position: relative;
  ${DatePickerStyles};

  .react-datepicker {
    border: 1px solid ${(props) => props.theme.gray1800};
  }
`;
