import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { ILocationCity } from '@/components/LocationsMap/LocationsMap.typings';
import { media } from '@/utils/mixin';
import { ScrollStyles } from '@/components/styles';

import ItemComponent from './components/ItemComponent';
import StyledCollapseShopsList from './components/StyledCollapseDealersList';

interface IProps {
  isMobile: boolean;
  isForm?: boolean;
  data: Array<ILocationCity>;
  selectedId: Nullable<number>;
  selectedElement?: Nullable<HTMLDivElement>;

  expandedIds: Array<number>;

  onSelect(id: number): void;

  onExpandedChange(id: number, state: boolean): void;

  setDataToParent?: (image: string, name: string, isHovered: boolean) => void;
}

function ItemsList({
  onSelect,
  isMobile,
  selectedId,
  data,
  expandedIds,
  onExpandedChange,
  selectedElement,
  setDataToParent,
  isForm,
}: IProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedElement) {
      ref.current?.scrollTo(0, selectedElement.offsetTop);
    }
  }, [selectedElement]);

  if (data.length === 0) {
    return null;
  }

  const handlerHoverCamera = (
    image: string,
    name: string,
    isHovered: boolean
  ): void => {
    if (setDataToParent) {
      setDataToParent(image, name, isHovered);
    }
  };

  return (
    <Container isMobile={isMobile}>
      <Content isMobile={isMobile} ref={ref}>
        {data.map((city, index) => {
          return (
            <StyledCollapseShopsList
              key={index}
              label={city.name}
              isExpanded={expandedIds.indexOf(city.id) !== -1}
              setIsExpanded={(value) => onExpandedChange(city.id, value)}
            >
              {city.items.map((dealer) => (
                <ItemComponent
                  key={dealer.id}
                  isMobile={isMobile}
                  isForm={isForm}
                  className={`className_${dealer.id} collapsed_list`}
                  setDataToParent={handlerHoverCamera}
                  handleClick={() => onSelect(dealer.id)}
                  isActive={
                    !!((!isMobile || isForm) && dealer.id === selectedId)
                  }
                  {...dealer}
                />
              ))}
            </StyledCollapseShopsList>
          );
        })}
      </Content>
    </Container>
  );
}

const Container = styled.div<{ isMobile: boolean }>`
  top: 20px;
  left: 113px;
  padding: 10px 0 15px;
  height: fit-content;
  border-radius: 17px;
  background: ${(props) => props.theme.white};
  position: ${(props) => (props.isMobile ? 'static' : 'absolute')};
  margin: ${(props) => (props.isMobile ? '0 auto 0' : '0 auto')};
  width: ${(props) => (props.isMobile ? '' : '410px')};
  box-shadow: ${(props) =>
    props.isMobile ? '' : '0 5px 30px 0 rgba(23, 43, 77, 0.17)'};

  @media (max-width: 1200px) {
    left: 20px;
  }

  @media (max-width: 1200px) {
    left: 20px;
  }

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const Content = styled.div<{ isMobile: boolean }>`
  overflow-y: ${(props) => (props.isMobile ? 'auto' : 'scroll')};
  max-height: ${(props) => (props.isMobile ? 'fit-content' : '697px')};

  ${ScrollStyles}
`;

export default ItemsList;
