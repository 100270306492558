import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import { handleScrollToDomElement } from '@/utils/scroll';
import CompaniesTicker from '@/components/CompaniesTicker';

function Brands() {
  const { brandsButtonTitle, brands } = useTopLevelFunnelLandingData();

  if (!brands || brands.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        {brandsButtonTitle && (
          <ButtonWrapper>
            <StyledButton
              variant="outlined"
              onClick={() => handleScrollToDomElement('consultation')}
            >
              {brandsButtonTitle}
            </StyledButton>
          </ButtonWrapper>
        )}

        <CompaniesTicker images={brands} />
      </ContentContainer>
    </Component>
  );
}

export default Brands;

const Component = styled.section`
  padding: 15px 0;

  ${media.tablet(css`
    padding: 10px 0;
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  max-width: 300px;
`;
