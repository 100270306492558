import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import {
  selectHomeBigBanners,
  selectHomeSmallBanners,
} from '@/store/reducers/banners';
import { media } from '@/utils/mixin';
import BannersCarousel from '@/components/BannersCarousel';

function Banners() {
  const isTablet = useMedia('(max-width: 1259px)');
  const isMobile = useMedia('(max-width: 767px)');
  const homeSliderBig = useTypedSelector(selectHomeBigBanners);
  const homeSliderSmall = useTypedSelector(selectHomeSmallBanners);

  if (!homeSliderBig || !homeSliderSmall || homeSliderBig.data.length === 0) {
    return null;
  }

  const convertedBigBanners = homeSliderBig.data.map(
    ({ link, openNewTab, image, fields }) => {
      const imageMobile = fields.find(
        (field) => field.name === 'imageMobile'
      )?.value;

      return {
        link,
        openNewTab,
        image: {
          desktop: image.default,
          mobile: imageMobile,
        },
      };
    }
  );

  const convertedSmallBanners = homeSliderSmall.data
    .map(({ link, openNewTab, image, fields }) => {
      const imageTablet = fields.find((field) => field.name === 'imageTablet')
        ?.value ?? { url: '' };
      const imageMobile = fields.find(
        (field) => field.name === 'imageMobile'
      )?.value;

      return {
        link,
        openNewTab,
        image: {
          desktop: !isTablet ? image.default : imageTablet,
          mobile: imageMobile,
        },
      };
    })
    .filter((banner) =>
      isTablet
        ? banner.image.desktop.url
        : isMobile
        ? banner.image.mobile?.url
        : true
    );

  const resultBigBanners = !isTablet
    ? convertedBigBanners
    : [...convertedBigBanners, ...convertedSmallBanners];

  return (
    <Component>
      <ContentContainer>
        <Content>
          <Big>
            <BannersCarousel banners={resultBigBanners} index={1} />
          </Big>

          {homeSliderSmall.data.length > 0 && (
            <Small>
              <BannersCarousel
                banners={convertedSmallBanners}
                small
                index={2}
              />
            </Small>
          )}
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default Banners;

const Component = styled.section`
  background: ${(props) => props.theme.gray};
  margin-bottom: 20px;

  ${media.tablet(css`
    background: ${(props) => props.theme.white};
  `)}
`;

const ContentContainer = styled.div`
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;

  ${media.tablet(css`
    padding: 30px 20px;
  `)}

  ${media.mobile(css`
    padding: 20px;
  `)}
`;

const Content = styled.div`
  display: flex;
`;

const Big = styled.div`
  width: calc(100% - 17.389% - 20px);

  ${media.tablet(css`
    width: 100%;
  `)}
`;

const Small = styled.div`
  width: calc(17.392%);
  margin-left: 20px;

  ${media.tablet(css`
    display: none;
  `)}

  & > div {
    width: 100%;
    height: 100%;
    padding-top: 0;
  }
`;
