import React from 'react';

import { Nullable } from '@tager/web-core';

import { QuestionModel } from './FaqAccordion.types';
import CollapsedQuestion from './components/CollapsedQuestion';
import CollapsedQuestionItem from './components/CollapsedQuestionItem';

interface Props {
  data: Nullable<Array<QuestionModel>>;
  linkHrefs?: Record<string, () => void>;
}

function FaqAccordion({ data, linkHrefs }: Props) {
  if (!data) {
    return null;
  }

  return (
    <>
      {data.map((item, index) => {
        return (
          <CollapsedQuestion key={index} question={item.question}>
            <CollapsedQuestionItem
              linkHrefs={linkHrefs}
              answer={item.answer ? item.answer : ''}
            />
          </CollapsedQuestion>
        );
      })}
    </>
  );
}

export default FaqAccordion;
