import React from 'react';
import styled, { css } from 'styled-components';

import { IUserPhotoSection } from '@/modules/ValueCar/ValueCar.types';
import UploadSpecificPhoto from '@/components/UploadSpecificPhoto';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  model: IUserPhotoSection;
  invalidIds: number[];
  onChange: (ind: number, value: string | null) => void;
};

const PhotosSection = ({ model, onChange, invalidIds }: Props) => {
  return (
    <Component withError={false}>
      <Title>{model.name}</Title>
      <Text>{model.description}</Text>
      <Row>
        {model.items.map((item, index) => (
          <Cell key={index}>
            <UploadSpecificPhoto
              invalid={invalidIds.indexOf(index) !== -1}
              name={item.name || ''}
              label="Загрузить фото"
              image={item.image}
              icon={item.icon}
              onChange={(value) => onChange(index, value)}
            />
          </Cell>
        ))}
      </Row>
    </Component>
  );
};

const Component = styled.div<{ withError: boolean }>`
  ${({ withError }) =>
    withError &&
    css`
      border: 1px solid ${colors.red700};
      background: ${(props) => props.theme.red};
    `};
`;

const Title = styled.span`
  font-size: 24px;
  display: block;
  font-weight: 700;
  color: ${(props) => props.theme.black};
`;

const Text = styled.p`
  display: block;
  font-size: 14px;
  line-height: 24px;
  padding-top: 10px;
  color: ${(props) => props.theme.blue100};

  ${media.mobile(
    css`
      font-size: 14px;
    `
  )}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
`;

const Cell = styled.div`
  padding: 0 12px;
  margin-top: 24px;
  flex-basis: 33.33333%;

  ${media.tablet(css`
    flex-basis: 50%;
  `)}

  ${media.mobile(css`
    flex-basis: 100%;
  `)}
`;

export default PhotosSection;
