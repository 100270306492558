import { useModal } from '@tager/web-components';

import Banner from '@/components/Redesign/Banner';
import { colors } from '@/constants/theme';
import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { submitCarCheck } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function ApplicationBanner() {
  const openModal = useModal();
  const {
    templateFields: {
      formTitle,
      formGoogleEvent,
      applicationTitle,
      applicationImage,
    },
  } = useVerifiedAtlantmLandingData();

  if (!applicationImage || !applicationTitle) {
    return null;
  }

  const sendCarCheckForm = async (
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      await submitCarCheck({
        ...values,
        model: undefined,
      }).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenModal = () => {
    openModal(
      ApplicationFormModal,
      {
        title: formTitle ?? '',
        fieldsConfig: {
          withMessage: true,
        },
        onSubmit: sendCarCheckForm,
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Banner
      title={applicationTitle}
      image={applicationImage}
      color={colors.blueGradient}
      size={'small'}
      orientation={'horizontal'}
      onClick={handleOpenModal}
    />
  );
}

export default ApplicationBanner;
