import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useAboutData } from '@/modules/About/About.hooks';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

function AboutBlock() {
  const {
    aboutBackground,
    aboutLogo,
    aboutText,
    aboutCountriesTitle,
    aboutCountriesNames,
  } = useAboutData();

  const countries = aboutCountriesNames && aboutCountriesNames.split('\n');

  return (
    <Component>
      <Background
        loading="lazy"
        {...convertThumbnailToPictureProps(aboutBackground)}
      />
      <ContentContainerSmall>
        <Content>
          <Top>
            <Logo
              loading="lazy"
              {...convertThumbnailToPictureProps(aboutLogo)}
            />
            <Text>{aboutText}</Text>
          </Top>

          <Bottom>
            <Title>{aboutCountriesTitle}</Title>
            <CountriesList>
              {countries &&
                countries.map((country: string, index) => (
                  <React.Fragment key={index}>
                    <Country>{country}</Country>
                    {countries.length !== index + 1 && <Dot>・</Dot>}
                  </React.Fragment>
                ))}
            </CountriesList>
          </Bottom>
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default AboutBlock;

const Component = styled.section`
  position: relative;
  padding: 130px 0 170px;

  ${media.tablet(css`
    padding: 40px 0 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Background = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    object-position: 100% 20%;

    ${media.mobile(css`
      object-position: inherit;
    `)}
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${media.tablet(css`
    width: auto;
    max-width: 370px;
  `)}
`;

const Logo = styled(Picture)`
  align-items: flex-start;
  justify-content: flex-start;

  ${media.tablet(css`
    max-width: 280px;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 30px;
  color: ${colors.blue600};

  ${media.tablet(css`
    font-size: 14px;
    margin-top: 40px;
  `)}
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue600};

  ${media.tablet(css`
    font-size: 14px;
  `)}
`;

const CountriesList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const Country = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${colors.blue700};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Dot = styled(Country)`
  ${media.mobile(css`
    display: none;
  `)}
`;
