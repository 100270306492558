import React from 'react';
import styled from 'styled-components';

import Charger from '../Charger';

import { ChargerListProps } from './types';

function ChargerList({ chargers }: ChargerListProps) {
  if (!chargers || chargers.length === 0) {
    return null;
  }

  return (
    <Component>
      {chargers.map((charger, index) => (
        <Charger key={index} {...charger} />
      ))}
    </Component>
  );
}

export default ChargerList;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
