import React, { useRef, useState } from 'react';
import { Swiper } from 'swiper/react';
import styled, { css } from 'styled-components';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { Nullable } from '@tager/web-core';

import ArrowLeftIcon from '@/assets/svg/swiper/arrow-left.svg';
import ArrowRightIcon from '@/assets/svg/swiper/arrow-right.svg';
import ArrowBigLeftIcon from '@/assets/svg/swiper/arrow-big-left.svg';
import ArrowBigRightIcon from '@/assets/svg/swiper/arrow-big-right.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { SliderWrapperProps } from './types';

SwiperCore.use([Navigation, Pagination]);

function SliderWrapper({ children, options, className }: SliderWrapperProps) {
  const [_, setSwiper] = useState<Nullable<SwiperCore>>(null);
  const prefElRef = useRef<Nullable<HTMLButtonElement>>(null);
  const nextElRef = useRef<Nullable<HTMLButtonElement>>(null);
  const paginationElRef = useRef<Nullable<HTMLDivElement>>(null);

  const navigation = options?.withNavigation && (
    <NavigationWrapper isInnerNavigation={options.isInnerNavigation}>
      <Button ref={prefElRef} isInnerNavigation={options.isInnerNavigation}>
        {!options.isInnerNavigation ? <ArrowLeftIcon /> : <ArrowBigLeftIcon />}
      </Button>

      <Button ref={nextElRef} isInnerNavigation={options.isInnerNavigation}>
        {!options.isInnerNavigation ? (
          <ArrowRightIcon />
        ) : (
          <ArrowBigRightIcon />
        )}
      </Button>
    </NavigationWrapper>
  );

  return (
    <Component className={className}>
      <SwiperWrapper>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={options?.slidesPerView ?? 'auto'}
          spaceBetween={options?.spaceBetween}
          speed={600}
          navigation={
            options?.withNavigation
              ? {
                  prevEl: prefElRef.current,
                  nextEl: nextElRef.current,
                }
              : false
          }
          pagination={
            options?.withPagination
              ? {
                  el: paginationElRef.current,
                  type: 'bullets',
                  clickable: true,
                }
              : false
          }
        >
          {children}

          {options?.isInnerNavigation && navigation}
        </Swiper>

        {!options?.isInnerNavigation && navigation}
      </SwiperWrapper>

      {options?.withPagination && (
        <PaginationWrapper
          ref={paginationElRef}
          isInnerPagination={options.isInnerPagination}
        />
      )}
    </Component>
  );
}

export default SliderWrapper;

const Component = styled.div``;

const SwiperWrapper = styled.div`
  position: relative;
`;

const NavigationWrapper = styled.div<{
  isInnerNavigation?: boolean;
}>`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 -20px;
  width: calc(100% + 40px);
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    display: none;
  `)}

  ${({ isInnerNavigation }) =>
    isInnerNavigation &&
    css`
      margin: 0;
      width: 100%;
      height: 100%;
    `}
`;

const Button = styled.button<{
  isInnerNavigation?: boolean;
}>`
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${colors.white};
  border: 1px solid ${colors.gray1300};
  border-radius: 50%;
  box-shadow: ${colors.grayShadow};

  &:hover {
    background: ${colors.gray800};
  }

  &:not(:first-of-type) {
    margin-left: auto;
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${colors.gray1500};
  }

  &.swiper-button-lock {
    display: none;
  }

  ${({ isInnerNavigation }) =>
    isInnerNavigation
      ? css`
          width: 80px;
          height: 100%;
          background: none;
          border: none;
          border-radius: 0;
          box-shadow: none;

          svg {
            width: 40px;
            height: 40px;
            color: ${colors.white};
          }

          &:hover {
            background: ${colors.gray1500_alpha40};
          }
        `
      : css`
          &:disabled {
            display: none;
          }
        `}
`;

const PaginationWrapper = styled.div<{
  isInnerPagination?: boolean;
}>`
  display: flex;
  justify-content: center;
  padding: 0 20px;
  margin-top: 20px;
  height: 5px;

  .swiper-pagination-bullet {
    cursor: pointer;
    z-index: 1;
    opacity: 1;
    width: 5px;
    height: 5px;
    border-radius: 30px;
    margin: 0 !important;
    background: ${colors.black};

    &-active {
      width: 25px;
    }

    &:not(:first-of-type) {
      margin-left: 10px !important;
    }
  }

  &.swiper-pagination-lock {
    display: none;
  }

  ${({ isInnerPagination }) =>
    isInnerPagination &&
    css`
      position: absolute;
      bottom: 10px !important;
    `}
`;
