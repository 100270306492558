import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Banner from '@/components/Redesign/Banner';
import { ContentContainer } from '@/modules/RefitSingleLanding/common.styles';
import { useRefitSingleLanding } from '@/modules/RefitSingleLanding/hooks';

function Hero() {
  const {
    templateFields: { heroTitle, heroImage },
  } = useRefitSingleLanding();

  if (!heroTitle || !heroImage) {
    return null;
  }

  return (
    <Component>
      <ContentContainer isBig>
        <Banner
          title={heroTitle}
          image={heroImage}
          color={colors.purpleGradient200}
          orientation="horizontal"
          size={'medium'}
          withoutBorderRadius
        />
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 0;

    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
