import React from 'react';
import styled, { css } from 'styled-components';

import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { Title } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';
import ImagesSlider from '@/components/ImagesSlider';

function Gallery() {
  const { galleryTitle, galleryImages } = useTopLevelFunnelLandingData();

  if (!galleryImages || galleryImages.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title title={galleryTitle ?? ''} />
      </ContentContainer>

      <ImagesSliderWrapper>
        <ImagesSlider slides={galleryImages.map((image) => ({ image }))} />
      </ImagesSliderWrapper>
    </Component>
  );
}

export default Gallery;

const Component = styled.section`
  padding: 15px 0;

  ${media.tablet(css`
    padding: 10px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const ImagesSliderWrapper = styled.div`
  margin-top: 20px;
`;
