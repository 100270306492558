import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { CssSnippet } from '@tager/web-components';

import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import { isGradientColor, isLightColor } from '@/utils/color';
import Picture from '@/components/Picture';

import { BannerProps } from './types';

function Banner({
  title,
  image,
  color,
  hoverColor,
  subtitle,
  size,
  orientation,
  link,
  withoutBorderRadius,
  onClick,
  isPointer = true,
}: BannerProps) {
  const content = (
    <Content>
      <Title size={size} orientation={orientation} color={color}>
        {title}
      </Title>
      {subtitle && (
        <Subtitle size={size} orientation={orientation} color={color}>
          {subtitle}
        </Subtitle>
      )}
      <Image
        desktop={convertThumbnailToPictureImage(image)}
        alt={title}
        size={size}
        orientation={orientation}
      />
    </Content>
  );

  return link ? (
    <ComponentLink
      to={link}
      size={size}
      orientation={orientation}
      color={color}
      hoverColor={hoverColor}
      withoutBorderRadius={withoutBorderRadius}
      isPointer={isPointer}
    >
      {content}
    </ComponentLink>
  ) : (
    <ComponentButton
      size={size}
      orientation={orientation}
      color={color}
      hoverColor={hoverColor}
      withoutBorderRadius={withoutBorderRadius}
      isPointer={isPointer}
      onClick={onClick}
    >
      {content}
    </ComponentButton>
  );
}

export default Banner;

const getComponentStyle = (): Record<
  BannerProps['size'],
  Record<BannerProps['orientation'], CssSnippet>
> => {
  return {
    'extra-small': {
      horizontal: css`
        align-items: center;
        padding: 15px;
        min-height: 100px;
      `,
      vertical: css``,
    },

    small: {
      horizontal: css`
        align-items: center;
        padding: 20px;
        min-height: 100px;

        ${media.tablet(css`
          padding: 15px;
        `)}
      `,
      vertical: css``,
    },

    medium: {
      horizontal: css`
        align-items: center;
        padding: 20px;
        min-height: 120px;

        ${media.tablet(css`
          padding: 15px;
          min-height: 130px;
        `)}
      `,
      vertical: css``,
    },

    big: {
      horizontal: css`
        align-items: center;
        padding: 30px;
        min-height: 140px;

        ${media.tablet(css`
          padding: 20px;
          min-height: 100px;
        `)}
      `,
      vertical: css`
        align-items: flex-start;
        padding: 30px;
        min-height: 340px;

        ${media.tablet(css`
          align-items: center;
          padding: 20px;
          min-height: 100px;
        `)}
      `,
    },
  };
};

const componentStyle = ({
  size,
  orientation,
  color,
  hoverColor,
  withoutBorderRadius,
  isPointer,
}: {
  size: BannerProps['size'];
  orientation: BannerProps['orientation'];
  color: BannerProps['color'];
  hoverColor?: BannerProps['hoverColor'];
  withoutBorderRadius?: BannerProps['withoutBorderRadius'];
  isPointer?: BannerProps['isPointer'];
}) => css`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  background: ${color};
  cursor: ${isPointer ? 'pointer' : 'default'};

  ${media.tablet(css`
    border-radius: ${withoutBorderRadius ? 0 : 20}px;
  `)}

  &:hover {
    background: ${hoverColor};
  }

  ${getComponentStyle()[size][orientation]}
`;

const ComponentLink = styled(Link)<{
  size: BannerProps['size'];
  orientation: BannerProps['orientation'];
  color: BannerProps['color'];
  hoverColor?: BannerProps['hoverColor'];
  withoutBorderRadius?: BannerProps['withoutBorderRadius'];
  isPointer?: BannerProps['isPointer'];
}>`
  ${componentStyle}
`;

const ComponentButton = styled.button<{
  size: BannerProps['size'];
  orientation: BannerProps['orientation'];
  color: BannerProps['color'];
  hoverColor?: BannerProps['hoverColor'];
  withoutBorderRadius?: BannerProps['withoutBorderRadius'];
  isPointer?: BannerProps['isPointer'];
}>`
  ${componentStyle}
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const getTitleStyle = (): Record<
  BannerProps['size'],
  Record<BannerProps['orientation'], CssSnippet>
> => {
  return {
    'extra-small': {
      horizontal: css`
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        max-width: 65%;
      `,
      vertical: css``,
    },

    small: {
      horizontal: css`
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        max-width: 55%;

        ${media.tablet(css`
          font-size: 14px;
          line-height: 17px;
        `)}
      `,
      vertical: css``,
    },

    medium: {
      horizontal: css`
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        max-width: 65%;

        ${media.tablet(css`
          font-size: 18px;
          line-height: 20px;
          max-width: none;
        `)}
      `,
      vertical: css``,
    },

    big: {
      horizontal: css`
        font-weight: 600;
        font-size: 24px;
        line-height: 26;
        max-width: 65%;

        ${media.tablet(css`
          font-size: 16px;
          line-height: 20px;
        `)}
      `,
      vertical: css`
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        ${media.tablet(css`
          font-size: 16px;
          line-height: 20px;
          max-width: 55%;
        `)}
      `,
    },
  };
};

const Title = styled.p<{
  size: BannerProps['size'];
  orientation: BannerProps['orientation'];
  color: BannerProps['color'];
}>`
  z-index: 1;
  text-align: left;

  ${({ theme, size, orientation, color }) => css`
    color: ${isLightColor(color) || isGradientColor(color)
      ? theme.black
      : theme.white};

    ${getTitleStyle()[size][orientation]}
  `}
`;

const getSubtitleStyle = (): Record<
  BannerProps['size'],
  Record<BannerProps['orientation'], CssSnippet>
> => {
  return {
    'extra-small': {
      horizontal: css``,
      vertical: css``,
    },

    small: {
      horizontal: css`
        font-size: 14px;
        line-height: 17px;
        max-width: 55%;

        ${media.tablet(css`
          font-size: 12px;
          line-height: 15px;
        `)}
      `,
      vertical: css``,
    },

    medium: {
      horizontal: css``,
      vertical: css``,
    },

    big: {
      horizontal: css`
        font-size: 14px;
        line-height: 17px;
        margin-top: 5px;
        max-width: 65%;

        ${media.tablet(css`
          display: none;
        `)}
      `,
      vertical: css`
        font-size: 14px;
        line-height: 17px;
        margin-top: 5px;

        ${media.tablet(css`
          max-width: 55%;
        `)}
      `,
    },
  };
};

const Subtitle = styled.p<{
  size: BannerProps['size'];
  orientation: BannerProps['orientation'];
  color: BannerProps['color'];
}>`
  z-index: 1;
  text-align: left;

  ${({ theme, size, orientation, color }) => css`
    color: ${isLightColor(color) || isGradientColor(color)
      ? theme.black
      : theme.white};

    ${getSubtitleStyle()[size][orientation]}
  `}
`;

const getImageStyle = (): Record<
  BannerProps['size'],
  Record<BannerProps['orientation'], CssSnippet>
> => {
  return {
    'extra-small': {
      horizontal: css`
        width: 160px;
        top: 0;
        right: 0;
      `,
      vertical: css``,
    },

    small: {
      horizontal: css`
        width: 240px;
        top: 0;
        right: 0;

        ${media.tablet(css`
          width: 160px;
        `)}
      `,
      vertical: css``,
    },

    medium: {
      horizontal: css`
        right: 0;
        bottom: 0;

        ${media.tablet(css`
          width: 150px;
        `)}
      `,
      vertical: css``,
    },

    big: {
      horizontal: css`
        width: 240px;
        top: 0;
        right: 0;

        ${media.tablet(css`
          width: 160px;
        `)}
      `,
      vertical: css`
        width: 285px;
        height: 180px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        ${media.tablet(css`
          width: 215px;
          height: 100%;
          top: 0;
          right: 0;
          left: initial;
          transform: none;
        `)}
      `,
    },
  };
};

const Image = styled(Picture)<{
  size: BannerProps['size'];
  orientation: BannerProps['orientation'];
}>`
  position: absolute;
  height: 100%;

  ${({ size, orientation }) => css`
    ${getImageStyle()[size][orientation]}
  `}
`;
