import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import HeroWithoutContent from '@/components/HeroWithoutContent';
import BottomLinkComponent from '@/modules/OnlineShopping/components/BottomLinkComponent';
import Layout from '@/layout';

import Recommendation from './components/Recommendation';
import Benefits from './components/Benefits';
import { useOnlineShoppingData } from './OnlineShopping.hooks';
import Questions from './components/Questions';
import Connection from './components/Connection';
import HowItWorks from './components/HowItWorks';

function OnlineShopping() {
  const { heroImage, heroTabletImage, heroMobileImage } =
    useOnlineShoppingData();

  const mobileMedia = useMedia('(max-width: 767px)');

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          onlineShopping
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
        />
        <Benefits />
        <HowItWorks />
        <Questions />
        <Recommendation />
        <Connection />
        {mobileMedia && <BottomLinkComponent />}
      </Component>
    </Layout>
  );
}

export default OnlineShopping;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.gray};
`;
