import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { useRefitSingleLanding } from '@/modules/RefitSingleLanding/hooks';
import Steps from '@/components/Redesign/Steps';
import {
  ContentContainer,
  Title,
} from '@/modules/RefitSingleLanding/common.styles';

function StepsList() {
  const {
    templateFields: { steps, stepTitle },
  } = useRefitSingleLanding();

  if (!stepTitle || !steps) {
    return null;
  }

  const stepsList = steps.map(({ step }) => ({
    text: step || '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Title>{stepTitle}</Title>
        <Steps steps={stepsList} columns={stepsList.length} />
      </ContentContainer>
    </Component>
  );
}

export default StepsList;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
