import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { ContentContainer } from '@/modules/Atlantm30/Atlantm30.styles';
import { numbers } from '@/modules/Atlantm30/utils';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import vector1 from '@/assets/images/atlantm30/vector1.png';
import vector2 from '@/assets/images/atlantm30/vector2.png';

import { DetailStepProps } from './types';

function DetailStep({
  content,
  imageMain,
  imageBackground,
  index,
  isYellowBg,
}: DetailStepProps) {
  return (
    <Component id={`detailStep-${index}`} isYellowBg={isYellowBg}>
      <ContentContainer>
        <ContentWrapper>
          <Left>
            <Content dangerouslySetInnerHTML={{ __html: content ?? '' }} />
            <StepImageWrapper>{numbers[index]}</StepImageWrapper>
          </Left>
          <Right>
            <MainImage
              loading="lazy"
              {...convertThumbnailToPictureProps(imageMain)}
            />
            <BackgroundImage
              loading="lazy"
              {...convertThumbnailToPictureProps(imageBackground)}
            />
          </Right>
        </ContentWrapper>
      </ContentContainer>
    </Component>
  );
}

export default DetailStep;

const Component = styled.section<{
  isYellowBg?: DetailStepProps['isYellowBg'];
}>`
  position: relative;
  margin: 60px 0;

  ${({ isYellowBg }) =>
    isYellowBg &&
    css`
      background: ${colors.yellow};

      &:before {
        content: '';
        display: inline-block;
        margin-top: -46px;
        width: 100%;
        height: 46px;
        background-image: url('${vector1}');
        background-repeat: no-repeat;
        background-position: right top;
      }

      &:after {
        content: '';
        display: inline-block;
        margin-bottom: -46px;
        width: 100%;
        height: 46px;
        background-image: url('${vector2}');
        background-repeat: no-repeat;
        background-position: right top;
      }
    `}
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  position: relative;
  padding: 29px 0 0 50px;
  max-width: 390px;

  ${media.tabletSmall(css`
    padding: 70px 0 0 50px;
  `)}
`;

const Content = styled.div`
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 49px;
    color: ${colors.main};

    ${media.tabletSmall(css`
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 70px;
    `)}
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.blue600};

    &:not(:first-child) {
      margin-top: 1.5vw;
    }

    strong {
      color: ${colors.black100};
    }
  }
`;

const StepImageWrapper = styled.div`
  svg {
    position: absolute;
    top: 0;
    left: -15px;
    height: 162px;
  }
`;

const Right = styled.div`
  position: relative;
  margin-left: 80px;

  ${media.mobile(css`
    margin-left: 0;
  `)}
`;

const MainImage = styled(Picture)`
  z-index: 1;
  position: absolute !important;
  right: 0;
  bottom: 0;
`;

const BackgroundImage = styled(Picture)`
  img {
    display: inline-block;
    max-width: 100%;
    object-fit: contain;
  }
`;
