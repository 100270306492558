import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/ServiceValuationLanding/common.styles';
import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import Benefits from '@/components/Benefits';
import { media } from '@/utils/mixin';

function Values() {
  const {
    templateFields: { valuesTitle, values },
  } = useServiceValuationLandingData();

  if (!values || values.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{valuesTitle}</Title>

        <BenefitsWrapper>
          <Benefits
            id={2}
            benefits={values.map(({ image, title, text }) => ({
              image,
              title: title ?? '',
              text: text ?? '',
            }))}
            isHorizontal
          />
        </BenefitsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Values;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const BenefitsWrapper = styled.div`
  margin-top: 20px;
`;
