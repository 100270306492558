import React, { useCallback, useState } from 'react';

import { Nullable } from '@tager/web-core';

import { usePackagesInfoData } from '@/modules/HelpOnTheRoad/hooks';
import { useTabsData } from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/Mobile/components/Tabs/hooks';
import { PackageTab } from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/PackagesTabs/types';

import { HelpOnTheRoadContext } from './HelpOnTheRoadContext';
import { SelectedPackage } from './types';

export const HelpOnTheRoadProvider: React.FC = ({ children }) => {
  const packagesData = usePackagesInfoData();
  const tabs = useTabsData();

  const [selectedPackage, setNewSelectedPackage] =
    useState<Nullable<SelectedPackage>>(null);
  const [selectedPackageTab, setNewSelectedPackageTab] = useState<PackageTab>(
    tabs[0]
  );

  const setSelectedPackage = useCallback((value: Nullable<SelectedPackage>) => {
    setNewSelectedPackage(value);
  }, []);

  const setSelectedPackageTab = useCallback((tab: PackageTab) => {
    setNewSelectedPackageTab(tab);
  }, []);

  return (
    <HelpOnTheRoadContext.Provider
      value={{
        packagesData,
        selectedPackage,
        setSelectedPackage,
        selectedPackageTab,
        setSelectedPackageTab,
      }}
    >
      {children}
    </HelpOnTheRoadContext.Provider>
  );
};
