import React from 'react';
import styled from 'styled-components';

import PackageList from './components/PackageList';
import TableParams from './components/TableParams';
import PriceList from './components/PriceList';

function Desktop() {
  return (
    <Component>
      <PackageList />
      <TableParams />
      <PriceList />
    </Component>
  );
}

export default Desktop;

const Component = styled.div``;
