import React from 'react';
import styled, { css } from 'styled-components';

import { useVedaemLandingData } from '@/modules/VedaemLanding/hooks';
import HeroBanner from '@/components/HeroBanner';
import { media } from '@/utils/mixin';

function Hero() {
  const {
    heroImage,
    heroMobileImage,
    heroTitle,
    heroButtonTitle,
    heroButtonLink,
  } = useVedaemLandingData();

  if (!heroImage) {
    return null;
  }

  return (
    <Component>
      <HeroBanner
        image={heroImage}
        imageMobile={heroMobileImage}
        title={heroTitle ?? ''}
        buttonText={heroButtonTitle ?? ''}
        buttonLink={heroButtonLink ?? ''}
      />
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
