import React from 'react';
import styled from 'styled-components';

import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import ConsultationFormTertiary from '@/components/Forms/ConsultationFormTertiary';
import { ConsultationFormTertiaryFields } from '@/components/Forms/ConsultationFormTertiary/types';
import { submitElectrocarsLandingConsultation } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function Form() {
  const {
    formImage,
    formMobileImage,
    formTitle,
    formSubtitle,
    formButtonTitle,
    formGoogleEvent,
  } = useElectrocarsLandingData();

  const sendConsultationForm = async (
    values: ConsultationFormTertiaryFields
  ): Promise<void> => {
    try {
      await submitElectrocarsLandingConsultation(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component id="consultation">
      <ConsultationFormTertiary
        image={formImage}
        imageMobile={formMobileImage}
        title={formTitle ?? ''}
        subtitle={formSubtitle ?? ''}
        buttonText={formButtonTitle ?? ''}
        onSubmit={sendConsultationForm}
      />
    </Component>
  );
}

export default Form;

const Component = styled.section``;
