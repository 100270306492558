import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/SollersHome/common.styles';
import { useSollersHomeData } from '@/modules/SollersHome/hooks';
import { Models, Title } from '@/components/LandingWidgets';
import { Component as TitleComponent } from '@/components/LandingWidgets/components/Title';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { submitSollersCarsLeadForm } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { Header as SliderWrapperHeader } from '@/components/LandingWidgets/components/SliderWrapper';
import { getDealersOptions } from '@/utils/common';

function ModelsList() {
  const openModal = useModal();
  const { modelsTitle, modelsItems, dealersRequests } = useSollersHomeData();

  if (!modelsItems || modelsItems.length === 0) {
    return null;
  }

  const sendApplicationForm = async (
    values: ApplicationFormFields,
    model: string,
    event: string
  ): Promise<void> => {
    try {
      await submitSollersCarsLeadForm({
        ...values,
        model,
        dealer: values.dealer ? Number(values.dealer.value) : undefined,
      }).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenApplicationFormModal = (
    title: string,
    model: string,
    event: string
  ) => {
    openModal(
      ApplicationFormModal,
      {
        title,
        fieldsConfig: { dealers: getDealersOptions(dealersRequests) },
        onSubmit: (values) => sendApplicationForm(values, model, event),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component id="models">
      <ContentContainer>
        <TitleWrapper>
          <Title title={modelsTitle ?? ''} />
        </TitleWrapper>

        <Models
          id={4}
          models={modelsItems.map(
            ({
              image,
              imageMobile,
              name,
              price,
              description,
              specifications,
              preorderButtonText,
              preorderFormTitle,
              link,
              isNewTabLink,
              isInStock,
              googleEvent,
            }) => ({
              image,
              imageMobile,
              name: name ?? '',
              price: price ?? '',
              description: description ?? '',
              specifications:
                specifications && specifications.length !== 0
                  ? specifications.map(({ label, value }) => ({
                      label: label ?? '',
                      value: value ?? '',
                    }))
                  : [],
              preorderText: preorderButtonText ?? '',
              link: link ?? '',
              isNewTabLink,
              isInStock,
              onPreorder: () =>
                handleOpenApplicationFormModal(
                  preorderFormTitle ?? '',
                  name ?? '',
                  googleEvent ?? ''
                ),
            })
          )}
        />
      </ContentContainer>
    </Component>
  );
}

export default ModelsList;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${SliderWrapperHeader} {
      right: 20px;
    }
  `)}
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  ${media.tablet(css`
    ${TitleComponent} {
      text-align: left;
      margin-left: 20px;
    }
  `)}
`;
