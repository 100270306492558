import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

import Loader from '@/components/Loader';
import {
  getHelpOnTheRoadSuccessInfo,
  submitHelpOnTheRoadContacts,
  submitHelpOnTheRoadPaymentProvider,
} from '@/services/orders/orders-service';
import Button from '@/components/Button';
import { QRCodeStyles } from '@/components/styles';
import { usePageUrl } from '@/hooks/usePageUrl';
import { redirect } from '@/utils/common';

import { OplatiQrProps } from './types';

function OplatiQr({
  orderId,
  packageId,
  qrCode,
  contactValues,
  total,
}: OplatiQrProps) {
  const [oplatiOrderId, setOplatiOrderId] = useState<string | null>(orderId);
  const [paymentProviderData, setPaymentProviderData] = useState<{
    qrCode: string;
    redirectUrl: string;
  } | null>(null);
  const [timerSec, setTimerSec] = useState(60);
  const [isLoading, setLoading] = useState(false);
  const url = usePageUrl();

  const handleRefreshQrCode = async () => {
    setLoading(true);

    try {
      const orderId = await submitHelpOnTheRoadContacts({
        ...contactValues,
        plan: packageId,
        total: Number(total),
      });

      if (orderId) {
        const response = await submitHelpOnTheRoadPaymentProvider(
          orderId.id,
          'OPLATI',
          {
            total: Number(total),
            returnUrl: `${url}?success&id=${orderId.id}`,
          }
        );

        setPaymentProviderData(response);
      }

      setOplatiOrderId(orderId.id);
      setTimerSec(60);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }

    setLoading(false);
  };

  // timer
  useEffect(() => {
    if (!timerSec) {
      return;
    }

    const interval = setInterval(() => {
      setTimerSec(timerSec - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timerSec]);

  // check payment status
  useEffect(() => {
    (async () => {
      try {
        if (timerSec % 5 === 0) {
          const response = await getHelpOnTheRoadSuccessInfo(
            oplatiOrderId ?? orderId
          );

          if (response.status === 'COMPLETED') {
            redirect(`${url}?success&id=${oplatiOrderId}`, {
              hardReload: true,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [timerSec]);

  return (
    <Component>
      <StyledQRCode
        value={paymentProviderData?.qrCode ?? qrCode}
        renderAs="svg"
      />
      <Time>{timerSec} секунд</Time>
      <StyledButton
        variant="contained"
        onClick={handleRefreshQrCode}
        disabled={isLoading}
      >
        {isLoading ? <Loader color={'#fff'} /> : 'Обновить'}
      </StyledButton>
    </Component>
  );
}

export default OplatiQr;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledQRCode = styled(QRCode)`
  ${QRCodeStyles}
`;

const Time = styled.div`
  font-size: 28px;
  line-height: 33px;
  margin-top: 25px;
  color: ${(props) => props.theme.black100};
`;

const StyledButton = styled(Button)`
  max-width: 220px;
  margin-top: 20px;
`;
