import React from 'react';
import styled from 'styled-components';
import Script from 'next/script';

import Layout from '@/layout';
import TechSpecifications from '@/modules/CarModelLanding/components/TechSpecifications';

import Hero from './components/Hero';
import Menu from './components/Menu';
import ModelConfiguration from './components/ModelConfiguration';
import SecondModelConfiguration from './components/SecondModelConfiguration';
import About from './components/About';
import Gallery from './components/Gallery';
import Reviews from './components/Reviews';
import Features from './components/Features';
import FeaturesSections from './components/FeaturesSections';
import ComplectationStorage from './components/ComplectationStorage';
import Guarantee from './components/Guarantee';
import TestDriveForm from './components/TestDriveForm';
import Panel from './components/Panel';
import { useCarModelLandingEventMicromarking } from './hooks';

function CarModelLanding() {
  const eventMicromarking = useCarModelLandingEventMicromarking();

  return (
    <Layout>
      <Component>
        <Hero />
        <Menu />
        <ModelConfiguration />
        <TechSpecifications />
        <About />
        <Gallery />
        <Reviews />
        <Features />
        <FeaturesSections />
        <ComplectationStorage />
        <Guarantee />
        <SecondModelConfiguration />
        <TestDriveForm />
        <Panel />
      </Component>

      {eventMicromarking.map((micromarking, index) => (
        <Script
          key={index}
          id="carModelLandingEvent"
          type="application/ld+json"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: micromarking,
          }}
        />
      ))}
    </Layout>
  );
}

export default CarModelLanding;

const Component = styled.section`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
