import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Button from '@/components/Button';

export const BottomWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(165, 173, 186, 0.6);

  ${media.mobile(
    css`
      flex-direction: column;
      align-items: flex-start;
    `
  )}
`;

export const PriceWrapper = styled.div``;

export const PriceLabel = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue100};
`;

export const Price = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  margin-top: 10px;
  color: ${(props) => props.theme.black200};

  ${media.tablet(
    css`
      font-size: 30px;
      line-height: 35px;
    `
  )}
`;

export const StyledButton = styled(Button)`
  max-width: 300px;

  ${media.mobile(css`
    max-width: none;
    margin-top: 30px;
  `)}
`;
