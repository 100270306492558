import React from 'react';

import { useMedia } from '@tager/web-core';

import { BenefitsProps } from '@/components/Benefits/types';

import BenefitList from './components/BenefitList';
import BenefitSlider from './components/BenefitSlider';

function Benefits({ id, benefits, cols, isHorizontal }: BenefitsProps) {
  const isMobile = useMedia('(max-width: 767px)');

  if (!benefits || benefits.length === 0) {
    return null;
  }

  return !isMobile ? (
    <BenefitList benefits={benefits} cols={cols} isHorizontal={isHorizontal} />
  ) : (
    <BenefitSlider id={id} benefits={benefits} />
  );
}

export default Benefits;
