import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { media } from '@/utils/mixin';
import LocationsMap from '@/components/LocationsMap';
import { ILocationCity } from '@/components/LocationsMap/LocationsMap.typings';

import Socials from './components/Socials';

interface Props {
  data: Array<ILocationCity>;
  isOfflineValuation?: boolean;
  title?: Nullable<string>;
  socialsTitle?: Nullable<string>;
}

function Map({ data, title, socialsTitle }: Props) {
  return (
    <Component>
      <Container>
        <InformationContainer>
          <Title>{title}</Title>
          <Socials socialsTitle={socialsTitle ?? ''} />
        </InformationContainer>
      </Container>
      <LocationsMap data={data} />
    </Component>
  );
}

export default Map;

const Component = styled.div`
  margin: 80px 0;

  ${media.mobile(css`
    margin: 40px 0;
  `)}
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
`;

const InformationContainer = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 30px;
  `)}
`;
