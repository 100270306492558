import React from 'react';
import styled, { css } from 'styled-components';

import { handleScrollToDomElement } from '@/utils/scroll';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { FaqProps } from './types';

function Faq({ faq }: FaqProps) {
  if (!faq || faq.length === 0) {
    return null;
  }

  return (
    <Component>
      <QuestionsList>
        {faq.map(({ question }, index) => (
          <QuestionsListQuestion
            key={index}
            onClick={() => handleScrollToDomElement(`faq${index + 1}`)}
          >
            {question}
          </QuestionsListQuestion>
        ))}
      </QuestionsList>

      <AnswerList>
        {faq.map(({ question, answer }, index) => (
          <Answer id={`faq${index + 1}`}>
            <AnswerTitle>{question}</AnswerTitle>
            <AnswerText dangerouslySetInnerHTML={{ __html: answer }} />
          </Answer>
        ))}
      </AnswerList>
    </Component>
  );
}

export default Faq;

const Component = styled.div`
  position: relative;
`;

const QuestionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const QuestionsListQuestion = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
  color: ${colors.main};
  text-align: left;

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}

  &:not(:first-child) {
    margin-top: 10px;
  }

  &:hover {
    color: ${colors.main100};
  }
`;

const AnswerList = styled.div`
  margin-top: 40px;
`;

const Answer = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${colors.gray400};

  &:last-child {
    padding-bottom: 0;
  }
`;

const AnswerTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const AnswerText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.black};

  a {
    color: ${colors.main};
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: ${colors.main};
    }
  }

  ul,
  ol {
    padding-left: 16px;
  }

  ul {
    list-style-type: square;
  }

  ol {
    list-style-type: decimal;
  }

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;
