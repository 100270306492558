import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Atlantm30/Atlantm30.styles';
import { useAtlantm30Data } from '@/modules/Atlantm30/Atlantm30.hooks';
import { colors } from '@/constants/theme';
import Step from '@/modules/Atlantm30/components/Steps/components/Step';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

function Steps() {
  const {
    stepsParticipationItems,
    stepsParticipationButtonTitle,
    stepsParticipationButtonLink,
  } = useAtlantm30Data();

  if (!stepsParticipationItems) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <StepsList>
          {stepsParticipationItems.map(({ title, text }, index) => (
            <Step key={index} title={title} text={text} index={index} />
          ))}
        </StepsList>

        <ButtonWrapper>
          <Button to={stepsParticipationButtonLink}>
            <ButtonTitle>{stepsParticipationButtonTitle}</ButtonTitle>
          </Button>
        </ButtonWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Steps;

const Component = styled.section`
  padding-bottom: 60px;
  background: ${colors.white};
`;

const StepsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;

  & > div:last-child {
    & > svg {
      display: none;
    }
  }

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 80px;
  `)}
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const Button = styled(Link)`
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border: 2px dashed ${colors.gray500};
  border-radius: 40px;
`;

const ButtonTitle = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding: 15px 55px;
  border-radius: 30px;
  background-color: ${colors.yellow};
  color: ${colors.black100};

  ${media.mobile(css`
    font-size: 24px;
    line-height: 32px;
    padding: 15px;
  `)}
`;
