import React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { SliderWrapper } from '@/components/LandingWidgets';

import StepCard from '../StepCard';
import { Component as StepCardComponent } from '../StepCard/StepCard';

import { StepsSliderProps } from './types';

function StepsSlider({ id, steps }: StepsSliderProps) {
  if (!steps || steps.length === 0) {
    return null;
  }

  const isWithoutPagination = steps.length === 1;
  const isNoSwiping = steps.length === 1;

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          spaceBetween: 30,
          noSwipingClass: 'swiper-no-swiping',
          withoutPagination: isWithoutPagination,
          withoutNavs: true,
        }}
      >
        {steps.map((step, index) => (
          <SwiperSlide
            key={index}
            className={isNoSwiping ? 'swiper-no-swiping' : ''}
          >
            <StepCard {...step} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default StepsSlider;

const Component = styled.div`
  .swiper-slide {
    height: auto;

    ${StepCardComponent} {
      height: 100%;
    }
  }
`;
