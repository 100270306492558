import styled, { css } from 'styled-components';

import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import { media } from '@/utils/mixin';
import CarCheckForm from '@/components/Redesign/Forms/CarCheckForm';
import { submitCarCheck } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { CarCheckFormValues } from '@/components/Redesign/Forms/CarCheckForm/types';
import { ContentContainer } from '@/modules/VerifiedAtlantmLanding/common.styles';

function VerifiedForm() {
  const {
    templateFields: { formTitle, formImage, formGoogleEvent },
  } = useVerifiedAtlantmLandingData();

  const fieldsConfig = {
    withMessage: true,
    withModel: true,
    withBrand: true,
  };

  const sendCarCheckForm = async (
    values: CarCheckFormValues
  ): Promise<void> => {
    try {
      await submitCarCheck(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <Component id="verifiedForm">
      <ContentContainer>
        <CarCheckForm
          image={formImage}
          title={formTitle ?? ''}
          fieldsConfig={fieldsConfig}
          onSubmit={sendCarCheckForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default VerifiedForm;

const Component = styled.div`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)}
`;
