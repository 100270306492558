import React, { useState } from 'react';
import styled from 'styled-components';

import {
  CarComplectation,
  CarComplectationInfo,
} from '@/components/CarModelLandingWidgets';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';

function ComplectationStorageContentDesktop() {
  const { complectations } = useCarModelLandingData();
  const [activeEquipment, setActiveEquipment] = useState(0);

  if (!complectations || complectations.length === 0) {
    return null;
  }

  const complectationInfoTitle = complectations[activeEquipment].title ?? '';
  const complectationInfoList = complectations[activeEquipment].list.map(
    ({ text }) => text ?? ''
  );
  const complectationInfoImage =
    complectations[activeEquipment].imageDetail?.url ?? '';

  return (
    <>
      <ComplectationList>
        {complectations.map(({ image, name }, index) => (
          <CarComplectationWrapper key={index}>
            <CarComplectation
              image={image}
              name={name ?? ''}
              onClick={() => setActiveEquipment(index)}
              isActive={index === activeEquipment}
            />
          </CarComplectationWrapper>
        ))}
      </ComplectationList>

      {complectationInfoList &&
        complectationInfoList.length > 0 &&
        complectationInfoImage && (
          <ComplectationInfo>
            <CarComplectationInfo
              title={complectationInfoTitle}
              list={complectationInfoList}
              image={{ url: complectationInfoImage }}
            />
          </ComplectationInfo>
        )}
    </>
  );
}

export default ComplectationStorageContentDesktop;

const ComplectationList = styled.div`
  display: flex;
`;

const CarComplectationWrapper = styled.div`
  width: 280px;

  &:not(:first-child) {
    margin-left: 20px;
  }
`;

const ComplectationInfo = styled.div`
  margin-top: 30px;
`;
