import React, { useState } from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import { Tabs } from '@/components/CarModelLandingWidgets';

import PartnersProgramsList from './components/PartnersProgramsList';
import PartnersProgramsSlider from './components/PartnersProgramsSlider';
import { PartnersProgramsProps } from './types';

function PartnersPrograms({ id, programs }: PartnersProgramsProps) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const isTablet = useMedia('(max-width: 1259px)');

  if (!programs || programs.length === 0) {
    return null;
  }

  const activePrograms = programs[activeTab].programs;

  return (
    <Component>
      <TabsWrapper>
        <Tabs
          data={programs.map(({ type }, index) => ({
            label: type,
            value: index,
          }))}
          activeValue={activeTab}
          onChange={setActiveTab}
        />
      </TabsWrapper>

      <Programs>
        {!isTablet ? (
          <PartnersProgramsList programs={activePrograms} />
        ) : (
          <PartnersProgramsSlider id={id} programs={activePrograms} />
        )}
      </Programs>
    </Component>
  );
}

export default PartnersPrograms;

const Component = styled.div`
  position: relative;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Programs = styled.div`
  margin-top: 20px;
`;
