import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import LandingCarCard from '@/components/LandingCarCard';
import { media } from '@/utils/mixin';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { getCatalogItems } from '@/store/reducers/catalog';
import { switchNamesItems } from '@/modules/DetailedCar/DetailedCar.helper';
import defaultStockCar from '@/assets/images/cars-stock/defaultStockCar.png';
import {
  getCatalogCarImages,
  getCatalogCarsByIds,
} from '@/modules/Catalog/Catalog.helper';
import { getDetailedCarListData } from '@/store/reducers/cars-stock';

function CarsGrid() {
  const { mayLikeDataCatalog, mayLikeDataStockNew } = useSpecialOfferData();
  const stockCars = useTypedSelector(getDetailedCarListData);
  const catalogCars = getCatalogCarsByIds(
    useTypedSelector(getCatalogItems).catalogItemsData,
    mayLikeDataCatalog && mayLikeDataCatalog.length > 0
      ? mayLikeDataCatalog
      : []
  );
  const isTablet = useMedia('(max-width: 1259px)');

  const isStockCarsEmpty =
    !stockCars ||
    stockCars.length === 0 ||
    !mayLikeDataStockNew ||
    mayLikeDataStockNew.length === 0;

  const isCatalogCarsEmpty =
    !catalogCars ||
    catalogCars.length === 0 ||
    !mayLikeDataCatalog ||
    mayLikeDataCatalog.length === 0;

  if (isStockCarsEmpty && isCatalogCarsEmpty) {
    return null;
  }

  return (
    <Component>
      {!isStockCarsEmpty &&
        stockCars.map((car, index) => {
          const title = `${car.brand.name} ${car.model.name}`;
          const link = `/cars/new/${car.id}`;
          const images =
            car.images.length !== 0
              ? car.images
                  .slice(0, 5)
                  .map((image) => image.gallery ?? defaultStockCar)
              : [{ url: defaultStockCar }];

          return (
            <LandingCarCard
              key={index}
              images={images}
              title={title}
              subtitle={
                car.params.bodyType ? switchNamesItems(car.params.bodyType) : ''
              }
              price={car.price ?? 0}
              link={link}
              isMobile={isTablet}
            />
          );
        })}

      {!isCatalogCarsEmpty &&
        catalogCars.map((car, index) => {
          const link = `/cars/new/${car.brand.alias}/${car.model.alias}`;
          const images = getCatalogCarImages(car)[0].items;

          return (
            <LandingCarCard
              key={index}
              images={images}
              title={car.name}
              subtitle={car.body?.name ?? ''}
              price={car.stock.minPrice ?? 0}
              pricePrefix="от"
              link={link}
              isMobile={isTablet}
            />
          );
        })}
    </Component>
  );
}

export default CarsGrid;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 40px;

  ${media.mobile(css`
    grid-template-columns: repeat(2, 1fr);
  `)};

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
