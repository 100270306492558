import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';
import { media } from '@/utils/mixin';
import { handleScrollToDomElement } from '@/utils/scroll';

function Service() {
  const {
    isVisibleService,
    serviceTitle,
    firstServiceInfo,
    secondServiceInfo,
    thirdServiceInfo,
    serviceButtonTitle,
    serviceImage,
  } = useAmpLandingData();

  if (!isVisibleService) {
    return null;
  }

  return (
    <Container>
      <Component>
        <ServiceTitle>
          <Title dangerouslySetInnerHTML={{ __html: serviceTitle ?? '' }} />
        </ServiceTitle>
        <ServiceInformation>
          <InformationContainer>
            <Info>{firstServiceInfo}</Info>
            <Sign>+</Sign>
            <Info>{secondServiceInfo}</Info>
            <Sign>=</Sign>
            <SizedInfo>{thirdServiceInfo}</SizedInfo>
          </InformationContainer>
          {serviceButtonTitle && (
            <StyledButton
              onClick={() => handleScrollToDomElement('consultation')}
            >
              {serviceButtonTitle}
            </StyledButton>
          )}
        </ServiceInformation>
        <ServiceImageContainer>
          <ServiceImage
            loading="lazy"
            {...convertThumbnailToPictureProps(serviceImage)}
            alt="service-image"
          />
        </ServiceImageContainer>
      </Component>
    </Container>
  );
}

export default Service;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Component = styled.div`
  margin: 82px 0;
  display: grid;
  grid-template-rows: 31% 69%;
  grid-template-columns: 37.53% 62.47%;
  grid-template-areas:
    'title img'
    'info img';

  @media (max-width: 1250px) {
    margin: 50px 0;
    height: fit-content;
    grid-template-rows: 1fr;
    grid-template-columns: 37.53% 62.47%;
    grid-template-areas:
      'title title'
      'img img'
      'info info';
  }
`;

const ServiceTitle = styled.div`
  grid-area: title;

  @media (max-width: 1250px) {
    margin: 0 auto 60px;
    text-align: center;
  }
`;

const Title = styled.div`
  display: block;
  font-weight: 700;
  font-size: 36px;
  color: ${(props) => props.theme.black};

  b,
  strong {
    color: ${colors.main};
  }

  ${media.mobile(css`
    font-size: 30px;
  `)}
`;

const ServiceInformation = styled.div`
  grid-area: info;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1250px) {
    padding-top: 60px;
  }
`;

const InformationContainer = styled.div`
  padding-right: 83px;

  @media (max-width: 1250px) {
    padding: 0;
    margin: 0 auto;
  }
`;

const Info = styled.span`
  display: block;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 2px 0;
  color: ${(props) => props.theme.black};
`;

const Sign = styled(Info)`
  font-weight: 500;
  font-size: 36px;
  color: ${colors.main};
`;

const SizedInfo = styled(Info)`
  width: 300px;
  margin: 0 auto;
  padding: 5px 0;
`;

const ServiceImageContainer = styled.div`
  grid-area: img;
  position: relative;
  padding-top: 69.712%;
  margin-left: 45.63px;

  @media (max-width: 1250px) {
    margin-left: 0;
  }
`;

const StyledButton = styled.button`
  width: 384px;
  padding: 16px 0;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 34px;
  display: block;
  text-align: center;
  color: ${colors.white};
  background: rgb(13, 110, 253);
  border: 1px solid rgb(13, 110, 253);
  transition: 0.3s;

  &:hover {
    background-color: #0b5ed7;
    border-color: #0a58ca;
  }

  @media (max-width: 1250px) {
    margin: 34px auto 0;
  }

  ${media.mobile(css`
    width: 100%;
  `)}
`;

const ServiceImage = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  img {
    object-fit: cover;
  }
`;
