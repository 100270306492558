import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { getPhoneLink } from '@/utils/common';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

import { DealerCardProps } from './types';

function DealerCard({
  image,
  name,
  address,
  phone,
  link,
  isNewTab,
  hasShadow,
}: DealerCardProps) {
  return (
    <Component hasShadow={hasShadow}>
      <AbsoluteLink to={link} target={isNewTab ? '_blank' : '_self'} />
      <Image
        loading="lazy"
        {...convertThumbnailToPictureImage(image)}
        alt={name}
      />
      <Content>
        <Name to={link}>{name}</Name>
        <Address>{address}</Address>
        {phone && <Phone to={getPhoneLink(phone)}>{phone}</Phone>}
      </Content>
    </Component>
  );
}

export default DealerCard;

const Image = styled(Picture)`
  flex: 1 1 190px;
  max-width: 190px;
  height: 120px;

  ${media.tablet(css`
    flex: 1 1 120px;
    max-width: 120px;
  `)}
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    transition: 0.3s;
    border-radius: 10px;
  }

  picture {
    height: 100%;
  }

  img {
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
  }
`;

const Name = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: 0.3s;
  color: ${colors.black};

  &:hover {
    color: ${colors.blue1100};
  }
`;

const Address = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin: 5px 0 10px;
  transition: 0.3s;
  color: ${colors.blue1200};
`;

const Phone = styled(Link)`
  z-index: 1;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: ${colors.black};
`;

const Component = styled.div<{ hasShadow?: DealerCardProps['hasShadow'] }>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: ${colors.white};

  box-shadow: ${({ hasShadow }) => (hasShadow ? colors.grayShadow : 'none')};

  &:hover {
    ${Image} {
      &:after {
        background: ${colors.blue1300_alpha70};
      }
    }

    ${Name}, ${Address}, ${Phone} {
      color: ${colors.blue1100};
    }
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 20px;
`;

const AbsoluteLink = styled(Link)`
  z-index: 1;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;
