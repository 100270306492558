import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import TimeIcon from '@/assets/svg/time.svg';
import TextInput from '@/components/TextInput';
import TimepickerPopup from '@/components/Timepicker/components/TimepickerPopup';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

import { TimepickerProps } from './types';

function Timepicker({
  selectedTime,
  disabled,
  disabledMessage,
  onClick,
  times,
  errorMessage,
}: TimepickerProps) {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (isOpenPopup) {
      setIsOpenPopup(false);
    }
  });

  function handleClick() {
    setIsOpenPopup(true);
  }

  return (
    <Component ref={ref}>
      <TextInput
        label="Время"
        value={selectedTime}
        badge={<TimeIcon />}
        onClick={handleClick}
        errorMessage={errorMessage}
      />

      {isOpenPopup && (
        <TimepickerPopup
          times={times}
          selectedTime={selectedTime}
          onClick={(time) => {
            onClick(time);
            setIsOpenPopup(false);
          }}
          disabled={disabled}
          disabledMessage={disabledMessage}
        />
      )}
    </Component>
  );
}

export default Timepicker;

const Component = styled.div`
  width: 100%;
  position: relative;
`;
