import { FormikErrors } from 'formik';

import { validators } from '@tager/web-core';

import { emailRegexp, phoneRegexp } from '@/utils/textInput';

import { ApplicationForCarValuesType } from './ValueCar.types';
import { ContactFormOfflineValuationModel } from './components/OfflineValuation/components/ContactFormOfflineValuation/interfaces';

export function handleValidateContactForm(
  values: ContactFormOfflineValuationModel
): FormikErrors<ContactFormOfflineValuationModel> {
  const errors: FormikErrors<ApplicationForCarValuesType> = {};
  const requiredMessage = 'required';

  if (validators.required(values.name)) {
    errors.name = requiredMessage;
  }

  if (!phoneRegexp.test(values.phone)) {
    errors.phone = requiredMessage;
  }

  if (!emailRegexp.test(values.email)) {
    errors.email = requiredMessage;
  }

  return errors;
}
