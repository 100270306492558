import React from 'react';
import styled, { css } from 'styled-components';

import CheckIcon from '@/assets/svg/checkbox-tick.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import MinusIcon from '@/assets/svg/minus-rounded.svg';

import { TableProps } from './types';

function Table({ title, rows }: TableProps) {
  if (!rows || rows.length === 0) {
    return null;
  }

  return (
    <Component>
      {title && (
        <Header>
          <Title>{title}</Title>
        </Header>
      )}

      {rows.map(({ title, cells }, index) => (
        <Row key={index}>
          <RowTitle>{title}</RowTitle>
          {cells && cells.length > 0 && (
            <Cells cellsCount={cells.length}>
              {cells.map((cell, index) => (
                <Cell key={index}>
                  {cell ? <StyledCheckIcon /> : <MinusIcon />}
                </Cell>
              ))}
            </Cells>
          )}
        </Row>
      ))}
    </Component>
  );
}

export default Table;

const Component = styled.div``;

const Header = styled.div`
  padding: 10px;
  background: ${colors.blue800};
  margin-bottom: 10px;
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.black};
`;

const Row = styled.div`
  display: flex;

  &:nth-child(odd) {
    background: ${(props) => props.theme.gray_1};
  }
`;

const RowTitle = styled.div`
  flex: 0 0 600px;
  max-width: 600px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black200};

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Cells = styled.div<{ cellsCount: number }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex: 100%;
  max-width: 100%;

  ${media.tablet(css`
    display: none;
  `)}

  ${({ cellsCount }) =>
    cellsCount &&
    css`
      grid-template-columns: repeat(${cellsCount}, 1fr);
    `}
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black200};
  text-align: center;
  margin-left: 20px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 23px;
  height: 23px;

  path {
    fill: ${colors.main};
    stroke: ${colors.main};
  }
`;
