import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import AdvantagesList from './components/AdvantagesList';
import AdvantagesSlider from './components/AdvantagesSlider';
import { AdvantagesProps } from './types';

function Advantages({
  advantages,
  title,
  columns = 5,
  isMobileSlider,
  isBig,
}: AdvantagesProps) {
  const isMobileDevice = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <Title>{title}</Title>

      <ContentWrapper>
        {isMobileSlider && isMobileDevice ? (
          <AdvantagesSlider advantages={advantages} isBig={isBig} />
        ) : (
          <AdvantagesList
            columns={columns}
            advantages={advantages}
            isBig={isBig}
          />
        )}
      </ContentWrapper>
    </Component>
  );
}

export default Advantages;

const Component = styled.div`
  background: ${colors.yellowGradient};
  padding: 30px;
  border-radius: 20px;

  ${media.tablet(css`
    padding: 30px 20px;
  `)}
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
`;
