import React from 'react';
import styled, { css } from 'styled-components';

import CheckboxCheckedIcon from '@/assets/svg/checked-blue-checkbox.svg';
import { colors } from '@/constants/theme';

interface Props {
  checked: boolean;
  isOfflineValuation?: boolean;
}

function CustomCheckbox({ checked, isOfflineValuation }: Props) {
  return checked ? (
    <CheckedCheckbox isOfflineValuation={isOfflineValuation}>
      <CheckboxCheckedIcon />
    </CheckedCheckbox>
  ) : (
    <UncheckedCheckbox isOfflineValuation={isOfflineValuation} />
  );
}

export default CustomCheckbox;

const UncheckedCheckbox = styled.div<{ isOfflineValuation?: boolean }>`
  border: 1px solid ${colors.gray400};
  width: 20px;
  height: 20px;
  border-radius: 50%;

  ${({ isOfflineValuation }) =>
    isOfflineValuation &&
    css`
      width: 30px;
      height: 30px;

      svg {
        width: 30px;
        height: 30px;
      }
    `}
`;

const CheckedCheckbox = styled(UncheckedCheckbox)<{
  isOfflineValuation?: boolean;
}>`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ isOfflineValuation }) =>
    isOfflineValuation &&
    css`
      width: 30px;
      height: 30px;
      svg {
        width: 30px;
        height: 30px;
      }
    `}
`;
