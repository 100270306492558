import React, { useState } from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import ItemsList from '@/components/LocationsMap/components/ItemsList/ItemsList';
import { colors } from '@/constants/theme';
import { getMapProps } from '@/utils/map';

import {
  ILocationCity,
  IMapItem,
  IHoveredImage,
  IMapLatLng,
  ILocationItem,
} from './LocationsMap.typings';
import Map from './components/Map';

const convertFromCitiesToMapItems = (
  cities: Array<ILocationCity>,
  activeItemId: null | number
): Array<IMapItem> => {
  const result: Array<IMapItem> = [];

  cities.forEach((city) =>
    city.items.forEach((item) => {
      result.push({
        id: item.id,
        latitude: item.latitude,
        longitude: item.longitude,
        active: item.id === activeItemId,
      });
    })
  );

  return result;
};

function findItemById(
  data: Array<ILocationCity>,
  id: number
): Nullable<ILocationItem> {
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].items.length; j++) {
      if (data[i].items[j].id === id) {
        return data[i].items[j];
      }
    }
  }
  return null;
}

function getCityIdForItem(
  data: Array<ILocationCity>,
  id: number
): Nullable<number> {
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].items.length; j++) {
      if (data[i].items[j].id === id) {
        return data[i].id;
      }
    }
  }
  return null;
}

type Props = {
  isForm?: boolean;
  activeId: Nullable<number>;
  setActiveId: (id: number) => void;
  data: Array<ILocationCity>;
};

const LocationsMapDesktop = ({
  data,
  activeId,
  setActiveId,
  isForm,
}: Props) => {
  const [isLoadImage, setIsLoadImage] = useState(false);
  const [center, setCenter] = useState<Nullable<IMapLatLng>>(null);

  const [expandedCityIds, setExpandedCityIds] = useState<Array<number>>(
    data.length ? [data[0].id] : []
  );

  const [selectedElement, setSelectedElement] =
    useState<Nullable<HTMLDivElement>>(null);
  const [hoveredImage, setHoveredImage] = useState<IHoveredImage>({
    image: '',
    name: '',
    isHovered: false,
    coordinateX: 0,
    coordinateY: 0,
  });

  const mapProps = getMapProps(
    data
      .map(({ items }) =>
        items.map(({ latitude, longitude }) => ({
          latitude,
          longitude,
        }))
      )
      .flat(1)
  );

  const onExpandedChange = (id: number, state: boolean) => {
    if (state) {
      setExpandedCityIds([...expandedCityIds, id]);
    } else {
      setExpandedCityIds(expandedCityIds.filter((item) => item !== id));
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (hoveredImage.isHovered) {
      setHoveredImage({
        ...hoveredImage,
        coordinateX: event.clientX - 25,
        coordinateY: event.clientY + 20,
      });
    }
  };

  const handlerHoverCamera = (
    image: string,
    name: string,
    isHovered: boolean
  ): void => {
    !image && setIsLoadImage(false);
    setHoveredImage({
      ...hoveredImage,
      image: image,
      name: name,
      isHovered: isHovered,
    });
  };

  const onSelectHandler = (id: number) => {
    setActiveId(id);

    const item = findItemById(data, id);
    if (item) {
      setCenter({ lat: item.latitude, lng: item.longitude });
    }

    const cityId = getCityIdForItem(data, id);
    if (cityId && expandedCityIds.indexOf(cityId) === -1) {
      setExpandedCityIds([...expandedCityIds, cityId]);
    }
  };

  const scrollToSelectedElement = (element: HTMLDivElement): void => {
    setSelectedElement(element);
  };

  return (
    <Component onMouseMove={(event) => handleMouseMove(event)}>
      <Sidebar>
        <ItemsList
          data={data}
          isMobile={false}
          selectedId={activeId}
          onSelect={onSelectHandler}
          setDataToParent={handlerHoverCamera}
          selectedElement={selectedElement}
          expandedIds={expandedCityIds}
          onExpandedChange={onExpandedChange}
          isForm={isForm}
        />
        <ImageContainer
          isLoadImage={isLoadImage}
          leftCoords={hoveredImage.coordinateX}
          topCoords={hoveredImage.coordinateY}
        >
          <Image src={hoveredImage.image} onLoad={() => setIsLoadImage(true)} />
          <TitleOnImage>«{hoveredImage.name}»</TitleOnImage>
        </ImageContainer>
      </Sidebar>
      <Map
        zoom={mapProps.zoom}
        center={center ?? mapProps.center}
        items={convertFromCitiesToMapItems(data, activeId)}
        getSelectedElement={scrollToSelectedElement}
        handleClickMapDealer={onSelectHandler}
      />
    </Component>
  );
};

const Component = styled.div`
  position: relative;
`;

const Sidebar = styled.div`
  position: absolute;
  z-index: 1;
`;

const ImageContainer = styled.div<{
  isLoadImage: boolean;
  leftCoords: number;
  topCoords: number;
}>`
  position: fixed;
  display: ${(props): string => (props.isLoadImage ? 'block' : 'none')};
  top: ${(props): string => `${props.topCoords}px`};
  left: ${(props): string => `${props.leftCoords}px`};
  z-index: 5;
  width: 384px;
  @media (max-width: 450px) {
    width: 280px;
  }
`;

const Image = styled.img`
  width: 384px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 50%);
  border-radius: 8px;
`;

const TitleOnImage = styled.span`
  height: 40px;
  width: 363px;
  opacity: 0.56;
  border-radius: 20px;
  background-color: ${colors.white};
  color: ${colors.black};
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  bottom: 15px;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 450px) {
    width: 93%;
  }
`;

export default LocationsMapDesktop;
