import React from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';

import { media } from '@/utils/mixin';

interface Props {
  title: string;
  params: Array<{
    value: Nullish<string>;
    label: string;
  }>;
}

function TechParamsItem({ title, params }: Props) {
  return (
    <Components>
      <Title>{title}</Title>
      <List>
        {params.map((item, index) => (
          <Item key={index}>
            <Value>{item.value}</Value>
            <Label main={!item.value}>{item.label}</Label>
          </Item>
        ))}
      </List>
    </Components>
  );
}

export default TechParamsItem;

const Components = styled.div``;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${(props) => props.theme.main};
  text-transform: uppercase;
`;

const List = styled.div`
  display: grid;
  gap: 30px 10px;
  grid-template-columns: repeat(3, 1fr);

  margin-top: 20px;
  ${media.tablet(css`
    display: block;
  `)}
`;

const Item = styled.div`
  ${media.tablet(css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    :not(:first-child) {
      margin-top: 20px;
    }
  `)}
`;

const Value = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  color: ${(props) => props.theme.black};
`;

const Label = styled.div<{ main: boolean }>`
  font-size: 14px;
  line-height: 16px;
  text-align: left;

  color: ${(props) => (props.main ? props.theme.black : props.theme.blue100)};
`;
