import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import SuccessIcon from '@/assets/svg/electrocars-landing/success.svg';
import { media } from '@/utils/mixin';

import { StepCardProps } from './types';

function StepCard({ number, text, isLastCard }: StepCardProps) {
  return (
    <Component isLastCard={isLastCard}>
      {!isLastCard ? (
        <Number>{number}</Number>
      ) : (
        <Icon>
          <SuccessIcon />
        </Icon>
      )}

      <Text isLastCard={isLastCard}>{text}</Text>
    </Component>
  );
}

export default StepCard;

const Component = styled.div<{ isLastCard?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: ${colors.main};
  border-radius: 15px;
  box-shadow: 0 2px 17px rgba(0, 0, 0, 0.15);

  ${({ isLastCard }) =>
    isLastCard &&
    css`
      background: ${colors.blue300};
    `}
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 42px;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  border-radius: 8px;
  border: 2px solid ${colors.white};
  color: ${colors.white};

  ${media.tablet(css`
    padding: 0 12px;
    height: 36px;
    font-size: 25px;
    line-height: 29px;
  `)}
`;

const Icon = styled.div`
  svg {
    width: 42px;
    height: 42px;

    ${media.tablet(css`
      width: 36px;
      height: 36px;
    `)}
  }
`;

const Text = styled.p<{ isLastCard?: boolean }>`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-top: 20px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 19px;
  `)}

  ${({ isLastCard }) =>
    isLastCard &&
    css`
      color: ${colors.main};
    `}
`;
