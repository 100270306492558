import React from 'react';
import styled from 'styled-components';

import HeroWithoutContent from '@/components/HeroWithoutContent';
import { useInsuranceData } from '@/modules/Insurance/Insurance.hooks';
import LinkTabs from '@/components/LinkTabs';
import Layout from '@/layout';

import TypesOfInsurance from './components/TypesOfInsurance';
import Services from './components/Services';

function Insurance() {
  const { heroImage, heroTabletImage, heroMobileImage, linksTabs } =
    useInsuranceData();

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
          insurance
        />
        <LinkTabs linksTabs={linksTabs} />
        <SectionGroup>
          <Services />
          <TypesOfInsurance />
        </SectionGroup>
      </Component>
    </Layout>
  );
}

export default Insurance;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;

const SectionGroup = styled.div`
  position: relative;
`;
