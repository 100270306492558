import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';

import PostCard from '@/components/PostCard';
import { useServicesData } from '@/modules/Services/Services.hooks';
import { useTypedSelector } from '@/store/store';
import { selectPostsListPosts } from '@/store/reducers/posts-list';
import { media } from '@/utils/mixin';
import { SliderWrapper } from '@/components/LandingWidgets';

function SharesSlider() {
  const sharesItems = useTypedSelector(selectPostsListPosts);
  const isMobile = useMedia('(max-width: 767px)');
  const isTablet = useMedia('(max-width: 1259px)');
  const data = useServicesData();

  if (data.isSharesHidden || !sharesItems || sharesItems.items.length === 0) {
    return null;
  }

  return (
    <Component>
      <Title>{data.sharesTitle ?? ''}</Title>

      {isMobile ? (
        <MobileList>
          {sharesItems.items.map((item, index) => (
            <MobileListItem key={index}>
              <PostCard
                title={item.title ?? ''}
                url={item.url ?? ''}
                image={item.image}
              />
            </MobileListItem>
          ))}
        </MobileList>
      ) : (
        <SliderWrapper
          options={{
            spaceBetween: 20,
            withoutNavs: sharesItems.items.length < 4 || isTablet,
            withoutPagination: true,
          }}
        >
          {sharesItems.items.map((item, index) => (
            <SwiperSlide key={index}>
              <PostCard
                title={item.title ?? ''}
                url={item.url ?? ''}
                image={item.image}
              />
            </SwiperSlide>
          ))}
        </SliderWrapper>
      )}
    </Component>
  );
}

export default SharesSlider;

const Component = styled.div`
  max-width: 1440px;
  margin: 20px auto 0;
  padding: 0 30px;
  position: relative;
  overflow: hidden;

  .swiper-slide {
    max-width: 360px;
    height: auto;
  }

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  margin: 40px 0 20px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 32px;
    line-height: 38px;
    margin: 20px 0 10px;
  `)}
`;

const MobileList = styled.div``;

const MobileListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;
