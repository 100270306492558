import React from 'react';
import styled, { css } from 'styled-components';

import { BannersSliderSecondary } from '@/components/LandingWidgets';
import { useHisunHomeData } from '@/modules/HisunHome/hooks';
import { media } from '@/utils/mixin';

function HeroSlider() {
  const { heroBanners } = useHisunHomeData();

  if (!heroBanners || heroBanners.length === 0) {
    return null;
  }

  return (
    <Component>
      <BannersSliderSecondary
        id={1}
        banners={heroBanners.map(
          ({ image, mobileImage, title, description, url }) => ({
            image,
            mobileImage,
            title: title ?? '',
            description: description ?? '',
            url: url ?? '',
          })
        )}
      />
    </Component>
  );
}

export default HeroSlider;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
