import { MutableRefObject } from 'react';

import { OnlineValuationUserChoiceModel } from '@/modules/ValueCar/ValueCar.types';
import { handleScrollToDomElement } from '@/utils/scroll';

import { OnlineCheckedValuesModel } from './interfaces';

export function checkIsFillOnlineValues(
  values: OnlineCheckedValuesModel
): boolean {
  for (let key in values) {
    // @ts-ignore
    if (!values[key]) return false;
  }
  return true;
}

export function checkUserValue(
  chosenData: OnlineValuationUserChoiceModel
): OnlineCheckedValuesModel {
  return {
    carDataCheck:
      Boolean(chosenData.brand) &&
      Boolean(chosenData.model) &&
      Boolean(chosenData.year),
    bodyTypeCheck: Boolean(chosenData.bodyType),
    generationCheck: Boolean(chosenData.generation),
    parametersCheck:
      Boolean(chosenData.parameters.enginePower) &&
      Boolean(chosenData.parameters.fuel) &&
      Boolean(chosenData.parameters.gearBox) &&
      Boolean(chosenData.parameters.engineVolume),
  };
}

export function checkIsScrollNeed(
  formRef: MutableRefObject<HTMLDivElement | null>,
  generationRef: MutableRefObject<HTMLDivElement | null>,
  bodyTypeRef: MutableRefObject<HTMLDivElement | null>,
  parametersRef: MutableRefObject<HTMLDivElement | null>,
  checkedValues: OnlineCheckedValuesModel
) {
  if (!checkedValues.carDataCheck) {
    handleScrollToDomElement(formRef);
    return;
  }

  if (!checkedValues.generationCheck) {
    handleScrollToDomElement(generationRef);
    return;
  }

  if (!checkedValues.bodyTypeCheck) {
    handleScrollToDomElement(bodyTypeRef);
    return;
  }

  if (!checkedValues.parametersCheck) {
    handleScrollToDomElement(parametersRef);
    return;
  }
}

export function getOnlineDataWithValues(
  brand = '',
  model = '',
  year = '',
  bodyType = '',
  generation = '',
  fuel = '',
  gearBox = '',
  enginePower = '',
  engineVolume = ''
): OnlineValuationUserChoiceModel {
  return {
    brand,
    model,
    year,
    bodyType,
    generation,
    parameters: {
      fuel,
      gearBox,
      enginePower,
      engineVolume,
    },
    photo: {
      exterior: {
        front: [],
        back: [],
        left: [],
        right: [],
        underHood: [],
      },
      interior: {
        dashboard: [],
        torpedo: [],
        interiorBack: [],
        interiorFront: [],
      },
      additionalPhoto: {
        additionalPhoto: [],
      },
      documents: {
        frontSide: [],
        backSide: [],
      },
    },
  };
}

export function getClearedOnlineData(): OnlineValuationUserChoiceModel {
  return {
    brand: '',
    model: '',
    year: '',
    bodyType: '',
    generation: '',
    parameters: {
      fuel: '',
      gearBox: '',
      enginePower: '',
      engineVolume: '',
    },
    photo: {
      exterior: {
        front: [],
        back: [],
        left: [],
        right: [],
        underHood: [],
      },
      interior: {
        dashboard: [],
        torpedo: [],
        interiorBack: [],
        interiorFront: [],
      },
      additionalPhoto: {
        additionalPhoto: [],
      },
      documents: {
        frontSide: [],
        backSide: [],
      },
    },
  };
}
