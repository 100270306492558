import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import { handleScrollToDomElement } from '@/utils/scroll';

import { MenuProps } from './types';

function Menu({ items }: MenuProps) {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Component>
      {items.map(({ text, anchor }, index) => (
        <Nav key={index} onClick={() => handleScrollToDomElement(anchor)}>
          {text}
        </Nav>
      ))}
    </Component>
  );
}

export default Menu;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Nav = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding-right: 15px;
  color: ${colors.white};
  transition: 0.3s;

  &:not(:first-child) {
    margin-top: 4px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    flex: 0 0 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${colors.gray100};
  }

  &:hover {
    opacity: 0.6;
  }
`;
