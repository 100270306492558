import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer, Title } from '@/modules/VedaemLanding/common.styles';
import { useVedaemLandingData } from '@/modules/VedaemLanding/hooks';
import { media } from '@/utils/mixin';

import InsuranceCard from './components/InsuranceCard';

function Insurance() {
  const { insuranceTitle, insuranceText, insurance } = useVedaemLandingData();

  if (!insurance || insurance.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{insuranceTitle}</Title>
        <Text dangerouslySetInnerHTML={{ __html: insuranceText ?? '' }} />

        <List>
          {insurance.map(
            (
              { image, name, description, benefits, buttonTitle, buttonLink },
              index
            ) => (
              <InsuranceCard
                key={index}
                image={image}
                name={name ?? ''}
                description={description ?? ''}
                benefits={
                  benefits && benefits.length !== 0
                    ? benefits.map(({ text }) => text ?? '')
                    : []
                }
                buttonTitle={buttonTitle ?? ''}
                buttonLink={buttonLink ?? ''}
              />
            )
          )}
        </List>
      </ContentContainer>
    </Component>
  );
}

export default Insurance;

const Component = styled.section`
  padding: 30px 0 60px;
  background: ${(props) => props.theme.gray};

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: ${(props) => props.theme.black};
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 40px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  `)}
`;
