import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useAboutData } from '@/modules/About/About.hooks';
import { colors } from '@/constants/theme';

function Quote() {
  const { quoteText, quoteDescription } = useAboutData();

  if (!quoteText && !quoteDescription) {
    return null;
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <Text>{quoteText}</Text>

          <Description>{quoteDescription}</Description>
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default Quote;

const Component = styled.section`
  padding: 40px 0 90px;

  ${media.tablet(css`
    padding: 20px 0 70px;
  `)}

  ${media.mobile(css`
    padding: 10px 0 40px;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: ${colors.black100};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
  color: ${colors.blue600};

  ${media.tablet(css`
    margin-top: 10px;
  `)}

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;
