import * as Yup from 'yup';

import { emailRegexp } from '@/utils/textInput';

import {
  EvaluationRedemptionModalFields,
  EvaluationRedemptionModalFieldsConfig,
  SelectedFieldsValues,
} from './types';

export const getInitialEvaluationRedemptionModalValues = ({
  brand,
  model,
  year,
}: SelectedFieldsValues): EvaluationRedemptionModalFields => ({
  brand,
  model,
  year,
  location: { value: '', label: '' },
  date: '',
  time: '',
  name: '',
  phone: '',
  email: '',
});

export const validationEvaluationRedemptionModalSchema = (
  fieldsConfig?: EvaluationRedemptionModalFieldsConfig
): Yup.SchemaOf<EvaluationRedemptionModalFields> => {
  return Yup.object().shape({
    brand: Yup.string().required('Выберите марку автомобиля'),
    model: Yup.string().required('Выберите модель автомобиля'),
    year: Yup.number().required('Выберите год автомобиля'),
    location: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when([], {
      is: () => fieldsConfig?.withDealers,
      then: Yup.object({
        label: Yup.string().required('Выберите автосалон'),
        value: Yup.string().required('Выберите автосалон'),
      }),
      otherwise: Yup.object({
        label: Yup.string().notRequired(),
        value: Yup.string().notRequired(),
      }),
    }),
    date: Yup.string().when([], {
      is: () => fieldsConfig?.withDatepicker,
      then: Yup.string().required('Выберите дату'),
      otherwise: Yup.string().notRequired(),
    }),
    time: Yup.string().when([], {
      is: () => fieldsConfig?.withTimepicker,
      then: Yup.string().required('Выберите время'),
      otherwise: Yup.string().notRequired(),
    }),
    name: Yup.string().required('Введите имя'),
    phone: Yup.string().required('Введите телефон'),
    email: Yup.string()
      .matches(emailRegexp, 'Email введен неверно')
      .when([], {
        is: () => fieldsConfig?.withEmail,
        then: Yup.string().required('Введите Email'),
        otherwise: Yup.string().notRequired(),
      }),
  }) as Yup.SchemaOf<EvaluationRedemptionModalFields>;
};
