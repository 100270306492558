import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { BenefitsItem } from '@/modules/OnlineShopping/OnlineShopping.types';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { media } from '@/utils/mixin';
import CallbackForm from '@/components/modals/CallbackForm';
import { handleSubmitCallbackForm } from '@/utils/forms';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';

function BenefitsListItem({
  title,
  image,
  description,
  info,
  buttonTitle,
  buttonLink,
}: BenefitsItem) {
  const openModal = useModal();

  function handleCallClick() {
    openModal(
      CallbackForm,
      {
        request: (values) => handleSubmitCallbackForm(values),
        title: 'Заказать обратный звонок',
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  }

  return (
    <Component>
      <Header>
        <Title>{title}</Title>
        <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
      </Header>
      <Body>
        <Description>{description}</Description>
        <Bottom>
          <Info>{info}</Info>
          <ButtonWrapper>
            {buttonLink ? (
              <ButtonLink to={buttonLink}>{buttonTitle}</ButtonLink>
            ) : (
              <StyledButton onClick={handleCallClick}>
                {buttonTitle}
              </StyledButton>
            )}
          </ButtonWrapper>
        </Bottom>
      </Body>
    </Component>
  );
}

export default BenefitsListItem;

const Component = styled.div`
  background: ${(props) => props.theme.white_1};
  padding: 40px 40px 60px;
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tablet(css`
    position: relative;
    padding: 20px 20px 20px 162px;
  `)}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet(css`
    align-items: flex-start;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Image = styled(Picture)`
  margin-top: 29px;

  img {
    width: 122px;
    height: 122px;
  }

  ${media.tablet(css`
    position: absolute;
    margin: -61px 0 0;
    top: 50%;
    left: 20px;
  `)}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 29px;

  ${media.tablet(css`
    margin-top: 10px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  min-height: 96px;
  color: ${(props) => props.theme.black200};

  ${media.tablet(css`
    min-height: auto;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
`;

const Info = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.blue200};
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const ButtonLink = styled(Link)`
  display: block;
  max-width: 100%;
  width: 280px;
  border-radius: 20px;
  padding: 11px 26px;
  height: 40px;
  text-align: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colors.white};
  background: ${colors.main};

  &:hover {
    background: ${colors.main100};
  }
`;

const StyledButton = styled(Button)`
  display: block;
  max-width: 100%;
  width: 280px;
  border-radius: 20px;
  padding: 11px 26px;
  height: 40px;
  transition: 0.3s;
  text-align: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colors.white};
  background: ${colors.main};

  &:hover {
    background: ${colors.main100};
  }
`;
