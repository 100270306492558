import React from 'react';
import styled, { css } from 'styled-components';

import KeyboardArrowUpIcon from '@/assets/svg/keyboard_arrow_up.svg';
import KeyboardArrowDownIcon from '@/assets/svg/keyboard_arrow_down.svg';
import { DisplayIcon } from '@/components/Collapse/Collapse';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import useCollapse from '@/hooks/useCollapse';

interface Props {
  label: string | null;
  children: React.ReactNode;

  isExpanded: boolean;

  setIsExpanded(state: boolean): void;
}

function StyledCollapseDealersList({
  children,
  label,
  isExpanded,
  setIsExpanded,
}: Props) {
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <Component>
      <Display
        {...getToggleProps({ onClick: () => setIsExpanded(!isExpanded) })}
      >
        <Title>{label ? label : ''}</Title>
        <DisplayIcon>
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </DisplayIcon>
      </Display>
      <Content {...getCollapseProps()}>
        <ContentContainer>{children}</ContentContainer>
      </Content>
    </Component>
  );
}

const Display = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 18px 20px;
  border-bottom: 1px solid ${(props) => props.theme.black100_alpha30};
  cursor: pointer;

  svg {
    fill: ${colors.main};

    path {
      fill: ${colors.main};
    }
  }

  ${media.mobile(css`
    padding: 8px 20px;
  `)}
`;

const Title = styled.span`
  text-align: center;
  height: 30px;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 18px;
    line-height: 30px;
    height: 30px;
  `)}
`;

const Content = styled.div``;

const ContentContainer = styled.div``;

const Component = styled.div``;

export default StyledCollapseDealersList;
