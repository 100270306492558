import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1340px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

export const Title = styled.p<{ isBlack?: boolean }>`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 28px;
    line-height: 33px;
  `)}

  ${({ isBlack }) =>
    isBlack &&
    css`
      color: ${colors.black};
    `}
`;
