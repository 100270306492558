import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { AllInclusiveItem } from '@/modules/Subscription/Subscription.types';
import AllInclusiveCard from '@/modules/Subscription/components/AllInclusive/components/AllInclusiveCard';
import { SliderWrapper } from '@/components/LandingWidgets';

interface Props {
  items: AllInclusiveItem[];
}

function AllInclusiveCardSlider({ items }: Props) {
  return (
    <Component>
      <SliderWrapper options={{ withoutNavs: true }}>
        {items.map(({ image, text }, index) => (
          <SwiperSlide key={index}>
            <AllInclusiveCard image={image} text={text} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default AllInclusiveCardSlider;

const Component = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
`;
