import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import BlueInfoIcon from '@/assets/svg/blue-info-icon.svg';
import CrossIcon from '@/assets/svg/cross.svg';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

export const convertToBase64 = (file: File): Promise<Nullable<string>> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result?.toString() || '');
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

type Props = {
  name: string;
  invalid?: boolean;
  image?: Nullable<ThumbnailType>;
  icon: ThumbnailType;
  label: string;
  onChange?: (value: Nullable<string>) => void;
};

const UploadSpecificPhoto = ({
  name,
  image,
  icon,
  label,
  onChange,
  invalid,
}: Props) => {
  const [value, setValue] = useState<Nullable<string>>(null);
  const [isHoveredIcon, setIsHoveredIcon] = useState(false);

  async function handleUpload(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const uploadPhoto = event.target.files[0];
      if (uploadPhoto.size === 0) return;
      const imageBase64 = await convertToBase64(uploadPhoto);

      setValue(imageBase64);
      if (onChange) {
        onChange(imageBase64);
      }
    }
  }

  const onResetClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setValue(null);
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <Component
      as={value ? 'div' : 'label'}
      withValue={value !== null}
      invalid={!!(value === null && invalid)}
    >
      {value ? (
        <UploadedContainer>
          <img loading="lazy" src={value} alt={name} />
          <CrossContainer onClick={onResetClick}>
            <CrossIcon />
          </CrossContainer>
        </UploadedContainer>
      ) : null}
      <Header>
        <Name>{name}</Name>
        {image && (
          <IconContainer
            onMouseEnter={() => setIsHoveredIcon(true)}
            onMouseLeave={() => setIsHoveredIcon(false)}
            isHoveredIcon={isHoveredIcon}
          >
            <BlueInfoIcon />
            {isHoveredIcon ? (
              <ImageHoverContainer>
                <StyledPicture {...convertThumbnailToPictureProps(image)} />
                <InformationBlock>
                  <Information>Пример</Information>
                </InformationBlock>
              </ImageHoverContainer>
            ) : null}
          </IconContainer>
        )}
      </Header>
      <ImageContainer>
        <Picture {...convertThumbnailToPictureProps(icon)} />
      </ImageContainer>
      <Label>{label}</Label>
      <Input
        type="file"
        accept="image/*"
        onChange={(event) => handleUpload(event)}
      />
    </Component>
  );
};

const Component = styled.label<{ withValue: boolean; invalid: boolean }>`
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.blackShadow100};
  padding: 20px 10px;
  position: relative;
  display: block;
  transition: 0.3s all ease;

  border: 1px solid ${(props) => (props.invalid ? 'red' : 'transparent')};
  background: ${(props) =>
    props.invalid ? 'rgba(255,0,0,0.1)' : props.theme.white_1};

  cursor: ${(props) => (props.withValue ? 'default' : 'pointer')};

  &:hover {
    background: ${(props) =>
      props.withValue ? props.theme.white_1 : props.theme.gray1200};
    border-color: transparent;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div<{ isHoveredIcon?: boolean }>`
  margin-left: 10px;
  margin-top: 5px;
  position: relative;
  cursor: pointer;

  svg {
    path {
      fill: ${(props) => (props.isHoveredIcon ? '#505F79' : '')};
    }
  }
`;

const StyledPicture = styled(Picture)`
  position: static !important;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Name = styled.span`
  font-size: 22px;
  line-height: 30px;
  display: block;
  color: ${(props) => props.theme.black};
`;

const ImageContainer = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: center;

  > div,
  img {
    width: 95px;
    height: 77px;
  }
`;

const Label = styled.span`
  color: ${colors.main};
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  display: block;
  text-align: center;
`;

const ImageHoverContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 25px;
  right: -114px;
  width: 381px;
  height: 200px;
  border-radius: 8px;
  background-color: ${colors.main};
  overflow: hidden;
  box-shadow: ${(props) => props.theme.blackShadow100};
`;

const InformationBlock = styled.div`
  background-color: #dbe5fb;
  border-radius: 13px;
  position: absolute;
  z-index: 100;
  bottom: 8px;
  right: 8px;
  padding: 6px 10px;
`;

const Information = styled.span`
  color: #3d69ea;
  font-size: 14px;
  line-height: 16px;
`;

const Input = styled.input`
  display: none;
`;

const UploadedContainer = styled.div`
  z-index: 2;
  position: absolute;
  inset: 0;
  border-radius: 8px;
  height: 100%;
  box-shadow: ${(props) => props.theme.blackShadow100};
  overflow: hidden;

  img {
    height: 100%;
    border-radius: 8px;
    width: 100%;
    object-fit: cover;
    max-width: 100%;
    display: block;
  }
`;

const CrossContainer = styled.div<{ isLoading?: boolean }>`
  position: absolute;
  background-color: rgba(216, 216, 216, 0.7);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all ease;

  &:hover {
    opacity: 0.75;
  }

  svg {
    fill: black;
    width: 19px;
    height: 19px;
  }
`;

export default UploadSpecificPhoto;
