import React from 'react';
import styled, { css } from 'styled-components';

import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { media } from '@/utils/mixin';
import { handleScrollToDomElement } from '@/utils/scroll';
import HeroBanner from '@/components/HeroBanner';

function Hero() {
  const {
    heroImage,
    heroImageMobile,
    heroTitle,
    heroBenefits,
    heroButtonText,
  } = useHelpOnTheRoadData();

  if (!heroImage) {
    return null;
  }

  return (
    <Component>
      <HeroBanner
        image={heroImage}
        imageMobile={heroImageMobile}
        title={heroTitle ?? ''}
        benefits={
          heroBenefits && heroBenefits.length !== 0
            ? heroBenefits.map(({ image, text }) => ({
                image,
                text: text ?? '',
              }))
            : []
        }
        buttonText={heroButtonText ?? ''}
        onClick={() => handleScrollToDomElement('prices')}
      />
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
