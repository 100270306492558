import { Nullable } from '@tager/web-core';

import { Package, SelectedPackage } from '@/modules/HelpOnTheRoad/types';
import { scrollToTop } from '@/utils/scroll';

export const getPackageInfoBySystemName = (
  adminPackages: Package[],
  systemName: string
): Nullable<Package> => {
  return (
    adminPackages.find(
      (packageItem) => packageItem.systemName === systemName
    ) ?? null
  );
};

export const handleBuyPackage = (
  selectedPackage: SelectedPackage,
  setSelectedPackage: (value: Nullable<SelectedPackage>) => void
) => {
  scrollToTop('auto');
  setSelectedPackage(selectedPackage);
};
