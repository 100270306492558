import React from 'react';
import styled from 'styled-components';

import { TableSectionMobileProps } from './types';

function TableSectionMobile({ title, rows }: TableSectionMobileProps) {
  if (!rows || rows.length === 0) {
    return null;
  }

  return (
    <Component>
      <Header>{title}</Header>

      <Body>
        {rows.map(({ title, param }, index) => (
          <Row key={index}>
            <Cell>{title}</Cell>
            {param && <Cell>{param}</Cell>}
          </Row>
        ))}
      </Body>
    </Component>
  );
}

export default TableSectionMobile;

const Component = styled.div``;

const Header = styled.div`
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  background: #a9d3fd;
  color: ${(props) => props.theme.black};
`;

const Body = styled.div`
  margin-top: 10px;
`;

const Row = styled.div`
  &:not(:first-child) {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #ebecf0;
  }
`;

const Cell = styled.div`
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black200};
`;
