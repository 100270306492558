import React from 'react';
import styled, { css } from 'styled-components';

import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { media } from '@/utils/mixin';
import { FeatureCards, Title } from '@/components/CarModelLandingWidgets';
import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { FeatureCardsSection } from '@/components/CarModelLandingWidgets/FeatureCards/types';

function FeaturesSections() {
  const { benefitsSectionsTitle, benefitsSections } = useCarModelLandingData();

  if (!benefitsSections || benefitsSections.length === 0) {
    return null;
  }

  const convertedBenefitsSections: FeatureCardsSection[] = benefitsSections.map(
    ({ section, benefits }) => ({
      section: section ?? '',
      cards:
        benefits && benefits.length !== 0
          ? benefits.map(
              ({ image, video, title, description, options }, index) => ({
                image,
                video: video?.url ?? '',
                title: title ?? '',
                description: description ?? '',
                isReverse: index % 2 === 1,
                options:
                  options && options.length !== 0
                    ? options.map(({ image, title, description }) => ({
                        image,
                        title: title ?? '',
                        description: description ?? '',
                      }))
                    : [],
              })
            )
          : [],
    })
  );

  return (
    <Component id="features-sections">
      <ContentContainer>
        <Title title={benefitsSectionsTitle ?? ''} />
      </ContentContainer>

      <FeatureCardsWrapper>
        <FeatureCards cards={convertedBenefitsSections} />
      </FeatureCardsWrapper>
    </Component>
  );
}

export default FeaturesSections;

const Component = styled.section`
  padding: 60px 0 30px;
`;

const FeatureCardsWrapper = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    margin: 30px 20px 0;
  `)}
`;
