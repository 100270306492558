import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import BenefitsList from '@/modules/SpecialOffer/components/Benefit/components/BenefitsList';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { media } from '@/utils/mixin';

function Benefit() {
  const { benefitTitle, benefitItems } = useSpecialOfferData();

  return (
    <Component>
      <Container>
        <TitleContainer>
          <Title>{benefitTitle}</Title>
          <InfoTitleContainer>
            <Information>x{benefitItems.length}</Information>
          </InfoTitleContainer>
        </TitleContainer>
        <BenefitsList benefitsItems={benefitItems} />
      </Container>
    </Component>
  );
}

export default Benefit;

const Component = styled.div``;

const Container = styled.div`
  margin: 40px auto 0 auto;
  max-width: 1280px;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0;
  `)}

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 130px;

  ${media.mobile(css`
    padding-left: 0;
  `)}
`;

const Title = styled.h2`
  font-size: 42px;
  line-height: 36px;
  margin-right: 30px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    margin-right: 20px;
  `)}
`;

const InfoTitleContainer = styled.div`
  border-radius: 50%;
  width: 66px;
  height: 66px;
  background-color: ${colors.yellow};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Information = styled.span`
  color: ${colors.main};
  font-size: 38px;
  font-weight: 500;
`;
