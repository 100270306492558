import React from 'react';
import styled, { css } from 'styled-components';

import { useHisunHomeData } from '@/modules/HisunHome/hooks';
import { AboutCardPrimary } from '@/components/LandingWidgets';
import { ContentContainer } from '@/modules/HisunHome/common.styles';
import { media } from '@/utils/mixin';

function About() {
  const {
    aboutImage,
    aboutMobileImage,
    aboutTitle,
    aboutTopText,
    aboutBenefits,
    aboutBottomText,
  } = useHisunHomeData();

  if (!aboutImage || !aboutTitle || !aboutTopText) {
    return null;
  }

  return (
    <Component id="about">
      <ContentContainer>
        <AboutCardPrimary
          id={4}
          image={aboutImage}
          imageMobile={aboutMobileImage}
          title={aboutTitle}
          topText={aboutTopText}
          benefits={
            aboutBenefits && aboutBenefits.length !== 0
              ? aboutBenefits.map(({ image, text }) => ({
                  image,
                  text: text ?? '',
                }))
              : []
          }
          bottomText={aboutBottomText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
