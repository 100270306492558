import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import {
  ContentContainer,
  Title,
} from '@/modules/ElectrocarsLanding/common.styles';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { useTypedSelector } from '@/store/store';
import {
  getCarBadges,
  getCarImages,
  getCarLink,
  getCarPrice,
  getCarStatus,
  getCarTags,
  handleOpenApplicationModal,
} from '@/modules/CarsStock/components/common.helpers';
import useSettingItem from '@/hooks/useSettingItem';
import { getStockCarList } from '@/store/reducers/cars-stock';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';
import { StockCarCardProps } from '@/components/StockCarCard/types';
import StockCarsList from '@/components/StockCarsList';

function StockCars() {
  const openModal = useModal();
  const {
    availableOrderTitle,
    availableOrderButtonTitle,
    availableOrderButtonLink,
  } = useElectrocarsLandingData();
  const stockCars = useTypedSelector(getStockCarList);
  const stockNewApplicationGoogleEvent = useSettingItem(
    'STOCK_NEW_APPLICATION_GOOGLE_EVENT'
  );
  const stockAmpApplicationGoogleEvent = useSettingItem(
    'STOCK_AMP_APPLICATION_GOOGLE_EVENT'
  );
  const stockPartnerCarApplicationGoogleEvent = useSettingItem(
    'STOCK_PARTNER_CAR_APPLICATION_GOOGLE_EVENT'
  );
  const phone = usePhoneNumber();

  if (
    !stockCars ||
    !stockCars.items ||
    stockCars.items.length === 0 ||
    !availableOrderButtonTitle ||
    !availableOrderButtonLink
  ) {
    return null;
  }

  const carsStockListData: StockCarCardProps[] = stockCars.items.map((car) => ({
    view: 'portrait',
    images: getCarImages(car),
    name: car.name,
    subName: car.complectation ?? '',
    price: getCarPrice(car, phone),
    tags: getCarTags(car),
    carStatus: getCarStatus(car),
    badgesList: getCarBadges(car),
    approved: car.approved,
    grade: car.grade ?? undefined,
    stockStatus: car.stockStatus ?? undefined,
    totalImagesCount: car.imagesCount,
    buttonHandle: () =>
      handleOpenApplicationModal(
        car,
        openModal,
        (car.isPartnerCar
          ? stockPartnerCarApplicationGoogleEvent
          : car.type === 'new'
          ? stockNewApplicationGoogleEvent
          : stockAmpApplicationGoogleEvent) ?? ''
      ),
    link: getCarLink(car.type, car.id),
  }));

  return (
    <Component id="availableOrder">
      <ContentContainer>
        <Title>{availableOrderTitle}</Title>

        <StockCarsListWrapper>
          <StockCarsList
            cars={carsStockListData}
            buttonTitle={availableOrderButtonTitle}
            buttonLink={availableOrderButtonLink}
          />
        </StockCarsListWrapper>
      </ContentContainer>
    </Component>
  );
}

export default StockCars;

const Component = styled.section`
  padding: 30px 0;
  background: ${colors.purpleGradient100};

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const StockCarsListWrapper = styled.div`
  margin-top: 58px;

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;
