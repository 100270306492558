import React, { useEffect, useState } from 'react';

import useTab from '@/modules/Home/hooks/useTab';
import BaseHomeSection from '@/modules/Home/components/BaseHomeSection';
import AmpItemImage from '@/assets/svg/new-sell-assist.svg?url';
import {
  CatalogPreviewAmpItem,
  Links,
  PreviewCardItem,
  TabButtons,
  TabContent,
} from '@/modules/Home/components/common.components';
import { useHomeData } from '@/modules/Home/Home.hooks';
import { getAmpCarsCount } from '@/services/requests';
import {
  StyledButton,
  StyledCarSelectionHelperDie,
} from '@/modules/Home/components/common.styles';
import { useCarSelectionHelper } from '@/hooks/useCarSelectionHelper';

function PreviewCatalog() {
  const [activeIndex, setActiveIndex] = useTab();
  const [ampCarsCount, setAmpCarsCount] = useState<number>(0);

  const ampItemData = {
    text: 'автомобилей с пробегом в нашем каталоге',
    image: { url: AmpItemImage },
    url: '/cars/amp',
    total: ampCarsCount ?? 0,
  };

  const {
    catalogTitle,
    catalogDescription,
    catalogButtonTitle,
    catalogButtonLink,
    catalogTabs,
    catalogLinks,
  } = useHomeData();
  const { die } = useCarSelectionHelper();

  useEffect(() => {
    (async () => {
      try {
        await getAmpCarsCount().then((response) =>
          setAmpCarsCount(response.count)
        );
      } catch (error) {
        console.log('error = ', error);
      }
    })();
  }, []);

  const LinksComponent = React.createElement(Links, { itemList: catalogLinks });

  const TabButtonsComponent = React.createElement(TabButtons, {
    activeIndex,
    data: catalogTabs,
    onClick: setActiveIndex,
  });

  const TabContentComponent = React.createElement(TabContent, {
    tabItemComponent: PreviewCardItem,
    tabAdditionalComponent: () => <CatalogPreviewAmpItem {...ampItemData} />,
    data: catalogTabs,
    activeIndex,
  });

  return (
    <BaseHomeSection
      sectionTitle={catalogTitle}
      sectionDescription={catalogDescription}
      Links={LinksComponent}
      TabButtons={TabButtonsComponent}
      TabContent={TabContentComponent}
      Die={die.text && <StyledCarSelectionHelperDie {...die} />}
      Button={
        <StyledButton to={catalogButtonLink}>{catalogButtonTitle}</StyledButton>
      }
    />
  );
}

export default PreviewCatalog;
