import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ArrowIcon from '@/assets/svg/arrow-right.svg';

import StepCard from '../StepCard';

import { StepsListProps } from './types';

function StepsList({ steps, cols = 4 }: StepsListProps) {
  if (!steps || steps.length === 0) {
    return null;
  }

  return (
    <Component cols={cols}>
      {steps.map((step, index) => (
        <StepCardItem key={index}>
          <StepCard {...step} />
          {(index + 1) % cols !== 0 && <StyledArrowIcon />}
        </StepCardItem>
      ))}
    </Component>
  );
}

export default StepsList;

const Component = styled.div<{ cols?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.cols}, 1fr);
  gap: 80px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 80px;
  `)}
`;

const StepCardItem = styled.div`
  position: relative;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -60px;
  width: 40px;
  height: 20px;

  ${media.tablet(css`
    display: none;
  `)}
`;
