import styled from 'styled-components';

import StepsList from '@/components/Redesign/Steps/components/StepsList';
import StepsSlider from '@/components/Redesign/Steps/components/StepsSlider';

import { StepsProps } from './types';

function Steps({ steps, columns }: StepsProps) {
  return (
    <Component>
      {steps.length > 5 ? (
        <StepsSlider steps={steps} />
      ) : (
        <StepsList steps={steps} columns={columns} />
      )}
    </Component>
  );
}

export default Steps;

const Component = styled.div``;
