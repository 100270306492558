import React from 'react';
import styled, { css } from 'styled-components';

import TrashIcon from '@/assets/svg/trash.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { ClearButtonProps } from './types';

function ClearButton({
  label = 'Очистить',
  isFullWidth,
  onClick,
}: ClearButtonProps) {
  return (
    <Component onClick={onClick} isFullWidth={isFullWidth}>
      {label}
      <StyledTrashIcon />
    </Component>
  );
}

export default ClearButton;

const Component = styled.button<{
  isFullWidth?: ClearButtonProps['isFullWidth'];
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.blue1100};

  ${media.tablet(css`
    width: 100%;
  `)}

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledTrashIcon = styled(TrashIcon)`
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  color: ${colors.blue1100};
`;
