import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import HeroSlider from './components/HeroSlider';
import SloganText from './components/SloganText';
import Benefits from './components/Benefits';
import Guarantee from './components/Guarantee';
import Cars from './components/Cars';
import SpecialVersions from './components/SpecialVersions';
import TradeIn from './components/TradeIn';
import Credit from './components/Credit';
import Form from './components/Form';
import Map from './components/Map';
import Departments from './components/Departments';

function B2bHome() {
  return (
    <Layout>
      <Component>
        <HeroSlider />
        <SloganText />
        <Benefits />
        <Guarantee />
        <Cars />
        <SpecialVersions />
        <TradeIn />
        <Credit />
        <Form />
        <Map />
        <Departments />
      </Component>
    </Layout>
  );
}

export default B2bHome;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
