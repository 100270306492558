import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { useOnlineShoppingData } from '@/modules/OnlineShopping/OnlineShopping.hooks';
import BenefitsListCollapsedItem from '@/modules/OnlineShopping/components/Benefits/components/BenefitsListCollapsedItem';

import BenefitsListItem from './components/BenefitsListItem';

function Benefits() {
  const { benefitsTitle, benefitsItems } = useOnlineShoppingData();
  const mobileMedia = useMedia('(max-width: 598.9px)');

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{benefitsTitle}</Title>

        <List>
          {benefitsItems &&
            benefitsItems.map(
              (
                { title, image, description, info, buttonTitle, buttonLink },
                index
              ) =>
                mobileMedia ? (
                  <BenefitsListCollapsedItem
                    key={index}
                    title={title}
                    image={image}
                    description={description}
                    info={info}
                    buttonTitle={buttonTitle}
                    buttonLink={buttonLink}
                  />
                ) : (
                  <BenefitsListItem
                    key={index}
                    title={title}
                    image={image}
                    description={description}
                    info={info}
                    buttonTitle={buttonTitle}
                    buttonLink={buttonLink}
                  />
                )
            )}
        </List>
      </ContentContainerSmall>
    </Component>
  );
}

export default Benefits;

const Component = styled.section`
  padding: 40px 0 60px;

  ${ContentContainerSmall} {
    padding: 0 40px;
    
    ${media.tablet(css`
      padding: 0 20px;
    `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  `)}
`;
