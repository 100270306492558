import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import TextBlock from './components/TextBlock';
import AllInclusive from './components/AllInclusive';
import About from './components/About';
import Faq from './components/Faq';

function Subscription() {
  return (
    <Layout>
      <Component>
        <Hero />
        <TextBlock />
        <AllInclusive />
        <About />
        <Faq />
      </Component>
    </Layout>
  );
}

export default Subscription;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
