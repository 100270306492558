import styled from 'styled-components';

import { colors } from '@/constants/theme';

import { TextareaFieldProps } from './types';

function TextareaField(props: TextareaFieldProps) {
  return <TextArea {...props}></TextArea>;
}

export default TextareaField;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 12px 20px 0 20px;
  color: ${colors.gray1500};
  font-size: 14px;
  line-height: 17px;
  border-radius: 10px;
  background: ${colors.white};
  resize: none;
  outline: none;
  border: none;
`;
