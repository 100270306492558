import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useOnlineShoppingData } from '@/modules/OnlineShopping/OnlineShopping.hooks';
import FaqAccordion from '@/components/FaqAccordion';

function Questions() {
  const { questionItems } = useOnlineShoppingData();

  return (
    <Component>
      <ContentContainerSmall>
        <FaqAccordion data={questionItems} />
      </ContentContainerSmall>
    </Component>
  );
}

export default Questions;

const Component = styled.section`
  padding: 60px 0;

  ${media.mobile(css`
    padding: 20px 0 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;
