import { OfflineValuationUserChoiceModel } from '@/modules/ValueCar/ValueCar.types';

import { OfflineCheckedValuesModel } from './interfaces';

export function checkIsFillOfflineValues(
  values: OfflineCheckedValuesModel
): boolean {
  for (let key in values) {
    // @ts-ignore
    if (!values[key]) return false;
  }
  return true;
}

export function getOfflineDataWithValues(
  brand = '',
  model = '',
  year = '',
  dealerId = '',
  date = '',
  time = ''
): OfflineValuationUserChoiceModel {
  return {
    brand,
    model,
    year,
    dealerId,
    date,
    time,
  };
}

export function getClearedOfflineData(): OfflineValuationUserChoiceModel {
  return {
    brand: '',
    model: '',
    year: '',
    dealerId: '',
    date: '',
    time: '',
  };
}
