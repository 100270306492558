import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { useSubscriptionData } from '@/modules/Subscription/Subscription.hooks';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

function TextBlock() {
  const { textText, textTitle, textItems } = useSubscriptionData();

  if (!textText && !textTitle) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{textTitle}</Title>

        {textText && (
          <Text dangerouslySetInnerHTML={{ __html: textText ?? '' }} />
        )}

        {textItems &&
          textItems.length !== 0 &&
          textItems.map((item, index) => {
            const { image, imageMobile, list } = item;

            return (
              <Content key={index}>
                {image && (
                  <Image
                    loading="lazy"
                    desktop={convertThumbnailToPictureImage(image)}
                    laptop={convertThumbnailToPictureImage(image)}
                    tabletSmall={convertThumbnailToPictureImage(image)}
                    mobile={convertThumbnailToPictureImage(
                      imageMobile ?? image
                    )}
                  />
                )}

                {list && list.length !== 0 && (
                  <List>
                    {list.map(({ item }, index) => (
                      <BenefitItem key={index}>
                        <BenefitIndex
                          rotate={(index + 1) * (180 / list.length)}
                        >
                          {index + 1}
                        </BenefitIndex>
                        <BenefitText
                          dangerouslySetInnerHTML={{
                            __html: item ?? '',
                          }}
                        />
                      </BenefitItem>
                    ))}
                  </List>
                )}
              </Content>
            );
          })}
      </ContentContainer>
    </Component>
  );
}

export default TextBlock;

const Component = styled.section`
  padding-top: 60px;
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    padding: 0 20px;
  `)}
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 150%;
  margin-top: 15px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 14px;
    padding: 0 20px;
  `)}
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 60px;

  ${media.mobile(css`
    flex-direction: column;
    margin-top: 30px;
  `)}

  &:not(:last-child) {
    margin-bottom: 60px;

    ${media.mobile(css`
      margin-bottom: 30px;
    `)}
  }
`;

const Image = styled(Picture)`
  position: relative;
  padding-top: 32.59%;
  flex: 1 1 586px;
  max-width: 586px;

  ${media.mobile(css`
    padding-top: 97.282%;
    width: 100%;
    flex: auto;
    max-width: none;
  `)}

  img,
  picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const List = styled.div`
  z-index: 2;
  flex: 1 1 687px;
  max-width: 687px;
  padding: 60px 50px;
  margin: 45px 0 0 -72px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tabletSmall(css`
    padding: 30px 20px;
    margin: 30px 0 0 -50px;
  `)}

  ${media.mobile(css`
    width: 100%;
    flex: auto;
    max-width: none;
    padding: 45px 20px;
    margin: -65px 0 0;
  `)}
`;

const BenefitItem = styled.div`
  display: flex;
  min-height: 55px;

  &:not(:first-child) {
    margin-top: 20px;

    ${media.tabletSmall(css`
      margin-top: 15px;
    `)}
  }
`;

const BenefitIndex = styled.div<{ rotate: number }>`
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.black};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: 1px solid ${colors.gray400};
    border-left-color: ${colors.main};
    border-bottom-color: ${colors.main};
    border-top-color: ${colors.main};
    transform: rotate(-${(props) => props.rotate}deg);
  }
`;

const BenefitText = styled.div`
  max-width: 407px;
  margin: 5px 0 0 25px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    max-width: none;
  `)}

  p {
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.black200};

    ${media.tabletSmall(css`
      font-size: 14px;
      line-height: 17px;
    `)}

    ${media.mobile(css`
      font-size: 16px;
      line-height: 19px;
    `)}
  }
`;
