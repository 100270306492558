import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import TextInput from '@/components/TextInput';
import { CheckboxAgreement } from '@/components/Checkbox';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { FormFields, FormProps } from './types';
import { handleValidateConsultationForm } from './helpers';

function Form({ setIsRequestAccept, request }: FormProps) {
  const [userAgreement, setUserAgreement] = useState(false);

  async function onSubmit(
    values: FormFields,
    formikHelpers: FormikHelpers<FormFields>
  ): Promise<void> {
    setIsRequestAccept(false);

    try {
      await request?.(values);

      setIsRequestAccept(true);
    } catch (error: any) {
      console.log(error);
      const errorMap = convertRequestErrorToMap(
        error
      ) as FormikErrors<FormFields>;
      formikHelpers.setErrors(errorMap);
    }
  }

  return (
    <Formik<FormFields>
      onSubmit={onSubmit}
      validateOnChange
      initialValues={{ name: '', phone: '' }}
      validate={handleValidateConsultationForm}
    >
      {({
        handleSubmit,
        values,
        errors,
        handleChange,
        handleBlur,
        touched,
      }) => {
        return (
          <Component onSubmit={handleSubmit}>
            <FormContainer>
              <FormContainerField>
                <TextInput
                  id="name"
                  name="name"
                  label="Имя"
                  placeholder="Имя"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    touched.name && errors.name ? errors.name : undefined
                  }
                />
              </FormContainerField>
              <FormContainerField>
                <TextInput
                  id="phone"
                  name="phone"
                  label="Телефон"
                  placeholder="+375 (__) ___-__-__"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    touched.phone && errors.phone ? errors.phone : undefined
                  }
                />
              </FormContainerField>
              <Button
                type="submit"
                disabled={
                  !values.name || Boolean(errors.phone) || !userAgreement
                }
              >
                заказать звонок
              </Button>
            </FormContainer>
            <CheckboxAgreementWrapper>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckboxAgreementWrapper>
          </Component>
        );
      }}
    </Formik>
  );
}

export default Form;

const Component = styled.form`
  position: relative;
  padding: 40px 0 55px;

  ${media.mobile(css`
    padding-bottom: 0;
  `)}
`;

const FormContainer = styled.div`
  padding: 0 28px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${media.mobile(css`
    justify-content: normal;
    flex-direction: column;
    align-items: center;
    padding: 0;
  `)}
`;

const FormContainerField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 384px;
  margin-right: 24px;

  ${media.mobile(css`
    width: 100%;
    margin-right: 0;

    &:not(:first-child) {
      margin-top: 35px;
    }
  `)}
`;

const Button = styled.button`
  padding: 11px 0;
  width: 384px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  border-radius: 8px;
  background: ${colors.main};
  color: ${colors.white};
  border: 1px solid ${colors.main};
  transition: 0.15s;

  &:hover {
    background: ${colors.main100};
    border-color: ${colors.main100};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  ${media.mobile(css`
    width: 100%;
    margin-top: 35px;
  `)}
`;

const CheckboxAgreementWrapper = styled.div`
  display: flex;
  margin: 25px 30px 0;

  ${media.mobile(css`
    margin: 25px 0 0;
  `)}
`;
