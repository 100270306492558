import React from 'react';
import styled, { css } from 'styled-components';

import { ServiceFormSecondary } from '@/components/LandingWidgets';
import { ContentContainer } from '@/modules/Contacts/common.styles';
import { useContactsData } from '@/modules/Contacts/hooks';
import { media } from '@/utils/mixin';
import { ServiceFormSecondaryFields } from '@/components/LandingWidgets/components/ServiceFormSecondary/types';
import { submitContactsRequest } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function Form() {
  const {
    formTitle,
    formSubtitle,
    formImage,
    formMobileImage,
    formButtonTitle,
    formGoogleEvent,
  } = useContactsData();

  if (!formTitle || !formImage) {
    return null;
  }

  const sendContactsForm = async (
    values: ServiceFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitContactsRequest(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ServiceFormSecondary
          title={formTitle}
          subtitle={formSubtitle ?? ''}
          image={formImage}
          buttonTitle={formButtonTitle ?? ''}
          imageMobile={formMobileImage}
          onSubmit={sendContactsForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}
`;
