import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Nullable } from '@tager/web-core';

interface UseSelectReturnType<T> {
  ref: MutableRefObject<Nullable<T>>;
  isOpened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

export const useSelect = <T extends HTMLElement>(): UseSelectReturnType<T> => {
  const [isOpened, setOpened] = useState(false);
  const ref = useRef<Nullable<T>>(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        !isOpened ||
        !ref.current ||
        (event.target instanceof Element && ref.current.contains(event.target))
      ) {
        return;
      }

      setOpened(false);
    },
    [isOpened]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick, isOpened]);

  return { ref, isOpened, setOpened };
};
