import { FormikErrors } from 'formik';

import { validators } from '@tager/web-core';

import { FormFields } from './types';

export function handleValidateConsultationForm(
  values: FormFields
): FormikErrors<FormFields> {
  const errors: FormikErrors<FormFields> = {};

  if (validators.required(values.name)) {
    errors.name = 'Введите имя';
  }

  if (!values.phone) {
    errors.phone = 'Введите телефон';
  }

  return errors;
}
