import React from 'react';
import styled, { css } from 'styled-components';

import HeroBanner from '@/components/HeroBanner';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import { media } from '@/utils/mixin';

function Hero() {
  const { heroImage, heroMobileImage, heroTitle, heroSubtitle } =
    useAutoSelectionLandingData();

  if (!heroImage) {
    return null;
  }

  return (
    <Component>
      <HeroBanner
        image={heroImage}
        imageMobile={heroMobileImage}
        title={heroTitle ?? ''}
        subtitle={heroSubtitle ?? ''}
      />
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
