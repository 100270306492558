import { submitElectrocarsLandingSelectionCar } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { CarSelectionHelperFormFields } from '@/components/modals/CarSelectionHelperModal/types';

export const handleSubmitElectrocarsLandingSelectionCar = async (
  values: CarSelectionHelperFormFields,
  event?: string
): Promise<void> => {
  try {
    await submitElectrocarsLandingSelectionCar(values).then(() => {
      if (event) {
        googleEvent(event);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};
