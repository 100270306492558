import React from 'react';
import styled from 'styled-components';

import TableSectionMobile from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/TableSectionMobile';
import { useHelpOnTheRoadContext } from '@/modules/HelpOnTheRoad/hooks';

import { useTableParamsData } from './hooks';

function TableParams() {
  const { selectedPackageTab } = useHelpOnTheRoadContext();
  const params = useTableParamsData(Number(selectedPackageTab.value));

  if (!params || params.length === 0) {
    return null;
  }

  return (
    <Component>
      {params.map((param, index) => (
        <TableSectionMobileWrapper key={index}>
          <TableSectionMobile {...param} />
        </TableSectionMobileWrapper>
      ))}
    </Component>
  );
}

export default TableParams;

const Component = styled.div`
  flex: 1;
`;

const TableSectionMobileWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;
