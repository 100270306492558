import { Page } from '@/typings/page';
import useCurrentPage from '@/hooks/useCurrentPage';
import { OptionType } from '@/typings/common';
import { useTypedSelector } from '@/store/store';
import { getStockBrandsAndModels } from '@/store/reducers/cars-stock';
import { getCatalogStockBrandDealerIds } from '@/modules/Catalog/Catalog.helper';

import { Subscription } from './Subscription.types';

export function useSubscriptionData(): Page<Subscription>['templateFields'] {
  return useCurrentPage<Page<Subscription>>().templateFields;
}

export function useBrandsOptions(): OptionType[] {
  const brands = useTypedSelector(getStockBrandsAndModels);
  const { aboutBrands } = useSubscriptionData();

  if (
    !brands ||
    brands.length === 0 ||
    !aboutBrands ||
    aboutBrands.length === 0
  ) {
    return [];
  }

  return brands
    .filter((brand) => aboutBrands.includes(String(brand.id)))
    .map((brand) => ({
      label: brand.name,
      value: getCatalogStockBrandDealerIds(brands, brand.id).join(','),
    }));
}
