import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';

function BottomLinkComponent() {
  const [scrollY, setScrollY] = useState(0);

  const handleSetScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSetScroll);

    return () => window.removeEventListener('scroll', handleSetScroll);
  }, []);

  return (
    <Component isVisible={scrollY > 60}>
      <ButtonWrapper>
        <ButtonLink to="/catalog">В каталог</ButtonLink>
      </ButtonWrapper>
    </Component>
  );
}

export default BottomLinkComponent;

const Component = styled.div<{ isVisible: boolean }>`
  display: none;
  width: 100%;
  padding: 15px;
  position: fixed;
  z-index: 100;
  bottom: 0;
  box-shadow: ${(props) => props.theme.blackShadow100};
  background: ${(props) => props.theme.white_1};

  ${({ isVisible }) =>
    isVisible &&
    css`
      display: block;
    `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLink = styled(Link)`
  display: block;
  max-width: 100%;
  width: 280px;
  border-radius: 20px;
  padding: 11px 26px;
  height: 40px;
  text-align: center;

  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${colors.white};
  background: ${colors.main};

  &:hover {
    background: ${colors.main100};
  }
`;
