import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { SliderWrapper } from '@/components/LandingWidgets';

import Charger from '../Charger';

import { ChargerSliderProps } from './types';

function ChargerSlider({ id, chargers }: ChargerSliderProps) {
  if (!chargers || chargers.length === 0) {
    return null;
  }

  const isNoSwiping = chargers.length === 1;

  return (
    <SliderWrapper
      id={id}
      options={{
        spaceBetween: 20,
        noSwipingClass: 'swiper-no-swiping',
        withoutNavs: true,
        withoutPagination: true,
      }}
    >
      {chargers.map((charger, index) => (
        <SwiperSlide
          key={index}
          className={isNoSwiping ? 'swiper-no-swiping' : ''}
        >
          <Charger {...charger} />
        </SwiperSlide>
      ))}
    </SliderWrapper>
  );
}

export default ChargerSlider;
