import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import ArrowIcon from '@/assets/svg/arrow-left-40px.svg';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

import { HeroBannerProps } from './types';

export function HeroBanner({
  image,
  brand,
  model,
  backLink,
  actualityText,
}: HeroBannerProps) {
  return (
    <Component>
      <Banner>
        <Background
          loading="lazy"
          desktop={convertThumbnailToPictureImage(image.desktop)}
          laptop={convertThumbnailToPictureImage(image.laptop ?? image.desktop)}
          tabletSmall={convertThumbnailToPictureImage(
            image.tablet ?? image.laptop ?? image.desktop
          )}
          mobile={convertThumbnailToPictureImage(
            image.mobile ?? image.tablet ?? image.laptop ?? image.desktop
          )}
          alt={`${brand} ${model}`}
        />

        <Content>
          {backLink && (
            <BackLink to={backLink}>
              <StyledArrowIcon /> В каталог
            </BackLink>
          )}

          <CarInfo>
            <Brand>{brand}</Brand> <Model>{model}</Model>
          </CarInfo>
        </Content>
      </Banner>

      {actualityText && (
        <Actuality>
          <ActualityText>{actualityText}</ActualityText>
        </Actuality>
      )}
    </Component>
  );
}

const Component = styled.div``;

const Banner = styled.div`
  position: relative;
  padding-top: 33.5%;

  ${media.mobile(css`
    padding-top: 92.9%;
  `)}
`;

const Background = styled(Picture)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 40px;
  padding: 0 40px;

  @media (max-width: 1400px) {
    padding: 0 20px;
  }

  ${media.mobile(css`
    top: 20px;
  `)}
`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: 13px;
  height: 13px;
  margin-right: 5px;
  transition: 0.3s;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  `)}

  &:hover {
    color: ${colors.main};

    ${StyledArrowIcon} {
      fill: ${colors.main};
    }
  }
`;

const CarInfo = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const Brand = styled.span`
  font-size: 24px;
  line-height: 32px;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 16px;
    line-height: 24px;
  `)}
`;

const Model = styled.span`
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 36px;
  `)}
`;

const Actuality = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 40px;
  margin-top: 10px;

  @media (max-width: 1400px) {
    padding: 0 20px;
  }
`;

const ActualityText = styled.p`
  font-size: 19px;
  line-height: 150%;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 16px;
  `)}
`;
