import React, { useState } from 'react';

import { Nullable, useMedia } from '@tager/web-core';

import LocationsMapDesktop from '@/components/LocationsMap/LocationsMap.desktop';
import LocationsMapMobile from '@/components/LocationsMap/LocationsMap.mobile';

import { Props } from './LocationsMap.typings';

const LocationsMap = (props: Props) => {
  const { selectedId, onSelect, ...rest } = props;
  const isMobileMode = useMedia(`(max-width: 1023px)`);

  const [activeId, setActiveId] = useState<Nullable<number>>(
    selectedId || null
  );

  const onChangeActiveHandler = (id: number) => {
    setActiveId(id);
    if (onSelect) {
      onSelect(id);
    }
  };

  if (isMobileMode) {
    return (
      <LocationsMapMobile
        activeId={activeId}
        setActiveId={onChangeActiveHandler}
        {...rest}
      />
    );
  } else {
    return (
      <LocationsMapDesktop
        activeId={activeId}
        setActiveId={onChangeActiveHandler}
        {...rest}
      />
    );
  }
};

export default LocationsMap;
