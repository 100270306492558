import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/ServiceValuationLanding/common.styles';
import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import { media } from '@/utils/mixin';
import ConsultationFormSecondary from '@/components/Forms/ConsultationFormSecondary';
import { useTypedSelector } from '@/store/store';
import { getServiceBrands, getServiceDealers } from '@/store/reducers/service';
import { getServicePageOptions } from '@/modules/Service/common.helpers';
import { submitServiceValuationLandingConsultation } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { ConsultationFormSecondaryFields } from '@/components/Forms/ConsultationFormSecondary/types';

function ConsultationForm() {
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);
  const {
    templateFields: {
      consultationFormImage,
      consultationFormImageMobile,
      consultationFormTitle,
      consultationFormButtonText,
      consultationFormGoogleEvent,
      consultationFormIsShowMark,
      consultationFormIsShowAutoCenter,
    },
  } = useServiceValuationLandingData();

  if (
    !consultationFormImage ||
    !consultationFormTitle ||
    !consultationFormButtonText
  ) {
    return null;
  }

  const { brands, dealers } = getServicePageOptions(
    serviceDealers,
    serviceBrands
  );

  const handleSubmitConsultationForm = async (
    values: ConsultationFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitServiceValuationLandingConsultation({
        ...values,
        brand: values.brand?.value,
        dealer: Number(values.dealer?.value) || undefined,
      }).then(() => {
        if (consultationFormGoogleEvent) {
          googleEvent(consultationFormGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ConsultationFormSecondary
          image={consultationFormImage}
          imageMobile={consultationFormImageMobile}
          title={consultationFormTitle ?? ''}
          buttonText={consultationFormButtonText ?? ''}
          brands={brands}
          dealers={dealers}
          fieldsConfig={{
            withBrands: !consultationFormIsShowMark,
            withDealers: !consultationFormIsShowAutoCenter,
            withMessage: true,
          }}
          onSubmit={handleSubmitConsultationForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default ConsultationForm;

const Component = styled.section`
  padding: 60px 0;

  ${media.tablet(css`
    padding: 40px 0;
  `)}
`;
