import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useAboutData } from '@/modules/About/About.hooks';
import BrandsCard from '@/components/BrandsCard';
import { colors } from '@/constants/theme';

function Brands() {
  const { brandsNumber, brandsName, brandsText, brandsItems } = useAboutData();

  if (!brandsItems || brandsItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <Left>
            <Number>{brandsNumber}</Number>
            <Name>{brandsName}</Name>
            <Text>{brandsText}</Text>
          </Left>
          <Right>
            <Cards>
              {brandsItems.map(({ image, name }, index) => (
                <BrandsCard key={index} image={image} name={name ?? ''} />
              ))}
            </Cards>
          </Right>
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default Brands;

const Component = styled.section`
  margin: 150px 0;
  background-color: ${colors.gray200};

  ${media.tablet(css`
    margin: 0;
    padding: 40px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 340px;
  max-width: 340px;
  padding: 60px 0 108px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    padding: 0;
  `)}
`;

const Number = styled.span`
  font-weight: 700;
  font-size: 72px;
  line-height: 88px;
  color: ${colors.black100};

  ${media.tablet(css`
    font-size: 40px;
    line-height: 52px;
  `)}
`;

const Name = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;
  `)}
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  color: ${colors.blue600};

  ${media.tablet(css`
    margin-top: 0;
  `)}
`;

const Right = styled.div`
  flex: 1 1 800px;
  max-width: 800px;
  margin: -85px 0 -85px 40px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    margin: 20px 0 0;
  `)}
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: ${colors.white};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tablet(css`
    box-shadow: none;
  `)};

  @media (max-width: 589px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
