import React from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import ArrowIcon from '@/assets/svg/arrow-left-40px.svg';
import { media } from '@/utils/mixin';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import CloseButton from '@/components/modals/shared/CloseButton';

import { OptionsModalProps } from './types';

SwiperCore.use([Navigation]);

function OptionsModal({ innerProps, closeModal }: OptionsModalProps) {
  const { options, activeSlideIndex = 0 } = innerProps;
  const isMoreThanOneSlide = options.length > 1;

  useLockBodyScroll();

  return (
    <Component>
      <CloseButton onClick={closeModal} />

      <Swiper
        slidesPerView={1}
        loop={true}
        speed={600}
        spaceBetween={10}
        navigation={{
          prevEl: `.swiper-prev`,
          nextEl: `.swiper-next`,
        }}
        initialSlide={activeSlideIndex}
        noSwipingClass={'swiper-no-swiping'}
      >
        {options.map(({ title, description, image }, index) => (
          <SwiperSlide
            key={index}
            className={options.length <= 1 ? 'swiper-no-swiping' : ''}
          >
            <Content>
              <Information>
                <GoBackButton onClick={closeModal}>
                  <ArrowIcon />
                  Смотреть все особенности
                </GoBackButton>
                <Title>{title}</Title>
                <Description>{description}</Description>
              </Information>

              <Image
                loading="lazy"
                {...convertThumbnailToPictureProps(image)}
                alt={title}
              />
            </Content>
          </SwiperSlide>
        ))}
      </Swiper>

      {isMoreThanOneSlide && (
        <NavButtonsWrapper>
          <NavButton className="swiper-prev" prev>
            <ArrowIcon />
          </NavButton>

          <NavButton className="swiper-next" next>
            <ArrowIcon />
          </NavButton>
        </NavButtonsWrapper>
      )}
    </Component>
  );
}

export default OptionsModal;

const Component = styled.div`
  position: relative;
  width: calc(100vw - 20px);
  max-width: 1200px;

  .swiper-slide {
    height: auto;
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  background: ${(props) => props.theme.white};

  ${media.mobile(css`
    flex: 1;
    flex-direction: column-reverse;
    justify-content: flex-end;
  `)}
`;

const Information = styled.div`
  padding: 24px 20px 44px;
  max-width: 410px;

  ${media.mobile(css`
    max-width: none;
  `)}
`;

const GoBackButton = styled.button`
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.black};

  svg {
    width: 11px;
    height: 11px;
    margin-right: 4px;
    fill: ${(props) => props.theme.black};
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-top: 24px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)};
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 18px;
  color: ${(props) => props.theme.black};
`;

const Image = styled(Picture)`
  flex: 1;
  padding-top: 41.667%;

  ${media.mobile(
    css`
      flex: none;
      padding-top: 78.874%;
    `
  )}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const NavButtonsWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;
  display: flex;
  justify-content: space-between;
  width: 150px;
`;

const NavButton = styled.button<{
  next?: boolean;
  prev?: boolean;
}>`
  z-index: 2;
  display: flex;

  svg {
    width: 30px;
    height: 30px;
    fill: ${(props) => props.theme.black};
  }

  ${({ next }) =>
    next &&
    css`
      transform: rotate(-180deg);
    `};
`;
