import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

import { StepCardProps } from './types';

function StepCard({ image, text }: StepCardProps) {
  return (
    <Component>
      <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </Component>
  );
}

export default StepCard;

export const Component = styled.div`
  padding: 40px 30px 30px;
  border-radius: 10px;
`;

const Image = styled(Picture)`
  picture,
  img {
    width: 90px;
    height: 90px;
  }

  img {
    object-fit: cover;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  a {
    font-weight: 500;
    color: ${colors.main};
    text-decoration: underline;

    &:hover {
      color: ${colors.main100};
      text-decoration: none;
    }
  }
`;
