import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { numbers } from '@/modules/Atlantm30/utils';
import { handleScrollToDomElement } from '@/utils/scroll';
import ArrowIcon from '@/assets/svg/atlantm30/arrow.svg';

import { StepProps } from './types';

function Step({ title, text, index }: StepProps) {
  return (
    <Component onClick={() => handleScrollToDomElement(`detailStep-${index}`)}>
      <StepImageWrapper>{numbers[index]}</StepImageWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <StyledArrowIcon />
    </Component>
  );
}

export default Step;

const Component = styled.div`
  cursor: pointer;
  position: relative;
  padding: 40px 0 0 50px;
`;

const StepImageWrapper = styled.div`
  svg {
    position: absolute;
    top: 0;
    left: -10px;
    max-height: 180px;

    ${media.tabletSmall(css`
      max-height: 150px;
      left: -15px;
    `)}

    ${media.mobile(css`
      max-height: 100px;
      top: -30px;
      left: -25px;
    `)}
  }
`;

const Title = styled.p`
  position: relative;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  max-width: 270px;
  color: ${colors.blue700};

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.mobile(css`
    font-size: 32px;
    line-height: 42px;
    max-width: none;
  `)}
`;

const Text = styled.p`
  position: relative;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.main};
`;

const StyledArrowIcon = styled(ArrowIcon)`
  position: absolute;
  top: 0;
  right: 0;

  ${media.mobile(css`
    transform: rotate(90deg);
    top: initial;
    bottom: -55px;
  `)}
`;
