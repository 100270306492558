import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useIsomorphicLayoutEffect } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import ArrowIcon from '@/assets/svg/arrow-down.svg';

import { BenefitCardProps } from './types';

function BenefitCard({ title, list, image, isMobile }: BenefitCardProps) {
  const [isOpened, setOpened] = useState(false);

  useIsomorphicLayoutEffect(() => {
    setOpened(!isMobile);
  }, [isMobile]);

  const handleOpenCard = () => {
    if (!isMobile) {
      return;
    }

    setOpened(!isOpened);
  };

  return (
    <Component isMobile={isMobile} isOpened={isOpened} withImage={!!image}>
      <Header isMobile={isMobile} isOpened={isOpened} onClick={handleOpenCard}>
        {title}
        {isMobile && <ArrowIcon />}
      </Header>

      {isOpened && (
        <Content>
          <List>
            {list.map((item, index) => (
              <ListItem key={index} isMobile={isMobile}>
                {item}
              </ListItem>
            ))}
          </List>

          {image && (
            <Image
              loading="lazy"
              {...convertThumbnailToPictureProps(image)}
              isMobile={isMobile}
            />
          )}
        </Content>
      )}
    </Component>
  );
}

export default BenefitCard;

const Component = styled.div<{
  isMobile?: boolean;
  isOpened?: boolean;
  withImage?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 39px 32px;
  background: ${colors.white};
  box-shadow: 0 2px 17px rgba(0, 0, 0, 0.15);
  border-radius: 15px;

  ${({ withImage }) =>
    withImage &&
    css`
      min-height: 276px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 20px;
      min-height: auto;
    `}

  ${({ isMobile, isOpened, withImage }) =>
    isMobile &&
    isOpened &&
    withImage &&
    css`
      min-height: 290px;
    `}
`;

const Header = styled.div<{ isMobile?: boolean; isOpened?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: ${colors.blue100};

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 23px;
      line-height: 27px;

      svg {
        flex: 0 0 15px;
        width: 15px;
        height: 15px;
        transform: rotate(-90deg);
        margin: 5px 0 0 20px;

        path {
          fill: ${colors.blue100};
        }
      }
    `}

  ${({ isMobile, isOpened }) =>
    isMobile &&
    isOpened &&
    css`
      svg {
        transform: rotate(0deg);
      }
    `}
`;

const Content = styled.div`
  flex: 1;
  position: relative;
  margin-top: 20px;
`;

const List = styled.div``;

const ListItem = styled.p<{ isMobile?: boolean }>`
  position: relative;
  font-size: 16px;
  line-height: 19px;
  padding-left: 45px;
  color: ${colors.blue600};

  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 30px;
    height: 2px;
    border-radius: 20px;
    background: ${colors.blue100};
  }

  &:not(:first-child) {
    margin-top: 15px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-left: 35px;

      &:before {
        width: 20px;
      }
    `}
`;

const Image = styled(Picture)<{ isMobile?: boolean }>`
  position: absolute;
  right: -32px;
  bottom: -39px;
  width: 80px;
  height: 80px;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      right: -20px;
      bottom: -20px;
    `}
`;
