import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ScrollStyles } from '@/components/styles';

interface Props {
  body: string;
}

function Static({ body }: Props) {
  return <Component dangerouslySetInnerHTML={{ __html: body }} />;
}

export default Static;

const Component = styled.div`
  color: ${(props) => props.theme.black};

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 35px;

    ${media.tablet(css`
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    `)}
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    margin: 40px 0 30px;

    ${media.tablet(css`
      font-size: 22px;
      line-height: 24px;
      margin: 34px 0 20px;
    `)}

    ${media.tabletSmall(css`
      font-size: 22px;
      line-height: 26px;
      margin: 40px 0 30px;
    `)}
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 1.5vw;
    word-break: break-word;

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 2vw;
    `)}
  }

  figure {
    width: 100% !important;
    margin: 20px 0;
    overflow: auto;

    ${ScrollStyles}

    table {
      width: 100%;
      min-width: 1200px;

      border-collapse: collapse;
      border: 1px solid ${colors.gray400};

      tbody {
        tr {
          th,
          td {
            min-width: 190px;
            padding: 5px;
            border: 1px solid ${colors.gray400};

            &:not(:last-child) {
              border-bottom-width: 0;
            }

            p {
              text-align: left !important;
              margin: 0;
            }
          }
        }
      }
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: ${(props) => props.theme.main};
    transition: all 0.3s linear;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    margin: 30px auto;
    max-width: 100%;
    object-fit: cover;
    overflow: hidden;

    ${media.tablet(css`
      margin: 24px auto;
    `)}

    ${media.tabletSmall(css`
      margin: 30px auto;
    `)}
    
    & + figcaption {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;

      ${media.tablet(css`
        font-size: 12px;
        line-height: 16px;
        margin-top: 16px;
      `)}

      ${media.tabletSmall(css`
        font-size: 16px;
        line-height: 30px;
        margin-top: 20px;
      `)}
    }
  }

  ul,
  ol {
    margin: 20px 0 20px 16px;

    ${media.mobile(css`
      margin: 20px 0 20px 10px;
    `)}
  }

  ul {
    li {
      position: relative;
      padding-left: 18px;
      font-size: 16px;
      line-height: 24px;

      ${media.tablet(css`
        padding-left: 16px;
        font-size: 14px;
        line-height: 20px;
      `)}

      &:not(:first-child) {
        margin-top: 25px;

        ${media.tablet(css`
          margin-top: 15px;
        `)}
      }

      &:before {
        position: absolute;
        content: '';
        top: 10px;
        left: 0;
        width: 5px;
        height: 5px;
        background: ${(props) => props.theme.black};

        ${media.tablet(css`
          top: 8px;
        `)}
      }
    }
  }

  ol {
    counter-reset: list;

    li {
      position: relative;
      padding-left: 13px;
      font-size: 16px;
      line-height: 24px;

      ${media.tablet(css`
        padding-left: 7px;
        font-size: 14px;
        line-height: 20px;
      `)}

      &:not(:first-child) {
        margin-top: 25px;

        ${media.tablet(css`
          margin-top: 15px;
        `)}
      }

      &:before {
        position: absolute;
        counter-increment: list;
        content: counter(list) '.';
        top: 0;
        left: -18px;
      }
    }
  }

  *:last-child {
    margin-bottom: 0;
  }

  *:first-child {
    margin-top: 0;
  }
`;
