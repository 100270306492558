import React from 'react';

import Layout from '@/layout';

import { HelpOnTheRoadProvider } from './HelpOnTheRoadProvider';
import Main from './components/Main';

function HelpOnTheRoad() {
  return (
    <Layout>
      <HelpOnTheRoadProvider>
        <Main />
      </HelpOnTheRoadProvider>
    </Layout>
  );
}

export default HelpOnTheRoad;
