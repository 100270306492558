import React from 'react';
import styled from 'styled-components';

import { FaqAccordionProps } from './types';
import FaqAccordionItem from './components/FaqAccordionItem';

function FaqAccordion({ questions }: FaqAccordionProps) {
  return (
    <FaqAccordionList>
      {questions.map(({ question, answer }, index) => (
        <FaqAccordionItem
          key={`${index}-faq`}
          question={question}
          answer={answer}
        />
      ))}
    </FaqAccordionList>
  );
}

export default FaqAccordion;

const FaqAccordionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
