import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Gallery from '@/components/Redesign/Gallery';
import { useRefitSingleLanding } from '@/modules/RefitSingleLanding/hooks';
import {
  ContentContainer,
  Title,
} from '@/modules/RefitSingleLanding/common.styles';

function MainGallery() {
  const {
    templateFields: { galleryImages, galleryTitle },
  } = useRefitSingleLanding();

  if (!galleryTitle || !galleryImages || galleryImages.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{galleryTitle}</Title>

        <GalleryWrapper>
          <Gallery title={galleryTitle} gallery={galleryImages} />
        </GalleryWrapper>
      </ContentContainer>
    </Component>
  );
}

export default MainGallery;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const GalleryWrapper = styled.div`
  margin-top: 20px;
`;
