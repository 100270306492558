import styled from 'styled-components';

import TickIcon from '@/assets/svg/tick.svg';
import { colors } from '@/constants/theme';

import { TickProps } from './types';

function Tick({ text }: TickProps) {
  return (
    <Component>
      <SvgWrapper>
        <TickIcon />
      </SvgWrapper>
      <Text>{text}</Text>
    </Component>
  );
}

export default Tick;

const Component = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const SvgWrapper = styled.div`
  display: flex;
  min-width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.black};
`;
