import React from 'react';
import styled, { css } from 'styled-components';

import ServiceCard from '@/components/ServiceCard';
import {
  useServiceContext,
  useServicesData,
} from '@/modules/Services/Services.hooks';
import { handleScrollToDomElement } from '@/utils/scroll';
import { media } from '@/utils/mixin';

function ServicesList() {
  const data = useServicesData();
  const { setSelectedValue } = useServiceContext();

  if (!data.servicesBlockItems || !data.servicesBlockItems.length) {
    return null;
  }

  function handleClickServiceCardButton(option: string) {
    if (!!data.consultationOptions.find((item) => item.option === option)) {
      setSelectedValue({ value: option, label: option });
    }

    handleScrollToDomElement('id-consultation-container');
  }

  return (
    <Component>
      <Title>{data.servicesBlockTitle ?? ''}</Title>
      <Container>
        {data.servicesBlockItems.map((item, index) => {
          return (
            <ItemWrapper key={index}>
              <ServiceCard
                image={item.image}
                title={item.title ?? ''}
                description={item.description ?? ''}
                onClick={() => handleClickServiceCardButton(item.option ?? '')}
              />
            </ItemWrapper>
          );
        })}
      </Container>
    </Component>
  );
}

export default ServicesList;

const Component = styled.div`
  max-width: 1260px;
  margin: 60px auto 0;
  padding: 0 30px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  margin: 20px 0;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    margin: 20px 0 10px;
    font-size: 32px;
    line-height: 38px;
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -40px -20px;

  ${media.mobile(css`
    margin: -20px -20px;
  `)}
`;

const ItemWrapper = styled.div`
  padding: 40px 20px;
  flex: 0 0 33.3333%;

  ${media.tablet(css`
    flex: 0 0 50%;
  `)}

  ${media.mobile(css`
    flex: 0 0 100%;
    padding: 20px 20px;
  `)}
`;
