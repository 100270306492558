import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';
import { media } from '@/utils/mixin';

import BenefitItem from './components/BenefitItem';

function Advantages() {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;

  return (
    <Component>
      {pageFields.bottomBlockItems.map((item, index) => (
        <BenefitItemWrapper key={index}>
          <BenefitItem icon={item.icon} title={item.title} text={item.text} />
        </BenefitItemWrapper>
      ))}
    </Component>
  );
}

export default Advantages;

const Component = styled.div`
  padding: 0 20px;
`;

const BenefitItemWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 56px;
  }

  ${media.tabletSmall(css`
    display: flex;
    justify-content: center;
  `)}
`;
