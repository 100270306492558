import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/SollersHome/common.styles';
import { media } from '@/utils/mixin';
import BenefitsCards from '@/components/BenefitsCards';
import { Title } from '@/components/LandingWidgets';

import { useSollersHomeData } from '../../hooks';

function Benefits() {
  const { benefits, benefitsTitle } = useSollersHomeData();

  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title title={benefitsTitle ?? ''} />

        <List>
          <BenefitsCards
            id={2}
            cards={benefits.map(({ image, title, text }) => ({
              image,
              title: title ?? '',
              text: text ?? '',
            }))}
          />
        </List>
      </ContentContainer>
    </Component>
  );
}

export default Benefits;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
    overflow: hidden;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const List = styled.div`
  margin-top: 20px;
`;
