import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { useZeekrHomeData } from '@/modules/ZeekrHome/hooks';
import { Banner } from '@/components/LandingWidgets';

function Models() {
  const { models } = useZeekrHomeData();

  if (!models || models.length === 0) {
    return null;
  }

  return (
    <Component id="models">
      {models.map(
        (
          { image, mobileImage, title, subtitle, buttonText, buttonLink },
          index
        ) => (
          <Banner
            key={index}
            image={image}
            mobileImage={mobileImage}
            title={title ?? ''}
            subtitle={subtitle ?? ''}
            buttonText={buttonText ?? ''}
            buttonLink={buttonLink ?? ''}
          />
        )
      )}
    </Component>
  );
}

export default Models;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(
    css`
      padding-bottom: 20px;
    `
  )}
`;
