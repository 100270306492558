import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import AdvantageItem from '../AdvantageItem';

import { AdvantagesListProps } from './types';

function AdvantagesList({
  advantages,
  columns = 5,
  isBig,
}: AdvantagesListProps) {
  return (
    <Component columns={columns}>
      {advantages.map((advantage, index) => (
        <AdvantageItem key={index} {...advantage} isBig={isBig} />
      ))}
    </Component>
  );
}

export default AdvantagesList;

const Component = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ columns }) => (columns > 6 ? 6 : columns)},
    1fr
  );
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: 1fr;
    gap: 10px;
  `)}
`;
