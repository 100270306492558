import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { Title } from '@/components/LandingWidgets';
import ServiceDealers from '@/components/ServiceDealers';
import { handleScrollToDomElement } from '@/utils/scroll';

function Dealers() {
  const { dealersTitle, dealers, dealersButtonTitle, dealersButtonLink } =
    useTopLevelFunnelLandingData();

  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title title={dealersTitle ?? ''} />

        <ServiceDealersWrapper>
          <ServiceDealers
            id={8}
            dealers={dealers.map(
              ({
                image,
                name,
                address,
                workTime,
                phone,
                brandsTitle,
                brands,
                buttonTitle,
                latitude,
                longitude,
              }) => ({
                image,
                name: name ?? '',
                address: address ?? '',
                workTime: workTime ?? '',
                phone: phone ?? '',
                brandsTitle: brandsTitle ?? '',
                brands:
                  brands && brands.length !== 0
                    ? brands.map((brand) => brand.name ?? '')
                    : [],
                buttonTitle: buttonTitle ?? '',
                lat: Number(latitude),
                lng: Number(longitude),
                onClick: () => handleScrollToDomElement('consultation'),
              })
            )}
            options={{ initialTab: 'map' }}
            buttonTitle={dealersButtonTitle ?? ''}
            buttonLink={dealersButtonLink ?? ''}
          />
        </ServiceDealersWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Dealers;

const Component = styled.section`
  padding: 15px 0;

  ${media.tablet(css`
    padding: 10px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const ServiceDealersWrapper = styled.div`
  margin-top: 20px;
`;
