import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap, useMedia } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { Button } from '@/components/Redesign/Button';
import SuccessForm from '@/components/Forms/SuccessForm';
import CheckboxAgreement from '@/components/Checkbox/CheckboxAgreement';
import TextField from '@/components/Redesign/TextField';
import TextareaField from '@/components/Redesign/TextareaField';

import { CarCheckFormProps, CarCheckFormValues } from './types';
import { initialCarCheckFormValues, validationSchema } from './helpers';

function CarCheckForm({
  title,
  fieldsConfig,
  image,
  mobileImage,
  onSubmit,
  isAbsoluteImage,
  isCenterImage,
}: CarCheckFormProps) {
  const isTablet = useMedia('(max-width: 1259px)');
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setErrors,
  } = useFormik<CarCheckFormValues>({
    initialValues: initialCarCheckFormValues,
    validationSchema: validationSchema(fieldsConfig),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onSubmit?.(values).then(() => {
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<CarCheckFormValues>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  return (
    <Component>
      {!isFormSend ? (
        <InfoWrapper>
          <Title>{title}</Title>

          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <TextField
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Имя"
              isRequired
              errorMessage={
                errors.name && touched.name ? errors.name : undefined
              }
            />
            <TextField
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              placeholder="+375 (__) ___-__-__"
              isRequired
              errorMessage={
                errors.phone && touched.phone ? errors.phone : undefined
              }
            />

            {fieldsConfig?.withBrand && (
              <TextField
                id="brand"
                name="brand"
                value={values.brand}
                onChange={handleChange}
                placeholder="Марка автомобиля"
                isRequired
                errorMessage={
                  errors.brand && touched.brand ? errors.brand : undefined
                }
              />
            )}

            {fieldsConfig?.withModel && (
              <TextField
                id="model"
                name="model"
                value={values.model}
                onChange={handleChange}
                placeholder="Модель автомобиля"
                isRequired
                errorMessage={
                  errors.model && touched.model ? errors.model : undefined
                }
              />
            )}

            {fieldsConfig?.withMessage && (
              <TextAreaWrapper>
                <TextareaField
                  id="message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  placeholder="Укажите важную на ваш взгляд информацию, например, о приобретении авто в официальном дилерском центре"
                />
              </TextAreaWrapper>
            )}

            <CheckboxWrapper>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckboxWrapper>

            <ButtonWrapper>
              <Button
                size={'big'}
                variant={'contained'}
                type="submit"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                Отправить
              </Button>
            </ButtonWrapper>
          </Form>

          <Image
            isAbsoluteImage={isAbsoluteImage}
            isCenterImage={isCenterImage}
            loading="lazy"
            {...convertThumbnailToPictureImage(isTablet ? mobileImage : image)}
          />
        </InfoWrapper>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default CarCheckForm;

const Component = styled.div`
  border-radius: 10px;
  background: ${colors.purpleGradient200};
  padding: 40px 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  ${media.tablet(css`
    justify-content: flex-end;
  `)}
`;

const ButtonWrapper = styled.div`
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  ${media.tablet(css`
    justify-content: flex-end;
  `)}
`;

const Form = styled.form`
  max-width: 65.85%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px 10px;
  padding: 0 30px;

  ${media.tablet(css`
    order: 1;
    max-width: 100%;
    padding: 0 20px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
  `)}
`;

const Title = styled.h2`
  width: 100%;
  padding: 0 30px;
  color: ${colors.black};
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;

  ${media.tablet(css`
    margin-bottom: 50px;
    padding: 0 20px;
  `)}
`;

const Image = styled(Picture)<{
  isAbsoluteImage?: boolean;
  isCenterImage?: boolean;
}>`
  display: block;
  width: clamp(260px, 32.92%, 100%);

  ${({ isCenterImage }) =>
    isCenterImage &&
    css`
      ${media.tablet(css`
        width: 100%;
      `)}
    `}

  img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 25px;

    ${(props) =>
      props.isAbsoluteImage &&
      css`
        position: absolute;
        bottom: -40px;
        margin-bottom: 0;

        ${media.tablet(css`
          position: static;
          margin-bottom: 25px;
        `)}
      `}
  }
`;

const TextAreaWrapper = styled.div`
  grid-column: span 2;

  ${media.tablet(css`
    grid-column: span 1;
  `)}
`;

const CheckboxWrapper = styled.div`
  grid-column: span 2;

  ${media.tablet(css`
    grid-column: span 1;
  `)}
`;
