import { useMedia } from '@tager/web-core';

import { colors } from '@/constants/theme';
import Banner from '@/components/Redesign/Banner';
import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import { getPhoneLink } from '@/utils/common';

function PhoneBanner() {
  const {
    templateFields: { phoneImage, phoneTitle, phone },
  } = useVerifiedAtlantmLandingData();

  const isTablet = useMedia('(max-width: 1259px)');
  const subtitle = isTablet ? '' : phone;

  if (!phoneImage || !phone) {
    return null;
  }

  return (
    <Banner
      title={phoneTitle ?? ''}
      subtitle={subtitle ?? ''}
      link={getPhoneLink(phone)}
      image={phoneImage}
      color={colors.greenGradient}
      size={'small'}
      orientation={'horizontal'}
    />
  );
}

export default PhoneBanner;
