import React from 'react';
import styled from 'styled-components';

import { useFinanceLandingData } from '@/modules/FinanceLanding/hooks';
import {
  Title,
  ContentContainer,
} from '@/modules/FinanceLanding/common.styles';
import PostCardSlider from '@/components/PostCardSlider';
import { useTypedSelector } from '@/store/store';
import { selectPostsListPosts } from '@/store/reducers/posts-list';

function Offers() {
  const { offersTitle } = useFinanceLandingData();
  const posts = useTypedSelector(selectPostsListPosts);

  if (!posts || !posts.items || posts.items.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{offersTitle}</Title>

        <PostCardSliderWrapper>
          <PostCardSlider
            id={3}
            posts={posts.items.map(({ image, title, url }) => ({
              image,
              title: title ?? '',
              url: url ?? '',
            }))}
          />
        </PostCardSliderWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Offers;

const Component = styled.section`
  padding: 15px 0;
`;

const PostCardSliderWrapper = styled.div`
  margin-top: 20px;
`;
