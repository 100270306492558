import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const ContentContainer = styled.div`
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;
