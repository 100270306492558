import React from 'react';
import styled from 'styled-components';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { Title, ReviewsSlider } from '@/components/CarModelLandingWidgets';

function Reviews() {
  const { reviewsTitle, reviewsSubtitle, reviews } = useCarModelLandingData();

  if (!reviews || reviews.length === 0) {
    return null;
  }

  const convertedReviews = reviews.map(({ videoLink, image, imageLink }) => ({
    videoLink: videoLink ?? '',
    image,
    imageLink: imageLink ?? '',
  }));

  return (
    <Component id="reviews">
      <ContentContainer>
        <Title title={reviewsTitle ?? ''} isGray />
        <Title title={reviewsSubtitle ?? ''} />

        <SliderWrapper>
          <ReviewsSlider reviews={convertedReviews} />
        </SliderWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Reviews;

const Component = styled.section`
  padding: 30px 0 60px;
`;

const SliderWrapper = styled.div`
  margin-top: 40px;
`;
