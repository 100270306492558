import React from 'react';
import styled, { css } from 'styled-components';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { useMobileAppLandingData } from '@/modules/MobileAppLanding/MobileAppLanding.hooks';
import { getYouTubeId } from '@/utils/common';

function Video() {
  const { mobileAppVideoTitle, mobileAppVideoLink } = useMobileAppLandingData();

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{mobileAppVideoTitle}</Title>

        <VideoWrapper>
          <LiteYouTubeEmbed
            id={getYouTubeId(mobileAppVideoLink ?? '')}
            title="YouTube Embed"
          />
        </VideoWrapper>
      </ContentContainerSmall>
    </Component>
  );
}

export default Video;

const Component = styled.section`
  padding: 10px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const VideoWrapper = styled.div`
  position: relative;
  margin-top: 35px;
  padding-bottom: 42.25%;

  ${media.tablet(css`
    margin-top: 20px;
    padding-bottom: 56.25%;
  `)}

  article {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;
