import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/AutoSelectionLanding/common.styles';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import Benefits from '@/components/Benefits';
import { media } from '@/utils/mixin';

function BenefitItems() {
  const { benefitsTitle, benefits } = useAutoSelectionLandingData();

  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{benefitsTitle}</Title>

        <BenefitsWrapper>
          <Benefits
            id={1}
            benefits={benefits.map(({ image, title }) => ({
              image,
              title: title ?? '',
            }))}
            cols={5}
          />
        </BenefitsWrapper>
      </ContentContainer>
    </Component>
  );
}

export default BenefitItems;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const BenefitsWrapper = styled.div`
  margin-top: 20px;
`;
