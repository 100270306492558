import React from 'react';
import styled, { css } from 'styled-components';

import HelpOnTheRoadForm from '@/components/Forms/HelpOnTheRoadForm';
import { ContentContainer } from '@/modules/HelpOnTheRoad/common.styles';
import { media } from '@/utils/mixin';

import { PackageFormProps } from './types';

function PackageForm({ id, title, price, handleBack }: PackageFormProps) {
  return (
    <Component>
      <ContentContainer>
        <HelpOnTheRoadForm
          id={id}
          title={title}
          price={price}
          handleBack={handleBack}
        />
      </ContentContainer>
    </Component>
  );
}

export default PackageForm;

const Component = styled.div`
  padding: 40px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}
`;
