import React from 'react';
import styled from 'styled-components';

import { ContentContainer, Title } from '@/modules/TokensLanding/common.styles';
import { useTokensLandingData } from '@/modules/TokensLanding/hooks';
import { colors } from '@/constants/theme';

function Text() {
  const { textTitle, text } = useTokensLandingData();

  if (!text) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{textTitle}</Title>
        <Content dangerouslySetInnerHTML={{ __html: text }} />
      </ContentContainer>
    </Component>
  );
}

export default Text;

const Component = styled.section`
  padding: 15px 0 30px;
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  a {
    color: ${colors.main};
  }
`;
