import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
// @ts-ignore
import { IMaskInput } from 'react-imask';

import { formatNumber } from '@tager/web-core';

import { colors } from '@/constants/theme';

import { StockFilterAmountProps } from './types';

function StockFilterAmount({
  label,
  min,
  max,
  value,
  badge,
  onChange,
}: StockFilterAmountProps) {
  const [resultValue, setResultValue] = useState<string>('');

  useEffect(() => {
    setResultValue(formatNumber(value));
  }, []);

  const handleAcceptInput = (value: string) => {
    const resultValue = Number(value.replaceAll(',', '.').split(' ').join(''));
    const isResultValueNaN = isNaN(resultValue);

    setResultValue(!isResultValueNaN ? value : formatNumber(min));
    onChange?.(!isResultValueNaN ? resultValue : min);
  };

  const handleBlurInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setResultValue(formatNumber(min));
      onChange?.(min);
    }
  };

  const handleChangeRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resultValue = event.target.value;

    setResultValue(formatNumber(Number(resultValue)));
    onChange?.(Number(resultValue));
  };

  return (
    <Component>
      <Label>{label}</Label>
      <InputWrapper>
        <Input
          placeholder={formatNumber(max)}
          mask={Number}
          min={min}
          max={max}
          thousandsSeparator=" "
          radix=","
          mapToRadix={['.']}
          scale={2}
          value={resultValue}
          onAccept={handleAcceptInput}
          onBlur={handleBlurInput}
        />
        <Badge>{badge}</Badge>
      </InputWrapper>

      <Range
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChangeRange}
      />
    </Component>
  );
}

export default StockFilterAmount;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.blue100};
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled(IMaskInput)`
  display: block;
  width: 100%;
  padding: 11px 60px 10px 15px;
  height: 40px;
  font-size: 16px;
  line-height: 19px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black200};
  border: 1px solid ${(props) => props.theme.gray1000};
  border-radius: 6px;

  &::placeholder {
    color: ${(props) => props.theme.gray1500};
    opacity: 1;
  }
`;

const Badge = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.gray1500};
`;

const RangeThumb = css`
  z-index: 2;
  cursor: grab;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${colors.white};
  border: 2px solid ${colors.main};
`;

const Range = styled.input`
  width: 100%;
  background: ${colors.gray1300};
  height: 2px;
  margin-top: 30px;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    ${RangeThumb};

    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    ${RangeThumb}
  }
`;
