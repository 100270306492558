import styled, { css } from 'styled-components';
import React from 'react';

import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import {
  ContentContainer,
  Title,
} from '@/modules/VerifiedAtlantmLanding/common.styles';
import Steps from '@/components/Redesign/Steps';
import { media } from '@/utils/mixin';
import { tabletPadding } from '@/modules/VerifiedAtlantmLanding/common.styles';

function WorkSteps() {
  const {
    templateFields: { stepTitle, steps },
  } = useVerifiedAtlantmLandingData();

  if (!steps || steps.length === 0) {
    return null;
  }

  const stepsItems = steps.map(({ title, description: text }) => ({
    title: title ?? '',
    text: text ?? '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Title>{stepTitle}</Title>
        <Steps columns={4} steps={stepsItems} />
      </ContentContainer>
    </Component>
  );
}

export default WorkSteps;

const Component = styled.section`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin: 0 -${tabletPadding}px 40px 0;
  `)}
`;
