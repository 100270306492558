import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/ElectrocarsLanding/common.styles';
import { media } from '@/utils/mixin';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { colors } from '@/constants/theme';

import AnimationBlock from './components/AnimationBlock';

function PurchaseSteps() {
  const { stepsTitle, stepsItems } = useElectrocarsLandingData();

  if (!stepsItems || stepsItems.length === 0) {
    return null;
  }

  return (
    <Component id="steps">
      <ContentContainer>
        <Title>{stepsTitle}</Title>
        <AnimationBlock />
      </ContentContainer>
    </Component>
  );
}

export default PurchaseSteps;

const Component = styled.section`
  padding: 30px 0;
  overflow: clip;
  background: ${colors.purple};

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
