import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { useB2bHomeData } from '@/modules/B2bHome/hooks';
import { ServicesBanners, Title } from '@/components/LandingWidgets';
import { ContentContainer } from '@/modules/B2bHome/common.styles';

function SpecialVersions() {
  const { specialVersionsTitle, specialVersions } = useB2bHomeData();

  if (!specialVersions || specialVersions.length === 0) {
    return null;
  }

  return (
    <Component id="special-versions">
      <ContentContainer>
        <Title title={specialVersionsTitle ?? ''} />
      </ContentContainer>

      <ServicesBanners
        id={4}
        services={specialVersions.map(
          ({ image, mobileImage, title, buttonText, buttonLink }) => ({
            image,
            mobileImage,
            title: title ?? '',
            buttonText: buttonText ?? '',
            buttonLink: buttonLink ?? '',
          })
        )}
      />
    </Component>
  );
}

export default SpecialVersions;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}

  ${ContentContainer} {
    margin-bottom: 20px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;
