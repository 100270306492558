import React from 'react';
import styled from 'styled-components';

import HeroWithoutContent from '@/components/HeroWithoutContent';
import Layout from '@/layout';

import { usePersonalCabinetData } from './PersonalCabinet.hooks';
import Info from './components/Info';
import MobileApp from './components/MobileApp';

function PersonalCabinet() {
  const {
    heroImage,
    heroTabletImage,
    heroMobileImage,
    personalCabinetInfoTitle,
    personalCabinetInfoItems,
    mobileAppTitle,
    mobileAppText,
    mobileAppItems,
    mobileAppImage,
  } = usePersonalCabinetData();

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
        />
        <Info
          title={personalCabinetInfoTitle}
          infoItems={personalCabinetInfoItems}
        />
        <MobileApp
          title={mobileAppTitle}
          text={mobileAppText}
          links={mobileAppItems}
          image={mobileAppImage}
        />
      </Component>
    </Layout>
  );
}

export default PersonalCabinet;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
