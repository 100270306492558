import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { isBrowser } from '@tager/web-core';

import KeyboardArrowUpIcon from '@/assets/svg/keyboard_arrow_up.svg';
import KeyboardArrowDownIcon from '@/assets/svg/keyboard_arrow_down.svg';
import LockIcon from '@/assets/svg/lock.svg';
import { DisplayIcon } from '@/components/Collapse/Collapse';
import { colors } from '@/constants/theme';
import Tooltip from '@/components/Collapse/components/Tooltip';
import { ScrollStyles } from '@/components/styles';
import useCollapse from '@/hooks/useCollapse';

import Search from './components/Search';

interface Props {
  rateCarListData: Array<string>;
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  label: string;
  children: React.ReactNode;
  handleExpanded: boolean;

  handleExpandedInner(state: boolean): void;

  disabled?: boolean;
  hintText?: string;
  isChecked?: boolean;
  isClickable?: boolean;
}

function VerticalCollapsedList({
  children,
  label,
  handleExpanded,
  handleExpandedInner,
  disabled,
  hintText,
  setValues,
  rateCarListData,
  isChecked,
  isClickable,
}: Props) {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    setExpanded(handleExpanded);
  }, [handleExpanded]);

  useEffect(() => {
    handleExpandedInner(isExpanded);
  }, [isExpanded, handleExpandedInner]);

  function handleOpen() {
    if (disabled) return;
    handleExpandedInner(!isExpanded);
  }

  return (
    <Component isChecked={isChecked}>
      <Display
        isClickable={isClickable}
        {...getToggleProps({
          onClick: handleOpen,
        })}
      >
        <Title>{label}</Title>
        {disabled ? (
          <Tippy
            content={<Tooltip text={hintText} />}
            offset={[25, 10]}
            placement="bottom"
            interactive
            appendTo={isBrowser() ? document.body : 'parent'}
          >
            <DisplayIcon>
              <LockIcon />
            </DisplayIcon>
          </Tippy>
        ) : (
          <DisplayIcon>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </DisplayIcon>
        )}
      </Display>
      <Content {...getCollapseProps()}>
        <Search setValues={setValues} rateCarListData={rateCarListData} />
        <ContentContainer>{children}</ContentContainer>
      </Content>
    </Component>
  );
}

export default VerticalCollapsedList;

const Component = styled.div<{ isChecked?: boolean }>`
  width: 100%;
  position: relative;
  border-bottom: 1px solid #ededed;
  background: ${(props) => props.theme.white_1};

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${({ isChecked }) =>
    !isChecked &&
    css`
      border: 1px solid ${colors.red700};
      background: ${(props) => props.theme.red_1};
    `};
`;

const Content = styled.div`
  width: 100%;
  max-height: 197px;
  overflow-y: scroll !important;
  border-radius: 8px;
  background: ${(props) => props.theme.white_1};
  position: relative;

  ${ScrollStyles}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  padding: 10px 30px;
  text-align: left;
  position: relative;
  z-index: 10;
`;

const Display = styled.div<{ isClickable?: boolean }>`
  position: relative;
  display: flex;
  text-align: left;
  padding: 6px 20px;
  border-radius: 4px;
  cursor: pointer;

  svg {
    fill: ${colors.main};

    path {
      fill: ${colors.main};
    }
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: default;
    `}

  ${({ isClickable }) =>
    isClickable &&
    css`
      pointer-events: none;
    `}
`;

const Title = styled.span`
  width: 100%;
  font-size: 24px;
  line-height: 38px;
  color: ${(props) => props.theme.black};
  text-align: center;
  display: block;
`;
