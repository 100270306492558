import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/HisunHome/common.styles';
import { media } from '@/utils/mixin';
import { SloganPrimary } from '@/components/LandingWidgets';
import { useHisunHomeData } from '@/modules/HisunHome/hooks';

function SloganText() {
  const { sloganText } = useHisunHomeData();

  if (!sloganText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <SloganWrapper>
          <SloganPrimary text={sloganText} />
        </SloganWrapper>
      </ContentContainer>
    </Component>
  );
}

export default SloganText;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const SloganWrapper = styled.div`
  ${media.tablet(css`
    padding: 0 10px;
  `)}
`;
