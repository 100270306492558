import React from 'react';
import styled, { css } from 'styled-components';
import Guarantees from 'src/components/Redesign/Guarantees';

import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/EmergencyManager/common.styles';
import Breadcrumbs from '@/components/Redesign/BreadCrumbs';

import PhoneBanner from './components/PhoneBanner';

function AboutSection() {
  const {
    title,
    path,
    templateFields: {
      serviceTitle,
      serviceText,
      supportTicks,
      supportTitle,
      serviceImage,
    },
  } = useEmergencyManagerData();

  if (!supportTicks || supportTicks.length === 0) {
    return null;
  }

  const ticks = supportTicks.map(({ text }) => ({ text: text ?? '' }));

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={[
            { label: 'Главная', link: '/' },
            { label: title, link: path },
          ]}
        />

        <Guarantees
          title={serviceTitle ?? ''}
          imageTitle={serviceTitle ?? ''}
          text={serviceText ?? ''}
          subTitle={supportTitle ?? ''}
          ticks={ticks}
          image={serviceImage}
          banner={<PhoneBanner />}
        />
      </ContentContainer>
    </Component>
  );
}

export default AboutSection;

const Component = styled.section`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)}
`;
