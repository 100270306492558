import React from 'react';
import styled from 'styled-components';

import RateAndAdvantages from './components/RateAndAdvantages';
import RateCar from './components/RateCar';
import Programs from './components/Programs';
import Questions from './components/Questions';

const ValueCarHome = () => {
  return (
    <Component>
      <RateCar />
      <Programs />
      <Questions />
      <RateAndAdvantages />
    </Component>
  );
};

export default ValueCarHome;

const Component = styled.div`
  background: ${(props) => props.theme.white};
`;
