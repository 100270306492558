import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import EcoHeader from '@/modules/Eco/component/EcoHeader';
import EcoContent from '@/modules/Eco/component/EcoContent';
import Layout from '@/layout';

function Eco() {
  const page = useCurrentPage();

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <Component>
        <EcoHeader />
        <EcoContent />
      </Component>
    </Layout>
  );
}

export default Eco;

const Component = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  background: ${(props) => props.theme.white};
`;
