import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/VedaemLanding/common.styles';
import { useVedaemLandingData } from '@/modules/VedaemLanding/hooks';
import {
  AboutCardPrimary,
  Image as AboutCardPrimaryImage,
} from '@/components/LandingWidgets/components/AboutCardPrimary';
import { media } from '@/utils/mixin';

function About() {
  const { aboutImage, aboutMobileImage, aboutTitle, aboutText } =
    useVedaemLandingData();

  if (!aboutText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutWrapper>
          <WrapperCardPrimary>
            <AboutCardPrimary
              image={aboutImage}
              imageMobile={aboutMobileImage}
              title={aboutTitle ?? ''}
              topText={aboutText}
            />
          </WrapperCardPrimary>

          <WidgetWrapper className="vedaem-calculator-widget" />
        </AboutWrapper>
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}

  ${AboutCardPrimaryImage} {
    flex: 0 0 280px;
    height: 180px;

    ${media.tablet(css`
      flex: auto;
      height: auto;
    `)}
  }
`;

const AboutWrapper = styled.div`
  display: flex;
  gap: 40px;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const WrapperCardPrimary = styled.div`
  max-width: 30%;
  width: 100%;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const WidgetWrapper = styled.div`
  width: 100%;
`;
