import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/CreditLeasing/common.styles';
import { media } from '@/utils/mixin';
import { useCreditLeasingData } from '@/modules/CreditLeasing/hooks';
import { TextContent } from '@/components/LandingWidgets';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { colors } from '@/constants/theme';

function Header() {
  const {
    path,
    templateFields: { pageType, title, topText },
  } = useCreditLeasingData();

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={[
            { label: 'Главная', link: '/' },
            {
              label: pageType && pageType === 'credit' ? 'Кредит' : 'Лизинг',
              link: path,
            },
          ]}
        />

        <Title>{title}</Title>

        {topText && (
          <TextContentWrapper>
            <TextContent text={topText} />
          </TextContentWrapper>
        )}
      </ContentContainer>
    </Component>
  );
}

export default Header;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  margin: 20px 0 0;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;

const TextContentWrapper = styled.div`
  margin-top: 20px;
`;
