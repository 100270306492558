import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Work from '@/components/Redesign/Works/components/Work';
import { media } from '@/utils/mixin';

import { WorksProps } from './types';

function Works({ worksList, image }: WorksProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        {...convertThumbnailToPictureImage(image)}
        alt="work-image"
      />

      <ListWrapper>
        {worksList.map((work, index) => (
          <Work key={index} work={work.point} />
        ))}
      </ListWrapper>
    </Component>
  );
}

export default Works;

const Component = styled.div`
  display: flex;
  gap: 20px;

  ${media.tablet(css`
    flex-direction: column-reverse;
  `)}
`;

const Image = styled(Picture)`
  max-width: 495px;
  width: 100%;

  ${media.tablet(css`
    max-width: none;
  `)}

  img,
  picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    ${media.tablet(css`
      position: relative;
    `)}
  }

  img {
    object-fit: cover;
    border-radius: 10px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
