import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { useJacHomeData } from '@/modules/JacHome/hooks';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { ContentContainer } from '@/modules/JacHome/common.styles';
import { media } from '@/utils/mixin';
import { ServiceCard } from '@/components/LandingWidgets';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { submitJacServiceForm } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { getDealersOptions } from '@/utils/common';

function ServiceBanner() {
  const openModal = useModal();
  const {
    serviceTitle,
    serviceText,
    serviceNote,
    serviceImage,
    serviceMobileImage,
    serviceButtonText,
    serviceButtonLink,
    serviceFormTitle,
    serviceGoogleEvent,
    dealersRequests,
  } = useJacHomeData();

  if (!serviceTitle || !serviceText || !serviceImage) {
    return null;
  }

  const sendApplicationForm = async (
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      await submitJacServiceForm({
        ...values,
        dealer: values.dealer ? Number(values.dealer.value) : undefined,
      }).then(() => {
        if (serviceGoogleEvent) {
          googleEvent(serviceGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenApplicationFormModal = () => {
    openModal(
      ApplicationFormModal,
      {
        title: serviceFormTitle ?? '',
        fieldsConfig: { dealers: getDealersOptions(dealersRequests) },
        onSubmit: sendApplicationForm,
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <ServiceCard
          title={serviceTitle}
          description={serviceText}
          note={serviceNote ?? ''}
          image={serviceImage}
          imageMobile={serviceMobileImage}
          buttonText={serviceButtonText ?? ''}
          buttonLink={serviceButtonLink ?? ''}
          onSubmit={handleOpenApplicationFormModal}
        />
      </ContentContainer>
    </Component>
  );
}

export default ServiceBanner;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
