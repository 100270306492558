import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/EmergencyManager/common.styles';
import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import FaqAccordion from '@/components/Redesign/FaqAccordion';
import { media } from '@/utils/mixin';

function Faq() {
  const {
    templateFields: { questionItems, questionTitle },
  } = useEmergencyManagerData();

  if (!questionTitle || !questionItems) {
    return null;
  }

  const questions = questionItems?.map(({ question, answer }) => ({
    question: question || '',
    answer: answer || '',
  }));

  return (
    <Component>
      <ContentContainer>
        <Title>{questionTitle}</Title>
        <FaqAccordion questions={questions} />
      </ContentContainer>
    </Component>
  );
}

export default Faq;

const Component = styled.section`
  background: ${(props) => props.theme.white};

  margin-bottom: 135px;

  ${media.tablet(css`
    margin-bottom: 66px;
  `)};
`;
