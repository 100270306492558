import React from 'react';
import styled, { css } from 'styled-components';

import { formatNumber } from '@tager/web-core';

import { colors } from '@/constants/theme';
import Link from '@/components/Link';

import { LandingCarCardProps } from './types';
import DesktopSlider from './components/DesktopSlider';
import MobileSlider from './components/MobileSlider';

function LandingCarCard({
  images,
  title,
  subtitle,
  price,
  pricePrefix,
  link,
  isMobile,
}: LandingCarCardProps) {
  return (
    <Component>
      {!isMobile ? (
        <DesktopSlider images={images} link={link} />
      ) : (
        <MobileSlider images={images} link={link} />
      )}

      <Content isMobile={isMobile}>
        <Top>
          <TitleWrapper>
            <Title to={link} isMobile={isMobile}>
              {title}
            </Title>
          </TitleWrapper>
          <Subtitle isMobile={isMobile}>{subtitle}</Subtitle>
        </Top>
        <Bottom>
          {price ? (
            <PriceWrapper>
              {pricePrefix && <PricePrefix>{pricePrefix}</PricePrefix>}
              <Price isMobile={isMobile}>{formatNumber(price)}</Price>
              <PriceCurrency isMobile={isMobile}>byn</PriceCurrency>
            </PriceWrapper>
          ) : null}

          <Button to={link} isMobile={isMobile}>
            Подробнее
          </Button>
        </Bottom>
      </Content>
    </Component>
  );
}

export default LandingCarCard;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.gray500};
  border-radius: 8px;
  background: ${(props) => props.theme.white_1};
`;

const Content = styled.div<{ isMobile?: boolean }>`
  padding: 30px 20px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 19px 12px 12px;
    `}
`;

const Top = styled.div``;

const TitleWrapper = styled.div`
  position: relative;
  overflow: hidden;
  text-align: left;
`;

const Title = styled(Link)<{ isMobile?: boolean }>`
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  white-space: nowrap;
  text-align: left;
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${colors.main};
  }

  &::after {
    content: '';
    background: ${(props) => props.theme.whiteGradient};
    right: 0;
    top: 0;
    bottom: 0;
    width: 147px;
    height: 100%;
    display: block;
    position: absolute;
    pointer-events: none;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 14px;
      line-height: 16px;
    `}
`;

const Subtitle = styled.p<{ isMobile?: boolean }>`
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  text-align: left;
  margin-top: 3px;
  color: ${(props) => props.theme.black};

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 10px;
      line-height: 12px;
    `}
`;

const Bottom = styled.div`
  margin-top: 7px;
`;

const PriceWrapper = styled.div`
  text-align: left;
`;

const PricePrefix = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-right: 10px;
  color: ${(props) => props.theme.blue100};
`;

const Price = styled.span<{ isMobile?: boolean }>`
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: ${(props) => props.theme.black};

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 22px;
      line-height: 26px;
    `}
`;

const PriceCurrency = styled.span<{ isMobile?: boolean }>`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  margin-left: 10px;
  color: ${(props) => props.theme.black};

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 11px;
      line-height: 12px;
      margin-left: 5px;
    `}
`;

const Button = styled(Link)<{ isMobile?: boolean }>`
  display: block;
  padding: 8px 0;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  margin-top: 15px;
  color: ${(props) => props.theme.main};
  border: 1px solid ${(props) => props.theme.main};
  border-radius: 20px;
  transition: 0.3s;

  &:hover {
    color: ${colors.white};
    background: ${colors.main100};
    border-color: ${colors.main100};
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 14px;
    `}
`;
