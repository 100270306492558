import React from 'react';
import styled from 'styled-components';

import FeatureItem from '../FeatureItem';

import { FeaturesListProps } from './types';

function FeaturesList({ features, isWhiteColor }: FeaturesListProps) {
  return (
    <Component>
      {features.map((feature, index) => (
        <FeatureItem key={index} {...feature} isWhiteColor={isWhiteColor} />
      ))}
    </Component>
  );
}

export default FeaturesList;

const Component = styled.div`
  display: flex;
  gap: 33px;
`;
