import { ApiService } from '@tager/web-core';

import {
  AssistPaymentResponse,
  CarOrderPaymentProviders,
  CarOrderStatus,
  HelpOnTheRoadPackage,
  ContractFormFields,
  ContractFormResponse,
  OplatiData,
  CarOrder,
  CarOrderInfo,
} from './typings';

const request = new ApiService({
  baseUrl: {
    csr: process.env.NEXT_PUBLIC_ORDERS_SERVICE_URL,
    ssr: process.env.NEXT_PUBLIC_ORDERS_SERVICE_URL,
  },
}).getRequest();

export function loadCarOrderStatus(params: {
  type: string;
  id: number;
}): Promise<CarOrderStatus> {
  return request.post({ path: `/create/purchase-car/check`, body: params });
}

export function submitCarContract(
  params: ContractFormFields
): Promise<ContractFormResponse> {
  return request.post({
    path: `/create/purchase-car`,
    body: params,
  });
}

export function loadCarOrder(orderId: string): Promise<CarOrder> {
  return request.get({ path: `/orders/${orderId}` });
}

export function submitWebpayPayment(
  webOrderId: string,
  params: { total: number; returnUrl: string }
): Promise<{ redirectUrl: string }> {
  return request.post({
    path: `/orders/${webOrderId}/webpay`,
    body: params,
  });
}

export function submitEripPayment(
  webOrderId: string,
  params: { total: number; returnUrl: string }
): Promise<{ redirectUrl: string }> {
  return request.post({
    path: `/orders/${webOrderId}/webpay`,
    body: { ...params, erip: true },
  });
}

export function submitOplatiPayment(
  webOrderId: string,
  params: { total: number; returnUrl: string }
): Promise<OplatiData> {
  return request.post({
    path: `/orders/${webOrderId}/oplati`,
    body: params,
  });
}

export function submitAssistPayment(
  webOrderId: string,
  params: { total: number; returnUrl: string }
): Promise<AssistPaymentResponse> {
  return request.post({
    path: `/orders/${webOrderId}/assist`,
    body: params,
  });
}

export function loadCarOrderInfo(): Promise<CarOrderInfo> {
  return request.get({ path: `/create/purchase-car/info` });
}

export function loadCarOrderPaymentProviders(
  orderId: string
): Promise<CarOrderPaymentProviders> {
  return request.get({ path: `/orders/${orderId}/payment-providers` });
}

export function submitConfirmPhone(params: { phone: string }): Promise<{}> {
  return request.post({
    path: `/create/confirm-phone`,
    body: params,
  });
}

/** Help on the Road **/

export function getHelpOnTheRoadPackagesInfo(): Promise<
  HelpOnTheRoadPackage[]
> {
  return request.get({ path: `/help-on-road/plans` });
}

export function submitHelpOnTheRoadContacts(form: {
  plan: string;
  total: number;
  name: string;
  phone: string;
  email: string;
  brand: string;
  model: string;
  vin: string;
}): Promise<{ id: string }> {
  return request.post({ path: `/help-on-road/create`, body: form });
}

export function getHelpOnTheRoadPaymentTypes(
  id: string
): Promise<{ providers: string[] }> {
  return request.get({ path: `/orders/${id}/payment-providers` });
}

export function submitHelpOnTheRoadPaymentProvider(
  id: string,
  paymentType: string,
  params?: {
    total: number;
    returnUrl: string;
    erip?: boolean;
  }
): Promise<{ qrCode: string; redirectUrl: string }> {
  return request.post({
    path: `/orders/${id}/${paymentType.toLowerCase()}`,
    body: params,
  });
}

export function getHelpOnTheRoadSuccessInfo(
  id: string
): Promise<{ status: string }> {
  return request.get({ path: `/orders/${id}` });
}
