import React from 'react';
import styled, { css } from 'styled-components';

import CloseButton from '@/components/modals/shared/CloseButton';
import { colors } from '@/constants/theme';
import { Title } from '@/modules/EmergencyManager/common.styles';
import Brand from '@/components/Redesign/Brand';
import { media } from '@/utils/mixin';

import { BrandsModalProps } from './types';

function BrandsModal({ innerProps, closeModal }: BrandsModalProps) {
  const { images, title } = innerProps;

  return (
    <Component>
      <CloseButton onClick={closeModal} />
      <Title>{title}</Title>
      <BrandsWrapper>
        <AllBrandsList>
          {images.map((image, index) => (
            <BrandWrapper key={index}>
              <Brand image={image} />
            </BrandWrapper>
          ))}
        </AllBrandsList>
      </BrandsWrapper>
    </Component>
  );
}

export default BrandsModal;

const Component = styled.div`
  width: 60vw;
  background: ${colors.white};
  padding: 40px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet(css`
    width: 100%;
  `)}
`;

const BrandsWrapper = styled.div`
  display: flex;
`;

const BrandWrapper = styled.div`
  flex: 0 0 140px;
  height: 70px;

  ${media.tablet(css`
    flex: 0 0 105px;
    height: 50px;
  `)}
`;

const AllBrandsList = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(80px, 105px));
  gap: 10px;
`;
