import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import HeroSlider from './components/HeroSlider';
import SloganText from './components/SloganText';
import ModelsList from './components/ModelsList';
import TestDriveBanner from './components/TestDriveBanner';
import About from './components/About';
import Guarantee from './components/Guarantee';
import Cars from './components/Cars';
import Credit from './components/Credit';
import TradeIn from './components/TradeIn';
import ServiceBanner from './components/ServiceBanner';
import AboutDealer from './components/AboutDealer';
import TestDriveForm from './components/TestDriveForm';
import Map from './components/Map';
import Departments from './components/Departments';

function JacHome() {
  return (
    <Layout>
      <Component>
        <HeroSlider />
        <SloganText />
        <ModelsList />
        <TestDriveBanner />
        <About />
        <Guarantee />
        <Cars />
        <Credit />
        <TradeIn />
        <ServiceBanner />
        <AboutDealer />
        <TestDriveForm />
        <Map />
        <Departments />
      </Component>
    </Layout>
  );
}

export default JacHome;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
