import { TableProps } from '@/components/Packages/components/Table/types';

export const getTableBySelectedPackage = (
  packageIndex: number,
  table: TableProps[]
): TableProps[] => {
  if (!table || table.length === 0) {
    return [];
  }

  return table
    .map(({ title, rows }) => ({
      title,
      rows: rows
        .map(({ title, cells }) => {
          const currentValue =
            cells && cells.length > 0 ? cells[packageIndex] : false;

          return {
            title,
            param: currentValue,
          };
        })
        .filter(({ param }) => param),
    }))
    .filter(({ rows }) => rows.length !== 0);
};
