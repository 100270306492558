import styled, { css } from 'styled-components';
import Guarantees from 'src/components/Redesign/Guarantees';

import { useVerifiedAtlantmLandingData } from '@/modules/VerifiedAtlantmLanding/hooks';
import { ContentContainer } from '@/modules/VerifiedAtlantmLanding/common.styles';
import { media } from '@/utils/mixin';

function AboutSection() {
  const {
    templateFields: { resultImage, resultText, resultTitle, resultPoints },
  } = useVerifiedAtlantmLandingData();

  if (!resultPoints || resultPoints.length === 0) {
    return null;
  }

  const ticks = resultPoints.map(({ text }) => ({ text: text ?? '' }));

  return (
    <Component>
      <ContentContainer>
        <Guarantees
          image={resultImage}
          text={resultText ?? ''}
          title={resultTitle ?? ''}
          imageTitle={resultTitle ?? ''}
          ticks={ticks}
        />
      </ContentContainer>
    </Component>
  );
}

export default AboutSection;

const Component = styled.div`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)}
`;
