import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { ContentContainerSmall } from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { MobileAppItem } from '@/modules/PersonalCabinet/PersonalCabinet.types';

interface MobileAppProps {
  title: Nullable<string>;
  text: Nullable<string>;
  links: MobileAppItem[];
  image: ThumbnailType;
}

function MobileApp({ title, text, links, image }: MobileAppProps) {
  return (
    <Component>
      <ContentContainerSmall>
        <Main>
          <Title>{title}</Title>
          <Text>{text}</Text>

          <AppsList>
            {links &&
              links.map(({ image, link }, index: number) => (
                <App key={index} to={link} target="_blank">
                  <AppImage
                    loading="lazy"
                    {...convertThumbnailToPictureProps(image)}
                  />
                </App>
              ))}
          </AppsList>
        </Main>
        <Qr loading="lazy" {...convertThumbnailToPictureProps(image)} />
      </ContentContainerSmall>
    </Component>
  );
}

export default MobileApp;

const Component = styled.section`
  padding: 50px 0;

  ${media.mobile(css`
    padding-top: 40px;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Main = styled.div`
  flex: 0 0 620px;
  max-width: 620px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 36px;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  color: ${(props) => props.theme.blue100};
`;

const AppsList = styled.div`
  display: flex;
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const App = styled(Link)`
  &:not(:first-child) {
    margin-left: 30px;
  }
`;

const AppImage = styled(Picture)`
  img {
    width: 132px;
    height: 40px;
  }
`;

const Qr = styled(Picture)`
  position: relative;
  flex: 0 0 130px;
  width: 130px;
  height: 130px;
  margin-left: 20px;

  ${media.tablet(css`
    display: none;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
