import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';
import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { StringField } from '@/typings/model';
import Picture from '@/components/Picture';

interface Props {
  icon: Nullable<ThumbnailType>;
  title: StringField;
  text: StringField;
}

function BenefitItem({ icon, title, text }: Props) {
  return (
    <Content>
      <IconContainer>
        <Picture loading="lazy" {...convertThumbnailToPictureProps(icon)} />
      </IconContainer>
      <Title>{title}</Title>
      <Value>{text}</Value>
    </Content>
  );
}

export default BenefitItem;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 380px;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;

  svg {
    width: 40px;
    height: 40px;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 500;
  font-size: 18px;
  margin: 6px 0 12px;
  color: ${(props) => props.theme.blue200};
`;

const Value = styled.span`
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.blue200};
`;
