import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  tabletPadding,
  Title,
} from '@/modules/EmergencyManager/common.styles';
import CompaniesTicker from '@/components/CompaniesTicker';
import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import { media } from '@/utils/mixin';

function Partners() {
  const {
    templateFields: { partnersTitle, partnersItems },
  } = useEmergencyManagerData();

  if (!partnersTitle) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{partnersTitle}</Title>

        <CompaniesTickerWrapper>
          <CompaniesTicker images={partnersItems.map(({ image }) => image)} />
        </CompaniesTickerWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Partners;

const Component = styled.section`
  padding: 15px 0;
  margin-bottom: 60px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)};
`;

const CompaniesTickerWrapper = styled.div`
  margin-top: 20px;

  ${media.tablet(css`
    margin-right: -${tabletPadding}px;
  `)}
`;
