import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { TimepickerPopupProps } from './types';
import { DEFAULT_TIMES } from './constants';

function TimepickerPopup({
  selectedTime,
  onClick,
  disabled,
  disabledMessage,
  times = DEFAULT_TIMES,
}: TimepickerPopupProps) {
  return (
    <Component>
      <TimeList>
        {disabled && disabledMessage ? (
          <Span>{disabledMessage}</Span>
        ) : (
          times.map((timeItem, index) => (
            <TimeItem
              key={index}
              isActive={selectedTime === timeItem}
              onClick={() => onClick(timeItem)}
            >
              {timeItem}
            </TimeItem>
          ))
        )}
      </TimeList>
    </Component>
  );
}

export default TimepickerPopup;

const Component = styled.div`
  width: 100%;
  height: auto !important;
  position: absolute;
  top: 50px;
  left: 0;
  right: -0;
  padding: 12px 9px;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray1800};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 5px;
  z-index: 2000;
`;

const TimeItem = styled.span<{ isActive: boolean }>`
  display: block;
  font-size: 13px;
  margin: 3px;
  line-height: 24px;
  padding: 2px 10px;
  border: 1px solid ${(props) => props.theme.black100_alpha15};
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => props.theme.black};

  ${(props) =>
    props.isActive
      ? css`
          background: ${colors.main};
          color: ${colors.white};
          border-color: ${colors.main};
        `
      : null}
`;

const TimeList = styled.div`
  display: flex;

  flex-wrap: wrap;
`;

const Span = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.blue100};
`;
