import * as Yup from 'yup';

import { FinanceCalculationFormFields } from './types';

export const initialFinanceCalculationFormValues: FinanceCalculationFormFields =
  {
    payment: 0,
    amount: 0,
    period: 0,
    name: '',
    phone: '',
    tradeIn: false,
  };

export const validationFinanceCalculationFormSchema: Yup.SchemaOf<FinanceCalculationFormFields> =
  Yup.object().shape({
    payment: Yup.number().required('Введите ежемесячный платеж'),
    amount: Yup.number().required('Введите сумму финансирования'),
    period: Yup.number().required('Введите период финансирования'),
    name: Yup.string().required('Введите имя'),
    phone: Yup.string().required('Введите телефон'),
    tradeIn: Yup.boolean(),
  });
