import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { Button, ButtonLink } from '@/components/Redesign/Button';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { AdvantageItemProps } from './types';

function AdvantageItem({
  title,
  text,
  image,
  list,
  buttonText,
  onClick,
  link,
  isBig,
}: AdvantageItemProps) {
  return (
    <Component isBig={isBig}>
      <InfoWrapper isBig={isBig}>
        <Image
          desktop={convertThumbnailToPictureImage(image)}
          alt={'advantage-image'}
        />
        {title && <Title>{title}</Title>}
        <Description>{text}</Description>
        {list && !!list.length && (
          <List>
            {list.map((text, index) => (
              <ListItem key={index}>{text}</ListItem>
            ))}
          </List>
        )}
      </InfoWrapper>

      {!!buttonText &&
        (link ? (
          <ButtonLink to={link} variant={'outlined'} size={'big'}>
            {buttonText}
          </ButtonLink>
        ) : onClick ? (
          <Button onClick={onClick} variant={'outlined'} size={'big'}>
            {buttonText}
          </Button>
        ) : null)}
    </Component>
  );
}

export default AdvantageItem;

const Component = styled.div<{ isBig?: AdvantageItemProps['isBig'] }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 20px;
  min-height: 145px;
  height: 100%;
  gap: 20px;
  background: ${colors.white};

  ${media.tablet(css`
    padding: 10px 20px 10px 5px;
    min-height: auto;
  `)}

  ${({ isBig }) =>
    isBig &&
    css`
      padding: 30px 20px !important;
    `}
`;

const InfoWrapper = styled.div<{ isBig?: AdvantageItemProps['isBig'] }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${media.tablet(css`
    flex-direction: row;
    gap: 10px;
    text-align: left;
  `)}

  ${({ isBig }) =>
    isBig &&
    css`
      gap: 10px;

      ${media.tablet(css`
        flex-direction: column;
        text-align: center;
      `)}
    `}
`;

const Image = styled(Picture)`
  width: 80px;
  height: 80px;
  border-radius: 20px;

  ${media.tablet(css`
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
  `)}
`;

const textStyle = css`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
`;

const Title = styled.p`
  font-weight: 600;

  ${textStyle}
`;

const Description = styled.p`
  ${textStyle}
`;

const List = styled.ul`
  align-self: flex-start;
`;

const ListItem = styled.li`
  margin-left: 20px;
  list-style-type: disc;
  text-align: left;

  ${textStyle}
`;
