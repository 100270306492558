import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/CreditLeasing/common.styles';
import { media } from '@/utils/mixin';

import Switch from './components/Switch';
import Offers from './components/Offers';
import Text from './components/Text';

function Main() {
  return (
    <Component>
      <ContentContainer>
        <Switch />
        <Offers />
        <Text />
      </ContentContainer>
    </Component>
  );
}

export default Main;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}
`;
