import React from 'react';
import styled from 'styled-components';

import Package from './components/Package';
import TableParams from './components/TableParams';
import Tabs from './components/Tabs';
import Price from './components/Price';

function Mobile() {
  return (
    <Component>
      <Package />
      <Table>
        <TableParams />
        <Tabs />
      </Table>
      <Price />
    </Component>
  );
}

export default Mobile;

const Component = styled.div``;

const Table = styled.div`
  display: flex;
  margin-top: 10px;
`;
