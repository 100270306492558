import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContentContainer,
  Title,
} from '@/modules/AutoSelectionLanding/common.styles';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import Packages from '@/components/Packages';
import { PackageCardProps } from '@/components/Packages/components/PackageCard/types';
import { TableProps } from '@/components/Packages/components/Table/types';
import { media } from '@/utils/mixin';

function PackageItems() {
  const { packagesTitle, packages, packagesDetails } =
    useAutoSelectionLandingData();

  if (
    !packages ||
    packages.length === 0 ||
    !packagesDetails ||
    packagesDetails.length === 0
  ) {
    return null;
  }

  const convertedPackages: PackageCardProps[] = packages.map(
    ({ image, name, description }) => ({
      image,
      title: name ?? '',
      description: description ?? '',
    })
  );

  const convertedTable: TableProps[] = [
    {
      rows: packagesDetails.map(({ param, value }) => ({
        title: param ?? '',
        cells: value ? value.split('\n').map((item) => item === '+') : [],
      })),
    },
  ];

  return (
    <Component>
      <ContentContainer>
        <Title>{packagesTitle}</Title>

        <PackagesWrapper>
          <Packages packages={convertedPackages} table={convertedTable} />
        </PackagesWrapper>
      </ContentContainer>
    </Component>
  );
}

export default PackageItems;

const Component = styled.section`
  padding: 60px 0 30px;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const PackagesWrapper = styled.div`
  margin-top: -65px;

  ${media.tablet(css`
    margin-top: 20px;
    min-height: 640px;
  `)}
`;
