import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { useBonusProgramData } from '@/modules/BonusProgram/BonusProgram.hooks';
import { colors } from '@/constants/theme';
import ListItem from '@/modules/BonusProgram/components/ProgramLoyalty/ListItem';
import { media } from '@/utils/mixin';

function ProgramLoyalty() {
  const {
    programLoyaltyTitle,
    programLoyaltyDescription,
    programLoyaltyListTitle,
    programLoyaltyItems,
    programLoyaltyBottomTitle,
    programLoyaltyBottomDescription,
  } = useBonusProgramData();

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{programLoyaltyTitle}</Title>
        <Description>{programLoyaltyDescription}</Description>
        <ListTitle>{programLoyaltyListTitle}</ListTitle>

        <List>
          {programLoyaltyItems &&
            programLoyaltyItems.map(({ text }, index) => (
              <ListItem key={index} text={text} />
            ))}
        </List>

        <Bottom>
          <InformationInner>
            <BottomTitle>{programLoyaltyBottomTitle}</BottomTitle>
            <BottomDescription
              dangerouslySetInnerHTML={{
                __html: programLoyaltyBottomDescription ?? '',
              }}
            />
          </InformationInner>
        </Bottom>
      </ContentContainerSmall>
    </Component>
  );
}

export default ProgramLoyalty;

const Component = styled.section`
  padding: 90px 0;

  ${media.tablet(css`
    padding: 60px 0;
  `)}

  ${media.mobile(css`
    padding: 20px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Description = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 30px 0 20px;
  color: ${(props) => props.theme.blue100};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 10px;
  `)}
`;

const ListTitle = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 40px;
`;

const InformationInner = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 20px;
  background: ${(props) => props.theme.gray_1};

  ${media.mobile(css`
    align-items: flex-start;
    flex-direction: column;
  `)}
`;

const BottomTitle = styled.span`
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: ${(props) => props.theme.main};
`;

const BottomDescription = styled.div`
  margin-left: 30px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    margin-left: 0;
  `)}

  ul,
  ol {
    li {
      font-size: 16px;
      line-height: 24px;
      color: ${(props) => props.theme.blue100};

      a {
        color: ${colors.main};
      }
    }
  }
`;
