import React from 'react';
import styled from 'styled-components';

import { useServiceBenefitsData } from '@/modules/ServiceBenefits/hooks';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ContentContainer } from '@/modules/ServiceBenefits/common.styles';

function Navigation() {
  const { breadcrumbs } = useServiceBenefitsData();

  if (!breadcrumbs || breadcrumbs.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={breadcrumbs.map(({ name, link }) => ({
            label: name ?? '',
            link: link ?? '',
          }))}
        />
      </ContentContainer>
    </Component>
  );
}

export default Navigation;

const Component = styled.section`
  padding: 10px 0;
`;
