import React from 'react';

import { useHowToData } from '@/modules/HowTo/HowTo.hooks';
import HeroWithoutContent from '@/components/HeroWithoutContent';

function Hero() {
  const { heroImage, heroMobileImage } = useHowToData();

  return (
    <HeroWithoutContent
      desktopImage={heroImage}
      mobileImage={heroMobileImage}
    />
  );
}

export default Hero;
