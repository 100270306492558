import React from 'react';

import { useMedia } from '@tager/web-core';

import { PackagesProps } from './types';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';

function Packages(props: PackagesProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  return !isTablet ? <Desktop {...props} /> : <Mobile {...props} />;
}

export default Packages;
