import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Question as QuestionProps } from '@/modules/Atlantm30/Atlantm30.types';
import MinusIcon from '@/assets/svg/question-minus.svg';
import PlusIcon from '@/assets/svg/question-plus.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function QuestionAccordion({ question, answer }: QuestionProps) {
  const [isOpened, setOpened] = useState(false);

  return (
    <Component isOpened={isOpened}>
      <Header onClick={() => setOpened(!isOpened)}>
        <Question isOpened={isOpened}>{question}</Question>
        {!isOpened ? <PlusIcon /> : <MinusIcon />}
      </Header>
      <Body
        isOpened={isOpened}
        dangerouslySetInnerHTML={{ __html: answer ?? '' }}
      />
    </Component>
  );
}

export default QuestionAccordion;

const Component = styled.div<{ isOpened?: boolean }>`
  border-radius: 30px;
  background: ${colors.white};

  &:not(:first-child) {
    margin-top: 2px;
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      box-shadow: 0 5px 30px 0 rgb(80 95 121 / 10%);

      &:not(:first-child) {
        margin-top: 5px;
      }
    `}
`;

const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  svg {
    margin-left: 20px;
  }
`;

const Question = styled.p<{ isOpened?: boolean }>`
  font-size: 24px;
  line-height: 32px;
  max-width: 90%;
  color: ${colors.main};
  transition: 0.15s;

  ${media.mobile(css`
    font-size: 20px;
    line-height: 28px;
  `)}

  ${({ isOpened }) =>
    isOpened &&
    css`
      color: ${colors.black100};
    `}
`;

const Body = styled.div<{ isOpened?: boolean }>`
  padding: 0 20px 25px;
  display: none;

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.blue700};

    ${media.tabletSmall(css`
      font-size: 14px;
      line-height: 20px;
    `)}
  }

  a {
    color: ${colors.main};
  }

  ul,
  ol {
    li {
      font-size: 16px;
      line-height: 24px;
      padding-left: 20px;
      color: ${colors.blue600};

      ${media.tabletSmall(css`
        font-size: 14px;
        line-height: 20px;
      `)}

      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        background-color: ${colors.white};
        border-radius: 50%;
        border: 1px solid ${colors.main};
        margin-right: 10px;
        margin-left: -16px;
      }

      &:not(:first-child) {
        margin-top: 5px;

        ${media.tabletSmall(css`
          margin-top: 0;
        `)}
      }
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      display: block;
    `}
`;
