import React from 'react';
import styled, { css } from 'styled-components';

import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { media } from '@/utils/mixin';
import { FeatureCards, Title } from '@/components/CarModelLandingWidgets';
import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { FeatureCardProps } from '@/components/CarModelLandingWidgets/FeatureCards/components/FeatureCard/types';

function Features() {
  const { benefitsTitle, benefits } = useCarModelLandingData();

  if (!benefits || benefits.length === 0) {
    return null;
  }

  const convertedBenefits: FeatureCardProps[] = benefits.map(
    ({ image, video, title, description, options }, index) => ({
      image,
      video: video?.url ?? '',
      title: title ?? '',
      description: description ?? '',
      isReverse: index % 2 === 1,
      options: options.map(({ image, title, description }) => ({
        image,
        title: title ?? '',
        description: description ?? '',
      })),
    })
  );

  return (
    <Component id="features">
      <ContentContainer>
        <Title title={benefitsTitle ?? ''} />
      </ContentContainer>

      <FeatureCardsWrapper>
        <FeatureCards cards={[{ cards: convertedBenefits }]} />
      </FeatureCardsWrapper>
    </Component>
  );
}

export default Features;

const Component = styled.section`
  padding: 60px 0;
  background: ${(props) => props.theme.gray_1};
`;

const FeatureCardsWrapper = styled.div`
  margin-top: 40px;

  ${media.tabletSmall(css`
    margin-top: 20px;
  `)}

  ${media.mobile(css`
    margin: 20px 20px 0;
  `)}
`;
