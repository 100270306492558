import styled from 'styled-components';

import RefitTypesItem from '../RefitTypesItem';

import { RefitTypesListProps } from './types';

function RefitTypesList({ refitData }: RefitTypesListProps) {
  return (
    <Component>
      {refitData.map((refit, index) => (
        <RefitTypesItem {...refit} key={index} />
      ))}
    </Component>
  );
}

export default RefitTypesList;

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > *:nth-child(-n + 3) {
    flex: 1 1 30%;
  }

  & > *:nth-child(n + 4) {
    flex: 1 1 23%;
  }
`;
