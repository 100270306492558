import React from 'react';
import styled, { css } from 'styled-components';

import ServiceConsultationForm from '@/components/Forms/ServiceConsultationForm';
import { useServicesData } from '@/modules/Services/Services.hooks';
import { media } from '@/utils/mixin';

function Consultation() {
  const data = useServicesData();

  if (!data.consultationOptions || !data.consultationOptions.length) {
    return null;
  }

  const options = data.consultationOptions.map((item) => {
    return { label: item.option, name: item.option, value: item.option };
  });

  return (
    <Component>
      <Container id="id-consultation-container">
        <ServiceConsultationForm
          title={data.consultationTitle ?? ''}
          description={data.consultationSubtitle ?? ''}
          options={options}
          event={data.consultationGoogleEvent ?? ''}
        />
      </Container>
    </Component>
  );
}

export default Consultation;

const Component = styled.div`
  margin-top: 60px;

  @media (max-width: 1000px) {
    margin-top: 40px;
  } ;
`;

const Container = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0;
  }

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;
