import React from 'react';
import styled, { css } from 'styled-components';

import Static from '@/components/Static';
import { media } from '@/utils/mixin';
import { getSubdomain } from '@/utils/common';
import Layout from '@/layout';

import { useSubdomainHomeData } from './hooks';

function SubdomainHome() {
  const {
    title,
    templateFields: { texts },
  } = useSubdomainHomeData();

  if (!texts || texts.length === 0) {
    return null;
  }

  const foundSubdomain = texts.find(
    (item) => item.subdomain === getSubdomain()
  );

  if (!foundSubdomain) {
    return null;
  }

  return (
    <Layout isPurchasePage>
      <Component>
        <Title>{title}</Title>
        <Static body={foundSubdomain.text ?? ''} />
      </Component>
    </Layout>
  );
}

export default SubdomainHome;

const Component = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 90px auto;
  background: ${(props) => props.theme.white};

  ${media.tablet(css`
    margin: 60px 0;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    margin: 20px 0;
  `)}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin: 0 0 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 20px;
  `)}
`;
