import React from 'react';
import styled, { css } from 'styled-components';

import { useB2bHomeData } from '@/modules/B2bHome/hooks';
import { AboutCardPrimary } from '@/components/LandingWidgets';
import { ContentContainer } from '@/modules/B2bHome/common.styles';
import { media } from '@/utils/mixin';

function Benefits() {
  const {
    benefitImage,
    benefitMobileImage,
    benefitTitle,
    benefitTopText,
    benefits,
    benefitBottomText,
  } = useB2bHomeData();

  if (!benefitImage || !benefitTitle || !benefitTopText) {
    return null;
  }

  return (
    <Component id="benefits">
      <ContentContainer>
        <AboutCardPrimary
          id={2}
          image={benefitImage}
          imageMobile={benefitMobileImage}
          title={benefitTitle}
          topText={benefitTopText}
          benefits={
            benefits && benefits.length !== 0
              ? benefits.map(({ image, text }) => ({
                  image,
                  text: text ?? '',
                }))
              : []
          }
          bottomText={benefitBottomText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default Benefits;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
