import React from 'react';

import { SloganSecondary } from '@/components/LandingWidgets';
import { useZeekrHomeData } from '@/modules/ZeekrHome/hooks';

function Slogan() {
  const { sloganText } = useZeekrHomeData();

  if (!sloganText) {
    return null;
  }

  return <SloganSecondary text={sloganText} />;
}

export default Slogan;
