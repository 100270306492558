import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/CreditLeasing/common.styles';
import { TextContent } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';
import { useCreditLeasingSingleData } from '@/modules/CreditLeasing/pages/CreditLeasingSingle/hooks';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { colors } from '@/constants/theme';

function Header() {
  const {
    title,
    parent,
    path,
    templateFields: { pageType, title: templateTitle, text, isShowBreadCrumbs },
  } = useCreditLeasingSingleData();

  return (
    <Component>
      <ContentContainer>
        {!isShowBreadCrumbs && (
          <BreadcrumbsWrapper>
            <Breadcrumbs
              links={[
                { label: 'Главная', link: '/' },
                {
                  label:
                    pageType && pageType === 'credit' ? 'Кредит' : 'Лизинг',
                  link: parent?.path ?? '',
                },
                {
                  label: title,
                  link: path,
                },
              ]}
            />
          </BreadcrumbsWrapper>
        )}

        <Title>{templateTitle}</Title>

        {text && (
          <TextContentWrapper>
            <TextContent text={text} />
          </TextContentWrapper>
        )}
      </ContentContainer>
    </Component>
  );
}

export default Header;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const BreadcrumbsWrapper = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;

const TextContentWrapper = styled.div`
  margin-top: 20px;
`;
