import React from 'react';
import styled from 'styled-components';

import { useLeadershipData } from '@/modules/Leadership/Leadership.hooks';
import HeroWithoutContent from '@/components/HeroWithoutContent';
import LinkTabs from '@/components/LinkTabs';
import FirstPersons from '@/modules/Leadership/components/FirstPersons';
import Leaders from '@/modules/Leadership/components/Leaders';
import Layout from '@/layout';

function Leadership() {
  const {
    heroImage,
    heroTabletImage,
    heroMobileImage,
    linksTabs,
    directorsTitle,
    directorsItems,
    governingTitle,
    governingItems,
  } = useLeadershipData();

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
          holding
        />
        <LinkTabs linksTabs={linksTabs} />
        <FirstPersons />
        <Leaders title={directorsTitle} items={directorsItems} />
        <Leaders title={governingTitle} items={governingItems} />
      </Component>
    </Layout>
  );
}

export default Leadership;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
