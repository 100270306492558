import React from 'react';
import Tippy from '@tippyjs/react';
import styled, { css } from 'styled-components';

import { isBrowser } from '@tager/web-core';

import ArrowDownIcon from '@/assets/svg/select-arrow-down.svg';
import TippyPopup from '@/components/Redesign/TippyPopup';
import LockIcon from '@/assets/svg/select-lock.svg';
import { colors } from '@/constants/theme';
import {
  appearanceEffectStyle,
  disappearanceStyle,
} from '@/components/Redesign/styles';
import SelectOptions from '@/components/Redesign/Select/components/SelectOptions';
import { useSelect } from '@/hooks/useSelect';

import { SelectProps } from './types';

function Select<T>({
  label,
  value,
  options,
  onChange,
  selectOptions,
}: SelectProps<T>) {
  const { ref, isOpened, setOpened } = useSelect<HTMLDivElement>();

  const isLabelInner = selectOptions?.isLabelInner;

  const select = (
    <SelectField
      onClick={(e) => {
        e.stopPropagation();
        setOpened(!isOpened);
      }}
      disabled={selectOptions?.disabled}
    >
      <Label isSelected={!!value} isVisible isSelectLabel>
        {value?.label ?? (isLabelInner ? label : '')}
      </Label>

      <Icon isOpened={isOpened}>
        {!selectOptions?.disabled ? (
          <ArrowDownIcon />
        ) : (
          <Tippy
            touch="hold"
            content={<TippyPopup>{selectOptions.disabledHint}</TippyPopup>}
            placement="bottom"
            interactive
            maxWidth={250}
            appendTo={isBrowser() ? document.body : 'parent'}
          >
            <LockIcon />
          </Tippy>
        )}
      </Icon>
    </SelectField>
  );

  return (
    <Component>
      <Label isVisible={!(isLabelInner && !value)}>{label}</Label>

      <Main isOpened={isOpened}>
        {select}

        <Menu ref={ref} isVisible={isOpened}>
          {select}

          <SelectOptionsWrapper>
            <SelectOptions
              value={value}
              options={options}
              onChange={onChange}
              hasMaxHeight
            />
          </SelectOptionsWrapper>
        </Menu>
      </Main>
    </Component>
  );
}

export default Select;

const Component = styled.div``;

const Label = styled.p<{
  isVisible: boolean;
  isSelected?: boolean;
  isSelectLabel?: boolean;
}>`
  flex: 1;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.black};

  ${appearanceEffectStyle};

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 600;
    `}

  ${({ isSelectLabel }) =>
    isSelectLabel &&
    css`
      overflow: hidden;
      white-space: nowrap;

      ${disappearanceStyle};
    `}
`;

const Main = styled.div<{ isOpened: boolean }>`
  position: relative;
  height: 40px;
  margin-top: 10px;
  background: ${colors.white};
  border: 1px solid ${colors.gray1300};
  border-radius: 10px;

  ${({ isOpened }) =>
    isOpened &&
    css`
      border-color: transparent;
    `}
`;

const SelectField = styled.button`
  padding: 12px 10px 11px 20px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  &:disabled {
    pointer-events: none;
  }
`;

const Icon = styled.div<{ isOpened: boolean }>`
  pointer-events: auto;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  transition: 0.3s;

  svg {
    width: 100%;
    height: 100%;
    color: ${colors.gray1500};
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(180deg);
    `}
`;

const Menu = styled.div<{ isVisible: boolean }>`
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  padding-bottom: 20px;
  background: ${colors.white};
  border: 1px solid ${colors.gray1300};
  border-radius: 10px;
  box-shadow: ${colors.grayShadow};

  ${appearanceEffectStyle};
`;

const SelectOptionsWrapper = styled.div`
  margin-top: 10px;
`;
