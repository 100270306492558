import React from 'react';
import styled from 'styled-components';

import { useServiceValuationLandingData } from '@/modules/ServiceValuationLanding/hooks';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ContentContainer } from '@/modules/ServiceValuationLanding/common.styles';

function Navigation() {
  const { title, path } = useServiceValuationLandingData();

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={[
            { label: 'Главная', link: '/' },
            { label: 'Сервис', link: '/service' },
            { label: title, link: path },
          ]}
        />
      </ContentContainer>
    </Component>
  );
}

export default Navigation;

const Component = styled.section`
  padding: 10px 0;
`;
