import React, { useEffect, useState } from 'react';

export function useFixedElement(elementRef: React.RefObject<HTMLElement>) {
  const [isFixed, setFixed] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      if (!elementRef || !elementRef.current) {
        return;
      }

      setFixed(
        Math.round(elementRef.current.getBoundingClientRect().top) <= 50
      );
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [elementRef]);

  return isFixed;
}
