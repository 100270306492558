import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Button from '@/components/Button';

import { PartnerProgramCardProps } from './types';

function PartnerProgramCard({
  logo,
  name,
  description,
  buttonTitle,
  onClick,
}: PartnerProgramCardProps) {
  return (
    <Component>
      <Image loading="lazy" {...convertThumbnailToPictureProps(logo)} />

      <Content>
        <Top>
          <Name>{name}</Name>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Top>

        <ButtonWrapper>
          <Button variant="outlined" onClick={onClick}>
            {buttonTitle}
          </Button>
        </ButtonWrapper>
      </Content>
    </Component>
  );
}

export default PartnerProgramCard;

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray1300_alpha50};
`;

const Image = styled(Picture)`
  justify-content: flex-start;

  picture,
  img {
    flex: 0 0 130px;
    max-width: 130px;
    height: 80px;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div``;

const Name = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-top: 20px;
  color: ${(props) => props.theme.black200};
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};

  ul,
  ol {
    padding-left: 16px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
