import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { useJetourHomeData } from '@/modules/JetourHome/hooks';
import { ServicesBanners } from '@/components/LandingWidgets';

function Services() {
  const { servicesItems } = useJetourHomeData();

  if (!servicesItems || servicesItems.length === 0) {
    return null;
  }

  return (
    <Component id="services">
      <ServicesBanners
        id={3}
        services={servicesItems.map(
          ({ image, mobileImage, title, buttonText, buttonLink }) => ({
            image,
            mobileImage,
            title: title ?? '',
            buttonText: buttonText ?? '',
            buttonLink: buttonLink ?? '',
          })
        )}
      />
    </Component>
  );
}

export default Services;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
