import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br, useMedia } from '@tager/web-core';

import { useSubscriptionData } from '@/modules/Subscription/Subscription.hooks';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import HeroBanner from './components/HeroBanner';
import SubscriptionType from './components/SubscriptionType';

function Hero() {
  const {
    heroTitle,
    heroSubtitle,
    heroSubscriptionTypes,
    heroBanner,
    heroBannerMobile,
  } = useSubscriptionData();

  const isMobile = useMedia('(max-width: 767px)');

  if (heroBanner) {
    return <HeroBanner image={heroBanner} imageMobile={heroBannerMobile} />;
  }

  if (!heroSubscriptionTypes || heroSubscriptionTypes.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{heroTitle}</Title>
        <Subtitle>{nl2br(heroSubtitle)}</Subtitle>
        <Cards>
          {heroSubscriptionTypes.map(({ title, image }, index) => (
            <SubscriptionType
              key={index}
              title={title}
              image={image}
              isBig={!isMobile ? index === 1 : index === 0}
            />
          ))}
        </Cards>
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-top: 75px;
  background: ${colors.main};

  ${media.mobile(css`
    padding-top: 30px;
  `)}
`;

const ContentContainer = styled.div`
  max-width: 1032px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 45px;
  line-height: 53px;
  text-align: center;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 36px;
    line-height: 42px;
  `)}
`;

const Subtitle = styled.p`
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  max-width: 675px;
  margin: 15px auto 0;
  color: ${colors.gray200};

  ${media.mobile(css`
    margin: 20px auto 0;
  `)}
`;

const Cards = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 54px;

  ${media.mobile(css`
    margin-top: 27px;

    & > div {
      &:nth-child(1) {
        order: 2;
        margin-left: 4px;
      }

      &:nth-child(2) {
        order: 1;
        margin-left: 0;
      }

      &:nth-child(3) {
        order: 3;
      }
    }
  `)}
`;
