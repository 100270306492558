import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap, formatNumber } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import StockFilterInput from '@/components/StockFilterInput';
import Checkbox, { CheckboxAgreement } from '@/components/Checkbox';
import SuccessForm from '@/components/Forms/SuccessForm';
import Loader from '@/components/Loader';
import Button from '@/components/Button';
import { getAnnuityCreditPayment, getCreditPeriods } from '@/utils/common';
import StockFilterPeriod from '@/components/StockFilterPeriod';
import StockFilterAmount from '@/components/StockFilterAmount';

import {
  initialFinanceCalculationFormValues,
  validationFinanceCalculationFormSchema,
} from './helpers';
import {
  FinanceCalculationFormFields,
  FinanceCalculationFormProps,
} from './types';

function FinanceCalculationForm({
  title,
  subtitle,
  rate,
  minAmount,
  maxAmount,
  minPeriod,
  maxPeriod,
  buttonTitle,
  onSubmit,
}: FinanceCalculationFormProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setErrors,
    isSubmitting,
  } = useFormik<FinanceCalculationFormFields>({
    initialValues: initialFinanceCalculationFormValues,
    validationSchema: validationFinanceCalculationFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(values).then(() => {
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<FinanceCalculationFormFields>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      amount: minAmount,
      period: minPeriod,
      payment: getAnnuityCreditPayment(rate, minAmount, minPeriod),
    });
  }, []);

  const handleChangeAmount = (amount: number) => {
    setValues({
      ...values,
      amount,
      payment: getAnnuityCreditPayment(rate, amount, values.period),
    });
  };

  const handleChangePeriod = (period: number) => {
    setValues({
      ...values,
      period,
      payment: getAnnuityCreditPayment(rate, values.amount, period),
    });
  };

  return (
    <Component>
      {isFormSend ? (
        <SuccessForm />
      ) : (
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>

          <Main>
            <Left>
              <Payment>
                <PaymentTitle>Платеж</PaymentTitle>
                <PaymentValue>
                  ~{formatNumber(values.payment)} BYN / мес.
                </PaymentValue>
              </Payment>

              <Calculator>
                <Field>
                  <StockFilterAmount
                    label="Сумма финансирования"
                    min={minAmount}
                    max={maxAmount}
                    value={values.amount}
                    badge="BYN"
                    onChange={handleChangeAmount}
                  />
                </Field>

                <Field>
                  <StockFilterPeriod
                    label="Срок финансирования"
                    periods={getCreditPeriods(minPeriod, maxPeriod)}
                    value={values.period}
                    onChange={handleChangePeriod}
                  />
                </Field>
              </Calculator>
            </Left>
            <Right>
              <Field>
                <StockFilterInput
                  id="name"
                  name="name"
                  label="Имя"
                  value={values.name}
                  onChange={handleChange}
                  errorMessage={
                    errors.name && touched.name ? errors.name : undefined
                  }
                />
              </Field>

              <Field>
                <StockFilterInput
                  id="phone"
                  name="phone"
                  label="Телефон"
                  placeholder="+375 (__) ___-__-__"
                  value={values.phone}
                  onChange={handleChange}
                  errorMessage={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                />
              </Field>

              <Field>
                <Checkbox
                  label="Авто в зачёт"
                  onChange={(value) => setFieldValue('tradeIn', value)}
                />
              </Field>

              <Field>
                <CheckboxAgreement
                  checked={userAgreement}
                  onChange={() => setUserAgreement(!userAgreement)}
                />
              </Field>

              <ButtonWrapper>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isSubmitting ? !userAgreement : true}
                >
                  {!isSubmitting ? (
                    buttonTitle
                  ) : (
                    <Loader color={colors.white} />
                  )}
                </Button>
              </ButtonWrapper>
            </Right>
          </Main>
        </Form>
      )}
    </Component>
  );
}

export default FinanceCalculationForm;

const Component = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px;
  border-radius: 10px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tablet(
    css`
      padding: 40px 20px;
    `
  )}
`;

const Form = styled.form`
  width: 100%;
  max-width: 980px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(
    css`
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    `
  )}
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.blue100};
`;

const Main = styled.div`
  display: flex;
  margin-top: 40px;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  flex: 1 1 50%;
  max-width: 50%;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 6px;
  background: ${colors.blue_alpha05};
`;

const PaymentTitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue100};
`;

const PaymentValue = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-left: 10px;
  white-space: nowrap;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 19px;
  `)}
`;

const Calculator = styled.div`
  margin-top: 40px;
`;

const Field = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const Right = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  margin-left: 60px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    margin: 40px 0 0;
  `)}
`;

const ButtonWrapper = styled.div`
  max-width: 289px;
  margin-top: 30px;

  ${media.tablet(css`
    max-width: none;
    margin-top: 20px;
  `)}
`;
