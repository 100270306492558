import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import Banner from '@/components/Redesign/Banner';
import { colors } from '@/constants/theme';
import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';

function PhoneBanner() {
  const {
    templateFields: {
      phoneBannerImage: image,
      phone: phoneNumber,
      phoneBannerText,
      phoneBannerTextMobile: tabletText,
    },
  } = useEmergencyManagerData();

  const isTablet = useMedia('(max-width: 1259px)');
  const title = isTablet ? tabletText : phoneNumber;

  if (!image || !phoneNumber) {
    return null;
  }

  return (
    <Component>
      <Banner
        title={title || ''}
        link={getPhoneLink(phoneNumber)}
        image={image}
        subtitle={phoneBannerText || ''}
        color={colors.purpleGradient200}
        size={'small'}
        orientation={'horizontal'}
      />
    </Component>
  );
}

export default PhoneBanner;

const Component = styled.div`
  ${media.tablet(css`
    margin-bottom: 40px;
  `)}
`;
