import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';

import VinInfo from '@/components/Forms/HelpOnTheRoadForm/components/VinInfo';
import Checkbox, { CheckboxAgreement } from '@/components/Checkbox';
import Loader from '@/components/Loader';
import { media } from '@/utils/mixin';
import { OptionType } from '@/typings/common';
import useSettingItem from '@/hooks/useSettingItem';
import StockFilterInput from '@/components/StockFilterInput';
import StockFilterSelect from '@/components/StockFilterSelect';
import { getZoomosModels, getZoomosBrands } from '@/services/requests';

import {
  BottomWrapper,
  PriceWrapper,
  Price,
  PriceLabel,
  StyledButton,
} from '../../common.styles';

import { ContactFormFields, ContactFormProps } from './types';
import {
  defaultContactFormFields,
  handleValidateContactForm,
  isHandleChangeNumber,
} from './helpers';

function ContactForm({ price, initialFields, onSubmit }: ContactFormProps) {
  const [brand, setBrand] = useState<OptionType>({
    label: initialFields?.brand ?? '',
    value: initialFields?.brand ?? '',
  });

  const [brandsList, setBrandsList] = useState<OptionType[]>([]);

  const [model, setModel] = useState<OptionType>({
    label: initialFields?.model ?? '',
    value: initialFields?.model ?? '',
  });

  const [modelsList, setModelsList] = useState<OptionType[]>([]);

  const [userAgreement, setUserAgreement] = useState(false);
  const [userServicesAgreement, setUserServicesAgreement] = useState(false);

  const noteText = useSettingItem('HELP_ON_THE_ROAD_TEXT');
  const userServicesAgreementText = useSettingItem(
    'HELP_ON_THE_ROAD_AGREEMENT_TEXT'
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await getZoomosBrands();
        setBrandsList(response.map((item) => ({ value: item, label: item })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useUpdateEffect(() => {
    if (!brand.value) {
      return;
    }

    (async () => {
      try {
        const response = await getZoomosModels(brand.value);
        setModelsList(response.map((item) => ({ value: item, label: item })));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [brand]);

  const onSubmitForm = async (
    values: ContactFormFields,
    { setSubmitting, setErrors }: FormikHelpers<ContactFormFields>
  ) => {
    setSubmitting(true);

    try {
      await onSubmit?.({ ...values, brand: brand.value, model: model.value });
    } catch (error: any) {
      console.log(error);
      const errorMap = convertRequestErrorToMap(
        error
      ) as FormikErrors<ContactFormFields>;
      setErrors(errorMap);
      setSubmitting(false);
    }

    setSubmitting(false);
  };

  return (
    <Formik<ContactFormFields>
      onSubmit={onSubmitForm}
      validateOnChange
      initialValues={initialFields ?? defaultContactFormFields}
      validate={handleValidateContactForm}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        isSubmitting,
      }) => {
        return (
          <Component onSubmit={handleSubmit} autoComplete="off" noValidate>
            <FormRow>
              <FormField>
                <StockFilterInput
                  id="name"
                  name="name"
                  label="ФИО"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    touched.name && errors.name ? errors.name : undefined
                  }
                />
              </FormField>

              <FormField>
                <StockFilterInput
                  id="phone"
                  name="phone"
                  label="Телефон"
                  placeholder="+375 (__) ___-__-__"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    touched.phone && errors.phone ? errors.phone : undefined
                  }
                />
              </FormField>

              <FormField>
                <StockFilterInput
                  id="email"
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={
                    touched.email && errors.email ? errors.email : undefined
                  }
                />
              </FormField>
            </FormRow>

            <FormRow>
              <FormField>
                <StockFilterSelect
                  label="Марка"
                  value={brand}
                  options={brandsList}
                  onChange={(option) => {
                    setBrand(option);
                    setModel({ label: '', value: '' });
                  }}
                />
              </FormField>

              <FormField>
                <StockFilterSelect
                  label="Модель"
                  value={model}
                  options={modelsList}
                  onChange={setModel}
                  disabled={!brand.value}
                />
              </FormField>

              <FormField>
                <StockFilterInput
                  id="vin"
                  name="vin"
                  label="VIN номер"
                  value={values.vin}
                  onChange={(event) =>
                    setFieldValue('vin', isHandleChangeNumber(event))
                  }
                  onBlur={handleBlur}
                  errorMessage={
                    touched.vin && errors.vin ? errors.vin : undefined
                  }
                />
                <VinInfoWrapper>
                  <VinInfo />
                </VinInfoWrapper>
              </FormField>
            </FormRow>

            <Note>{noteText}</Note>

            <Checkboxes>
              <CheckboxItem>
                <CheckboxAgreement
                  checked={userAgreement}
                  onChange={() => setUserAgreement(!userAgreement)}
                />
              </CheckboxItem>

              <CheckboxItem>
                <Checkbox
                  label={userServicesAgreementText ?? ''}
                  checked={userServicesAgreement}
                  onChange={() =>
                    setUserServicesAgreement(!userServicesAgreement)
                  }
                />
              </CheckboxItem>
            </Checkboxes>

            <BottomWrapper>
              <PriceWrapper>
                <PriceLabel>К оплате</PriceLabel>
                <Price>{price} BYN</Price>
              </PriceWrapper>

              <StyledButton
                variant="contained"
                type="submit"
                disabled={
                  !values.name ||
                  !values.phone ||
                  !values.email ||
                  !values.vin ||
                  !brand.value ||
                  !model.value ||
                  !userAgreement ||
                  !userServicesAgreement
                }
              >
                {isSubmitting ? <Loader color={'#fff'} /> : 'Далее'}
              </StyledButton>
            </BottomWrapper>
          </Component>
        );
      }}
    </Formik>
  );
}

export default ContactForm;

const Component = styled.form``;

const FormRow = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 30px;
  }

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const FormField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 30px;

    ${media.mobile(css`
      margin-left: 0;
      margin-top: 30px;
    `)}
  }
`;

const VinInfoWrapper = styled.div`
  margin-left: 10px;
`;

const Note = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 40px;
  color: ${(props) => props.theme.blue100};

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const CheckboxItem = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
