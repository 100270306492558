import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/Checkbox';
import { ContentContainer } from '@/modules/CarModelCompareLanding/common.styles';
import {
  useCarModelCompareLandingContext,
  useCarModelCompareLandingData,
} from '@/modules/CarModelCompareLanding/hooks';
import { CarCard } from '@/components/CarModelCompareLandingWidgets';

function ModelsMobile() {
  const { models } = useCarModelCompareLandingData();
  const { isDifferentParams, setDifferentParams } =
    useCarModelCompareLandingContext();

  if (!models || models.length === 0) {
    return null;
  }

  return (
    <ContentContainer>
      <Checkbox
        label="Только различающиеся параметры"
        checked={isDifferentParams}
        onChange={setDifferentParams}
      />
      <CarsList>
        {models.map(({ image, name, price, link, isNewTab }, index) => (
          <CarItem key={index}>
            <CarCard
              image={image}
              name={name ?? ''}
              price={price ?? ''}
              link={link ?? ''}
              isNewTab={isNewTab}
              isMobile
            />
          </CarItem>
        ))}
      </CarsList>
    </ContentContainer>
  );
}

export default ModelsMobile;

const CarsList = styled.div`
  margin-top: 20px;
`;

const CarItem = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
