import React from 'react';
import styled from 'styled-components';

import { useAtlantm30Data } from '@/modules/Atlantm30/Atlantm30.hooks';

import DetailStep from './components/DetailStep';

function DetailSteps() {
  const { stepsDetailsItems } = useAtlantm30Data();

  if (!stepsDetailsItems) {
    return null;
  }

  return (
    <Component>
      {stepsDetailsItems.map(
        ({ content, imageMain, imageBackground }, index) => (
          <DetailStep
            key={index}
            index={index}
            content={content}
            imageMain={imageMain}
            imageBackground={imageBackground}
            isYellowBg={index % 2 === 0}
          />
        )
      )}
    </Component>
  );
}

export default DetailSteps;

const Component = styled.section`
  & > section:last-child {
    & > div > div > div:last-child {
      & > div:first-child {
        bottom: -83px;
      }
    }
  }
`;
