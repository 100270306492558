import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import {
  getHelpOnTheRoadPaymentTypes,
  submitHelpOnTheRoadContacts,
  submitHelpOnTheRoadPaymentProvider,
} from '@/services/orders/orders-service';
import { scrollToTop } from '@/utils/scroll';
import Steps from '@/components/Forms/HelpOnTheRoadForm/components/Steps';
import CloseIcon from '@/assets/svg/close-12.svg';
import { useTypedSelector } from '@/store/store';
import { getCabinetPersonalData } from '@/store/reducers/cabinet';
import { usePageUrl } from '@/hooks/usePageUrl';
import ArrowIcon from '@/assets/svg/service/arrow-right.svg';
import { redirect } from '@/utils/common';

import ContactForm from './components/ContactForm';
import PaymentProviders from './components/PaymentProviders';
import OplatiQr from './components/OplatiQr';
import { HelpOnTheRoadFormProps } from './types';
import { ContactFormFields } from './components/ContactForm/types';
import { defaultContactFormFields } from './components/ContactForm/helpers';

function HelpOnTheRoadForm({
  id,
  title,
  price,
  handleBack,
}: HelpOnTheRoadFormProps) {
  const isTablet = useMedia('(max-width: 1259px)');
  const url = usePageUrl();
  const [stepType, setStepType] = useState<
    'CONTACT' | 'PAYMENT_PROVIDERS' | 'OPLATI'
  >('CONTACT');

  const [paymentProviders, setPaymentProviders] = useState<string[] | null>(
    null
  );
  const [paymentType, setPaymentType] = useState<string>('');
  const [orderId, setOrderId] = useState<string | null>(null);
  const [paymentProviderData, setPaymentProviderData] = useState<{
    qrCode: string;
    redirectUrl: string;
  } | null>(null);

  const profilePersonalData = useTypedSelector(getCabinetPersonalData);

  const [contactFormValues, setContactFormValues] =
    useState<ContactFormFields | null>(
      profilePersonalData
        ? {
            ...defaultContactFormFields,
            name: `${profilePersonalData.lastName} ${profilePersonalData.name} ${profilePersonalData.patronymic}`,
            phone: profilePersonalData.phones[0] ?? '',
            email: profilePersonalData.emails[0] ?? '',
          }
        : null
    );

  const [isLoading, setLoading] = useState(false);

  const handleCloseClick = () => {
    handleBack?.();
    scrollToTop('auto');
  };

  const handleBackClick = () => {
    if (orderId && paymentProviders && paymentProviderData) {
      setPaymentType('');
      setPaymentProviderData(null);
      setStepType('PAYMENT_PROVIDERS');
    } else if (orderId && paymentProviders) {
      setPaymentProviders(null);
      setOrderId(null);
      setStepType('CONTACT');
    } else {
      handleBack?.();
    }

    scrollToTop('auto');
  };

  const onSubmitContactForm = async (values: ContactFormFields) => {
    try {
      const newOrderId = await submitHelpOnTheRoadContacts({
        ...values,
        plan: id,
        total: +price,
      });
      const allPaymentTypes = await getHelpOnTheRoadPaymentTypes(newOrderId.id);
      setPaymentProviders(allPaymentTypes.providers);
      setOrderId(newOrderId.id);
      setContactFormValues(values);
      setStepType('PAYMENT_PROVIDERS');
      scrollToTop('auto');
    } catch (error: any) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  async function onSubmitPaymentProviders(): Promise<void> {
    setLoading(true);

    try {
      localStorage.setItem(
        'helpOnTheRoadEmail',
        contactFormValues?.email ?? ''
      );

      if (orderId) {
        const response = await submitHelpOnTheRoadPaymentProvider(
          orderId,
          paymentType === 'WEBPAY_ERIP' ? 'WEBPAY' : paymentType,
          {
            total: Number(price),
            returnUrl: `${url}?success&id=${orderId}`,
            erip: paymentType === 'WEBPAY_ERIP',
          }
        );

        if (
          !isTablet &&
          (paymentType === 'WEBPAY' || paymentType === 'WEBPAY_ERIP')
        ) {
          redirect(response.redirectUrl, { hardReload: true });
        } else if (isTablet) {
          redirect(response.redirectUrl, { hardReload: true });
        }

        setPaymentProviderData(response);

        if (!isTablet && paymentType === 'OPLATI') {
          setStepType('OPLATI');
          scrollToTop('auto');
        }
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }

    if (paymentType === 'OPLATI') {
      setLoading(false);
    }
  }

  return (
    <Component>
      <CloseButton onClick={handleCloseClick}>
        <CloseIcon />
      </CloseButton>

      <BackButton onClick={handleBackClick}>
        <ArrowIcon />
      </BackButton>

      <Title>{title}</Title>

      <StepsWrapper>
        <Steps steps={[1, 2]} activeStep={stepType === 'CONTACT' ? 1 : 2} />
      </StepsWrapper>

      <Main>
        {stepType === 'CONTACT' && (
          <ContactForm
            initialFields={contactFormValues}
            onSubmit={onSubmitContactForm}
            price={price}
          />
        )}

        {paymentProviders && stepType === 'PAYMENT_PROVIDERS' && (
          <PaymentProviders
            onSubmit={onSubmitPaymentProviders}
            isLoading={isLoading}
            price={price}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            paymentProviders={paymentProviders}
          />
        )}

        {!isTablet && contactFormValues && stepType === 'OPLATI' && (
          <OplatiQr
            orderId={orderId ?? ''}
            packageId={id}
            qrCode={paymentProviderData?.qrCode ?? ''}
            contactValues={contactFormValues}
            total={price}
          />
        )}
      </Main>
    </Component>
  );
}

export default HelpOnTheRoadForm;

const Component = styled.div`
  position: relative;
  padding: 60px;
  border-radius: 10px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tablet(css`
    padding: 60px 20px;
  `)}

  ${media.mobile(css`
    border-radius: 0;
    box-shadow: none;
  `)}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 40px;
  right: 30px;
  width: 20px;
  height: 20px;
  transition: 0.3s;

  ${media.tablet(css`
    top: 35px;
  `)}

  ${media.mobile(css`
    top: 20px;
    right: 20px;
  `)}

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${(props) => props.theme.black100_alpha30};
    }
  }

  &:hover {
    opacity: 0.6;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.black100_alpha30};
  border-radius: 50px;
  transition: 0.3s;

  ${media.tablet(css`
    width: 30px;
    height: 30px;
  `)}

  ${media.mobile(css`
    top: 15px;
    left: 20px;
  `)}

  svg {
    width: 14px;
    height: 14px;
    transform: rotate(180deg);

    ${media.tablet(css`
      width: 12px;
      height: 12px;
    `)}
  }

  &:hover {
    opacity: 0.6;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)};
`;

const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Main = styled.div`
  margin-top: 30px;
`;
