import React from 'react';
import styled from 'styled-components';

import { useAboutData } from '@/modules/About/About.hooks';
import HeroWithoutContent from '@/components/HeroWithoutContent';
import LinkTabs from '@/components/LinkTabs';
import Layout from '@/layout';

import Birthday from './components/Birthday';
import Stats from './components/Stats';
import Quote from './components/Quote';
import Brands from './components/Brands';
import History from './components/History';
import HoldingStructure from './components/HoldingStructure';
import AboutBlock from './components/AboutBlock';

function About() {
  const { heroImage, heroTabletImage, heroMobileImage, linksTabs } =
    useAboutData();

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
          holding
        />
        <LinkTabs linksTabs={linksTabs} />
        <AboutBlock />
        <HoldingStructure />
        <Stats />
        <Quote />
        <Brands />
        <History />
        <Birthday />
      </Component>
    </Layout>
  );
}

export default About;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
