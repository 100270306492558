import React, { MutableRefObject, useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import FormRateCar from '@/components/FormRateCar';
import { useTypedSelector } from '@/store/store';
import { selectOnlineUserChoice } from '@/store/reducers/online-valuation-user-data';

import CarValuationSubtitle from './components/CarValuationSubtitle';

interface Props {
  title: string;

  formRef: MutableRefObject<HTMLDivElement | null>;
  isChecked: boolean;

  switchTitle: string;
  switchLabel: string;
  onSwitch: () => void;
}

function FormPageHeader({
  title,
  formRef,
  isChecked,
  switchLabel,
  switchTitle,
  onSwitch,
}: Props) {
  const userChoice = useTypedSelector(selectOnlineUserChoice);
  const [isFormShown, setIsFormShown] = useState(false);

  return (
    <Component>
      <Title>{title}</Title>

      <Container ref={formRef}>
        <CarValuationSubtitle
          brand={userChoice.brand}
          model={userChoice.model}
          year={userChoice.year}
          setIsFormShown={setIsFormShown}
        />
        {isFormShown && (
          <FormContainer>
            <FormRateCar
              isHorizontalForm={false}
              isValuationPage={true}
              isChecked={isChecked}
            />
          </FormContainer>
        )}
      </Container>

      <BottomText>
        <BottomTextTitle>{switchTitle}</BottomTextTitle>{' '}
        <BottomTextSwitch onClick={() => onSwitch()}>
          {switchLabel}
        </BottomTextSwitch>
      </BottomText>
    </Component>
  );
}

export default FormPageHeader;

const Component = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Title = styled.h2`
  font-size: 40px;
  margin: 0 auto;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 36px;
  `)}
`;

const Container = styled.div`
  margin-top: 10px;
`;

const FormContainer = styled.div`
  margin: 30px auto 0 auto;
  max-width: 640px;
`;

const BottomText = styled.div`
  margin-top: 20px;

  ${media.mobile(css`
    display: flex;
    flex-direction: column;
  `)}
`;

const BottomTextTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.blue100};
`;

const BottomTextSwitch = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.main};
  cursor: pointer;

  ${media.mobile(css`
    padding-top: 10px;
  `)}
`;
