import { Page } from '@/typings/page';
import useCurrentPage from '@/hooks/useCurrentPage';
import { getFirstDateOfYear, getLastDateOfYear } from '@/utils/date';
import { usePageUrl } from '@/hooks/usePageUrl';
import useSettingItem from '@/hooks/useSettingItem';
import { getEventMicromarking } from '@/utils/seo';

import { ElectrocarsLanding } from './types';

export function useElectrocarsLandingData(): Page<ElectrocarsLanding>['templateFields'] {
  return useCurrentPage<Page<ElectrocarsLanding>>().templateFields;
}

export function useElectrocarsLandingEventMicromarking(): string[] {
  const { seoParams, templateFields } =
    useCurrentPage<Page<ElectrocarsLanding>>();
  const organization = useSettingItem('ORGANIZATION_NAME');
  const address = useSettingItem('MAIN_ADDRESS');
  const url = usePageUrl();

  const eventMicromarkingNames = [
    '✔️ Новые авто',
    '🔥 Электромобили',
    '✔️ Высокое качество',
  ];
  const eventMicromarkingData = eventMicromarkingNames.map((name) => ({
    image: templateFields.heroImage?.url ?? '',
    name,
    description: seoParams.description ?? '',
    startDate: getFirstDateOfYear(),
    endDate: getLastDateOfYear(),
    url,
    organization: organization ?? '',
    address: address ?? '',
  }));

  return eventMicromarkingData.map((data) => getEventMicromarking(data));
}
