import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import Tick from './components/Tick';
import { TicksProps } from './types';

function Ticks({ ticks, columns = 1 }: TicksProps) {
  return (
    <Component>
      <List columns={columns}>
        {ticks.map(
          ({ text }, index) =>
            text && <Tick key={`${index}-tick`} text={text}></Tick>
        )}
      </List>
    </Component>
  );
}

export default Ticks;

const Component = styled.div``;

const List = styled.ul<{ columns?: TicksProps['columns'] }>`
  display: grid;
  gap: 11px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
