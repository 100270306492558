import * as Yup from 'yup';

import { CarCheckFormFieldsConfig, CarCheckFormValues } from './types';

export const initialCarCheckFormValues: CarCheckFormValues = {
  name: '',
  phone: '',
  brand: '',
  model: '',
  message: '',
};

export const validationSchema = (
  fieldsConfig?: CarCheckFormFieldsConfig
): Yup.SchemaOf<CarCheckFormValues> =>
  Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    brand: Yup.string().when([], {
      is: () => fieldsConfig?.withBrand,
      then: Yup.string().required('Укажите марку автомобиля'),
      otherwise: Yup.string().notRequired(),
    }),
    model: Yup.string().when([], {
      is: () => fieldsConfig?.withModel,
      then: Yup.string().required('Укажите модель автомобиля'),
      otherwise: Yup.string().notRequired(),
    }),
    message: Yup.string().notRequired(),
  }) as Yup.SchemaOf<CarCheckFormValues>;
