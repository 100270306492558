import styled, { css } from 'styled-components';

import YandexMap from '@/components/Redesign/YandexMap';
import { media } from '@/utils/mixin';
import { useRefitData } from '@/modules/RefitLanding/hooks';
import { ContentContainer } from '@/modules/RefitLanding/common.styles';
import { DealerMapCardProps } from '@/components/Redesign/YandexMap/types';
import { getLinkYandexMap } from '@/utils/common';

function Map() {
  const {
    templateFields: { markers },
  } = useRefitData();

  if (!markers?.length) {
    return null;
  }

  const dealers: DealerMapCardProps[] = markers.map(
    ({ latitude, longitude, name, phone, address, image }) => ({
      latitude: Number(latitude) ?? 0,
      longitude: Number(longitude) ?? 0,
      name: name ?? '',
      phone: phone ?? '',
      address: address ?? '',
      link: getLinkYandexMap(Number(latitude) ?? 0, Number(longitude) ?? 0),
      image,
    })
  );

  return (
    <Component>
      <ContentContainer>
        <YandexMap dealers={dealers} />
      </ContentContainer>
    </Component>
  );
}

export default Map;

const Component = styled.section`
  margin-top: 60px;
  margin-bottom: 20px;

  ${media.tablet(css`
    margin-top: 40px;
    margin-bottom: 0;

    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
