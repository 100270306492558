import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/SollersHome/common.styles';
import { useSollersHomeData } from '@/modules/SollersHome/hooks';
import { GuaranteeBanner } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

function Slogan() {
  const { sloganImage, sloganMobileImage, sloganText } = useSollersHomeData();

  if (!sloganImage) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <GuaranteeBanner
          image={sloganImage}
          imageMobile={sloganMobileImage}
          text={sloganText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default Slogan;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
