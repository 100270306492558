import React from 'react';
import styled, { css } from 'styled-components';

import { useInsuranceData } from '@/modules/Insurance/Insurance.hooks';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function CarInsurance() {
  const { carInsuranceTitle, carInsuranceText, carInsuranceNote } =
    useInsuranceData();

  return (
    <Component>
      <Shaft>
        <Wrapper>
          <Title>{carInsuranceTitle}</Title>
          <Text dangerouslySetInnerHTML={{ __html: carInsuranceText ?? '' }} />
          <Note>{carInsuranceNote}</Note>
        </Wrapper>
      </Shaft>
    </Component>
  );
}

export default CarInsurance;

const Component = styled.div`
  flex: 0 0 auto;
  width: 385px;
  margin-left: 55px;

  ${media.tablet(css`
    position: relative;
    top: 0;
    width: auto;
    margin-left: 0;
    margin-top: 30px;
  `)}
`;

const Shaft = styled.div`
  position: absolute;
  width: 385px;
  top: 40px;
  bottom: 90px;

  ${media.tablet(css`
    position: relative;
    width: auto;
    top: 0;
    bottom: 0;
  `)}
`;

const Wrapper = styled.div`
  position: sticky;
  top: 90px;
  padding: 60px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.tablet(css`
    padding: 0;
    background: ${(props) => props.theme.white};
    box-shadow: none;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Text = styled.div`
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.blue100};

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    a {
      border-bottom: 1px solid transparent;

      &:hover {
        color: ${colors.main};
        border-color: ${colors.main};
      }
    }
  }
`;

const Note = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  color: ${colors.gray500};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;
