import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/RefitLanding/common.styles';
import { media } from '@/utils/mixin';
import { useRefitData } from '@/modules/RefitLanding/hooks';
import { RefitTypesItemProps } from '@/components/Redesign/RefitTypes/components/RefitTypesItem/types';
import RefitTypes from '@/components/Redesign/RefitTypes';

function RefitTypesGallery() {
  const {
    templateFields: { refurbishmentCars, refurbishmentTitle },
  } = useRefitData();

  if (
    !refurbishmentTitle ||
    !refurbishmentCars ||
    refurbishmentCars.length === 0
  ) {
    return null;
  }

  const refitData: RefitTypesItemProps[] = refurbishmentCars.map(
    ({ image, text, link }) => ({
      text: text ?? '',
      link: link ?? '',
      image,
    })
  );

  return (
    <Component>
      <ContentContainer>
        <RefitTypes title={refurbishmentTitle} refitData={refitData} />
      </ContentContainer>
    </Component>
  );
}

export default RefitTypesGallery;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
