import React from 'react';
import styled, { css } from 'styled-components';

import ConsultationFormSecondary from '@/components/Forms/ConsultationFormSecondary';
import { useVedaemLandingData } from '@/modules/VedaemLanding/hooks';
import { ContentContainer } from '@/modules/VedaemLanding/common.styles';
import { media } from '@/utils/mixin';
import { ConsultationFormSecondaryFields } from '@/components/Forms/ConsultationFormSecondary/types';
import { submitVedaemLanding } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function Form() {
  const { formImage, formTitle, formButtonText, formGoogleEvent } =
    useVedaemLandingData();

  if (!formImage || !formTitle || !formButtonText) {
    return null;
  }

  const sendConsultationForm = async (
    values: ConsultationFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitVedaemLanding(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ConsultationFormSecondary
          image={formImage}
          title={formTitle}
          buttonText={formButtonText}
          onSubmit={sendConsultationForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 60px 0;

  ${media.tablet(css`
    padding: 40px 0;
  `)}

  ${media.tablet(css`
    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
