export const getComplectationText = (complectationCount: number) => {
  switch (complectationCount) {
    case 1:
      return 'комплектация';
    case 2:
    case 3:
    case 4:
      return 'комплектации';
    default:
      return 'комплектаций';
  }
};
