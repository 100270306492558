import React from 'react';
import styled, { css } from 'styled-components';

import ConsultationFormSecondary from '@/components/Forms/ConsultationFormSecondary';
import { useAutoSelectionLandingData } from '@/modules/AutoSelectionLanding/hooks';
import { ContentContainer } from '@/modules/AutoSelectionLanding/common.styles';
import { media } from '@/utils/mixin';
import { ConsultationFormSecondaryFields } from '@/components/Forms/ConsultationFormSecondary/types';
import { submitAutoSelectionLanding } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function Form() {
  const { formImage, formTitle, formButtonText, formGoogleEvent } =
    useAutoSelectionLandingData();

  if (!formImage || !formTitle || !formButtonText) {
    return null;
  }

  const sendConsultationForm = async (
    values: ConsultationFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitAutoSelectionLanding(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <ConsultationFormSecondary
          image={formImage}
          title={formTitle}
          buttonText={formButtonText}
          onSubmit={sendConsultationForm}
          fieldsConfig={{ withMessage: true }}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0 60px;

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}

  ${media.tablet(css`
    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
