import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { Nullable } from '@tager/web-core';

import { StoreDispatch } from '@/store/store';
import {
  OnlineValuationUserChoiceModel,
  UserChoiceParametersModel,
} from '@/modules/ValueCar/ValueCar.types';
import { OnlineCheckedValuesModel } from '@/modules/ValueCar/components/OnlineValuation/interfaces';

import CustomCheckbox from './component/CustomCheckbox';

interface Props {
  value: string;
  dispatch: StoreDispatch;
  isContentSelect?: Nullable<string>;
  userChoiceParameter?: UserChoiceParametersModel;
  setValue?: ActionCreatorWithPayload<string>;
  setParameter?: ActionCreatorWithPayload<UserChoiceParametersModel>;
  label?: Nullable<string>;
  checkedValues?: OnlineCheckedValuesModel;
  setCheckedValues?: Dispatch<SetStateAction<OnlineCheckedValuesModel>>;
  userChoice?: OnlineValuationUserChoiceModel;
}

function ValuesItem({
  value,
  isContentSelect,
  userChoiceParameter,
  dispatch,
  setValue,
  setParameter,
  label,
  checkedValues,
  setCheckedValues,
  userChoice,
}: Props) {
  function handleClickCheckbox() {
    if (isContentSelect !== value) {
      if (setValue) {
        dispatch(setValue(value));
      }
    }

    if (userChoiceParameter && label && value && setParameter) {
      dispatch(setParameter({ ...userChoiceParameter, [label]: value }));
    }
  }

  useEffect(() => {
    if (!checkedValues || !setCheckedValues || !userChoice) return;
    setCheckedValues({
      ...checkedValues,
      parametersCheck:
        Boolean(userChoice.parameters.engineVolume) &&
        Boolean(userChoice.parameters.enginePower) &&
        Boolean(userChoice.parameters.fuel) &&
        Boolean(userChoice.parameters.gearBox),
    });
  }, [
    userChoiceParameter?.fuel,
    userChoiceParameter?.gearBox,
    userChoiceParameter?.engineVolume,
    userChoiceParameter?.enginePower,
  ]);

  return (
    <Component>
      <Content onClick={handleClickCheckbox}>
        {!label && (
          <CustomCheckbox checked={String(value) === String(isContentSelect)} />
        )}

        {userChoiceParameter && label && (
          <CustomCheckbox
            checked={
              String(value) === String(isContentSelect) ||
              value === get(userChoiceParameter, label)
            }
          />
        )}

        <Value>{value}</Value>
      </Content>
    </Component>
  );
}

export default ValuesItem;

const Component = styled.li`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Value = styled.span`
  display: block;
  padding-left: 11px;
  font-size: 18px;
  line-height: 40px;
  color: ${(props) => props.theme.black};
`;
