import { CollapseInfoProps } from '@/components/CarModelCompareLandingWidgets/CollapseInfo/types';
import { useCarModelCompareLandingData } from '@/modules/CarModelCompareLanding/hooks';

export const useCollapseInfoData = (
  isDifferentParams?: boolean
): CollapseInfoProps[] => {
  const { modelsDetails } = useCarModelCompareLandingData();

  if (!modelsDetails || modelsDetails.length === 0) {
    return [];
  }

  const convertedData = modelsDetails
    .map(({ sectionName, sectionParams }) => ({
      title: sectionName ?? '',
      rows: sectionParams.map(({ param, value }) => ({
        name: param ?? '',
        cells: value
          ? value.split('\n').map((resultValue) => ({
              cell:
                resultValue === '+'
                  ? ''
                  : resultValue === '-'
                  ? null
                  : resultValue,
            }))
          : [],
      })),
    }))
    .filter(({ rows }) => rows.length !== 0);

  if (!isDifferentParams) {
    return convertedData;
  } else {
    const differentRows = convertedData.map((collapse) =>
      collapse.rows.filter((row) =>
        row.cells
          .map((cellIItem) => row.cells[0].cell === cellIItem.cell)
          .includes(false)
      )
    );

    return convertedData
      .map(({ title }, index) => ({
        title: title,
        rows: differentRows[index],
      }))
      .filter(({ rows }) => rows.length !== 0);
  }
};
