import React from 'react';
import styled from 'styled-components';

import { useCarModelCompareLandingContext } from '@/modules/CarModelCompareLanding/hooks';
import { CollapseTable } from '@/components/CarModelCompareLandingWidgets';

import { useCollapseTableData } from './hooks';

function CompareMobile() {
  const { isDifferentParams } = useCarModelCompareLandingContext();
  const collapseTableData = useCollapseTableData(isDifferentParams);

  if (!collapseTableData || collapseTableData.length === 0) {
    return null;
  }

  return (
    <Component>
      {collapseTableData.map((collapse, index) => (
        <CollapseItem key={index}>
          <CollapseTable {...collapse} />
        </CollapseItem>
      ))}
    </Component>
  );
}

export default CompareMobile;

const Component = styled.div``;

const CollapseItem = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
