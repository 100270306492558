import React, { useState } from 'react';

import { ServiceContext } from '@/modules/Services/ServiceContext';
import { OptionType } from '@/typings/common';

export const ServiceProvider: React.FC = ({ children }) => {
  const [selectedValue, setSelectedValue] = useState<OptionType>({
    label: '',
    value: '',
  });

  return (
    <ServiceContext.Provider value={{ selectedValue, setSelectedValue }}>
      {children}
    </ServiceContext.Provider>
  );
};
