import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { PackageTab } from '@/modules/HelpOnTheRoad/components/Main/components/Packages/components/PackagesTabs/types';

export const useTabsData = (): PackageTab[] => {
  const { packages } = useHelpOnTheRoadData();

  if (!packages || packages.length === 0) {
    return [];
  }

  return packages.map(({ shortName, isHit }, index) => ({
    label: shortName ?? '',
    value: String(index),
    isHit,
  }));
};
