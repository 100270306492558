import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { TitleProps } from './types';

export function Title({ title, isGray }: TitleProps) {
  return <Component isGray={isGray}>{title}</Component>;
}

const Component = styled.p<{ isGray?: boolean }>`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 24px;
    line-height: 30px;
  `)}

  ${({ isGray }) =>
    isGray &&
    css`
      color: ${(props) => props.theme.black200_alpha24};
    `}
`;
