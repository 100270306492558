import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';
import { Component as DealerWebHeaderComponent } from '@/layout/components/DealerWebHeader';

import HeroSlider from './components/HeroSlider';
import Models from './components/Models';
import Services from './components/Services';
import News from './components/News';
import Universe from './components/Universe';
import AboutDealer from './components/AboutDealer';
import Map from './components/Map';
import Departments from './components/Departments';
import TestDriveForm from './components/TestDriveForm';

function JetourHome() {
  return (
    <StyledLayout>
      <Component>
        <HeroSlider />
        <Models />
        <Services />
        <News />
        <Universe />
        <AboutDealer />
        <TestDriveForm />
        <Map />
        <Departments />
      </Component>
    </StyledLayout>
  );
}

export default JetourHome;

const StyledLayout = styled(Layout)`
  ${DealerWebHeaderComponent} {
    position: sticky;
    top: 0;
  }
`;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
