import React from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import NavArrow from '@/assets/svg/nav-arrow.svg';
import Picture from '@/components/Picture';

import { CarConfigurationSliderProps } from './types';

SwiperCore.use([Navigation]);

export function CarConfigurationSlider({
  images,
}: CarConfigurationSliderProps) {
  const isOneSlide = images.length <= 1;

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <Component>
      <Swiper
        slidesPerView={1}
        loop={true}
        speed={600}
        navigation={{
          prevEl: `.swiper-prev1`,
          nextEl: `.swiper-next1`,
        }}
        pagination={{
          el: '.swiper-pagination-images',
          type: 'bullets',
          clickable: true,
        }}
        noSwipingClass={'swiper-no-swiping'}
      >
        {images.length > 0 &&
          images.map((image, index) => (
            <SwiperSlide
              key={index}
              className={isOneSlide ? 'swiper-no-swiping' : ''}
            >
              <Image
                loading="lazy"
                {...convertThumbnailToPictureProps(image)}
                alt={`car configuration ${index + 1}`}
              />
            </SwiperSlide>
          ))}
      </Swiper>

      {!isOneSlide && (
        <NavButtonsWrapper>
          <NavButton className={`swiper-prev1`} disabled={isOneSlide} prev>
            <StyledNavArrow />
          </NavButton>

          <NavButton className={`swiper-next1`} disabled={isOneSlide} next>
            <StyledNavArrow />
          </NavButton>
        </NavButtonsWrapper>
      )}

      <Pagination className="swiper-pagination-images" />
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 0 70px;
  width: 100%;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Image = styled(Picture)`
  padding-top: 43.114%;

  ${media.mobile(css`
    padding-top: 55.114%;
  `)}

  picture,
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  img {
    margin: auto;
    object-fit: contain;
  }
`;

const NavButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);

  ${media.mobile(css`
    display: none;
  `)}
`;

const NavButton = styled.button<{
  next?: boolean;
  prev?: boolean;
}>`
  position: absolute;
  padding: 30px 20px;
  background-color: rgba(255, 255, 255, 0.15);
  top: 50%;
  z-index: 2;

  ${media.mobile(css`
    padding: 0;
  `)}

  ${({ next }) =>
    next &&
    css`
      right: 0;
      transform: translateY(-50%);

      ${media.mobile(css`
        right: -10px;
      `)}
    `};

  ${({ prev }) =>
    prev &&
    css`
      left: 0;
      transform: translateY(-50%) rotate(-180deg);

      ${media.mobile(css`
        left: -10px;
      `)}
    `};
`;

const StyledNavArrow = styled(NavArrow)`
  width: 23px;
  height: 45px;

  path {
    fill: #c7c8cb;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 140px);
  margin: 40px auto 0;

  ${media.mobile(css`
    width: calc(100% - 40px);
  `)}

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 100%;
    max-width: 80px;
    height: 2px;
    border-radius: 0;
    background: ${(props) => props.theme.black};

    ${media.mobile(css`
      max-width: 40px;
    `)}
  }
`;
