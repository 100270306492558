import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import ChargerList from '@/modules/ElectrocarsLanding/components/Chargers/components/ChargerCard/components/ChargerList';
import ChargerSlider from '@/modules/ElectrocarsLanding/components/Chargers/components/ChargerCard/components/ChargerSlider';

import { ChargerCardProps } from './types';

function ChargerCard({
  id,
  title,
  text,
  buttonTitle,
  chargers,
  onClick,
}: ChargerCardProps) {
  const isMobile = useMedia('(max-width: 767px)');

  return (
    <Component>
      <Content>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Actions>
          <StyledButton variant="contained" onClick={onClick}>
            {buttonTitle}
          </StyledButton>
        </Actions>
      </Content>

      <Chargers>
        {!isMobile ? (
          <ChargerList chargers={chargers} />
        ) : (
          <ChargerSlider id={id} chargers={chargers} />
        )}
      </Chargers>
    </Component>
  );
}

export default ChargerCard;

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tablet(css`
    flex-direction: column;
    align-items: initial;
  `)}

  ${media.mobile(css`
    padding-bottom: 84px;
  `)}
`;

const Content = styled.div`
  flex: 0 0 418px;
  max-width: 418px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;

  ${media.tablet(css`
    font-size: 28px;
    line-height: 33px;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: ${colors.blue600};
`;

const Actions = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
  `)}
`;

const StyledButton = styled(Button)`
  max-width: 221px;

  ${media.mobile(css`
    max-width: 280px;
  `)}
`;

const Chargers = styled.div`
  flex: 1 1 658px;
  max-width: 658px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    margin-top: 40px;
  `)}

  ${media.mobile(css`
    .swiper-slide {
      min-width: calc(100% - 40px);
      flex-shrink: initial;
    }
  `)}
`;
