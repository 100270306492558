import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Redesign/BreadCrumbs';
import Guarantees from '@/components/Redesign/Guarantees';
import { useRefitData } from '@/modules/RefitLanding/hooks';
import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/RefitLanding/common.styles';

function AboutSection() {
  const {
    title,
    path,
    templateFields: { aboutText, aboutTitle, aboutImage, doTitle, doTicks },
  } = useRefitData();

  if (!doTicks || doTicks.length === 0) {
    return null;
  }

  const ticks = doTicks.map(({ text }) => ({ text: text ?? '' }));

  return (
    <Component>
      <ContentContainer>
        <Breadcrumbs
          links={[
            { label: 'Главная', link: '/' },
            { label: title, link: path },
          ]}
        />

        <Guarantees
          title={aboutTitle ?? ''}
          text={aboutText ?? ''}
          subTitle={doTitle ?? ''}
          ticks={ticks}
          image={aboutImage}
          ticksColumns={2}
          isReverse
        />
      </ContentContainer>
    </Component>
  );
}

export default AboutSection;

const Component = styled.section`
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;
