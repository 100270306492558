import React, { useState } from 'react';
import styled from 'styled-components';

import { useUpdateEffect } from '@tager/web-core';

import { PackagesProps } from '@/components/Packages/types';
import { PackageTab } from '@/components/Packages/components/PackagesTabs/types';
import PackageCard from '@/components/Packages/components/PackageCard';
import Table from '@/components/Packages/components/Table';
import PackagesTabs from '@/components/Packages/components/PackagesTabs';
import { getTableBySelectedPackage } from '@/components/Packages/components/Mobile/helpers';

function Mobile({ packages, table }: PackagesProps) {
  const tabs: PackageTab[] = packages.map(({ title, isHit }, index) => ({
    label: title,
    value: String(index),
    isHit,
  }));

  const [selectedPackageTab, setSelectedPackageTab] = useState<PackageTab>(
    tabs[0]
  );

  useUpdateEffect(() => {
    setSelectedPackageTab(tabs[0]);
  }, [packages]);

  if (!packages || packages.length === 0 || !table || table.length === 0) {
    return null;
  }

  const selectedPackageValue = Number(selectedPackageTab.value);

  const selectedPackage = packages[selectedPackageValue];
  const selectedTable = getTableBySelectedPackage(selectedPackageValue, table);

  return (
    <Component>
      <Package>
        <PackageCard {...selectedPackage} />
      </Package>

      <Info>
        <TableWrapper>
          {selectedTable.map((table, index) => (
            <TableItem key={index}>
              <Table {...table} />
            </TableItem>
          ))}
        </TableWrapper>

        <PackagesTabsWrapper>
          <PackagesTabs
            activeTab={selectedPackageTab.value}
            tabs={tabs}
            onClick={setSelectedPackageTab}
          />
        </PackagesTabsWrapper>
      </Info>
    </Component>
  );
}

export default Mobile;

const Component = styled.div``;

const Package = styled.div`
  max-width: 285px;
`;

const Info = styled.div`
  display: flex;
  margin-top: 10px;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const TableItem = styled.div`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const PackagesTabsWrapper = styled.div`
  flex: 0 0 40px;
  max-width: 40px;
  margin-left: 10px;
`;
