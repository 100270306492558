import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/HisunHome/common.styles';
import { useHisunHomeData } from '@/modules/HisunHome/hooks';
import { media } from '@/utils/mixin';
import { AboutCardSecondary } from '@/components/LandingWidgets';

function Benefits() {
  const { benefitsTitle, benefitsItems } = useHisunHomeData();

  if (!benefitsItems || benefitsItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardSecondary
          id={5}
          title={benefitsTitle ?? ''}
          benefits={benefitsItems.map(({ image, title, description }) => ({
            image,
            title: title ?? '',
            description: description ?? '',
          }))}
        />
      </ContentContainer>
    </Component>
  );
}

export default Benefits;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;
