import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';

import { BenefitProps } from './types';

function Benefit({ image, title, text, isHorizontal }: BenefitProps) {
  return (
    <Component isHorizontal={isHorizontal}>
      <Image
        loading="lazy"
        isHorizontal={isHorizontal}
        {...convertThumbnailToPictureProps(image)}
      />

      <Content isHorizontal={isHorizontal}>
        <Title>{title}</Title>
        {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
      </Content>
    </Component>
  );
}

export default Benefit;

export const Component = styled.div<{ isHorizontal?: boolean }>`
  display: flex;
  padding: 30px 20px;
  border: 1px solid ${(props) => props.theme.gray1300_alpha50};
  border-radius: 10px;
  background: ${(props) => props.theme.white_1};

  ${({ isHorizontal }) => css`
    flex-direction: ${!isHorizontal ? 'column' : 'row'};
  `}
`;

const Image = styled(Picture)<{ isHorizontal?: boolean }>`
  align-items: flex-start;

  picture,
  img {
    width: ${(props) => (!props.isHorizontal ? '100%' : '')};
    flex: ${(props) => (!props.isHorizontal ? '' : '0 0 220px')};
    max-width: 220px;
    height: 153px;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div<{ isHorizontal?: boolean }>`
  margin-top: 30px;

  ${({ isHorizontal }) => css`
    margin: ${!isHorizontal ? '30px 0 0' : '0 0 0 30px'};
  `}
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.black200};
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};

  ul,
  ol {
    padding-left: 16px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`;
