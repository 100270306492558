import { FormikErrors } from 'formik';
import React from 'react';

import { validators } from '@tager/web-core';

import { ContactFormFields } from './types';

export const defaultContactFormFields: ContactFormFields = {
  name: '',
  phone: '',
  email: '',
  brand: '',
  model: '',
  vin: '',
};

export function handleValidateContactForm(
  values: ContactFormFields
): FormikErrors<ContactFormFields> {
  const errors: FormikErrors<ContactFormFields> = {};

  if (validators.required(values.name)) {
    errors.name = 'Введите ФИО';
  }

  if (validators.required(values.phone)) {
    errors.phone = 'Введите телефон';
  }

  if (validators.email(values.email)) {
    errors.email = 'Email введен неверно';
  }

  if (validators.required(values.email)) {
    errors.email = 'Введите email';
  }

  if (validators.required(values.vin)) {
    errors.vin = 'Введите VIN';
  }

  return errors;
}

export const isHandleChangeNumber = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const value = event.target.value;
  const reg1 = /[^a-z0-9]/gi;
  const reg2 = /[o]/gi;

  return value.replaceAll(reg2, '').replaceAll(reg1, '');
};
