import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Video from '@/components/Redesign/Video';
import CloseButton from '@/components/modals/shared/CloseButton';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import { GalleryModalProps } from './types';

function GalleryModal({ innerProps, closeModal }: GalleryModalProps) {
  const { title, subtitle, gallery } = innerProps;

  useLockBodyScroll();

  return (
    <Component>
      <CloseButton onClick={closeModal} />
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}

      <Gallery>
        {gallery.map(({ image, video }, index) =>
          image ? (
            <Image
              key={index}
              {...convertThumbnailToPictureImage(image)}
              alt="gallery image"
            />
          ) : (
            <StyledVideo key={index} src={video ?? ''} />
          )
        )}
      </Gallery>
    </Component>
  );
}

export default GalleryModal;

const Component = styled.div`
  width: calc(100vw - 40px);
  max-width: 850px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.black};
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 16px;
  `)}
`;

const Gallery = styled.div`
  margin-top: 20px;
`;

const galleryItemStyle = css`
  border-radius: 20px;
  background: ${colors.grayGradient100};
  padding-top: 58.33%;

  ${media.tablet(css`
    border-radius: 10px;
  `)}

  img, video, article, picture {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    ${media.tablet(css`
      border-radius: 10px;
    `)}
  }

  img {
    position: absolute;
    inset: 0;
  }

  &:not(:first-of-type) {
    margin-top: 20px;

    ${media.tablet(css`
      margin-top: 10px;
    `)}
  }
`;

const Image = styled(Picture)`
  ${galleryItemStyle};
`;

const StyledVideo = styled(Video)`
  ${galleryItemStyle};
`;
