import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ContentContainer } from '@/modules/CarModelCompareLanding/common.styles';
import { media } from '@/utils/mixin';

import CompareDesktop from './components/CompareDesktop';
import CompareMobile from './components/CompareMobile';

function Compare() {
  const isMobile = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <ContentContainer>
        {!isMobile ? <CompareDesktop /> : <CompareMobile />}
      </ContentContainer>
    </Component>
  );
}

export default Compare;

const Component = styled.section`
  margin: 60px 0;

  ${media.tablet(css`
    margin: 40px 0 60px;
  `)}
`;
