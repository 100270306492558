import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import FacebookIcon from '@/assets/svg/socials-amp-landing/facebook-grey.svg';
import InstagramIcon from '@/assets/svg/socials-amp-landing/instagram-grey.svg';
import YoutubeIcon from '@/assets/svg/socials-amp-landing/youtube-grey.svg';
import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';
import { colors } from '@/constants/theme';

interface Props {
  socialsTitle: Nullable<string>;
}

function Socials({ socialsTitle }: Props) {
  const {
    mapSocialFacebookLink,
    mapSocialInstagramLink,
    mapSocialYouTubeLink,
  } = useAmpLandingData();

  return (
    <SocialsContainer>
      <SocialsInformation>{socialsTitle}</SocialsInformation>
      <SocialsList>
        <SocialItem>
          <Link
            href={mapSocialFacebookLink ?? ''}
            target="_blank"
            title="Facebook"
          >
            <FacebookIcon />
          </Link>
        </SocialItem>
        <SocialItem>
          <Link
            href={mapSocialInstagramLink ?? ''}
            target="_blank"
            title="Instagram"
          >
            <InstagramIcon />
          </Link>
        </SocialItem>
        <SocialItem>
          <Link
            href={mapSocialYouTubeLink ?? ''}
            target="_blank"
            title="Youtube"
          >
            <YoutubeIcon />
          </Link>
        </SocialItem>
      </SocialsList>
    </SocialsContainer>
  );
}

const SocialsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  margin-top: 30px;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  @media (max-width: 450px) {
    max-width: 280px;
  }
`;

const SocialsInformation = styled.span`
  color: ${colors.gray};
  font-size: 16px;
  padding-right: 20px;

  @media (max-width: 700px) {
    padding: 0;
  }
`;

const SocialsList = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 180px;

  @media (max-width: 700px) {
    justify-content: space-evenly;
    align-items: center;
    margin-top: 15px;
  }
`;

const SocialItem = styled.li``;

const Link = styled.a`
  display: inline-block;
  width: 32px;

  &:hover {
    opacity: 0.6;
  }
`;

export default Socials;
