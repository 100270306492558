import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { DealerProps } from '@/components/Dealerships/Dealerships.types';
import { getPhoneLink } from '@/utils/common';

function Dealer({ name, url, phones, legalName, address }: DealerProps) {
  return (
    <Component>
      {url && <ComponentLink to={url} />}

      <Left>
        <Name>{name}</Name>
      </Left>
      <Right>
        <LegalName>{legalName}</LegalName>

        <Addresses>
          {address?.map((address, index) => (
            <Address
              key={index}
              dangerouslySetInnerHTML={{ __html: address ?? '' }}
            />
          ))}
        </Addresses>

        <Phones>
          {phones?.map((phone, index) => (
            <Phone key={index} to={getPhoneLink(phone)}>
              {phone}
            </Phone>
          ))}
        </Phones>
      </Right>
    </Component>
  );
}

export default Dealer;

const Component = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  &:not(:last-child) {
    border-bottom: solid 1px ${colors.gray600};
  }

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Name = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.main};
  border-bottom: 1px solid transparent;

  ${media.mobile(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Left = styled.div`
  flex: 0 0 370px;
  max-width: 370px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Right = styled.div`
  ${media.tablet(css`
    flex: 1 1 370px;
    max-width: 370px;
  `)}

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}
`;

const LegalName = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: ${(props) => props.theme.blue100};

  ${media.mobile(css`
    font-size: 13px;
    line-height: 19px;
    text-align: left;
  `)}
`;

const Addresses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;

  ${media.mobile(css`
    align-items: flex-start;
  `)}
`;

const Address = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: ${(props) => props.theme.blue100};

  ${media.mobile(css`
    font-size: 13px;
    line-height: 19px;
    text-align: left;
  `)}
`;

const Phones = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;

  ${media.mobile(css`
    align-items: flex-start;
  `)}
`;

const Phone = styled(Link)`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.main};
  border-bottom: 1px solid transparent;

  ${media.mobile(css`
    font-size: 14px;
    line-height: 22px;
  `)}

  &:hover {
    border-color: ${colors.main};
  }
`;
