import React from 'react';
import styled from 'styled-components';

import { useBrandsData } from '@/modules/Brands/Brands.hooks';
import HeroWithoutContent from '@/components/HeroWithoutContent';
import LinkTabs from '@/components/LinkTabs';
import { colors } from '@/constants/theme';
import Dealers from '@/modules/Brands/components/Dealers';
import Layout from '@/layout';

import BrandsBlock from './components/BrandsBlock';

function Brands() {
  const { heroImage, heroTabletImage, heroMobileImage, linksTabs } =
    useBrandsData();

  return (
    <Layout>
      <Component>
        <HeroWithoutContent
          desktopImage={heroImage}
          tabletImage={heroTabletImage}
          mobileImage={heroMobileImage}
          holding
        />
        <LinkTabs linksTabs={linksTabs} />
        <BrandsBlock />
        <Dealers />
      </Component>
    </Layout>
  );
}

export default Brands;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${colors.gray200};
`;
